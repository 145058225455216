import React from 'react';
import { InputValuesObject } from '../InputValues';
import RadioField from './RadioField';
import { Row } from 'antd';
import RangeField from './RangeField';
import TextField from './TextField';
import SelectField from './SelectField';
import MLCheckBoxField from './MLCheckBoxField';
import { ColorField, RenderUpload } from '../..';
import DatePickerField from './DatePickerField';

const RenderField = props => {
  const {
    inputType,
    id,
    label,
    choices,
    marks,
    step,
    min,
    max,
    rules,
    onChange = () => {},
    value,
    setLoad,
    defaultValue,
    tooltip
  } = props;
  const [colorLoading, setColorLoading] = React.useState(false);

  return (
    <>
      {/* Multiple */}
      {inputType === InputValuesObject.radio && (
        <RadioField name={id} label={label} choices={choices} rules={rules} tooltip={tooltip} />
      )}
      {inputType === InputValuesObject.checkbox && (
        <MLCheckBoxField name={id} label={label} rules={rules} choices={choices} tooltip={tooltip} />
      )}

      {inputType === InputValuesObject.range && (
        <RangeField
          name={id}
          label={label}
          rules={rules}
          marks={marks}
          step={step}
          defaultValue={defaultValue}
          min={min}
          max={max}
          tooltip={tooltip}
        />
      )}

      {/* Select */}
      {inputType === InputValuesObject.select && (
        <SelectField
          otherOption={true}
          name={id}
          label={label}
          choices={choices}
          rules={rules}
          tooltip={tooltip}
          offset={1}
        />
      )}

      {inputType === InputValuesObject.color && !colorLoading && (
        <>
          <ColorField
            name={id}
            label={label}
            rules={rules}
            choices={choices}
            // onChange={e => {
            //   // setColorLoading(true);
            //   e && onChange(e);
            //   // setColorLoading(false);
            // }}
            value={value}
            tooltip={tooltip}
          />
        </>
      )}

      {/* Date */}
      {inputType === InputValuesObject.date && (
        <DatePickerField
          margin={false}
          name={id}
          label={label}
          offset={1}
          format="YYYY-MM-DD"
          rules={rules}
          enabledisabledDate={false}
          onChange={e => e && onChange(e)}
          tooltip={tooltip}
        />
      )}

      {inputType === InputValuesObject.datetime && (
        <DatePickerField
          margin={false}
          name={id}
          label={label}
          offset={1}
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          rules={rules}
          enabledisabledDate={false}
          onChange={e => e && onChange(e)}
          // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
          tooltip={tooltip}
        />
      )}

      {inputType === InputValuesObject.month && (
        <DatePickerField
          margin={false}
          name={id}
          label={label}
          offset={1}
          rules={rules}
          enabledisabledDate={false}
          picker="month"
          onChange={e => e && onChange(e)}
          // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
          tooltip={tooltip}
        />
      )}

      {inputType === InputValuesObject.time && (
        <DatePickerField
          margin={false}
          name={id}
          label={label}
          offset={1}
          rules={rules}
          enabledisabledDate={false}
          type="time"
          onChange={e => e && onChange(true)}
          // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
          tooltip={tooltip}
        />
      )}

      {inputType === InputValuesObject.week && (
        <DatePickerField
          margin={false}
          name={id}
          label={label}
          offset={1}
          rules={rules}
          enabledisabledDate={false}
          picker="week"
          onChange={e => e && onChange(e)}
          // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
          tooltip={tooltip}
        />
      )}

      {/* Text */}
      {inputType === InputValuesObject.email && (
        <TextField
          name={id}
          label={label}
          rules={rules}
          offset={2}
          onBlur={e => e?.target?.value && onChange(e)}
          tooltip={tooltip}
        />
      )}

      {inputType === InputValuesObject.number && (
        <TextField
          name={id}
          label={label}
          rules={rules}
          type="number"
          offset={2}
          // onChange={e => e && handleChange(false)}
          onBlur={e => e?.target?.value && onChange(e)}
          tooltip={tooltip}
        />
      )}

      <Row>
        {inputType === InputValuesObject.text && (
          <TextField name={id} label={label} /* rules={required ? rules : []} */ tooltip={tooltip} />
        )}
      </Row>

      {/* Upload */}
      {inputType === InputValuesObject.file && (
        <RenderUpload setUrl={e => onChange} setLoad={setLoad} offset={2} name={id} label={label} tooltip={tooltip} />
      )}
    </>
  );
};

export default RenderField;
