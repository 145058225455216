import React from 'react';
import _ from 'lodash';
import { Col, Button, Spin, Modal, Form, Divider, Row, Switch, message } from 'antd';

import {
  EditIcon,
  getIntFromString,
  SelectField,
  TextField,
  CKEditorField,
  InputValues,
  InputValuesObject,
  withMarkdownFieldValue
} from '../../look';

import {
  withAddSimpleField,
  withAddSimpleFieldChoice,
  withDeleteSimpleFieldChoice,
  withEditSimpleField,
  withEditSimpleFieldChoice
} from './FormSectionOperation';
import SimpleFieldChoice from '../components/SimpleFieldChoice';
import { compose } from '../../core';

export const CKEditorFieldName = 'helpText';

const AddSimpleField = props => {
  const {
    btn,
    simpleField,
    btnText,
    addSimpleField,
    addSimpleFieldChoice,
    onSubmit,
    editSimpleField,
    editSimpleFieldChoice,
    deleteSimpleFieldChoice
  } = props;
  const [value, setValue] = React.useState(simpleField?.inputType?.toLowerCase());
  const [visible, setVisible] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);
  const [choices, setChoices] = React.useState(
    simpleField?.simplefieldchoiceSet?.edges?.map(({ node }) => ({ ...node })) || []
  );
  const [showChoices, setShowChoices] = React.useState(
    simpleField
      ? simpleField?.inputType.toUpperCase() === InputValuesObject.radio ||
          simpleField?.inputType.toUpperCase().toUpperCase() === InputValuesObject.checkbox ||
          simpleField?.inputType.toUpperCase().toUpperCase() === InputValuesObject.select
      : true
  );
  const [form] = Form.useForm();

  const handleSubmit = async values => {
    setActionLoading(true);
    try {
      if (!simpleField) {
        const response = await addSimpleField({ simpleFieldData: withMarkdownFieldValue(CKEditorFieldName, values) });
        if (response) {
          await Promise.all(
            choices.map(async simpleFieldChoiceData => {
              await addSimpleFieldChoice({
                simpleFieldChoiceData: {
                  ..._.omit(simpleFieldChoiceData, 'index'),
                  simplefieldId: getIntFromString(response.id)
                }
              });
            })
          );
          handleClose();
          onSubmit(getIntFromString(response.id));
        }
      } else {
        const response = await editSimpleField({
          ...withMarkdownFieldValue(CKEditorFieldName, values),
          id: getIntFromString(simpleField.id)
        });

        if (response) {
          await Promise.all(
            choices.map(async simpleFieldChoiceData => {
              if (simpleFieldChoiceData.id) {
                await editSimpleFieldChoice({
                  ...simpleFieldChoiceData,
                  simplefieldId: getIntFromString(response.id),
                  id: getIntFromString(simpleFieldChoiceData.id)
                });
              } else {
                await addSimpleFieldChoice({
                  simpleFieldChoiceData: {
                    ..._.omit(simpleFieldChoiceData, 'index'),
                    simplefieldId: getIntFromString(response.id)
                  }
                });
              }
            })
          );
          handleClose();
          onSubmit(getIntFromString(response.id));
        }
      }
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw Error(err.message);
    }
  };

  const handleChoiceDelete = () => {
    choices &&
      choices.map(async record => {
        if (record.id) {
          try {
            const response = await deleteSimpleFieldChoice(getIntFromString(record.id));
            response && message.success('Success!');
          } catch (e) {
            message.error('Failed!');
            throw Error(e.message);
          }
        }
      });
    setChoices([]);
    form.resetFields(['min', 'max', 'default', 'jump']);
  };

  function handleInputFieldChange(e) {
    setValue(e);
    if (
      e.toUpperCase() === InputValuesObject.radio ||
      e.toUpperCase() === InputValuesObject.checkbox ||
      e.toUpperCase() === InputValuesObject.select
    ) {
      if (value && value.toUpperCase() === InputValuesObject.range) {
        handleChoiceDelete();
      }
      setShowChoices(true);
    } else {
      setShowChoices(false);
      if (
        value &&
        (value.toUpperCase() === InputValuesObject.radio ||
          value.toUpperCase() === InputValuesObject.checkbox ||
          value.toUpperCase() === InputValuesObject.range ||
          value.toUpperCase() === InputValuesObject.select)
      ) {
        choices?.length > 0 &&
          Modal.warning({
            title: 'Are you sure?',
            content: `Changing the Input type to ${e.toUpperCase()} will delete existing options.`,
            onOk: () => {
              handleChoiceDelete();
            },
            onCancel: () => {
              form.setFieldsValue(_.set(form.getFieldsValue(true), 'inputType', value));
              setValue(value);
            },
            closable: true
          });
      }
    }
  }

  const handleChange = (value, index) => {
    if (choices.length > 0) {
      const arr = choices[0]?.value?.split(':');
      arr[index] = value;
      setChoices([
        {
          id: choices[0].id,
          value: arr?.join(':'),
          label: ''
        }
      ]);
    } else {
      const arr = `0:0:0:0`.split(':');
      arr[index] = value;
      setChoices([
        {
          value: arr?.join(':'),
          label: ''
        }
      ]);
    }
  };

  const handleClose = params => {
    setVisible(false);
    setChoices([]);
    setValue(null);
    form.resetFields();
  };

  const handleVisible = params => {
    setVisible(true);
    setChoices(simpleField?.simplefieldchoiceSet?.edges?.map(({ node }) => ({ ...node })) || []);
    setValue(simpleField?.inputType?.toLowerCase());
  };

  const initialValue = {
    label: simpleField?.label,
    editable: String(simpleField?.editable) === 'false' ? false : true,
    // sequence: simpleField?.sequence,
    inputType: simpleField?.inputType?.toLowerCase(),
    helpText: simpleField?.helpText,

    ...(simpleField?.inputType === InputValuesObject.range
      ? {
          min: choices[0]?.value.split(':')[0],
          max: choices[0]?.value.split(':')[1],
          jump: choices[0]?.value.split(':')[2],
          default: choices[0]?.value.split(':')[3]
        }
      : {})
  };
  return (
    <>
      {btn === 'add' ? (
        <Button type="primary" ghost onClick={handleVisible}>
          Add Simple Field
        </Button>
      ) : (
        <EditIcon type="link" tooltipSuffix="Simple Field" size="sm" onClick={handleVisible} />
      )}

      <Modal
        destroyOnClose
        centered
        visible={/* true || */ visible || window.location.href.includes('openmodal')}
        onCancel={handleClose}
        // footer={null}
        width="50%"
        title={btn === 'add' ? 'Add Simple Field' : 'Edit Simple Field'}
        footer={null}
      >
        <Spin spinning={actionLoading} size="medium">
          <Form
            form={form}
            layout="vertical"
            initialValues={initialValue}
            onFinish={e =>
              handleSubmit(
                e?.inputType?.toUpperCase() === InputValuesObject.range ? _.omit(e, ['min', 'max', 'default', 'jump']) : e
              )
            }
            scrollToFirstError={true}
          >
            <Row gutter={24}>
              <Col lg={12} md={24}>
                <TextField name="label" label="Label" rules={[{ required: true, message: 'Label is required!' }]} />
              </Col>

              <Col lg={12} md={24}>
                <SelectField
                  name="inputType"
                  label="Input Type"
                  choices={InputValues}
                  offset={1}
                  rules={[{ required: true, message: 'Input Value is required!' }]}
                  onChange={handleInputFieldChange}
                />
              </Col>

              <Col span={24}>
                <Row>
                  <Col lg={12} md={24}>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24, offset: 1 }}
                      name="editable"
                      label="Editable"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                  {value === 'range' && (
                    <Row>
                      <Col lg={24} md={24}>
                        <h4>Range Configuration: </h4>
                      </Col>

                      <Col lg={6} md={24}>
                        <TextField
                          name="min"
                          label="Min"
                          offset={1}
                          rules={[{ required: true, message: 'Value is required!' }]}
                          onChange={e => handleChange(e?.target?.value, 0)}
                        />
                      </Col>

                      <Col lg={6} md={24}>
                        <TextField
                          name="max"
                          label="Max"
                          offset={1}
                          rules={[{ required: true, message: 'Value is required!' }]}
                          onChange={e => handleChange(e?.target?.value, 1)}
                        />
                      </Col>

                      <Col lg={6} md={24}>
                        <TextField
                          name="jump"
                          label="Jump"
                          offset={1}
                          rules={[{ required: true, message: 'Value is required!' }]}
                          onChange={e => handleChange(e?.target?.value, 2)}
                        />
                      </Col>

                      <Col lg={6} md={24}>
                        <TextField
                          name="default"
                          label="Default"
                          offset={1}
                          rules={[{ required: true, message: 'Value is required!' }]}
                          onChange={e => handleChange(e?.target?.value, 3)}
                        />
                      </Col>
                    </Row>
                  )}
                </Row>
              </Col>

              <Col span={24}>
                <CKEditorField
                  name={CKEditorFieldName}
                  label="Help Text"
                  initialValue={initialValue && initialValue.helpText}
                  // rules={[{ required: true, message: 'All questions are required!' }]}
                />
              </Col>
              <Col span={24}>
                {showChoices && (
                  <SimpleFieldChoice
                    choices={choices}
                    setChoices={setChoices}
                    onSubmit={values => {
                      setChoices([...choices, values]);
                    }}
                  />
                )}
              </Col>
            </Row>
            <Divider />
            <Row /* justify="space-between" */>
              <Col>
                <Button type="primary" htmlType="submit">
                  {btnText}
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default compose(
  withAddSimpleField,
  withAddSimpleFieldChoice,
  withEditSimpleField,
  withEditSimpleFieldChoice,
  withDeleteSimpleFieldChoice
)(AddSimpleField);
