import React from 'react';
import gql from 'graphql-tag';
import { message, Spin } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

import * as ROUTE from '../../../organization/route';

import SelectField from './SelectField';
import { getIntFromString } from '../functions';

export const VERTICAL_BY_VERTICAL_Id_QUERY = gql`
  query verticalByVerticalId($verticalId: ID!) {
    verticalByVerticalId(verticalId: $verticalId) {
      id
      name
      description
      employees{
        edges {
          cursor
          node {
            id
            user {
              id
              firstName
              lastName
              username
            }
          }
        }
      }
    }
  }
`;

const TeamManagerField = props => {
  const { verticalByVerticalId, loading, deleteEmployee } = props;
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const handleDelete = async id => {
    setDeleteLoading(true);
    try {
      const response = await deleteEmployee(id);
      response ? message.success('Action Successfull!') : message.error('Cannot delete this employee!');
    } catch (e) {
      message.error('Action Failed!');
      throw new Error(e.message);
    }
    setDeleteLoading(false);
  };
  const choices =
    verticalByVerticalId &&
    verticalByVerticalId.employees &&
    verticalByVerticalId.employees.edgeCount > 0 &&
    verticalByVerticalId.employees.edges.map(({ node }) => ({
      label: node.user.firstName && node.user.lastName ? `${node.user.firstName} ${node.user.lastName}` : node.user.username,
      value: getIntFromString(node.id)
    }));

  return (
    <Spin spinning={loading || deleteLoading} size="small">
      {verticalByVerticalId && (
        <SelectField
          onAdd={ROUTE.addEmployee}
          onEdit={ROUTE.editEmployeeLink}
          // onDelete={handleDelete}
          {...props}
          choices={choices}
        />
      )}
    </Spin>
  );
};

export default graphql(VERTICAL_BY_VERTICAL_Id_QUERY, {
  options: props => {
    return {
      variables: {
        verticalId: props.verId
      },
      fetchPolicy: 'network-only'
    };
  },
  props({ data: { loading, error, verticalByVerticalId, subscribeToMore, updateQuery } }) {
    return { loading, error, verticalByVerticalId, subscribeToMore, updateQuery };
  }
})(TeamManagerField);
