import React from 'react';
import AdminLayout from './AdminLayout';
import { Row, Col, Button, Table, Upload, message, Progress, Spin } from 'antd';
import { PlusOutlined, InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { Helmet } from 'react-helmet';

const renderMetaData = () => (
  <Helmet
    script={[{ type: 'text/javascript', src: 'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.15.3/xlsx.full.min.js' }]}
  />
);

const AddFromXLSLayout = props => {
  const { title, active, dataMutation, columns, expandable, fileColumns } = props;
  const [data, setData] = React.useState(null);
  const [succeededData, setSucceededData] = React.useState([]);
  const counterRef = React.useRef(null)
  const [failedData, setFailedData] = React.useState([]);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async () => {
    if (data && data.length > 0) {
      setSubmitLoading(true);
      const results = await Promise.allSettled(
        data.map(async (d, i) => {
          const response = await dataMutation(d);
          return !response.errors
            ? { success: !response.errors, ...d, errors: response.errors }
            : { success: !response.errors, ...d, errors: response.errors };
        })
      );

      setData(results.map((k, i) => {
        counterRef.current = i + 1

        return (
          k.value || { success: false, ...data[i], errors: k.reason }
        )
      }));
      setSucceededData(results.filter(k => k.status === 'fulfilled' && !k?.value?.errors));
      setFailedData(results.filter(k => k.status === 'rejected' || k?.value?.errors));
      setSubmitLoading(false);
      message.success('Finished!');
    }
  };
  const uploadProps = {
    accept: '.xls,.xlsx',
    multiple: false,
    beforeUpload: file => {
      if (
        !(
          file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'application/vnd.ms-excel'
        )
      ) {
        message.error(`${file.name} is not a xlsx or xls file`);
      }

      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        var data = event.target.result;

        var workbook = window.XLSX.read(data, {
          type: 'binary'
        });
        workbook.SheetNames.forEach(sheet => {
          const rowObject = window.XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
          const removeLeadingTralingSpaces = JSON.parse(JSON.stringify(rowObject).replace(/"\s+|\s+"/g, '"'));
          const replaceSpaceWithUnderScore = removeLeadingTralingSpaces.map(o => {
            let obj = {};
            Object.keys(o).map(k => (obj[k] = String(o[k]).split(' ').join('_')));
            Object.keys(obj).map((key, index) => {
              switch (obj[key]) {
                case 'true':
                  obj[key] = true;
                  break;
                case 'false':
                  obj[key] = false;
                  break;
                default:
              }
            });
            return obj;
          });
          setData(replaceSpaceWithUnderScore);
          setSucceededData(0);
          setFailedData(0);
        });
      };
      fileReader.readAsBinaryString(file);

      return file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
        ? true
        : Upload.LIST_IGNORE;
    },
    maxCount: 1,
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    },
    style: { width: '100%' }
  };

  return (
    <>
      {renderMetaData()}
      <Spin spinning={submitLoading} size={'large'}>
        <AdminLayout
          table={false}
          active={active}
          title={`Add Multiple ${title}`}
          FormComponent={
            <Row>
              <Col span={23}>
                <Row justify="center" align="middle" gutter={[24, 24]}>
                  <Col span={12}>
                    <table style={{ width: '100%' }}>
                      <tr>
                        <td>{title} to add :</td>
                        <td> &nbsp;{(data && data.length) || '0'}</td>
                      </tr>
                      <tr>
                        <td>{title} Succeeded to add :</td>
                        <td> &nbsp;{(succeededData && succeededData.length) || '0'}</td>
                      </tr>
                      <tr>
                        <td>{title} Failed to add :</td>
                        <td> &nbsp;{(failedData && failedData.length) || '0'}</td>
                      </tr>
                    </table>
                    <br />
                    <br />
                    <br />
                    <Button size="md" block type="primary" ghost onClick={handleSubmit}>
                      <PlusOutlined />
                      Add
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Dragger {...uploadProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint" style={{ padding: '0 12px' }}>
                        {`The file containing user data should be of type 'xls', or 'xlsx'. The file should contain column names
                      as ${fileColumns}. Uploading is limited to a single file.`}
                      </p>
                    </Dragger>
                  </Col>
                  <Col span={24}>
                    {submitLoading !== null && <br />}
                    {/* <Progress type="circle" percent={counterRef.current * 10} /> */}
                    {/* (counterRef.current / (data.length * 1.0)) * 100 */}
                    <Table
                      // loading={submitLoading}
                      size="small"
                      expandable={expandable}
                      // rowSelection={}
                      hasData={data}
                      pagination={{
                        size: 'small'
                        // pageSize: 2
                      }}
                      columns={columns}
                      dataSource={submitLoading !== null ? data /* ||  DATA */ : []}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        />
      </Spin>
    </>
  );
};

export default AddFromXLSLayout;
