import React from 'react';
import { Modal } from 'antd';

import { compose } from '../../core';
import { getIntFromString } from '../../look';
import { withDeleteCondition } from '../../text-section/containers/TextSectionOperations';

import CommitmentFormComponent from './CommitmentFormComponent';
import { withDeleteCoachingCommitment } from '../../dashboard/containers/DashboardOperations';

const AddCommitmentView = props => {
  const { visible, setVisible, title = 'Add Commitment', getCommitmentById, deleteCondition } = props;
  const [data, setData] = React.useState(getCommitmentById?.condition?.edges || 0);
  const [coachingCommitmentData, setCoachingCommitmentData] = React.useState(
    getCommitmentById?.coachingcommitmentSet?.edges?.map(({ node }, index) => ({ node: { ...node, index } })) || 0
  );

  React.useEffect(() => {
    if (visible) {
      setData(getCommitmentById?.condition?.edges || []);
      setCoachingCommitmentData(getCommitmentById?.coachingcommitmentSet?.edges || []);
    }
  }, [visible, getCommitmentById]);

  async function handleCancel() {
    const ids = [];
    if (data && getCommitmentById?.condition?.edges?.length > 0) {
      data.map(({ node: d }) => {
        const com = getCommitmentById?.condition?.edges?.filter(({ node }) => node.id === d.id);
        if (com.length === 0) ids.push(d.id);
      });
    }

    if (getCommitmentById?.condition?.edges?.length > 0 ? ids.length > 0 : data.length > 0) {
      try {
        (getCommitmentById?.condition?.edges?.length > 0 ? ids : data?.map(({ node }) => node.id)).map(id => {
          deleteCondition(getIntFromString(id));
        });
      } catch (err) {
        throw Error(err.message);
      }
    }
    setVisible(false);
    setData([]);
    setCoachingCommitmentData([]);
  }

  return (
    <Modal
      destroyOnClose
      centered
      visible={props.visible || window.location.href.includes('openmodal')}
      onCancel={handleCancel}
      // footer={null}
      title={title}
      width="75%"
      footer={null}
    >
      <CommitmentFormComponent
        handleCancel={handleCancel}
        data={data}
        setData={setData}
        coachingCommitmentData={coachingCommitmentData}
        setCoachingCommitmentData={setCoachingCommitmentData}
        setVisible={setVisible}
        {...props}
      />
    </Modal>
  );
};

export default compose(withDeleteCondition, withDeleteCoachingCommitment)(AddCommitmentView);
