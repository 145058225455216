import React from 'react';
import { Spin } from 'antd';

import AddUserView from '../components/AddUserView';
import ROUTE from '../route';

import { withRegister } from './UserOperations';
import { ButtonsCatch } from '../../look';

const AddUser = props => {
  const { history, register } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    try {
      const response = await register(values);

      response && ButtonsCatch(item, ROUTE.user, history);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddUserView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default withRegister(AddUser);
