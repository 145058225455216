import gql from 'graphql-tag';

export const CONTACT_REQUEST_TYPE = gql`
  fragment ContactRequestType on ContactRequestType {
    id
    email
    name
    done
    message
    requestDate
  }
`;
