import moment from 'moment-timezone';
import { getSyncItem } from "../../modules/core/clientStorage";

export { default as Loading } from './components/Loading';
export * from './components/functions';
export { default as AdminLayout } from './components/AdminLayout';
export { default as CardLayout } from './components/CardLayout';
export { default as AddFromXLSLayout } from './components/AddFromXLSLayout';
export { default as MetaTags } from './components/MetaTags';
export { default as UserLayout } from './components/UserLayout';
export { default as DeleteIcon } from './components/DeleteIcon';
export { default as AddIcon } from './components/AddIcon';
export { default as EditIcon } from './components/EditIcon';
export { default as RenderTableWithLayout } from './components/RenderTableWithLayout';
export { default as RenderTable } from './components/RenderTable';
export { default as BackButton } from './components/BackButton';
export { default as ReactMarkdown } from './components/ReactMarkdown';
export { default as SelectFilterField } from './components/SelectFilterField';
export { default as Pagination } from './components/Pagination';
export { default as InputValues } from './components/InputValues';
export { InputValuesObject } from './components/InputValues';

// form-components/
export { default as MLCheckBoxField } from './components/form-components/MLCheckBoxField';
export { default as MLSelectField } from './components/form-components/MLSelectField';
export { default as RadioField } from './components/form-components/RadioField';
export { default as RangeField } from './components/form-components/RangeField';
export { default as SelectField } from './components/form-components/SelectField';
export { default as TextField } from './components/form-components/TextField';
export { default as SwitchField } from './components/form-components/SwitchField';
export { default as TransferField } from './components/form-components/TransferField';
export { default as EmployeeField } from './components/form-components/EmployeeField';
export { default as OrganizationField } from './components/form-components/OrganizationField';
export { default as UsersField } from './components/form-components/UserField';
export { default as VerticalField } from './components/form-components/VerticalField';
export { default as DatePickerField } from './components/form-components/DatePickerField';
export { default as CheckBoxField } from './components/form-components/CheckBoxField';
export { default as SurveyField } from './components/form-components/SurveyField';
export { default as TeamField } from './components/form-components/TeamField';
export { default as UserField } from './components/form-components/UserField';
export { default as TagField } from './components/form-components/TagField';
export { default as TagAutoComplete } from './components/form-components/TagAutoComplete';
export { default as UserTranferField } from './components/form-components/UserTranferField';
export { default as MarkdownField } from './components/form-components/MarkdownField';
export { default as CKEditorField } from './components/form-components/CKEditorField';
export { default as GraphicsField } from './components/form-components/GraphicsField';
export { default as ColorField } from './components/form-components/ColorField';
export { default as RenderField } from './components/form-components/RenderField';

export { ButtonsComponent, ButtonsCatch } from './components/form-components/ButtonsComponent';
export { default as UserAutoComplete } from './components/form-components/UserAutoComplete';
export { default as SurveyAutoComplete } from './components/form-components/SurveyAutoComplete';
export { default as SurveyAutoCompleteEndUser } from './components/form-components/SurveyAutoCompleteEndUser';
export { default as QuestionAutoComplete } from './components/form-components/QuestionAutoComplete';
export { default as ResponseAutoComplete } from './components/form-components/ResponseAutoComplete';
export { default as ConditionAutoComplete } from './components/form-components/ConditionAutoComplete';
export { default as SimpleFieldAutoComplte } from './components/form-components/SimpleFieldAutoComplte';
export { default as ReferenceFieldAutoComplete } from './components/form-components/ReferenceFieldAutoComplete';
export { default as FormSectionAutoComplete } from './components/form-components/FormSectionAutoComplete';
export { default as OrganizationAutoComplete } from './components/form-components/OrganizationAutoComplete';
export { default as SubverticalAutoComplete } from './components/form-components/SubverticalAutoComplete';
export { default as ToolCommitmentAutoComplete } from './components/form-components/ToolCommitmentAutoComplete';
export { default as EmployeeTransfer } from './components/form-components/EmployeeTransfer';
export { default as OrganizationTransfer } from './components/form-components/OrganizationTransfer';
export { default as VerticalTransferField } from './components/form-components/VerticalTransferField';
export { default as TagTransfer } from './components/form-components/TagTransfer';
export { default as VerticalHeadField } from './components/form-components/VerticalHeadField';
export { default as AddSubVerticalField } from './components/form-components/AddSubVerticalField';
export { default as TeamEmployeeTranferField } from './components/form-components/TeamEmployeeTranferField';
export { default as TeamManagerField } from './components/form-components/TeamManagerField';
export { default as VerticalEmployeeTransfer } from './components/form-components/VerticalEmployeeTransfer';
export { default as TeamEmployeeField } from './components/form-components/TeamEmployeeField';
export { default as RenderUploadWithCrop } from './components/form-components/RenderUploadWithCrop';
export { default as RenderUpload } from './components/form-components/RenderUpload';
export { default as OperandAutoComplete } from './components/form-components/OperandAutoComplete';
export { default as WeightedQuestionsAutoComplete } from './components/form-components/WeightedQuestionsAutoComplete';
export { default as OperandField } from './components/form-components/OperandField';
export { default as ErrorBoundary } from './components/ErrorBoundary';
export function getMomentUtcToUserTimezone(date) {
    return moment.utc(date).tz(getSyncItem("userRequestedTimezone") || Intl.DateTimeFormat().resolvedOptions().timeZone);
  }
export function getMomentUserTimezoneWithFormat(date, dateFormat) {
    return moment(date).tz(getSyncItem("userRequestedTimezone") || Intl.DateTimeFormat().resolvedOptions().timeZone).format(dateFormat);
  }
