import React from 'react';
import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import { RightCircleOutlined, MenuOutlined, DownCircleOutlined } from '@ant-design/icons';
import { Tooltip, Divider, Button, Row, Col, Table, message } from 'antd';
import { compose } from '../../core';
import styled from 'styled-components';
import {
  AdminLayout,
  displayDataCheck,
  displayBoolean,
  getIntFromString,
  GetColumnSearchProps,
  getOrderBy,
  EditIcon,
  AddIcon,
  DeleteIcon,
  ReactMarkdown
} from '../../look';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import ROUTE from '../route';
import {
  withAllGroupsForSurveyBySurveyIdQuery,
  withDeleteGroup,
  withSurveyState,
  withSurveyFilterUpdating,
  withEditGroup
} from './SurveyOperations';
import { subscribeToGroup } from './SurveySubscriptions';
import QuestionTable from './QuestionTable';

const Group = props => {
  const {
    loading,
    allGroupsForSurveyBySurveyId,
    onPaginationChange,
    match,
    deleteGroup,
    onNameChange,
    onLabelChange,
    onOrderByChange,
    orderBy,
    // onHelpTextChange,
    onSequenceChange,
    onFiltersRemove,
    subscribeToMore,
    editGroup,
    SurveyById
  } = props;
  const [sequenceLoading, setSequenceLoading] = React.useState(false);
  const [allowDraggable, setAllowDraggable] = React.useState(true);
  const [dataSource, setDataSource] = React.useState([]);
  const components = true;

  React.useEffect(() => {
    const subscribe = subscribeToGroup(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  React.useEffect(() => {
    if (components) {
      const edges = [];
      SurveyById?.groupSet &&
        SurveyById.groupSet.edges.length > 0 &&
        SurveyById.groupSet.edges.map(({ node }, index) => {
          edges.push({ ...node, index: index + 1 });
        });
      setDataSource(edges);
    } else {
      setDataSource(SurveyById?.groupSet);
    }
  }, [components, SurveyById]);

  const handleDelete = async id => {
    setSequenceLoading(true);
    try {
      await deleteGroup(id);
      message.success('Deleted!');
    } catch (e) {
      message.error('Failed, try again!');
      throw new Error(e.message);
    }
    setSequenceLoading(false);
  };

  const onSort = async sortedItems => {
    setSequenceLoading(true);
    try {
      await Promise.all(
        sortedItems.map(async i => {
          // const response = await
          editGroup({ id: getIntFromString(i.id), name: i.name, sequence: i.index + 1 });
        })
      );
      setSequenceLoading(false);
    } catch (e) {
      setSequenceLoading(false);
      throw Error(e.message);
    }
  };

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999', margin: '-16px', padding: '16px' }} />
  ));

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Name',
      key: 'name',
      className: 'drag-visible',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onNameChange),
      render: (text, record) => record && displayDataCheck(record.name)
    },
    {
      title: 'Label',
      key: 'label',
      sorter: () => onOrderByChange(getOrderBy('label', orderBy)),
      ...GetColumnSearchProps('label', onLabelChange),
      render: (text, record) => record && <ReactMarkdown>{displayDataCheck(record.label)}</ReactMarkdown>
    },
    {
      title: 'Link',
      key: 'link',
      align: 'right',
      render: (text, record) => (
        <>
          <AddIcon
            type="link"
            size="sm"
            tooltipSuffix="Question"
            /* target="_blank" */ href={`${ROUTE.addQuestionLink}${getIntFromString(record.id)}`}
          />
          <Divider type="vertical" />
          <EditIcon
            type="link"
            tooltipSuffix="Group"
            size="sm"
            /* target="_blank" */ href={`${ROUTE.editGroupLink}${getIntFromString(record.id)}`}
          />
          <Divider type="vertical" />
          <DeleteIcon size="md" tooltipSuffix="Group" onClick={() => handleDelete(getIntFromString(record.id))} />
        </>
      )
    }
  ];

  const rowKey = 'index';
  const SortableItem = sortableElement(props => <tr {...props} />);
  const SortableContainer = sortableContainer(props => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      const indexData = [];
      newData.map((node, index) => {
        const data = newData[index];
        indexData.push({ ...data, index: index });
      });
      onSort && onSort(indexData);
      setDataSource(indexData);
    }
  };

  const DraggableContainer = props => (
    <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource && dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const dragable = allowDraggable
    ? {
        components: {
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }
      }
    : {};

  return (
    <>
      <MyTable
        size={!(SurveyById && SurveyById.groupSet && SurveyById && SurveyById.groupSet.totalCount) && 'middle'}
        loading={loading || sequenceLoading}
        rowKey={record => (rowKey === 'id' ? getIntFromString(record[rowKey]) : record[rowKey])}
        showHeader={false}
        dataSource={
          dataSource
            ? components
              ? dataSource.sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
              : dataSource.edges && dataSource.edges.map(({ node }) => node)
            : []
        }
        // bordered
        columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
        expandable={{
          expandedRowRender: (record, index, indent, expanded) => (
            <QuestionTable
              record={record}
              index={index}
              indent={indent}
              expanded={expanded}
              setSequenceLoading={setSequenceLoading}
            />
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            record.questionSet.edgeCount !== 0 ? (
              expanded ? (
                <DownCircleOutlined
                  onClick={e => {
                    setAllowDraggable(true);
                    onExpand(record, e);
                  }}
                  style={{ fontSize: 18, display: 'flex', alignSelf: 'center', color: '#CACACA' }}
                />
              ) : (
                <RightCircleOutlined
                  onClick={e => {
                    setAllowDraggable(false);
                    onExpand(record, e);
                  }}
                  style={{ fontSize: 18, display: 'flex', alignSelf: 'center', color: '#CACACA' }}
                />
              )
            ) : null
        }}
        {...dragable}
      />
    </>
  );
};

export default compose(withSurveyState, withDeleteGroup, withEditGroup, withSurveyFilterUpdating)(Group);

export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
