import React from 'react';
import _ from 'lodash';
import { Spin } from 'antd';
import { compose } from '../../core';
import { useHistory } from 'react-router-dom';
import AddVerticalView from '../components/AddVerticalView';
import ROUTE from '../route';
import { getIntFromString, ButtonsCatch, withMarkdownFieldValue } from '../../look';

import { /* withVerticals, */ withAddVertical, withOrganizationState } from './OrganizationOperation';
import { CKEditorFieldName } from '../components/VerticalFormComponent';

const AddVertical = props => {
  const { addVertical, setVisible, orgId } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const history = useHistory();

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    const heads = _.get(values, 'heads');
    const input = _.omit(values, ['heads', 'employees']);
    // const employees = (({ employees }) => ({ employees }))(values);
    // console.log(values.employees);

    try {
      const response = await addVertical({
        employees: values.employees,
        heads,
        verticalData: { ...withMarkdownFieldValue(CKEditorFieldName, { ...input, organizationId: orgId }) }
      });
      response && setVisible(false);
      response && window.location.href.includes('closeonsave') && window.close();
      response &&
        ButtonsCatch(
          item,
          window.location.pathname,
          history,
          item === undefined
            ? `${ROUTE.editVerticalLink}${getIntFromString(response.id)}`
            : `${window.location.pathname}?args=openmodal`
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddVerticalView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withOrganizationState, /* withVerticals, */ withAddVertical)(AddVertical);
