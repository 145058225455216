import gql from 'graphql-tag';
import { COACHING_COMMITMENT_PROGRESS_TYPE } from '../../dashboard/graphql/CoachingCommitmentProgressType.gql';

export const GET_MY_COACH_COMMITMENTS_PROGRESS = gql`
  ${COACHING_COMMITMENT_PROGRESS_TYPE}
  query getMyCoachingCommitmentsProgress($orderBy: [String], $offset: Int, $first: Int, $forUser: Float) {
    getMyCoachingCommitmentsProgress(orderBy: $orderBy, offset: $offset, first: $first, forUser_Id: $forUser) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...CoachingCommitmentProgressType
        }
        cursor
      }
      edgeCount
      totalCount
    }
  }
`;
