import ManagerDashboard from './containers/ManagerDashboard';

import ROUTE from './route';

export default [
  {
    name: 'Manager Dashboard',
    path: ROUTE.manager,
    component: ManagerDashboard,
    protect: true
  }
];
