import { graphql } from '@apollo/client/react/hoc';

import { removeTypename } from '../../core/clientStorage';
import { withOperation } from '../../look';

import dashboardResolvers from '../resolvers';

// Query
import { PERCEPTION_SURVEY_REPORT } from '../graphql/PerceptionSurveyReport.gql';
import { PREDICTION_SURVEY_REPORT } from '../graphql/PredictionSurveyReport.gql';
import { MY_RESILIENCE_PULSE_CHECK } from '../graphql/MyResiliencePulseCheck.gql';
import { MY_TEAMS_RESILIENCE_PULSE_CHECK } from '../graphql/MyTeamsResiliencePulseCheck.gql';
import { MY_VERTICALS_RESILIENCE_PULSE_CHECK } from '../graphql/MyVerticalsResiliencePulseCheck.gql';
import { MY_ORGANIZATION_RESILIENCE_PULSE_CHECK } from '../graphql/MyOrganizationResiliencePulseCheck.gql';
import { TEAMS_RESILIENCE_PULSE_CHECK } from '../graphql/TeamsResiliencePulseCheck.gql';
import { MY_RESILIENCE_ANCHORS } from '../graphql/MyResilienceAnchors.gql';

import { GET_ALL_COACHING_COMMITMENTS } from '../graphql/GetAllCoachingCommitments.gql';
import { GET_ALL_DEFAULT_COMMITMENTS_PROGRESS } from '../graphql/GetAllDefaultCommitmentsProgress.gql';
import { GET_ALL_CUSTOM_USER_COMMITMENTS } from '../graphql/GetAllCustomUserCommitments.gql';
import { ALL_COMPARISON_REQUEST_FOR_USER } from '../graphql/AllComparisonRequestForUser.gql';
import { ALL_COMPARISON_REQUEST_FROM_USER } from '../graphql/AllComparisonRequestFromUser.gql';

// Client
import { DASHBOARD_STATE_QUERY } from '../graphql/DashboardStateQuery.client.gql';
import { UPDATE_DASHBOARD_FILTER } from '../graphql/UpdateDashboardFilter.client.gql';

// Mutation
import { ADD_CUSTOM_USER_COMMITMENT } from '../graphql/AddCustomUserCommitment.gql';
import { ADD_COACHING_COMMITMENT } from '../graphql/AddCoachingCommitment.gql';
import { ADD_SELECT_DEFAULT_TOOL_COMMITMENT } from '../graphql/AddSelectDefaultToolCommitment.gql';
import { ADD_RESPONSE_COMPARE_REQUEST } from '../graphql/AddResponseCompareRequest.gql';
import { ADD_COACHING_COMMITMENT_PROGRESS } from '../graphql/AddCoachingCommitmentProgress.gql'

import { EDIT_DEFAULT_TOOL_COMMITMENT } from '../graphql/EditDefaultToolCommitment.gql';
import { EDIT_CUSTOM_USER_COMMITMENT } from '../graphql/EditCustomUserCommitment.gql';
import { EDIT_COACHING_COMMITMENT } from '../graphql/EditCoachingCommitment.gql';
import { RESPONSE_COMPARE_REQUEST } from '../graphql/RespondToResponseCompareRequest.gql';

import { DELETE_DEFAULT_TOOL_COMMITMENT } from '../graphql/DeleteDefaultToolCommitment.gql';
import { DELETE_CUSTOM_USER_COMMITMENT } from '../graphql/DeleteCustomUserCommitment.gql';
import { DELETE_RESPONSE_COMPARE_REQUEST } from '../graphql/DeleteResponseCompareRequest.gql';
import { DELETE_COACHING_COMMITMENT } from '../graphql/DeleteCoachingCommitment.gql';
import { DELETE_COACHING_COMMITMENT_PROGRESS } from '../graphql/DeleteCoachingCommitmentProgress.gql'

// import { ADD_STORY } from '../graphql/AddStory.gql';

// Query
export const withTheirPerceptionSurveyReport = Component =>
  graphql(PERCEPTION_SURVEY_REPORT, {
    options: ({ requestedUserId }) => {
      return {
        variables: { requestedUserId }
      };
    },
    props({ data: { loading, error, getReportDataForRequestedUsersPerceptionSurvey, subscribeToMore, updateQuery } }) {
      return {
        loading,
        error,
        theirReport:
          getReportDataForRequestedUsersPerceptionSurvey && JSON.parse(getReportDataForRequestedUsersPerceptionSurvey),
        subscribeToMore,
        updateQuery
      };
    }
  })(Component);

export const withTheirPredictionSurveyReport = Component =>
  graphql(PREDICTION_SURVEY_REPORT, {
    options: ({ requestedUserId }) => {
      return {
        variables: { requestedUserId }
      };
    },
    props({ data: { loading, error, getReportDataForRequestedUsersPredictionSurvey, subscribeToMore, updateQuery } }) {
      return {
        loading,
        error,
        theirReport:
          getReportDataForRequestedUsersPredictionSurvey && JSON.parse(getReportDataForRequestedUsersPredictionSurvey),
        subscribeToMore,
        updateQuery
      };
    }
  })(Component);

export const withMyResiliencePulseCheck = Component =>
  graphql(MY_RESILIENCE_PULSE_CHECK, {
    props({ data }) {
      const { loading, error, myResiliencePulseCheck, subscribeToMore, updateQuery } = data;
      return { loading, error, myResiliencePulseCheck, subscribeToMore, updateQuery };
    }
  })(Component);

export const withMyTeamsResiliencePulseCheck = Component =>
  graphql(MY_TEAMS_RESILIENCE_PULSE_CHECK, {
    props({ data }) {
      const { loading, error, myTeamsResiliencePulseCheck, subscribeToMore, updateQuery } = data;
      return { loading, error, myTeamsResiliencePulseCheck, subscribeToMore, updateQuery };
    }
  })(Component);

export const withMyVerticalResiliencePulseCheck = Component =>
  graphql(MY_VERTICALS_RESILIENCE_PULSE_CHECK, {
    props({ data }) {
      const { loading, error, myVerticalResiliencePulseCheck, subscribeToMore, updateQuery } = data;
      return { loading, error, myVerticalResiliencePulseCheck, subscribeToMore, updateQuery };
    }
  })(Component);

export const withMyOrganizationResiliencePulseCheck = Component =>
  graphql(MY_ORGANIZATION_RESILIENCE_PULSE_CHECK, {
    props({ data }) {
      const { loading, error, myOrganizationResiliencePulseCheck, subscribeToMore, updateQuery } = data;
      return { loading, error, myOrganizationResiliencePulseCheck, subscribeToMore, updateQuery };
    }
  })(Component);

export const withTeamsResiliencePulseCheck = Component =>
  graphql(TEAMS_RESILIENCE_PULSE_CHECK, {
    props({ data }) {
      const { loading, error, reportingTeamsResiliencePulseCheck, subscribeToMore, updateQuery } = data;
      return { loading, error, reportingTeamsResiliencePulseCheck, subscribeToMore, updateQuery };
    }
  })(Component);

export const withMyResilienceAnchors = Component =>
  graphql(MY_RESILIENCE_ANCHORS, {
    props({ data }) {
      const { loading, error, myResilienceAnchors, subscribeToMore, updateQuery } = data;
      return { loading, error, myResilienceAnchors, subscribeToMore, updateQuery };
    }
  })(Component);

export const withGetAllDefaultCommitmentsProgress = Component =>
  graphql(GET_ALL_DEFAULT_COMMITMENTS_PROGRESS, {
    props({ data }) {
      const { loading, error, getAllDefaultCommitmentsProgress, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllDefaultCommitmentsProgress, subscribeToMore, updateQuery };
    }
  })(Component);

export const withGetAllCustomUserCommitments = Component =>
  graphql(GET_ALL_CUSTOM_USER_COMMITMENTS, {
    props({ data }) {
      const { loading, error, getAllCustomUserCommitments, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllCustomUserCommitments, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllComparisonRequestForUser = Component =>
  graphql(ALL_COMPARISON_REQUEST_FOR_USER, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allComparisonRequestForUser, subscribeToMore, updateQuery } = data;
      return { loading, error, allComparisonRequestForUser, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllComparisonRequestFromUser = Component =>
  graphql(ALL_COMPARISON_REQUEST_FROM_USER, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allComparisonRequestFormUser, subscribeToMore, updateQuery } = data;
      return { loading, error, allComparisonRequestFromUser: allComparisonRequestFormUser, subscribeToMore, updateQuery };
    }
  })(Component);

// Client
export const withDashboardState = Component =>
  graphql(DASHBOARD_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.dashboardState;
      const dashboardState = { ...removeTypename(rest), orderBy };
      return { ...dashboardState, stateLoading: data.loading };
    }
  })(Component);

export const withDashboardFilterUpdating = Component =>
  graphql(UPDATE_DASHBOARD_FILTER, {
    props: ({ mutate }) => ({
      onOrderByChange(orderBy) {
        mutate({ variables: { orderBy } });
      },
      onPaginationChange(pagination) {
        mutate({ variables: { pagination } });
      },
      onFiltersRemove() {
        mutate({ variables: { ...dashboardResolvers.defaults.surveyState } });
      }
    })
  })(Component);

// Mutation
export const withAddCustomUserCommitment = Component =>
  withOperation({
    mutation: ADD_CUSTOM_USER_COMMITMENT,
    funcName: 'addCustomUserCommitment',
    query: GET_ALL_CUSTOM_USER_COMMITMENTS,
    queryName: 'getAllCustomUserCommitments',
    node: ['createCustomUserCommitment', 'customUserCommitment'],
    type: 'add'
  })(Component);

export const withAddCoachingCommitment = Component =>
  withOperation({
    mutation: ADD_COACHING_COMMITMENT,
    funcName: 'addCoachingCommitment',
    query: GET_ALL_COACHING_COMMITMENTS,
    queryName: 'createCoachingCommitment',
    node: ['createCoachingCommitment', 'coachingCommitment'],
    type: 'add'
  })(Component);

export const withDeleteDefaultToolCommitment = Component =>
  withOperation({
    mutation: DELETE_DEFAULT_TOOL_COMMITMENT,
    mutationVarName: 'id',
    funcName: 'deleteDefaultToolCommitment',
    query: GET_ALL_DEFAULT_COMMITMENTS_PROGRESS,
    queryName: 'getAllDefaultCommitmentsProgress',
    node: ['deleteDefaultToolCommitment', 'defaultCommitmentProgress'],
    type: 'delete'
  })(Component);

export const withDeleteCustomUserCommitment = Component =>
  withOperation({
    mutation: DELETE_CUSTOM_USER_COMMITMENT,
    mutationVarName: 'id',
    funcName: 'deleteCustomUserCommitment',
    query: GET_ALL_CUSTOM_USER_COMMITMENTS,
    queryName: 'getAllCustomUserCommitments',
    node: ['deleteCustomUserCommitment', 'customUserCommitment'],
    type: 'delete'
  })(Component);

export const withDeleteResponseFromCompareRequest = Component =>
  withOperation({
    mutation: DELETE_RESPONSE_COMPARE_REQUEST,
    mutationVarName: 'id',
    funcName: 'deleteResponseCompareRequest',
    query: ALL_COMPARISON_REQUEST_FOR_USER,
    queryName: 'allComparisonRequestForUser',
    node: ['deleteResponseCompareRequest', 'compareRequest'],
    type: 'delete'
  })(Component);

export const withDeleteCoachingCommitment = Component =>
  withOperation({
    mutation: DELETE_COACHING_COMMITMENT,
    mutationVarName: 'id',
    funcName: 'deleteCoachingCommitment',
    query: GET_ALL_COACHING_COMMITMENTS,
    queryName: 'GetMyCoachCommimentsProgress',
    node: ['deleteCoachingCommitment', 'CoachingCommitmentType'],
    type: 'delete'
  })(Component);

export const withDeleteResponseForCompareRequest = Component =>
  withOperation({
    mutation: DELETE_RESPONSE_COMPARE_REQUEST,
    mutationVarName: 'id',
    funcName: 'deleteResponseCompareRequest',
    query: ALL_COMPARISON_REQUEST_FROM_USER,
    queryName: 'allComparisonRequestFormUser',
    node: ['deleteResponseCompareRequest', 'compareRequest'],
    type: 'delete'
  })(Component);

export const withEditDefaultToolCommitment = Component =>
  graphql(EDIT_DEFAULT_TOOL_COMMITMENT, {
    props: ({ mutate }) => ({
      editDefaultToolCommitment: async values => {
        try {
          const {
            data: { updateDefaultToolCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateDefaultToolCommitment.defaultCommitmentProgress;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withAddSelectDefaultToolCommitment = Component =>
  withOperation({
    mutation: ADD_SELECT_DEFAULT_TOOL_COMMITMENT,
    funcName: 'addSelectDefaultToolCommitment',
    query: GET_ALL_DEFAULT_COMMITMENTS_PROGRESS,
    queryName: 'getAllDefaultCommitmentsProgress',
    node: ['selectDefaultToolCommitment', 'defaultCommitmentProgress'],
    type: 'add'
  })(Component);

export const withAddResponseCompareRequest = Component =>
  withOperation({
    mutation: ADD_RESPONSE_COMPARE_REQUEST,
    funcName: 'addResponseCompareRequest',
    query: ALL_COMPARISON_REQUEST_FROM_USER,
    queryName: 'allComparisonRequestFormUser',
    node: ['createResponseCompareRequest', 'compareRequest'],
    type: 'add',
    variable: { type: 'all' }
  })(Component);

export const withResponseCompareRequest = Component =>
  graphql(RESPONSE_COMPARE_REQUEST, {
    props: ({ mutate }) => ({
      respondToResponseCompareRequest: async values => {
        try {
          const {
            data: { respondToResponseCompareRequest }
          } = await mutate({
            variables: {
              ...values
            }
          });

          return respondToResponseCompareRequest.compareRequest;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditCustomUserCommitment = Component =>
  graphql(EDIT_CUSTOM_USER_COMMITMENT, {
    props: ({ mutate }) => ({
      editCustomUserCommitment: async values => {
        try {
          const {
            data: { updateCustomUserCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateCustomUserCommitment.customUserCommitment;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditCoachingCommitment = Component =>
  graphql(EDIT_COACHING_COMMITMENT, {
    props: ({ mutate }) => ({
      editCoachingCommitment: async values => {
        try {
          const {
            data: { updateCoachingCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateCoachingCommitment.coachingCommitment;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);
