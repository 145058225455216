import React from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/client/react/hoc';
import { Input, Divider, Modal, Spin, Col, Row, Button, DatePicker, Switch, List, Tooltip } from 'antd';
import { compose } from '../../core';
import { getIntFromString, removeDuplicate, getOrgListFromVer, getOrgListFromTeam, OrganizationTransfer } from '../../look';
import OrganizationTree from './OrganizationTree';
import { IMG } from '../../../config';

import SelectFilterField from '../../look/components/SelectFilterField';
import { withEditTool, withAllToolsAdminQuery } from './ToolOperations';
import {
  withOrganizationState,
  withAllOrganization,
  withOrganizationFilterUpdating
} from '../../organization/containers/OrganizationOperation';

const SurveyAssign = props => {
  const {
    assign,
    setAssign,
    getToolByIdAdmin,
    onFiltersRemove,
    editTool,
    allOrganization,
    onNameChange,
    onDescriptionChange,
    onEmailChange
  } = props;
  const [visible, setVisible] = React.useState(false);
  const [orgId, setOrgId] = React.useState(0);
  const [tree, setTree] = React.useState(false);
  const handleSubmit = async organizations => {
    try {
      await editTool({ id: getIntFromString(getToolByIdAdmin.id), name: getToolByIdAdmin.name, organizations });
    } catch (e) {
      throw new Error(e.message);
    }
  };
  const renderOrgList = (org, header = false) =>
    org?.edgeCount > 0 ? (
      <ListScroll
        header={header && <div align="center">Organizations</div>}
        // footer={<div>Footer</div>}
        bordered
        dataSource={org?.edgeCount > 0 && org?.edges.map(({ node }) => node)}
        renderItem={item => (
          <Selectable>
            {/* <OrganizationTree item={item} orgId={getIntFromString(item.id)} /> */}
            <List.Item>
              <Row justify="space-between">
                <Col
                  type="default"
                  ghost
                  onClick={() => {
                    setOrgId(item.id);
                    setTree(true);
                  }}
                >
                  <img src={IMG} alt="" style={{ width: 40, borderRadius: 30, marginRight: 10 }} /> {`${item.name}`}
                </Col>
              </Row>
            </List.Item>
          </Selectable>
        )}
      />
    ) : null;
  const allVerticals =
    getToolByIdAdmin &&
    getToolByIdAdmin.vertical &&
    getToolByIdAdmin.vertical.edges.map(({ node }) => getIntFromString(node.id));
  const allTeams =
    getToolByIdAdmin && getToolByIdAdmin.team && getToolByIdAdmin.team.edges.map(({ node }) => getIntFromString(node.id));
  const allOrganizations =
    getToolByIdAdmin &&
    getToolByIdAdmin.organization &&
    getToolByIdAdmin.organization.edges.map(({ node }) => getIntFromString(node.id));
  const assignedOrg = removeDuplicate([
    ...getToolByIdAdmin.organization.edges,
    ...((getToolByIdAdmin?.vertical &&
      getToolByIdAdmin.vertical.edgeCount > 0 &&
      getOrgListFromVer(getToolByIdAdmin.vertical).edges) ||
      []),
    ...((getToolByIdAdmin?.team &&
      getToolByIdAdmin.team.edgeCount > 0 &&
      renderOrgList(getOrgListFromTeam(getToolByIdAdmin.team)).edges) ||
      [])
  ]);
  return (
    <>
      <Button type="primary" ghost onClick={() => setAssign(true)}>
        Tool Assigned
      </Button>
      <Modal
        destroyOnClose
        visible={assign}
        onCancel={() => setAssign(false)}
        footer={null}
        title="Survey Assigned"
        width={'45%'}
        bodyStyle={{ padding: 16, minHeight: '80%' }}
      >
        {visible ? (
          <>
            <Row>
              <Button type="primary" onClick={() => setVisible(false)}>
                All Organizations
              </Button>
            </Row>
            <br />
            {getToolByIdAdmin && renderOrgList(assignedOrg, true)}
          </>
        ) : (
          <>
            <Row justify="space-between">
              <Button type="primary" onClick={() => setVisible(true)}>
                Assigned
              </Button>
              <SelectFilterField
                placeholder="Search Organization by"
                onFiltersRemove={onFiltersRemove}
                options={[
                  {
                    label: 'Name',
                    type: 'text',
                    value: onNameChange
                  },
                  {
                    label: 'Description',
                    type: 'text',
                    value: onDescriptionChange
                  },
                  {
                    label: 'Email',
                    type: 'text',
                    value: onEmailChange
                  }
                ]}
              />
            </Row>
            <br />
            {allOrganization && allOrganization.edgeCount > 0 && (
              <ListScroll
                header={<div align="center">Organizations</div>}
                // footer={<div>Footer</div>}
                bordered
                dataSource={
                  allOrganization && allOrganization.edgeCount > 0 && allOrganization.edges.map(({ node }) => node)
                }
                renderItem={item => (
                  <Selectable>
                    {/* <OrganizationTree item={item} orgId={getIntFromString(item.id)} /> */}
                    <List.Item>
                      <Row justify="space-between">
                        <Col
                          type="default"
                          ghost
                          onClick={() => {
                            setOrgId(item.id);
                            setTree(true);
                          }}
                        >
                          <img src={IMG} alt="" style={{ width: 40, borderRadius: 30, marginRight: 10 }} /> {`${item.name}`}
                        </Col>
                      </Row>
                    </List.Item>
                  </Selectable>
                )}
              />
            )}
          </>
        )}
      </Modal>
      <Modal
        destroyOnClose
        visible={tree}
        onCancel={() => setTree(false)}
        footer={null}
        title="Survey Assigned"
        width={'80%'}
        bodyStyle={{ padding: 16, maxHeight: '80vh', overflowX: 'scroll' }}
      >
        {tree && (
          <OrganizationTree
            orgId={getIntFromString(orgId)}
            toolId={getToolByIdAdmin.id}
            toolName={getToolByIdAdmin.name}
            allVerticals={allVerticals}
            allTeams={allTeams}
            allOrganizations={allOrganizations}
            editTool={editTool}
          />
        )}
      </Modal>
    </>
  );
};

const Selectable = styled.div`
  &:hover {
    cursor: pointer;
    background-color: #fffff0;
  }
`;

const ListScroll = styled(List)`
  .ant-list-items {
    max-height: 60vh;
    overflow-y: scroll;
  }
`;
export default compose(
  withOrganizationState,
  withAllOrganization,
  withOrganizationFilterUpdating,
  withEditTool
)(SurveyAssign);
