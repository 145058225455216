import React from 'react';
import { Row, Col, Card, Divider, Button } from 'antd';
import styled from 'styled-components';
import { withAllPublishedStories, withStoryState } from '../../story/containers/StoryOperations';
import { compose } from '../../core';
import { Link } from 'react-router-dom';

import STORY_ROUTES from '../../story/route';
import { getIntFromString } from '../../look';
import { IMG, USER_IMG } from '../../../config';
import CompareYourScore from '../containers/CompareYourScore';

const TeamComponent = props => {
  const { me, allPublishedStories } = props;

  return (
    <>
      {me && (
        <>
          <Col lg={{ span: 6, offset: 1 }} xs={{ span: 21, offset: 1 }}>
            <Card bodyStyle={{ boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)', borderRadius: '2%', marginBottom:'1.2em' }} bordered={false}>
              <Row>
                <h2 style={{ fontWeight: 'normal' }}>Team</h2>
              </Row>
              <Row gutter={[12, 12]}>
                {me.employee &&
                  me.employee.teamMembersEmployee.edges &&
                  me.employee.teamMembersEmployee.edges.map((member, index) => (
                    <Col span={6} key={index}>
                      <img src={USER_IMG} alt="" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                    </Col>
                  ))}
              </Row>
              <br />

              <CompareYourScore />
              </Card>
            <Card bodyStyle={{ boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)', borderRadius: '2%' }} bordered={false}>
            <Row>
                <h2 style={{ fontWeight: 'normal' }}>Stories</h2>
              </Row>
              
              {allPublishedStories &&
                [...Array(allPublishedStories.edges.length < 2 ? allPublishedStories.edges.length : 2).keys()].map(index => {
                  const { node } = allPublishedStories && allPublishedStories.edges[index];
                  return (
                    <>
                      <NewCol span={24}>
                        <Link to={`${STORY_ROUTES.storyDetailLink}${getIntFromString(node.id)}`} target="_blank">
                          <Row gutter={[34, 34]}>
                            <Col span={10}>
                              <img
                                src={node.thumbnailImage || IMG}
                                style={{ width: '100%', height: '100%', borderRadius: '15px' }}
                                alt="col-7"
                              />
                            </Col>

                            <Col span={14} style={{ alignSelf: 'center' }}>
                              <h3>{node.title}</h3>
                            </Col>
                          </Row>
                        </Link>
                      </NewCol>
                      <br />
                    </>
                  );
                })}
                              <Row justify="end">
                <Link target={'_blank'} to={STORY_ROUTES.allStories}>{`View all stories`}</Link>
              </Row>
              </Card>

          </Col>

          <Col lg={0} xs={1}></Col>
        </>
      )}
    </>
  );
};

export default compose(withStoryState, withAllPublishedStories)(TeamComponent);

const NewCol = styled(Col)`
  padding: 5px;
  :hover {
    box-shadow: 5px 0px 25px 3px rgba(32, 32, 32, 0.1);
    border-radius: 10px;
  }
`;
