import React from 'react';
import HomeView from '../components/HomeView';
import { compose } from '../../core';
import { withMyResponse } from '../../user/containers/UserOperations';

const Home = ({allResponsesByImplicitUserId}) => {
  return <HomeView isSurveyGiven={allResponsesByImplicitUserId} />;
};

export default compose(withMyResponse)(Home);
