import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import './App.less';

// Route object
import home from './modules/home';
import organization from './modules/organization';
import page from './modules/page';
import survey from './modules/survey';
import user from './modules/user';
import story from './modules/story';
import dashboard from './modules/dashboard';
import mangerDashboard from './modules/manager';
import tool from './modules/tool';
import report from './modules/report';

import lookResolvers from './modules/look/resolvers';
import dashboardResolvers from './modules/dashboard/resolvers';
import toolResolvers from './modules/tool/resolvers';
import storyResolvers from './modules/story/resolvers';
import pageResolvers from './modules/page/resolvers';
import userResolvers from './modules/user/resolvers';
import surveyResolvers from './modules/survey/resolvers';
import organizationResolvers from './modules/organization/resolvers';
import managerResolvers from './modules/manager/resolvers';

import Routes from './modules/layout/Routes';
import NotFound from './modules/page/containers/NotFound';
import ErrorBoundary from './modules/look/components/ErrorBoundary';
import { IS_MAINTENANCE } from './config';

export const clientResolvers = {
  defaults: {
    ...lookResolvers.defaults,
    ...dashboardResolvers.defaults,
    ...toolResolvers.defaults,
    ...storyResolvers.defaults,
    ...pageResolvers.defaults,
    ...userResolvers.defaults,
    ...surveyResolvers.defaults,
    ...organizationResolvers.defaults,
    ...managerResolvers.defaults
  },
  resolvers: {
    Mutation: {
      ...lookResolvers.resolvers.Mutation,
      ...dashboardResolvers.resolvers.Mutation,
      ...toolResolvers.resolvers.Mutation,
      ...storyResolvers.resolvers.Mutation,
      ...pageResolvers.resolvers.Mutation,
      ...userResolvers.resolvers.Mutation,
      ...surveyResolvers.resolvers.Mutation,
      ...organizationResolvers.resolvers.Mutation,
      ...managerResolvers.resolvers.Mutation
    }
  }
};

export const ROUTES = IS_MAINTENANCE
  ? [
      {
        name: 'NotFound',
        up: false,
        component: NotFound
      }
    ]
  : [
      ...home,
      ...organization,
      ...survey,
      ...user,
      ...story,
      ...dashboard,
      ...mangerDashboard,
      ...tool,
      ...report,
      // Keep this last for not-found page
      ...page
    ];

const App = () => {
  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div>
              <Spin size="large" />
            </div>
          }
        >
          <ErrorBoundary>
            <Switch>
              {ROUTES.map(route => (
                <Routes key={route.path} adminRoutes={ROUTES.filter(r => r.adminDropdown)} {...route} />
              ))}
            </Switch>
          </ErrorBoundary>
        </Suspense>
      </Router>
    </>
  );
};

export default App;
