import React from 'react';
// import { withApollo } from '@apollo/client/react/hoc';

import { compose } from '../../core';
// import { handleGetReport } from '../../look';

import RequestStatusView from '../components/RequestStatusView';

import {
  withAllComparisonRequestFromUser,
  withDashboardFilterUpdating,
  withDashboardState,
  withDeleteResponseForCompareRequest
} from './DashboardOperations';
import { subscribeToSurveyComparisonRequestFromUser } from './DashboardSubscriptions';

const RequestStatus = props => {
  const { deleteResponseCompareRequest, /* client, setReport, */ setActionLoading, subscribeToMore } = props;

  React.useEffect(() => {
    const subscribe = subscribeToSurveyComparisonRequestFromUser(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  async function handleCancel(id) {
    setActionLoading(true);
    try {
      await deleteResponseCompareRequest(id);
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  }

  // async function handleReport(receiver, surveyName, variables, reponseId) {
  //   setActionLoading(true);
  //   try {
  //     const res = await handleGetReport(surveyName, client, variables, reponseId);
  //     setActionLoading(false);
  //     res && setReport({ surveyName, receiver, ...res });
  //   } catch (err) {
  //     setActionLoading(false);
  //     throw new Error(err.message);
  //   }
  // }

  return <RequestStatusView onCancel={handleCancel} /* onReport={handleReport} */ {...props} />;
};

export default compose(
  withDashboardState,
  withAllComparisonRequestFromUser,
  // withApollo,
  withDeleteResponseForCompareRequest,
  withDashboardFilterUpdating
)(RequestStatus);
