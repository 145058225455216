import update from 'immutability-helper';
import _ from 'lodash';

import { getCursorFromInt, getIntFromString, subscribeToQuery } from '../../look';
import { FORM_SECTION_SUBSCRIPTION } from '../graphql/FormSectionSubscription.gql';
import { SIMPLE_FIELD_SUBSCRIPTION } from '../graphql/SimpleFieldSubscription.gql';

export const subscribeToFormSection = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: FORM_SECTION_SUBSCRIPTION,
      subscriptionName: 'toolsFormSectionSubscription',
      nodeName: 'formSection',
      queryName: ['getToolByIdAdmin', 'formsection']
    },
    variables
  );
};

export const subscribeToSimpleField = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SIMPLE_FIELD_SUBSCRIPTION,
      subscriptionName: 'toolsSimpleFieldSubscription',
      nodeName: 'simpleField',
      queryName: ['getToolByIdAdmin', 'formsection', 'edges', 'simplefield']
    },
    variables
  );
};
