import React from 'react';
import { useParams } from 'react-router-dom';

import { getItem, setItem } from '../../core';
import { RECENT_TOKEN } from '../../../config';

const AddToolToRecent = props => {
  const { id } = useParams();

  React.useEffect(() => {
    return () => {
      // console.log('called', getItem(RECENT_TOKEN));
      if (getItem(RECENT_TOKEN)) {
        const recentToken = JSON.parse(getItem(RECENT_TOKEN)).reverse();
        const filteredRecentToken = recentToken.filter(i => i !== String(id));
        setItem('recentToken', JSON.stringify([...filteredRecentToken.slice(-4), id].reverse()));
      } else setItem(RECENT_TOKEN, JSON.stringify([id]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default AddToolToRecent;
