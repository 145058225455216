import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Spin, Tooltip } from 'antd';

import ManagerTeamComponent from '../containers/ManagerTeamComponent';
import DashboardMenuBar from '../../dashboard/components/DashboardMenuBar';
import Dashboard from '../../dashboard/containers/Dashboard';

import ROUTE from '../route';

import TeamsResilienceAnchors from '../containers/TeamsResilienceAnchors';
import ResiliencePulseCheck from '../../dashboard/containers/ResiliencePulseCheck';
import CoachingCommitment from '../containers/CoachingCommitment';
import MyCoachingCommitment from '../containers/MyCoachingCommitment';
import EmployeeResilienceAnchors from '../containers/EmployeeResilienceAnchors';

const ManagerDashboardComponent = props => {
  const { loading, submitLoading, setSubmitLoading } = props;
  const [activeEmployee, setActiveEmployee] = useState(/* 11 */ null);
  const [activeUser, setActiveUser] = useState(null);
  const [memPaneClosed, setMemPaneClosed] = useState(false)
  const [id, setId] = React.useState(1);
  const [selectedMember, setSelectedMember] = useState({
    userId: '',
    employeeId: ''
  })
  const [memberName, setMemberName] = useState()
  const [perCommOpen, setPerCommOpen] = useState()
  const scrollRef = useRef(()=>{})

  console.log('perComm99yOpen',perCommOpen)
  
  const scrollToElement = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth', // Optional: Adds smooth scrolling behavior
        block: 'start', // Optional: Scrolls to the top of the element
      });
    }
  };

  useEffect(()=>{
    if(!perCommOpen && perCommOpen !== undefined){
      scrollToElement()
      // triggerAnimation()
    }
  },[perCommOpen])

  useEffect(()=>{
    if(memPaneClosed){
      scrollToElement()
    }
  },[memPaneClosed])

  console.log('the435b8oi9cted', ROUTE.manager)

  return (
    <Spin spinning={loading || submitLoading} size="large">
      <Dashboard active={ROUTE.manager}>
        {!loading && (
          <Row gutter={[24, 24]}>
            <Col style={{scrollMarginTop:'70px'}} ref={scrollRef} lg={{ span: 24 }}>
              <DashboardMenuBar active={ROUTE.manager} />
              <br />

              <Row gutter={[24, 24]} align="middle" style={{ marginBottom: '2em' }}>
                <TeamsResilienceAnchors />
              </Row>

              <Col lg={24} md={24} xs={24}>
                <Row justify='center' align='middle' gutter={[24, 20]}>
                  {/* <Col span={24} align="right">
                      <Link>Take a tour</Link>
                    </Col> */}

                  {/* <ResiliencePulseCheck manager /> */}
                  <Col span={24} style={{padding:'0px'}}>
                    <ManagerTeamComponent
                      me={props.me}
                      activeEmployee={activeEmployee}
                      setActiveEmployee={e => setActiveEmployee(e)}
                      setActiveUser={e => setActiveUser(e)}
                      activeUser={activeUser}
                      setSelectedMember={setSelectedMember}
                      selectedMember={selectedMember}
                    />
                  </Col>


                  {/* <Col span={12}>
                    <Tooltip title="This feature is coming soon!">
                      <Card
                        bodyStyle={{
                          boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
                          borderRadius: '2%',
                          paddingBottom: 0,
                          paddingTop: 0,
                          cursor: 'not-allowed'
                        }}
                        bordered={false}
                      // onClick={() => setId(2)}
                      >
                        <Row justify="center">
                          <h2
                            style={{
                              margin: '1em 0',
                              fontWeight: 'normal',
                              color: id === 2 ? '#881FCC' : '#222'
                            }}
                          >
                            Compare
                          </h2>
                        </Row>
                      </Card>
                    </Tooltip>
                  </Col> */}
                </Row>
              </Col>

            </Col>



            {activeEmployee && (
              <Col lg={{ span: 24 }}>
                <Row gutter={[24, 24]}>
                  <EmployeeResilienceAnchors
                    setMemPaneClosed={setMemPaneClosed}
                    memberName={memberName}
                    employeeId={activeEmployee} />
                    
                  <CoachingCommitment
                    setManCoComm={setPerCommOpen}
                    employeeId={activeEmployee}
                    setSubmitLoading={setSubmitLoading}
                    dashboard={'manager'}
                    selectedMember={selectedMember}
                    me={props.me}
                    setMemberName={setMemberName}
                  />

                  {/* <MyCoachingCommitment
                    memberName={memberName}
                    forUser={activeUser}
                    setSubmitLoading={setSubmitLoading}
                    dashboard={'manager-personal'}
                  /> */}
                </Row>
              </Col>
            )}
          </Row>
        )}
      </Dashboard>
    </Spin>
  );
};

export default ManagerDashboardComponent;
