import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button, Modal, Tooltip, Spin } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';

import { compose } from '../../core';
import {
  displayDataCheck,
  getIntFromString,
  RenderTableWithLayout,
  UserLayout,
  generateReport,
  getOrderBy,
  readableTime
} from '../../look';
import { withDeleteResponse } from '../../survey/containers/SurveyOperations';
import { subscribeToMyResponses } from '../../survey/containers/SurveySubscriptions';

import ROUTE from '../route';

import { withMyResponse, withUserFilterUpdating, withUserState } from './UserOperations';
import { REPORT_AVAILABLE_FOR } from '../../../config';

const MyResponse = props => {
  const {
    loading,
    allResponsesByImplicitUserId,
    onPaginationChange,
    client,
    onOrderByChange,
    orderBy,
    deleteResponse,
    onFiltersRemove,
    subscribeToMore
  } = props;
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const subscribe = subscribeToMyResponses(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });
  const columns = [
    {
      title: 'Survey Name',
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      render: (text, record) => <>{record && displayDataCheck(record.survey.name)}</>
    },
    {
      title: 'Respone Date',
      key: 'responseDate',
      sorter: () => onOrderByChange(getOrderBy('responseDate', orderBy)),
      render: (text, record) => (
        <>{`${new Date(record.responseDate).toDateString()} ${new Date(record.responseDate).toTimeString()}`}</>
      )
    },
    {
      title: 'Response Duration',
      key: 'responseDuration',
      sorter: () => onOrderByChange(getOrderBy('responseDuration', orderBy)),
      render: (text, record) => record?.responseDuration && <>{`${readableTime(record?.responseDuration)}`}</>
    },
    {
      title: 'Action',
      key: 'action',
      sorter: () => onOrderByChange(getOrderBy('action', orderBy)),
      render: (text, record) => (
        <>
          {record &&
            (record?.survey ? (
              <Button
                type="primary"
                onClick={() => {
                  generateReport(getIntFromString(record.id), getIntFromString(record.survey.id), client);
                  setVisible(true);
                }}
                disabled={!record?.survey}
              >
                Get Your Report
              </Button>
            ) : (
              <Tooltip title="This survey does not have a report yet.">
                <Button
                  type="primary"
                  onClick={() => {
                    generateReport(getIntFromString(record.id), getIntFromString(record.survey.id), client);
                    setVisible(true);
                  }}
                  disabled={!record?.survey}
                >
                  Get Your Report
                </Button>
              </Tooltip>
            ))}
        </>
      )
    }
  ];

  const EmailSent = () => (
    <>
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        {/* <MailSent>
          <CheckCircleFilled style={{ color: '#009c4a', fontSize: '25px' }} /> &nbsp; Email Sent! <br />
        </MailSent> */}
        <h3>
          This could take a minute.
          <br />
          Generated report will be sent to your registered email.
        </h3>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                setVisible(false);
              }}
              size="middle"
            >
              Close
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Button type="primary" ghost onClick={() => setVisible(false)} size="middle">
              Close
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
  return (
    <Spin spinning={loading}>
      {!loading && allResponsesByImplicitUserId && (
        <UserLayout active={ROUTE.myResponse} history={props.history}>
          <RenderTableWithLayout
            loading={loading}
            title={'My Workplace Resilience Report'}
            tableData={allResponsesByImplicitUserId}
            columns={columns}
            onDelete={deleteResponse}
            handleDrawer={() => setVisible(!visible)}
            onFiltersRemove={onFiltersRemove}
            onPaginationChange={onPaginationChange}
            // addLink={'sifoweim'}
            // editLink={'somehign'}
            // showBack={true}
          />
          <Modal
            closable={false}
            footer={null}
            centered
            visible={visible}
            onCancel={() => setVisible(false)}
            bodyStyle={{ padding: '0px' }}
          >
            <ModalBody>
              <EmailSent />
            </ModalBody>
          </Modal>
        </UserLayout>
      )}
    </Spin>
  );
};

export default compose(withUserState, withApollo, withMyResponse, withDeleteResponse, withUserFilterUpdating)(MyResponse);

const ModalBody = styled.div`
  padding: 24px 48px;
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;
