import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { message } from 'antd';

import { removeTypename } from '../../core/clientStorage';
import organizationResolvers from '../resolvers';
import { getIntFromString, withOperation } from '../../look';

// Query
import { ALL_ORGANIZATION_QUERY } from '../graphql/AllOrganizationQuery.gql';
import { ALL_ORGANIZATION_EMPLOYEES } from '../graphql/AllOrganizationEmployeesQuery.gql';
import { VERTICALS_QUERY } from '../graphql/VerticalsQuery.gql';
import { VERTICALS_BY_ORG_ID_QUERY } from '../graphql/VerticalsByOrgIdQuery.gql';
import { TEAMS_QUERY } from '../graphql/TeamsQuery.gql';
import { TEAMS_BY_VERTICAL_ID_QUERY } from '../graphql/TeamsByVerticalIdQuery.gql';
import { ALL_EMPLOYEES_QUERY } from '../graphql/AllEmployees.gql';
import { ORGANIZATION_BY_ID_QUERY } from '../graphql/OrganizationByIdQuery.gql';
import { EMPLOYEE_BY_ID_QUERY } from '../graphql/EmployeeByIdQuery.gql';
import { VERTICAL_BY_VERTICAL_Id_QUERY } from '../graphql/VerticalByVerticalIdQuery.gql';
import { VERTICAL_QUERY } from '../graphql/VerticalQuery.gql';
import { TEAM_QUERY } from '../graphql/TeamQuery.gql';
import { TEAM_BY_TEAM_ID_QUERY } from '../graphql/TeamByTeamIdQuery.gql';

// Client
import { ORGANIZATION_STATE_QUERY } from '../graphql/OrganizationStateQuery.client.gql';
import { UPDATE_ORGANIZATION_FILTER } from '../graphql/UpdateOrganizationFilter.client.gql';

// Mutation
import { ADD_ORGANIZATION } from '../graphql/AddOrganization.gql';
import { ADD_VERTICAL } from '../graphql/AddVertical.gql';
import { ADD_EMPLOYEE } from '../graphql/AddEmployee.gql';
import { ADD_TEAM } from '../graphql/AddTeam.gql';
import { EDIT_EMPLOYEE } from '../graphql/EditEmployee.gql';
import { EDIT_TEAM } from '../graphql/EditTeam.gql';
import { EDIT_ORGANIZATION } from '../graphql/EditOrganization.gql';
import { EDIT_VERTICAL } from '../graphql/EditVertical.gql';
import { DELETE_ORGANIZATION } from '../graphql/DeleteOrganization.gql';
import { DELETE_VERTICAL } from '../graphql/DeleteVertical.gql';
import { DELETE_TEAM } from '../graphql/DeleteTeam.gql';
import { DELETE_EMPLOYEE } from '../graphql/DeleteEmployee.gql';

// Query
export const withOrganizationById = Component =>
  graphql(ORGANIZATION_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, organizationById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loadingVerifyToken: loading, error, organizationById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withVerticalByVerticalId = (
  Component // Admin
) =>
  graphql(VERTICAL_BY_VERTICAL_Id_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { verticalId: Number(id) }
      };
    },
    props({ data: { loading, error, verticalByVerticalId, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loadingVerifyToken: loading, error, verticalByVerticalId, subscribeToMore, updateQuery };
    }
  })(Component);

export const withVertical = Component =>
  graphql(VERTICAL_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, vertical, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loadingVerifyToken: loading, error, vertical, subscribeToMore, updateQuery };
    }
  })(Component);

export const withTeam = Component =>
  graphql(TEAM_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, Team, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, team: Team, subscribeToMore, updateQuery };
    }
  })(Component);

export const withTeamByTeamId = Component =>
  graphql(TEAM_BY_TEAM_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { teamId: Number(id) }
      };
    },
    props({ data: { loading, error, TeamByTeamId, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, teamByTeamId: TeamByTeamId, subscribeToMore, updateQuery };
    }
  })(Component);

export const withEmployeeByIdQuery = Component =>
  graphql(EMPLOYEE_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, employeeById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, employeeById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withVerticals = Component =>
  graphql(VERTICALS_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, verticals, subscribeToMore, updateQuery } = data;
      return { loading, error, verticals, subscribeToMore, updateQuery };
    }
  })(Component);

export const withVerticalsByOrgIdQuery = Component =>
  graphql(VERTICALS_BY_ORG_ID_QUERY, {
    options: ({ match, navigation, filter, orderBy, pagination }) => {
      let id = '';
      if (match) {
        id = match.params.id;
      } else if (navigation) {
        id = navigation.state.params.id;
      }
      return {
        variables: { ...pagination, ...filter, orgId: Number(id), orderBy },
        fetchPolicy: 'network-only'
      };
    },
    props({ data }) {
      const { loading, error, verticalsByOrgId, subscribeToMore, updateQuery } = data;
      return { loading, error, verticalsByOrgId, subscribeToMoreVerticalsByOrgId: subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllOrganization = Component =>
  graphql(ALL_ORGANIZATION_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allOrganization, subscribeToMore, updateQuery } = data;
      return { loading, error, allOrganization, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllOrganizationEmployees = Component =>
  graphql(ALL_ORGANIZATION_EMPLOYEES, {
    options: ({ filter, orderBy, pagination }) => {
      let filters = filter;
      if (filter.name_Icontains === '') filters = _.omit(filter, 'name_Icontains');
      else filters.nameIcontains = filter.name_Icontains;

      return { variables: { ...pagination, ...filters, orderBy } };
    },
    props({ data: { loading, error, allOrganizationEmployees, subscribeToMore, updateQuery } }) {
      return { loading, error, allOrganizationEmployees, subscribeToMore, updateQuery };
    }
  })(Component);

export const withTeams = Component =>
  graphql(TEAMS_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, Teams, subscribeToMore, updateQuery } = data;
      return { loading, error, Teams, subscribeToMore, updateQuery };
    }
  })(Component);

export const withTeamsByVerticalIdQuery = Component =>
  graphql(TEAMS_BY_VERTICAL_ID_QUERY, {
    options: ({ match, navigation, filter, orderBy, pagination }) => {
      let id = '';
      if (match) {
        id = match.params.id;
      } else if (navigation) {
        id = navigation.state.params.id;
      }
      return { variables: { ...pagination, ...filter, verticalId: Number(id), orderBy }, fetchPolicy: 'network-only' };
    },
    props({ data }) {
      const { loading, error, TeamsByVerticalId, subscribeToMore, updateQuery } = data;
      return { loading, error, TeamsByVerticalId, subscribeToMoreTeamsByVerticalId: subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllEmployees = Component =>
  graphql(ALL_EMPLOYEES_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allEmployees, subscribeToMore, updateQuery } = data;
      return { loading, error, allEmployees, subscribeToMore, updateQuery };
    }
  })(Component);

// Mutation
export const withAddOrganization = Component =>
  withOperation({
    mutation: ADD_ORGANIZATION,
    funcName: 'addOrganization',
    query: ALL_ORGANIZATION_QUERY,
    queryName: 'allOrganization',
    node: ['createOrganization', 'organization'],
    type: 'add'
  })(Component);

export const withAddVertical = Component =>
  withOperation({
    mutation: ADD_VERTICAL,
    funcName: 'addVertical',
    // query: VERTICALS_QUERY,
    // queryName: "verticals",
    query: VERTICALS_BY_ORG_ID_QUERY,
    queryName: 'verticalsByOrgId',
    node: ['createVertical', 'vertical'],
    type: 'add',
    variable: { type: 'all', custom: false, varName: 'orgId' }
  })(Component);

export const withAddTeam = Component =>
  withOperation({
    mutation: ADD_TEAM,
    funcName: 'addTeam',
    query: TEAMS_BY_VERTICAL_ID_QUERY,
    queryName: 'TeamsByVerticalId',
    // query: TEAMS_QUERY,
    // queryName: "Teams",
    node: ['createTeam', 'team'],
    type: 'add',
    variable: { type: 'all', custom: false, varName: 'verticalId' }
  })(Component);

export const withAddEmployee = Component =>
  withOperation({
    mutation: ADD_EMPLOYEE,
    funcName: 'addEmployee',
    query: ALL_EMPLOYEES_QUERY,
    queryName: 'allEmployees',
    node: ['addEmployee', 'employee'],
    type: 'add'
  })(Component);

export const withEditEmployee = Component =>
  graphql(EDIT_EMPLOYEE, {
    props: ({ mutate }) => ({
      editEmployee: async values => {
        try {
          const {
            data: { updateEmployee }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateEmployee.employee;
        } catch (e) {
          if (e.message.includes('Duplicate entry')) {
            message.error('Employee already exists!');
          }
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditTeam = Component =>
  graphql(EDIT_TEAM, {
    props: ({ mutate }) => ({
      editTeam: async values => {
        try {
          const {
            data: { updateTeam }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateTeam.team;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditVertical = Component =>
  graphql(EDIT_VERTICAL, {
    props: ({ mutate }) => ({
      editVertical: async values => {
        try {
          const {
            data: { updateVertical }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateVertical.vertical;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditOrganization = Component =>
  graphql(EDIT_ORGANIZATION, {
    props: ({ mutate }) => ({
      editOrganization: async values => {
        try {
          const {
            data: { updateOrganization }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateOrganization.organization;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withDeleteOrganization = Component =>
  withOperation({
    mutation: DELETE_ORGANIZATION,
    mutationVarName: 'id',
    funcName: 'deleteOrganization',
    query: ALL_ORGANIZATION_QUERY,
    queryName: 'allOrganization',
    node: ['deleteOrganization', 'organization'],
    type: 'delete'
  })(Component);

export const withDeleteVertical = Component =>
  withOperation({
    mutation: DELETE_VERTICAL,
    mutationVarName: 'id',
    funcName: 'deleteVertical',
    query: VERTICALS_QUERY,
    queryName: 'verticals',
    node: ['deleteVertical', 'vertical'],
    type: 'delete'
  })(Component);

export const withDeleteTeam = Component =>
  withOperation({
    mutation: DELETE_TEAM,
    mutationVarName: 'id',
    funcName: 'deleteTeam',
    query: TEAMS_QUERY,
    queryName: 'Teams',
    node: ['deleteTeam', 'team'],
    type: 'delete'
  })(Component);

export const withDeleteEmployee = Component =>
  withOperation({
    mutation: DELETE_EMPLOYEE,
    mutationVarName: 'id',
    funcName: 'deleteEmployee',
    query: ALL_EMPLOYEES_QUERY,
    queryName: 'allEmployees',
    node: ['deleteEmployee', 'employee'],
    type: 'delete'
  })(Component);

export const withOrganizationState = Component =>
  graphql(ORGANIZATION_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.organizationState;
      const organizationState = {
        ...removeTypename(rest),
        orderBy
      };
      organizationState.filter.subvertical = rest.filter.subvertical;
      return { ...organizationState, stateLoading: data.loading };
    }
  })(Component);

export const withOrganizationFilterUpdating = Component =>
  graphql(UPDATE_ORGANIZATION_FILTER, {
    props: ({ mutate }) => ({
      onOrderByChange(orderBy) {
        mutate({ variables: { orderBy } });
      },
      onPaginationChange(pagination) {
        mutate({ variables: { pagination } });
      },
      onFiltersRemove() {
        mutate({ variables: { ...organizationResolvers.defaults.organizationState } });
      },
      onNameChange(name_Icontains) {
        mutate({ variables: { filter: { name_Icontains } } });
      },
      onDescriptionChange(description_Icontains) {
        mutate({ variables: { filter: { description_Icontains } } });
      },
      onEmailChange(email_Icontains) {
        mutate({ variables: { filter: { email_Icontains } } });
      },
      onPocChange(poc) {
        mutate({ variables: { filter: { poc } } });
      },
      onCeoChange(ceo) {
        mutate({ variables: { filter: { ceo } } });
      },
      onOrganizationChange(organization) {
        mutate({ variables: { filter: { organization } } });
      },
      onVerticalChange(vertical) {
        mutate({ variables: { filter: { vertical } } });
      },
      onSubverticalChange(subvertical) {
        mutate({ variables: { filter: { subvertical: [getIntFromString(subvertical)] } } });
      },
      onUserChange(user) {
        mutate({ variables: { filter: { user } } });
      }
      // onEmployeesChange(employees_Icontains) {
      //   mutate({ variables: { filter: { employees_Icontains } } });
      // }
    })
  })(Component);
