import gql from 'graphql-tag';
import { USER_TYPE } from '../../user/graphql/UserType.gql';
import { SURVEY_TYPE } from './SurveyType.gql';

export const CREATE_TOKEN_MUTATION = gql`
  ${USER_TYPE}, ${SURVEY_TYPE}
  mutation createToken($email: String, $firstName: String!, $invite: Boolean, $lastName: String!, $message: String, $surveyId: Int!, $mobileNumber: String) {
    createToken(email: $email, firstName: $firstName, invite: $invite, lastName: $lastName, message: $message, surveyId: $surveyId, mobileNumber: $mobileNumber) {
      token {
        id
        user {
          ...UserType
        }
        survey {
          ...SurveyType
        }
        invited
        dateCreated
        message
        valid
        createdBy {
          ...UserType
        } 
        updatedBy {
          ...UserType
        } 
      }
    }
  }
`;
