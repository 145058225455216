import React from 'react';

import { compose } from '../../core';
import {tipsData} from './tipsData'
import EmployeeResilienceAnchorsView from '../components/EmployeeResilienceAnchorsView';

import { withAddCustomUserCommitment, withMyResilienceAnchors, withTeamsResiliencePulseCheck } from './DashboardOperations';
import { withGetTips, withMe } from '../../user/containers/UserOperations';
import { getIntFromString } from '../../look';

const ResilienceAnchors = props => {
  const { myResilienceAnchors, tips, addCustomUserCommitment, me, setTipPosted, reportingTeamsResiliencePulseCheck, setResAnchorsOpen } = props;
  const handleAdd = async values => {
    setTipPosted(true)
    console.log('value7g78ds ::-', tips,myResilienceAnchors)
    try {
      const response = await addCustomUserCommitment({
        customUserCommitmentData: {
          ...values,
          userId: getIntFromString(me.id),
        }
      });
      
    } catch (err) {
      throw new Error(err.message);
    }
  };

return <EmployeeResilienceAnchorsView setResAnchorsOpen={setResAnchorsOpen} addCustomUserComm={handleAdd} tipsData={tipsData} resilienceAnchors={myResilienceAnchors} {...props} />;
};

export default compose(withMyResilienceAnchors,withMe,withAddCustomUserCommitment)(ResilienceAnchors);
