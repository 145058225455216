import React from 'react';
import { UndoOutlined, SearchOutlined } from '@ant-design/icons';
import { Divider, Button, Space, Tooltip, Input, Select } from 'antd';
import UserAutoComplete from './form-components/UserAutoComplete';
import SubverticalAutoComplete from './form-components/SubverticalAutoComplete';
import { getOrganizationBase64StringFromInt, getUserBase64StringFromInt } from './functions';

const { Option } = Select;

const SelectFilterField = props => {
  const { placeholder, options, onFiltersRemove } = props;
  const [search, setSearch] = React.useState(options[0]);

  return (
    <Space size="xs">
      {search?.type === 'text' && (
        <Input
          placeholder={placeholder}
          prefix={<SearchOutlined style={{ color: '#AFB0A2' }} />}
          onChange={e => search?.value(e.target.value)}
        />
      )}

      {search?.type === 'user' && (
        <div align="left">
          <UserAutoComplete
            margin={false}
            placeholder={placeholder}
            onChange={userId => search?.value(getUserBase64StringFromInt(userId))}
          />
        </div>
      )}

      {search?.type === 'subvertical' && (
        <SubverticalAutoComplete
          margin={false}
          placeholder={placeholder}
          onChange={organizationId => search?.value(getOrganizationBase64StringFromInt(organizationId))}
          organizationId={search?.organizationId || ''}
        />
      )}

      <div align="left">
        <Select
          value={search?.label}
          style={{ width: 100 }}
          onChange={e => {
            onFiltersRemove && onFiltersRemove();
            setSearch(options.filter(opt => opt.label === e)[0]);
          }}
          // labelInValue
        >
          {options.map((opt, key) => (
            <Option key={key} value={opt.label}>
              {opt.label}
            </Option>
          ))}
        </Select>
      </div>
      <Divider type="vertical" />

      <Tooltip title="Reset">
        <Button
          icon={<UndoOutlined />}
          shape="circle"
          type="primary"
          ghost
          onClick={() => {
            onFiltersRemove && onFiltersRemove();
          }}
        />
      </Tooltip>
    </Space>
  );
};

export default SelectFilterField;
