import gql from 'graphql-tag';
import { VERTICAL_TYPE_CONNECTION } from './VerticalTypeConnection.gql';

export const VERTICALS_BY_ORG_ID_QUERY = gql`
  ${VERTICAL_TYPE_CONNECTION}
  query verticalsByOrgId(
    $orgId: ID!
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
    $employees_Icontains: [ID]
    $organization: ID
    $subvertical: [ID]
  ) {
    verticalsByOrgId(
      orgId: $orgId
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
      employees_Icontains: $employees_Icontains
      organization: $organization
      subvertical: $subvertical
    ) {
      ...VerticalTypeConnection
    }
  }
`;
