import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../look';

import ROUTE from '../route';
import AddOrganizationView from '../components/AddOrganizationView';
import { CKEditorFieldName, CKEditorFieldName1 } from '../components/OrganizationFormComponent';

import { withAddOrganization, withOrganizationState } from './OrganizationOperation';

const AddOrganization = props => {
  const { history, addOrganization } = props;
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    const { employees, ...rest } = values;

    try {
      setLoading(true);
      const response = await addOrganization({
        employees: employees,
        organizationData: {
          ...withMarkdownFieldValue(CKEditorFieldName, withMarkdownFieldValue(CKEditorFieldName1, rest))
        }
      });
      if (response)
        ButtonsCatch(
          item,
          ROUTE.organization,
          history,
          item === undefined ? `${ROUTE.editOrganizationLink}${getIntFromString(response.id)}` : window.location.pathname
        );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw new Error(e.message);
    }
  };

  return (
    <Spin spinning={loading} size="large">
      <AddOrganizationView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withOrganizationState, withAddOrganization)(AddOrganization);
