/* eslint-disable import/first */
import React from 'react';
import ReactDOM from 'react-dom';
import { fromPromise } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';
import { RetryLink } from '@apollo/client/link/retry';
import { withClientState } from 'apollo-link-state';
import ApolloCacheRouter from 'apollo-cache-router';
import { hasDirectives } from 'apollo-utilities';
import { getOperationAST } from 'graphql';
import { WebSocketLink } from '@apollo/client/link/ws';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
// import { split, HttpLink } from "@apollo/client";
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import createApolloClient from './createApolloClient';
import createLink from './createLink';
import { setContext } from '@apollo/client/link/context';

require('dotenv').config();

import App, { clientResolvers } from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BACKEND_URI } from './config';

import { setItem, getItem, removeItem } from './modules/core';
import { REFRESH_TOKENS_MUTATION } from './modules/user/graphql/RefreshToken.gql';
import REPORTHELPER from './modules/survey/helpers';
import ROUTES from './modules/user/route';
import DASHBOARD_ROUTES from './modules/dashboard/route';
import REPORT_ROUTE from './modules/report/route';
import SignInAgain from './modules/user/containers/SignInAgain';

let isRefreshing = false;
let pendingRequests = [];
let client;

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  // console.log(pendingRequests);
  pendingRequests = [];
};

const getNewToken = async () => {
  const ERROR = 'Invalid RefreshToken';
  return client
    .mutate({ mutation: REFRESH_TOKENS_MUTATION, variables: { refreshToken: await getItem('refreshToken') } })
    .then(response => {
      // extract your accessToken from your response data and return it
      const { token } = response.data.refreshToken;
      if (response.data.refreshToken.errors) {
        throw Error(ERROR);
      }
      setItem('accessToken', token);
      resolvePendingRequests();
      subscriptionClient.url = `${BACKEND_URI.replace(/^http/, 'ws')}${token ? `?token=${token}` : ''}`;
      return token;
    })
    .catch(err => {
      if (err) {
        SignInAgain(client, resolvePendingRequests);
      }
    });
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      // console.log(err.message, isRefreshing, networkError);
      switch (err.message) {
        case 'Signature has expired':
          // error code is set to UNAUTHENTICATED
          // when AuthenticationError thrown in resolver
          let forward$;
          subscriptionClient.close();
          if (!isRefreshing) {
            isRefreshing = true;
            forward$ = fromPromise(
              getNewToken().catch(error => {
                console.log('New token fetch failed', error);
                // console.log('RefreshToken', getItem('refreshToken'), 'AccessToken', getItem('accessToken'));
                // Handle token refresh errors e.g clear stored tokens, redirect to login
                removeItem('accessToken');
                removeItem('refreshToken');
                return;
              })
            ).filter(value => Boolean(value));
            isRefreshing = false;
          } else {
            // Will only emit once the Promise is resolved
            forward$ = fromPromise(
              new Promise(resolve => {
                pendingRequests.push(() => resolve());
              })
            );
          }

          return forward$.flatMap(() => forward(operation));
        case 'Error decoding signature':
          removeItem('accessToken');
          removeItem('refreshToken');
          break;
        case 'Permission Denied.':

          if (typeof window !== undefined && window.location.pathname !== '/') window.location = ROUTES.login;
          break;
        default:
          const noBackTopRoutes = [DASHBOARD_ROUTES.personal, ROUTES.login,REPORT_ROUTE.reportComparisonLink].includes(
            window.location.pathname.replace(/[0-9]/g, '')
          );
          if (
            err.message.includes('does not exist') &&
            noBackTopRoutes
            // window.location.pathname !== ROUTES.login &&
            // will be removed onece the myResilienceAnchors error message for non employee user is changed
            // window.location.pathname !== DASHBOARD_ROUTES.personal
          )
            window.location = '/';
          break;
      }
    }
  }
  if (networkError) {
    // if you would also like to retry automatically on
    // network errors, we recommend that you use
    // apollo-link-retry
  }
});

const retryIf = (error, operation) => {
  const doNotRetryCodes = [500, 400];
  return (
    !!error && !doNotRetryCodes.includes(error.statusCode) && !Object.keys(REPORTHELPER).includes('emailPerceptionReport')
  );
};

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true
  },
  attempts: {
    max: 5,
    retryIf
  }
});

const subscriptionClient = new SubscriptionClient(
  `${BACKEND_URI.replace(/^http/, 'ws')}${getItem('accessToken') ? `?token=${getItem('accessToken')}` : ''}`,
  {
    lazy: true,
    reconnect: true,
    connectionParams: () => ({
      headers: {
        Authorization: getItem('accessToken') ? `JWT ${getItem('accessToken')}` : ''
      }
    })
  }
);

const wsLink = new WebSocketLink(subscriptionClient);

// const httpLink = new BatchHttpLink({
//   uri: BACKEND_URI
//   // credentials: "include",
//   // fetch
// });

const httpLink = new HttpLink({
  uri: BACKEND_URI
});

const authLink = setContext(async operation => {
  // Retrieve the authorization token from local storage.
  const token = await getItem('accessToken');

  return {
    headers: {
      Authorization: token ? `JWT ${token}` : ''
    }
  };
});

const splitLink = ApolloLink.split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink)
);

const netCache = new InMemoryCache();
const localCache = new InMemoryCache();
const cache = ApolloCacheRouter.override(
  ApolloCacheRouter.route([netCache, localCache], document => {
    const operationName = getOperationAST(document).name;
    if (hasDirectives(['client'], document) || (operationName && operationName.value === 'GeneratedClientQuery')) {
      // Pass all @client queries and @client defaults to localCache
      return localCache;
    } else {
      // Pass all the other queries to netCache);
      return netCache;
    }
  }),
  {
    reset: () => {
      // On apolloClient.resetStore() reset only netCache and keep localCache intact
      return netCache.reset();
    }
  }
);

const linkState = withClientState({ ...clientResolvers, cache });
const allLinks = [retryLink, errorLink, splitLink, linkState];

client = new ApolloClient({
  link: ApolloLink.from(allLinks),
  cache,
  resolvers: clientResolvers.resolvers,
  shouldBatch: true
});

// const apolloClient = createApolloClient({
//   apiUrl: BACKEND_URI,
//   // createNetLink: modules.createNetLink,
//   createLink,
//   // connectionParams: modules.connectionParams,
//   clientResolvers: clientResolvers
// });

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={/* apolloClient */ client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
