import React, { useState } from 'react';
import { compose } from '../../core';
import { getIntFromString, withMarkdownFieldValue } from '../../look';
import ManagerAddCommitmentView, { CKEditorFieldName } from '../components/ManagerAddCommitmentView';
import { withAddCustomizedCoachingCommitment, withMyCustomizedCoachingCommitments, withEditCoachingCommitmentProgress, withGetMyCoachingCommitmentsProgressWithUser, withDeleteCoachingCommitmentProgress, withGetMyCoachingCommitmentsProgress } from './ManagerOperations';
import { withMe } from '../../user/containers/UserOperations';
import ManagerViewCommitments from '../components/ManagerViewCommitments'

const ManagerAddCommitment = props => {
  const { addCustomizedCoachingCommitment,grabSeverities, editCoachingCommitmentProgress, me, modal3, setModal3, commitment, modalTwoTrigger, getMyCoachingCommitmentsProgress, deleteCoachingCommitmentProgress } = props;
  const [ description, setDescription ] = useState()
  const [title, setTitle] = useState()
  console.log('old449dhh: ', getMyCoachingCommitmentsProgress)

  async function handleDefaultCommitmentProgress(values) {
    try {
      const response = await editCoachingCommitmentProgress(values);
      return response && true;
    } catch (err) {
      return false;
      // throw new Error(err.message);
    }
  }

  const handleDeleteCoachingCommitmentProgress = async (id) => {
    try {
      // console.log(id);
      const response = await deleteCoachingCommitmentProgress(id);
      console.log("deleted");
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleSubmit = async values => {
    // const message = description
    console.log('old values here :: ', commitment)

    try {
      let data = {
        ...withMarkdownFieldValue(CKEditorFieldName, values),
        forUserId: getIntFromString(me.id)
      };
      if (modal3.default) {
        data.coachingCommitmentData.commitmentId = modal3.id;
      } else {
        data.coachingCommitmentData.customusercommitmentId = modal3.id;
      }
      data.coachingCommitmentData.description = description
      data.coachingCommitmentData.title = title

      console.log('old values here after changing :: ',data, description);
      const response = await addCustomizedCoachingCommitment(data);
      if (response) {
        setModal3({ visible: false, id: 0, default: false });
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };
  return <ManagerViewCommitments grabSeverities={grabSeverities} delPersonalComm={handleDeleteCoachingCommitmentProgress} coachingComms={getMyCoachingCommitmentsProgress} markCoachCommitment={handleDefaultCommitmentProgress} setTitle={setTitle} modalTwoTrigger={modalTwoTrigger} commitment={commitment} setDescription={setDescription} {...props} onSubmit={handleSubmit} />;
};

export default compose(
  withMe,
  withMyCustomizedCoachingCommitments,
  withGetMyCoachingCommitmentsProgress,
  withAddCustomizedCoachingCommitment,
  withEditCoachingCommitmentProgress,
  withGetMyCoachingCommitmentsProgressWithUser,
  withDeleteCoachingCommitmentProgress
)(ManagerAddCommitment);
