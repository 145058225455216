import React from "react";
import { compose } from "../../core";

import CommitmentsListView from "../components/CommitmentsListView";
import {
  withAddCoachingCommitment,
  withAddSelectDefaultToolCommitment,
  withDeleteCustomUserCommitment,
  withDeleteDefaultToolCommitment,
} from "./DashboardOperations";
import { withGetCommitmentRecommendations } from "../../tool/containers/ToolOperations";
import { withMe } from "../../user/containers/UserOperations";
import { getIntFromString } from "../../look";
import {
  withAddCoachingCommitmentProgress,
  withDeleteCoachingCommitmentProgress,
} from "../../manager/containers/ManagerOperations";

const CommitmentsList = (props) => {
  const {
    addCoachingCommitment,
    allDefaultCommitmentsProgress,
    addCoachingCommitmentProgress,
    addSelectDefaultToolCommitment,
    me,
    deleteDefaultToolCommitment,
    deleteCustomUserCommitment,
    deleteCoachingCommitmentProgress,
    getCommitmentRecommendations,
    dashboard,
    forUser,
  } = props;

  const handleAdd = async (values) => {
    try {
      // const response = await addCoachingCommitment(values);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleDeleteDefaultToolCommitment = async (id) => {
    try {
      const response = await deleteDefaultToolCommitment(id);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleDeleteCoachingCommitmentProgress = async (id) => {
    try {
      // console.log(id);
      const response = await deleteCoachingCommitmentProgress(id);
      console.log("deleted");
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleDeleteCustomUserCommitment = async (id) => {
    try {
      const response = await deleteCustomUserCommitment(id);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleCreateCoachingCommitment = async (id) => {
    try {
      const coachingCommitmentProgressData = {
        userId: getIntFromString(me?.id),
        coachingcommitmentId: id,
        forUserId: forUser,
      };
      await addCoachingCommitmentProgress({
        coachingCommitmentProgressData,
      });
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleSelectDefaultToolCommitment = async (commitmentId) => {
    try {
      const defaultCommitmentProgressData = {
        userId: me && getIntFromString(me.id),
        commitmentId,
      };
      await addSelectDefaultToolCommitment({ defaultCommitmentProgressData });
    } catch (err) {
      throw new Error(err.message);
    }
  };

  console.log('yekidarabnjef9', getCommitmentRecommendations,allDefaultCommitmentsProgress);

  return (
    <CommitmentsListView
      onAdd={handleAdd}
      onDeleteDefaultToolCommitment={
        dashboard === "manager-personal"
          ? handleDeleteCoachingCommitmentProgress
          : handleDeleteDefaultToolCommitment
      }
      onDeleteCustomUserCommitment={handleDeleteCustomUserCommitment}
      handleSelectDefaultToolCommitment={
        dashboard === "manager-personal"
          ? handleCreateCoachingCommitment
          : handleSelectDefaultToolCommitment
      }
      commitmentRecommendations={
        dashboard === "manager-personal"
          ? allDefaultCommitmentsProgress
          : getCommitmentRecommendations
      }
      {...props}
    />
  );
};

export default compose(
  withMe,
  withAddCoachingCommitment,
  withDeleteDefaultToolCommitment,
  withDeleteCustomUserCommitment,
  withAddSelectDefaultToolCommitment,
  withGetCommitmentRecommendations,
  withDeleteCoachingCommitmentProgress,
  withAddCoachingCommitmentProgress
)(CommitmentsList);
