import gql from 'graphql-tag';

export const REPORTING_TEAMS_RESILIENCE_ANCHORS_STATE_QUERY = gql`
  query reportingTeamsResilienceAnchorsState {
    reportingTeamsResilienceAnchorsState @client {
      filter {
        includingManager
      }
    }
  }
`;
