import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from '../../core';

import {
  AdminLayout,
  displayDataCheck,
  getIntFromString,
  displayBoolean,
  GetColumnSearchProps,
  getOrderBy
} from '../../look';
import ROUTE from '../route';
import { withAllFaqCategories, withDeleteFaqCategory, withPageFilterUpdating, withPageState } from './PageOperations';
import { subscribeToFAQCategory } from './PageSubscriptions';

const Organization = props => {
  const {
    loading,
    allFaqCategories,
    deleteFaqCategory,
    onPaginationChange,
    onFiltersRemove,
    onOrderByChange,
    orderBy,
    onNameChange,
    onDescriptionChange,
    subscribeToMore
  } = props;

  React.useEffect(() => {
    const subscribe = subscribeToFAQCategory(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onNameChange),
      render: (text, record) =>
        record && <Link to={`${ROUTE.faqQuestionLink}${getIntFromString(record.id)}`}>{displayDataCheck(record.name)}</Link>
    },
    {
      title: 'Description',
      key: 'description',
      sorter: () => onOrderByChange(getOrderBy('description', orderBy)),
      ...GetColumnSearchProps('description', onDescriptionChange),
      render: (text, record) => record && displayDataCheck(record.description)
    },
    {
      title: 'Active',
      key: 'active',
      sorter: () => onOrderByChange(getOrderBy('active', orderBy)),
      render: (text, record) => displayBoolean(record.active)
    }
  ];

  return (
    <AdminLayout
      table={true}
      active={ROUTE.faq}
      title="FAQ"
      loading={loading}
      tableData={allFaqCategories}
      columns={columns}
      onDelete={deleteFaqCategory}
      editLink={ROUTE.editFaqLink}
      addLink={ROUTE.addFaq}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
    />
  );
};

export default compose(withPageState, withAllFaqCategories, withDeleteFaqCategory, withPageFilterUpdating)(Organization);
