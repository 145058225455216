import gql from 'graphql-tag';
import { VERTICAL_TYPE } from './VerticalType.gql';

export const VERTICAL_BY_VERTICAL_Id_QUERY = gql`
  ${VERTICAL_TYPE}
  query verticalByVerticalId($verticalId: ID!) {
    verticalByVerticalId(verticalId: $verticalId) {
      ...VerticalType
    }
  }
`;
