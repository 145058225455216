import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react';
import { Spin, Form } from 'antd';
import { withHTMLFieldValue } from '../functions';
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_PRESET } from '../../../../config';

const editorVersion = '4.15.1';
const editorUrl = `https://cdn.ckeditor.com/${editorVersion}/full-all/`;

CKEditor.editorUrl = `${editorUrl}ckeditor.js`;

const HVCenter = styled.div`
  min-height: 40vh;
  display: grid;
  grid-template-rows: auto;
`;

const FormItem = Form.Item;

const CKEditorField = props => {
  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const { label, value, name, inFilter = false, disabled, rules, initialValue, changeHandler, style, offset = 1 } = props;
  let validateStatus = '';

  // const changeHandler = e => {
  //   setFieldValue(name, e && e.editor && e.editor.getData());
  // };

  let labels = inFilter
    ? {}
    : {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
      };

  const cloudName = `${CLOUDINARY_CLOUDNAME}/image`;
  const unsignedUploadPreset = `${CLOUDINARY_PRESET}`;

  if (typeof window !== 'undefined') {
    // To load the script -> <script src="https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image"></script>
    const scr = document.createElement('script');
    const head = document.head || document.getElementsByTagName('head')[0];
    scr.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
    scr.async = false;
    head.insertBefore(scr, head.firstChild);
  }

  return (
    <div style={style}>
      <FormItem
      
        name={name}
        rules={rules}
        label={label} /* {...labels} */
        /* validateStatus={validateStatus} */
        // initialValue={initialValue}
        /* help={touched && error} */
        labelCol={{ span: 24 }}
        disabled={disabled}
        wrapperCol={{ span: 24, offset }}
      >
        <CKEditor
        
          name={Array.isArray(name) ? name.join('.') : name}
          mode="wysiwyg"
          readOnly={disabled}
          data={withHTMLFieldValue(initialValue)}
          onChange={changeHandler}
          config={{
            extraPlugins: [/* 'ckeditor_wiris', */ 'image2'],

            // Remove what you want
            // removePlugins: ['forms', 'link', 'bidi', 'div', 'flash', 'iframe', 'about'],

            // Enable what you want.
            // toolbar: [
            // { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
            // { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
            // { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
            // { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
            // '/',
            // { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat' ] },
            // { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
            // { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
            // { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] }
            // '/',
            // { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
            // { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
            // { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
            // { name: 'others', items: [ '-' ] },
            // { name: 'about', items: [ 'About' ] }
            // ],
            uiColor: '#9bd446',
            // stylesSet: {

            // },
            allowedContent: true,
            height: 300,
            language: 'en',
            // startupFocus: 'end',
            filebrowserImageUploadUrl: '/uploader/upload.php', // this is only for the upload tab to appear on dialog
            // Change request to upload to Cloudinary server
            on: {
              loaded: function () {
                setLoaded(true);
              },
              fileUploadRequest: function (evt) {
                // Prepare request to Cloudinary
                var url = 'https://api.cloudinary.com/v1_1/' + cloudName + '/upload';
                var xhr = evt.data.fileLoader.xhr;
                xhr.open('POST', url, true);
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

                // Fill all necessary fields for Cloudinary
                // eslint-disable-next-line no-undef
                var fd = new FormData();
                fd.append('upload_preset', unsignedUploadPreset);
                // fd.append('folder', 'content/articles'); // Optional - place image at specific folder admin in Cloudinary
                // fd.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
                fd.append('file', evt.data.fileLoader.file);
                // Send the request to Cloudinary server
                xhr.send(fd);

                // Prevented the default behavior.
                evt.stop();
              },
              // Change response to handle Cloudinary response
              fileUploadResponse: function (evt) {
                // Prevent the default response handler.
                evt.stop();

                // Get XHR and response.
                var data = evt.data,
                  xhr = data.fileLoader.xhr,
                  response = JSON.parse(xhr.responseText);

                // Transfer the response to Ckeditor format
                data.uploaded = 1;
                data.fileName = response.public_id + '.' + response.format;
                data.url = response.secure_url;
              }
            }
          }}
          // onSelectionChange={changeHandler}
          onBeforeLoad={CKEDITOR => {
            // CKEDITOR.plugins.addExternal('ckeditor_wiris', 'https://www.wiris.net/demo/plugins/ckeditor/', 'plugin.js');
            CKEDITOR.plugins.addExternal(
              'image2',
              `https://ckeditor.com/docs/vendors/${editorVersion}/ckeditor/plugins/image2/`,
              'plugin.js'
            );
          }}
        />
      </FormItem>
      {!loaded && (
        <div style={{ height: '40vh', display: 'grid' }} align="center">
          <Spin style={{ margin: 'auto' }} />
        </div>
      )}
    </div>
  );
};

CKEditorField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  inFilter: PropTypes.bool,
  meta: PropTypes.object,
  formik: PropTypes.object
};

export default CKEditorField;
