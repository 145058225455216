import React, { useState } from 'react';

import { compose } from '../../core';
import CommitmentView from '../../dashboard/components/CommitmentView';

import { withGetMyCoachingCommitmentsProgress, withEditCoachingCommitmentProgress,withDeleteCoachingCommitmentProgress } from './ManagerOperations';
import { withGetAllDefaultCommitmentsProgress, withDeleteCustomUserCommitment, withDeleteDefaultToolCommitment } from '../../dashboard/containers/DashboardOperations';

const MyCoachingCommitment = props => {
  const {
    setSubmitLoading,
    editDefaultToolCommitment,
    editCustomUserCommitment,
    getMyCoachingCommitmentsProgress,
    getAllDefaultCommitmentsProgress,
    editCoachingCommitmentProgress,
    deleteCustomUserCommitment,
    deleteDefaultToolCommitment,
    deleteCoachingCommitmentProgress,
    // getAllDefaultCommitmentsProgressForReportingEmployee
  } = props;

  const [activeDashboard, setActiveDashboard] = useState()

  async function handleDefaultCommitmentProgress(values) {
    setSubmitLoading(true);
    try {
      const response = await editCoachingCommitmentProgress(values);
      response && setSubmitLoading(false);
      return response && true;
    } catch (err) {
      setSubmitLoading(false);
      return false;
      // throw new Error(err.message);
    }
  }
console.log('activeDashboard776',activeDashboard)
  const handleDeleteCustomUserCommitment = async (id) => {
    try {
      const response = await deleteCustomUserCommitment(id);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleDeleteCoachingCommitmentProgress = async (id) => {
    try {
      // console.log(id);
      const response = await deleteCoachingCommitmentProgress(id);
      console.log("deleted");
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleDeleteDefaultToolCommitment = async (id) => {
    try {
      const response = await deleteDefaultToolCommitment(id);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  // async function handleCustomUserCommitment(values) {
  //   setSubmitLoading(true);
  //   try {
  //     const response = await editCustomUserCommitment(values);
  //     response && setSubmitLoading(false);
  //   } catch (err) {
  //     setSubmitLoading(false);
  //     throw new Error(err.message);
  //   }
  // }
console.log('getMyCoaching4tCommitme24ntsProgress ::', getMyCoachingCommitmentsProgress)
  return (
    <CommitmentView
    setActiveDashboard={setActiveDashboard}
    delPersonalComm={
      activeDashboard === "manager-personal"
        ? handleDeleteCoachingCommitmentProgress
        : handleDeleteDefaultToolCommitment
    }
    // delPersonalComm={handleDeleteCustomUserCommitment}
      title={'Coaching Commitments'}
      // customUserCommitments={getMyCoachingCommitmentsProgress}
      defaultCustomUserCommitments={getMyCoachingCommitmentsProgress}
      allDefaultCommitmentsProgress={getAllDefaultCommitmentsProgress}
      onDefaultCommitmentProgress={handleDefaultCommitmentProgress}
      // onCustomUserCommitment={handleCustomUserCommitment}
      commitmentKey={'coachingcommitment'}
      {...props}
    />
  );
};

export default compose(
  // withEditDefaultToolCommitment,
  withGetMyCoachingCommitmentsProgress,
  // withEditCustomUserCommitment
  withEditCoachingCommitmentProgress,
  withGetAllDefaultCommitmentsProgress,
  withDeleteCustomUserCommitment,
  withDeleteCoachingCommitmentProgress,
  withDeleteDefaultToolCommitment
)(MyCoachingCommitment);
