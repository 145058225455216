import * as React from 'react';
import { graphql } from '@apollo/client/react/hoc';

import { getItem, compose, removeItem } from '../../core';
// import ORGANIZATION_ROUTE from '../../organization/route';

import ProfileView from '../components/ProfileView';
import ROUTE from '../route';
import { withMe } from './UserOperations';

const ProfilePage = props => {
  return <ProfileView {...props} />;
};

export default compose(withMe)(ProfilePage);
