import gql from "graphql-tag";
import { CHOICE_TYPE_CONNECTION } from "./ChoiceTypeConnection.gql";

export const QUESTION_TYPE = gql`
  ${CHOICE_TYPE_CONNECTION}
  fragment QuestionType on QuestionType {
    id
    questionText
    helpText
    sequence
    group {
      id
      survey {
        id
      }
      # ...GroupType
    }
    pubDate
    required
    choiceSet {
      ...ChoiceTypeConnection
    }
  }
`;
