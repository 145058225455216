import React from 'react';
import _ from 'lodash';
import { Spin } from 'antd';

import { compose } from '../../core';
import { getIntFromString, ButtonsCatch, withMarkdownFieldValue } from '../../look';

import ROUTE from '../route';
import AddTeamView from '../components/AddTeamView';
import { CKEditorFieldName } from '../components/TeamFormComponent';

import { withAddTeam, withOrganizationState } from './OrganizationOperation';

const AddTeam = props => {
  const { history, addTeam, verId, setVisible } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    const managers = _.get(values, 'managers');
    const teamData = (({ name, description, verticalId }) => ({ name, description, verticalId }))(values);
    // const employees = (({ employees }) => ({ employees }))(values);

    try {
      const response = await addTeam({
        employees: values.employees,
        managers,
        teamData: withMarkdownFieldValue(CKEditorFieldName, { ...teamData, verticalId: verId })
      });
      response && setVisible(false);
      response &&
        ButtonsCatch(
          item,
          window.location.pathname,
          history,
          item === undefined
            ? `${ROUTE.editTeamLink}${getIntFromString(response.id)}`
            : `${window.location.pathname}?args=openmodal`
        );
      // response && ButtonsCatch(item, ROUTE.team, history);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddTeamView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withOrganizationState, withAddTeam)(AddTeam);
