import React from 'react';
import { Row, Col, Layout, Collapse } from 'antd';
import styled from 'styled-components';
import { LinkedinOutlined, FacebookFilled, TwitterCircleFilled } from '@ant-design/icons';

import LogoImg from '../assets/BrainAyan-Logo-Web.png';
import PAGE_ROUTE from '../page/route';
import { NODE_ENV } from '../../config';

const NewH3 = styled.h3`
  color: #cacaca;
`;
const NewA = styled.a`
  color: #898a82;
`;

const Footer = () => {
  const { Panel } = Collapse;
  const socialBtn = (
    <Row gutter={24}>
      <Col span={8}>
        <a href="https://www.facebook.com/buddyforlearning/" target="_blank" rel="noopener noreferrer">
          <FacebookFilled style={{ fontSize: 'x-large', color: '#AFB0A2' }} />
        </a>
      </Col>
      <Col span={8}>
        <a href="https://twitter.com/BrainayanLnD" target="_blank" rel="noopener noreferrer">
          <TwitterCircleFilled style={{ fontSize: 'x-large', color: '#AFB0A2' }} />
        </a>
      </Col>
      <Col span={8}>
        <a href="https://www.linkedin.com/company/brainayan" target="_blank" rel="noopener noreferrer">
          <LinkedinOutlined style={{ fontSize: 'x-large', color: '#AFB0A2' }} />
        </a>
      </Col>
    </Row>
  );
  return (
    <>
      <Layout.Footer style={{ backgroundColor: '#202020' }}>
        <Row style={{ padding: '3vh', marginBottom: '7vh' }}>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Row>
              <Col lg={{ span: 3, offset: 0 }} md={{ span: 4, offset: 0 }}>
                <img src={LogoImg} alt="" height={'30px'} style={{ marginBottom: '10px' }} />
                {socialBtn}
              </Col>
              <Col lg={{ span: 6, offset: 1 }} md={{ span: 5, offset: 1 }}>
                <NewH3>About Resilience</NewH3>
                <h4>
                  <NewA href={PAGE_ROUTE.about}>About Us</NewA>
                </h4>
              </Col>
              <Col lg={{ span: 6, offset: 1 }} md={{ span: 5, offset: 1 }}>
                <NewH3>Quick links</NewH3>
                <h4>
                  <NewA href={PAGE_ROUTE.faqPage}>FAQ</NewA>
                  <br />
                  <NewA href={PAGE_ROUTE.termsAndCondition}>Terms and Conditions</NewA>
                  <br />
                  <NewA href={PAGE_ROUTE.privacy}> Privacy policy</NewA>
                </h4>
              </Col>
              <Col lg={{ span: 6, offset: 1 }} md={{ span: 5, offset: 1 }}>
                <NewH3>Keep in touch</NewH3>
                <h4>
                  <NewA href={PAGE_ROUTE.contactUs}>Contact us</NewA>
                  <br />
                  {NODE_ENV !== 'production' && <NewA href="//#endregion">Need help?</NewA>}
                </h4>
              </Col>
            </Row>
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Row justify="center">
              <Col xs={24} align="center">
                <img src={LogoImg} alt="" height={'40px'} style={{ marginBottom: '20px' }} />
              </Col>
              <Col xs={24} align="center">
                {socialBtn}
              </Col>
            </Row>
            <br />
            <Collapse /* defaultActiveKey={['1']} */ expandIconPosition="right" ghost>
              <Panel header={<NewH3>About Resilience</NewH3>} key="1">
                <h4>
                  <NewA href={PAGE_ROUTE.about}>About Us</NewA>
                </h4>
              </Panel>
              <Panel header={<NewH3>Quick links</NewH3>} key="2">
                <h4>
                  <NewA href={PAGE_ROUTE.faqPage}>FAQ</NewA>
                  <br />
                  <br />
                  <NewA href={PAGE_ROUTE.termsAndCondition}>Terms and Conditions</NewA>
                  <br />
                  <br />
                  <NewA href={PAGE_ROUTE.privacy}> Privacy policy</NewA>
                </h4>
              </Panel>
              <Panel header={<NewH3>Keep in touch</NewH3>} key="3">
                <h4>
                  <NewA href={PAGE_ROUTE.contactUs}>Contact us</NewA>
                  <br />
                  <br />
                  {NODE_ENV !== 'production' && <NewA href="//#endregion">Need help?</NewA>}
                </h4>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Layout.Footer>
    </>
  );
};

export default Footer;
