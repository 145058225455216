import React from 'react';
import styled from 'styled-components';
import { Tag, Row, Col, Divider, Card, Switch, Tooltip, Empty, Form, Button } from 'antd';
import { MenuOutlined, EyeOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { compose } from '../../core';
import { DeleteIcon, ReactMarkdown, getIntFromString } from '../../look';

import { withEditFormSection } from '../../form-section/containers/FormSectionOperation';
import { subscribeToFormSection } from '../../form-section/containers/FormSectionSubscription';

import TextSectionComponent from '../../text-section/containers/TextSectionComponent';
import { withEditTextSection } from '../../text-section/containers/TextSectionOperations';
import { subscribeToTextSection } from '../../text-section/containers/TextSectionSubscription';
import SimpleReferenceComponent from '../../form-section/containers/SimpleReferenceComponent';

import AddComponent, { TargetedDiv } from './AddComponent';
import ROUTE from '../route';
import { useParams } from 'react-router-dom';

const TextFormSectionComponent = props => {
  const {
    formsection,
    textsection,
    onTextSectionDelete,
    onFormSectionDelete,
    setActionLoading,
    editTextSection,
    editFormSection,
    subscribeToMore,
    handleEditTool
  } = props;
  const [enableSorting, setEnableSorting] = React.useState(false);
  const { id } = useParams();
  React.useEffect(() => {
    const subscribe = subscribeToTextSection(subscribeToMore /* , props.filter */);
    const subscribe1 = subscribeToFormSection(subscribeToMore /* , props.filter */);
    return () => {
      subscribe();
      subscribe1();
    };
  });

  const [data, setData] = React.useState(
    [
      ...textsection?.edges.map(({ node }) => ({ ...node, type: 'textsection' })),
      ...formsection?.edges.map(({ node }) => ({ ...node, type: 'formsection' }))
    ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
  );

  React.useEffect(() => {
    setData(
      [
        ...textsection?.edges.map(({ node }) => ({ ...node, type: 'textsection' })),
        ...formsection?.edges.map(({ node }) => ({ ...node, type: 'formsection' }))
      ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
    );
  }, [formsection, textsection]);

  const onSort = async sortedItems => {
    setActionLoading(true);
    try {
      await Promise.all(
        sortedItems.map(async (i, index) => {
          // const response = await
          i.type === 'textsection'
            ? editTextSection({ id: getIntFromString(i.id), title: i.title, sequence: index + 1 })
            : editFormSection({ id: getIntFromString(i.id), title: i.title, sequence: index + 1 });
        })
      );
      setActionLoading(false);
    } catch (e) {
      setActionLoading(false);
      throw Error(e.message);
    }
  };

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999', margin: '-16px', padding: '16px' }} />
  ));

  const addComponent = (node, isForw = true) => (
    <AddComponent
      formSection={formsection}
      sequence={isForw ? node.sequence + 1 : node.sequence - 1}
      onSubmit={async (e, d) => {
        const response = await handleEditTool(e, d);
        onSort(
          arrayMove(
            [
              ...response?.textsection?.edges.map(({ node }) => ({ ...node, type: 'textsection' })),
              ...response?.formsection?.edges.map(({ node }) => ({ ...node, type: 'formsection' }))
            ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0)),
            node.sequence,
            d === 'formsection' ? node.sequence + 1 : node.sequence
          )
        );
      }}
    />
  );

  const SortableItem = SortableElement(({ node, idx }) =>
    node.type === 'textsection' ? (
      <StyledCol span={24}>
        {idx === 0 && addComponent(node, false)}
        <Card>
          <Row>
            <Col span={1} align="left">
              {enableSorting && <DragHandle />}
            </Col>

            <Col span={19} align="left">
              <Row gutter={24}>
                <Col>
                  <h3>{node.title}</h3>
                </Col>
                <Col>{node.editable ? <Tag color="success">Editable</Tag> : <Tag color="error">Not-editable</Tag>}</Col>
              </Row>
            </Col>

            <Col span={4} align="right">
              <TextSectionComponent textSection={node} />
              <Divider type="vertical" />
              <DeleteIcon
                tooltipSuffix="Text Section"
                type="link"
                size="sm"
                onClick={() => onTextSectionDelete(getIntFromString(node.id))}
              />
            </Col>

            <Col span={24}>
              <ReactMarkdown>{node.renderedText}</ReactMarkdown>
              <br />
            </Col>
          </Row>
        </Card>
        {addComponent(node)}
      </StyledCol>
    ) : (
      <Col span={24}>
        {idx === 0 && addComponent(node, false)}

        <SimpleReferenceComponent
          node={node}
          enableSorting={enableSorting}
          onFormSectionDelete={onFormSectionDelete}
          simplefield={node?.simpleField}
          referencefield={node?.referencefield}
          setActionLoading={setActionLoading}
          subscribeToMore={subscribeToMore}
        />
        {addComponent(node)}
      </Col>
    )
  );

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Row gutter={[24, 24]}>
        {items.length > 0 ? (
          <>
            {items.map((node, index) => (
              <SortableItem key={`item-${node.id}`} index={index} node={node} idx={index} />
            ))}
          </>
        ) : (
          <Col span={24} align="center">
            <br />
            <br />
            <br />
            <br />
            <Empty />
            <br />
            <br />
            <br />
            <br />
          </Col>
        )}
      </Row>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setData(arrayMove(data, oldIndex, newIndex));
    onSort(arrayMove(data, oldIndex, newIndex));
  };

  return (
    <Row>
      <Col lg={12} xs={24} align="left">
        <h2>Components</h2>
      </Col>

      <Col lg={12} xs={24} align="right">
        <Tooltip title={'Preview'}>
          <Button
            /* onClick={openPrivewTab} */ href={`${ROUTE.genericToolAdminLink}${id}`}
            target="_blank"
            disabled={data?.length === 0}
            icon={<EyeOutlined />}
            shape="circle"
          />
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip title={'Sort Sequence'}>
          <Switch checked={enableSorting} onChange={setEnableSorting} />
        </Tooltip>
        <Divider type="vertical" />
        <AddComponent formSection={formsection} onSubmit={(e, d) => handleEditTool(e, d)} />
      </Col>

      <Divider style={{ width: '100%', marginTop: '10px' }} />
      <Col lg={24}>
        <SortableList useDragHandle helperClass="row-dragging" items={data} onSortEnd={onSortEnd} />
      </Col>
    </Row>
  );
};

export default compose(withEditTextSection, withEditFormSection)(TextFormSectionComponent);

const StyledCol = styled(Col)`
  &:hover ~ ${TargetedDiv} {
    opacity: 1;
  }
`;
