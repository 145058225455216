import gql from 'graphql-tag';
import { COACHING_COMMITMENT_PROGRESS_TYPE } from './CoachingCommitmentProgressType.gql';

export const EDIT_COACHING_COMMITMENT_PROGRESS = gql`
  ${COACHING_COMMITMENT_PROGRESS_TYPE}
  mutation editCoachingCommitmentProgress($id: ID!, $completed: Boolean) {
    updateCoachingCommitmentProgress(id: $id, completed: $completed) {
        coachingCommitmentProgress {
            ...CoachingCommitmentProgressType,
        }
    }
  }
`;
