import * as React from 'react';

import { compose } from '../../core';

import PasswordSetView from '../components/PasswordSetView';
import { withPasswordResetMail, withPasswordSet } from './UserOperations';
import { message } from 'antd';

const PasswordSet = props => {
  const { passwordResetMail, passwordSet } = props;
  const handlePasswordSetMail = async email => {
    try {
      const response = await passwordResetMail(email);
      return response;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const handlePasswordSet = async values => {
    try {
      const response = await passwordSet(values);
      response.success ? message.success('Done!') : message.error('Failed, try again!');
      return response;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  return (
    <PasswordSetView onPasswordSetMailSubmit={handlePasswordSetMail} onPasswordSetSubmit={handlePasswordSet} {...props} />
  );
};

export default compose(withPasswordResetMail, withPasswordSet)(PasswordSet);
