import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Dropdown, Menu, message, Spin, Switch, Pagination, Empty } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { compose } from '../../core';
import {
  AdminLayout,
  CardLayout,
  displayDataCheck,
  GetColumnSearchProps,
  getIntFromString,
  getOrderBy,
  SelectFilterField
} from '../../look';

import ROUTE from '../route';

import { withToolState, withAllToolsAdminQuery, withToolFilterUpdating, withToolDeleting } from './ToolOperations';
// import { subscribeToTool } from './ToolSubscription';
import ReactMarkdown from '../../look/components/ReactMarkdown';
import { PRIMARY_CLR, PAGINATION_LIMIT, IMG } from '../../../config';
// import SelectFilterField from '../../look/components/SelectFilterField';

const Tool = props => {
  const {
    loading,
    getAllToolsAdmin,
    onPaginationChange,
    deleteTool,
    onTitleChange,
    onDescriptionChange,
    // orderBy,
    // onOrderByChange,
    onFiltersRemove,
    // subscribeToMore,
    // editTool,
    pagination: { offset, first }
  } = props;
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  // React.useEffect(() => {
  //   const subscribe = subscribeToTool(subscribeToMore /* , props.filter */);
  //   return () => subscribe();
  // });

  const handleSelect = id => {
    setSelectedRowKeys(
      selectedRowKeys.indexOf(id) === -1 ? [...selectedRowKeys, id] : selectedRowKeys.filter(s => s !== id)
    );
  };
  const paginationProps = !(getAllToolsAdmin && getAllToolsAdmin.totalCount)
    ? {}
    : {
        defaultCurrent: 1,
        showTotal: (total, range) =>
          getAllToolsAdmin.totalCount && (
            <>
              {/* {console.log(total, range, allToolsForTokens.totalCount)} */}
              {total < PAGINATION_LIMIT
                ? `Showing 1 to ${total} of ${total} entries.`
                : `Showing ${offset + 1} to ${offset + first} of ${total} entries.`}
            </>
          ),
        onChange: (page, pageSize) =>
          onPaginationChange({
            offset: page * pageSize - pageSize,
            first: pageSize
          }),
        defaultPageSize: PAGINATION_LIMIT,
        total: getAllToolsAdmin.totalCount,
        style: {
          textAlign: 'right'
        }
      };

  const handleDelete = async id => {
    setDeleteLoading(true);
    try {
      await deleteTool(id);
      message.success('Deleted!');
    } catch (e) {
      message.error('Failed, try again!');
      throw new Error(e.message);
    }
    setDeleteLoading(false);
  };
  const menu = id => (
    <Menu>
      <Menu.Item key="0">
        <Link to={`${ROUTE.editToolLink}${id}`}>Edit</Link>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => handleDelete(id)} danger={true}>
        Delete
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleSelect(id)}>
        Select
      </Menu.Item>
    </Menu>
  );
  const handleEditTool = async (e, field, id) => {
    let data = {};
    if (field === 'public') data = { public: e };
    if (field === 'shuffle') data = { shuffle: e };
    // try {
    //   await editTool({
    //     id: Number(getIntFromString(id)),
    //     ...data
    //   });
    // } catch (e) {
    //   throw new Error(e.message);
    // }
  };
  return (
    <CardLayout
      table={true}
      active={ROUTE.tool}
      title={[{ name: 'Tool' }]}
      loading={loading}
      tableData={getAllToolsAdmin}
      onDelete={deleteTool}
      editLink={ROUTE.editTool}
      addLink={ROUTE.addTool}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      selectedRowKeys={selectedRowKeys}
      extra={
        <SelectFilterField
          placeholder="Search Tool by"
          onFiltersRemove={onFiltersRemove}
          options={[
            {
              label: 'Title',
              type: 'text',
              value: onTitleChange
            },
            {
              label: 'Description',
              type: 'text',
              value: onDescriptionChange
            }
          ]}
        />
      }
    >
      <Spin spinning={loading || deleteLoading} size="large">
        <div style={{ minHeight: '70vh' }}>
          <Row gutter={[24, 24]}>
            {getAllToolsAdmin &&
              getAllToolsAdmin.edges.map(({ node }) => (
                <>
                  <Col xs={24} md={12} lg={8}>
                    <Dropdown overlay={() => menu(getIntFromString(node.id))} placement="bottomLeft">
                      <MoreOutlined
                        style={{
                          // fontSize: 'large',
                          position: 'absolute',
                          right: 20,
                          zIndex: 1,
                          top: 20
                        }}
                      />
                    </Dropdown>
                    <Link to={`${ROUTE.toolDetailLink}${getIntFromString(node.id)}`}>
                      <StyledCard
                        active={selectedRowKeys.indexOf(getIntFromString(node.id)) !== -1}
                        cover={
                          <img
                            alt={node.title}
                            src={node.thumbnailImage || IMG}
                            style={{ /* width: '100%', */ height: '250px' }}
                          />
                        }
                      >
                        <Row>
                          <Col>
                            <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>{node.title}</h3>
                            <h5 /* style={{ color: '#CACACA' }} */>
                              {node && <ReactMarkdown>{node.description}</ReactMarkdown>}
                            </h5>
                          </Col>
                        </Row>
                        <br />
                      </StyledCard>
                    </Link>
                    <Row
                      justify="space-between"
                      align="bottom"
                      style={{ position: 'relative', bottom: 80, padding: '0 24px' }}
                    >
                      <Col>
                        <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Status</h4>
                        <h4 style={{ marginBottom: 0, fontWeight: 'normal', color: PRIMARY_CLR }}>
                          {node.status ? 'Published' : 'Not-Published'}
                        </h4>
                      </Col>
                      <Col>
                        <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Public</h4>
                        <Switch checked={node.public} size="small" onClick={e => handleEditTool(e, 'public', node.id)} />
                      </Col>
                    </Row>
                  </Col>
                </>
              ))}
          </Row>
          {getAllToolsAdmin && getAllToolsAdmin.edgeCount === 0 && (
            <div className="HVCenter" style={{ width: '70vw' }}>
              <Empty description="No result found" />
            </div>
          )}
        </div>
        <br />
        <Pagination {...paginationProps} />
      </Spin>
    </CardLayout>
  );
};

export default compose(withToolState, withAllToolsAdminQuery, withToolDeleting, withToolFilterUpdating)(Tool);

const StyledCard = styled(Card)`
  height: 100%;
  border-color: ${props => props.active && PRIMARY_CLR};
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
  &:hover {
    border-color: ${props => PRIMARY_CLR};
  }
`;
