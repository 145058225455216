import gql from 'graphql-tag';
import { TOOL_TYPE } from './ToolType.gql';

export const TOOL_BY_ID_ADMIN_QUERY = gql`
  ${TOOL_TYPE}
  query getToolByIdAdmin($toolId: ID!) {
    getToolByIdAdmin(toolId: $toolId) {
      ...ToolType
    }
  }
`;
