import React from "react";
import { Spin } from "antd";

import { compose } from "../../core";
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from "../../look";

import EditVerticalView from "../components/EditVerticalView";
import ROUTE from "../route";

import { withVertical, withEditVertical, withVerticalByVerticalId } from "./OrganizationOperation";
import { CKEditorFieldName } from "../components/VerticalFormComponent";

const EditVertical = props => {
  const { history, editVertical, verticalByVerticalId } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    try {
      const response = await editVertical({
        id: getIntFromString(verticalByVerticalId.id),
        organizationId: getIntFromString(verticalByVerticalId.organization.id),
        ...withMarkdownFieldValue(CKEditorFieldName, values)
      });

      response &&
        ButtonsCatch(
          item,
          `${ROUTE.verticalDetailLink}${getIntFromString(verticalByVerticalId.id)}`,
          history,
          item === undefined ? window.location.pathname : `${ROUTE.organizationDetailLink}${getIntFromString(response.organization.id)}?args=openmodal`
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditVerticalView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(
  // withVertical,
  withVerticalByVerticalId,
  withEditVertical
)(EditVertical);
