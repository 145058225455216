// checkbox, color, date, datetime, email, file, hidden, month, number, password, radio, range, tel, text, time, url, week, select.
// For range type use value format as <Min>:<Max>:<Jump>:<Default>, 0:100:1:0

const InputValues = [
  { label: 'Checkbox', value: 'checkbox', timer: 10 },
  { label: 'Color', value: 'color', timer: 5 },
  { label: 'Date', value: 'date', timer: 5 },
  { label: 'Date-Time', value: 'datetime', timer: 5 },
  { label: 'Email', value: 'email', timer: 5 },
  { label: 'File', value: 'file', timer: 5 },
  // { label: 'Hidden', value: 'hidden', timer: 5 },
  { label: 'Month', value: 'month', timer: 5 },
  { label: 'Number', value: 'number', timer: 5 },
  // { label: 'Password', value: 'password', timer: 5 },
  { label: 'Radio', value: 'radio', timer: 5 },
  { label: 'Range', value: 'range', timer: 5 },
  // { label: 'Tel', value: 'tel', timer: 5 },
  { label: 'Text', value: 'text', timer: 5 }, // onfocus left
  { label: 'Time', value: 'time', timer: 5 },
  // { label: 'Url', value: 'url', timer: 5 },
  { label: 'Week', value: 'week', timer: 5 },
  { label: 'Drop-down', value: 'select', timer: 5 }
];

const InputValuesObject = {};
InputValues.map(i => {
  InputValuesObject[i.value] = i.value.toUpperCase();
});

export { InputValuesObject };
export default InputValues;
