import React, { useState } from 'react';

import { compose } from '../../core';
import { getIntFromString, withMarkdownFieldValue } from '../../look';

import { withAddCustomUserCommitment } from './DashboardOperations';
import CustomCommitFormComponentView, { CKEditorFieldName } from '../components/CustomCommitFormComponentView';
import { withMe } from '../../user/containers/UserOperations';

const CustomCommitFormComponent = props => {
  const { me, addCustomUserCommitment, setVisible } = props;
  const [dueDate, setDueDate] = useState()
  const [descForm, setDescForm] = useState()
  const [description, setDescription] = useState()

  const handleAdd = async values => {
    // console.log('va3flues ::-', values, dueDate, descForm)
    console.log('descrip8y8dtion',description)
    try {
      const response = await addCustomUserCommitment({
        customUserCommitmentData:  {
          ...values,
          userId: getIntFromString(me.id),
          dueDate: dueDate,
          description: description
        }
      });
      response && setVisible(false);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  return <CustomCommitFormComponentView setDescription={setDescription} setDescForm={setDescForm} onSubmit={handleAdd} setDueDate={setDueDate} {...props} />;
};

export default compose(withAddCustomUserCommitment, withMe)(CustomCommitFormComponent);
