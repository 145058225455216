import React from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { compose } from '../../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../../look';
import AddFaqQuestionView from './components/AddFaqQuestionView';
import ROUTE from '../../route';

import { withAddFaqQuestion, withPageState } from '../PageOperations';
import { CKEditorFieldName, CKEditorFieldName1 } from './components/FaqQuestionFormComponent';

const AddFaqQuestion = props => {
  const { history, addFaqQuestion } = props;
  const { id } = useParams();
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    try {
      const response = await addFaqQuestion({
        questionData: {
          catagoryId: id,
          ...withMarkdownFieldValue(CKEditorFieldName, withMarkdownFieldValue(CKEditorFieldName1, values))
          // ...withMarkdownFieldValue(CKEditorFieldName[1], withMarkdownFieldValue(CKEditorFieldName[0], values))
        }
      });
      response &&
        ButtonsCatch(
          item,
          false,
          history,
          item === undefined ? `${ROUTE.editFaqQuestionLink}${getIntFromString(response.id)}` : window.location.pathname
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddFaqQuestionView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withPageState, withAddFaqQuestion)(AddFaqQuestion);
