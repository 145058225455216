import gql from 'graphql-tag';
import { SIMPLE_FIELD_CHOICE_TYPE } from './SimpleFieldChoiceType.gql';

export const ADD_SIMPLE_FIELD_CHOICE_MUTATION = gql`
  ${SIMPLE_FIELD_CHOICE_TYPE}
  mutation addSimpleFieldChoice($simpleFieldChoiceData: SimpleFieldChoiceInput!) {
    createSimpleFieldChoice(simpleFieldChoiceData: $simpleFieldChoiceData) {
      simpleFieldChoice {
        ...SimpleFieldChoiceType
      }
    }
  }
`;
