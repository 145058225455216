import { graphql } from '@apollo/client/react/hoc';

import { withOperation } from '../../look/components/functions';

import { GET_ALL_FORM_SECTIONS } from '../graphql/GetAllFormSections.gql';
import { GET_FORM_SECTION_RESPONSE_BY_ID_QUERY } from '../../form-section/graphql/GetFormSectionResponseById.gql';

import { ADD_FORM_SECTION_MUTATION } from '../graphql/AddFormSectionMutation.gql';
import { ADD_SIMPLE_FIELD_MUTATION } from '../graphql/AddSimpleFieldMutation.gql';
import { ADD_SIMPLE_FIELD_RESPONCE_MUTATION } from '../graphql/AddSimpleFieldResponceMutation.gql';
import { ADD_SIMPLE_FIELD_CHOICE_MUTATION } from '../graphql/AddSimpleFieldChoiceMutation.gql';
import { ADD_REFERENCE_FIELD_MUTATION } from '../graphql/AddReferenceFieldMutation.gql';

import { EDIT_FORM_SECTION } from '../graphql/EditFormSection.gql';
import { EDIT_SIMPLE_FIELD } from '../graphql/EditSimpleField.gql';
import { EDIT_REFERENCE_FIELD } from '../graphql/EditReferenceField.gql';
import { EDIT_SIMPLE_FIELD_CHOICE } from '../graphql/EditSimpleFieldChoice.gql';
import { EDIT_SIMPLE_FIELD_RESPONCE } from '../graphql/EditSimpleFieldResponce.gql';

import { DELETE_FORM_SECTION } from '../graphql/DeleteFormSection.gql';
import { DELETE_SIMPLE_FIELD_CHOICE } from '../graphql/DeleteSimpleFieldChoice.gql';
import { TOOL_BY_ID_ADMIN_QUERY } from '../../tool/graphql/ToolByIdAdmin.gql';

export const getFormSectionResponseById = Component =>
  graphql(GET_FORM_SECTION_RESPONSE_BY_ID_QUERY, {
    options: ({ formSectionId }) => {
      return {
        variables: { formSectionId: Number(formSectionId) }
      };
    },
    props({ data: { loading, error, getFormSectionResponseById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return {
        loading,
        error,
        getFormSectionResponseById: getFormSectionResponseById?.edges[0]?.node,
        subscribeToMore,
        updateQuery
      };
    }
  })(Component);

export const withAddFormSection = Component =>
  withOperation({
    mutation: ADD_FORM_SECTION_MUTATION,
    funcName: 'addFormSection',
    query: GET_ALL_FORM_SECTIONS,
    queryName: 'getAllForm',
    node: ['createFormSection', 'formSection'],
    type: 'add'
    // variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withAddSimpleField = Component =>
  withOperation({
    mutation: ADD_SIMPLE_FIELD_MUTATION,
    funcName: 'addSimpleField',
    query: GET_ALL_FORM_SECTIONS,
    queryName: 'getAllForm',
    node: ['createSimpleField', 'simpleField'],
    type: 'add'
    // variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withAddSimpleFieldResponce = Component =>
  withOperation({
    mutation: ADD_SIMPLE_FIELD_RESPONCE_MUTATION,
    funcName: 'addSimpleFieldResponce',
    query: GET_ALL_FORM_SECTIONS,
    queryName: 'getAllForm',
    node: ['createSimpleFieldResponce', 'simpleFieldResponce'],
    type: 'add'
    // variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withAddSimpleFieldChoice = Component =>
  withOperation({
    mutation: ADD_SIMPLE_FIELD_CHOICE_MUTATION,
    funcName: 'addSimpleFieldChoice',
    query: GET_ALL_FORM_SECTIONS,
    queryName: 'getAllForm',
    node: ['createSimpleFieldChoice', 'simpleFieldChoice'],
    type: 'add'
    // variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withAddReferenceField = Component =>
  withOperation({
    mutation: ADD_REFERENCE_FIELD_MUTATION,
    funcName: 'addReferenceField',
    query: GET_ALL_FORM_SECTIONS,
    queryName: 'getAllForm',
    node: ['createReferenceField', 'referenceField'],
    type: 'add'
    // variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withEditFormSection = Component =>
  graphql(EDIT_FORM_SECTION, {
    props: ({ mutate }) => ({
      editFormSection: async values => {
        try {
          const {
            data: { updateFormSection }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateFormSection.formSection;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditSimpleField = Component =>
  graphql(EDIT_SIMPLE_FIELD, {
    props: ({ mutate }) => ({
      editSimpleField: async values => {
        try {
          const {
            data: { updateSimpleField }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateSimpleField.simpleField;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditSimpleFieldChoice = Component =>
  graphql(EDIT_SIMPLE_FIELD_CHOICE, {
    props: ({ mutate }) => ({
      editSimpleFieldChoice: async values => {
        try {
          const {
            data: { updateSimpleFieldChoice }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateSimpleFieldChoice.simpleFieldChoice;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditSimpleFieldResponce = Component =>
  graphql(EDIT_SIMPLE_FIELD_RESPONCE, {
    props: ({ mutate }) => ({
      editSimpleFieldResponce: async values => {
        try {
          const {
            data: { updateSimpleFieldResponce }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateSimpleFieldResponce.simpleFieldResponce;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditReferenceField = Component =>
  graphql(EDIT_REFERENCE_FIELD, {
    props: ({ mutate }) => ({
      editReferenceField: async values => {
        try {
          const {
            data: { updateReferenceField }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateReferenceField.referenceField;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withDeleteFormSection = Component =>
  withOperation({
    mutation: DELETE_FORM_SECTION,
    mutationVarName: 'id',
    funcName: 'deleteFormSection',
    query: TOOL_BY_ID_ADMIN_QUERY,
    queryName: ['getToolByIdAdmin', 'formsection'],
    node: ['deleteFormSection', 'formSection'],
    type: 'delete',
    variable: { type: 'all', custom: true, varName: 'toolId' }
  })(Component);

export const withDeleteSimpleFieldChoice = Component =>
  withOperation({
    mutation: DELETE_SIMPLE_FIELD_CHOICE,
    mutationVarName: 'id',
    funcName: 'deleteSimpleFieldChoice',
    // query: ALL_QUESTIONS_FOR_A_GROUP_BY_GROUP_ID_QUERY,
    // queryName: 'allQuestionsForAGroupByGroupId',
    node: ['deleteSimpleFieldChoice', 'simpleFieldchoice'],
    type: 'delete'
    // variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);
