import React from 'react';
import styled from 'styled-components';
import { withApollo } from '@apollo/client/react/hoc';
import { useParams } from 'react-router-dom';
import { Spin, Button, Result, Modal, Row, Col } from 'antd';

import { compose } from '../../core';

import ROUTE from '../route';

import { EMAIL_PERCEPTION_REPORT_QUERY } from '../graphql/EmailPerceptionReport.gql';
import { EMAIL_PREDICTION_REPORT_QUERY } from '../graphql/EmailPredictionReport.gql';

import { withResponseByResponseId } from './SurveyOperations';
import { generateReport, getIntFromString, MetaTags } from '../../look';

const SurveyResponse = props => {
  const { loading, responseByResponseId, client, history } = props;
  const [visible, setVisible] = React.useState(false);
  const [reportLoading, setReportLoading] = React.useState(false);
  const { id } = useParams();

  // const generateReport = async () => {
  //   setReportLoading(true);

  //   try {
  //     if (Number(getIntFromString(responseByResponseId.survey.id)) === 1) {
  //       const { data } = client.query({
  //         query: EMAIL_PERCEPTION_REPORT_QUERY,
  //         variables: { responseId: id }
  //       });
  //     } else if (Number(getIntFromString(responseByResponseId.survey.id)) === 2) {
  //       const { data } = client.query({
  //         query: EMAIL_PREDICTION_REPORT_QUERY,
  //         variables: { responseId: id }
  //       });
  //     }
  //     setVisible(true);
  //     setReportLoading(false);
  //   } catch (e) {
  //     setReportLoading(false);
  //     // message.error('Failed! try again.');
  //     // throw new Error(e.message);
  //   }
  // };

  function handleCancel() {
    setVisible(false);
    history.push(ROUTE.allOrganizationSurvey);
  }

  const EmailSent = () => (
    <>
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        {/* <MailSent>
          <CheckCircleFilled style={{ color: '#009c4a', fontSize: '25px' }} /> &nbsp; Email Sent! <br />
        </MailSent> */}
        <h3>
          This could take a minute.
          <br />
          Generated report will be sent to your registered email.
        </h3>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <a>
              <Button type="primary" ghost onClick={handleCancel} size="middle">
                Close
              </Button>
            </a>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <a>
              <Button type="primary" ghost onClick={handleCancel} size="middle">
                Close
              </Button>
            </a>
          </Col>
        </Row>
      </div>
    </>
  );

  const handleGetFunc = () => {
    try {
      generateReport(id, getIntFromString(responseByResponseId.survey.id), client);
    } catch (err) {}
    setVisible(true);
  };

  return (
    <div className="HVCenter">
      <Spin
        spinning={loading || reportLoading}
        size="large"
        tip={reportLoading ? <span style={{ color: '#000' }}>This could take few minutes...</span> : ''}
      >
        <MetaTags title="response" description="This is response page" />
        {responseByResponseId && (
          <Spin spinning={loading} size="large">
            <Result
              status="success"
              title="Thanks for taking the Workplace Resilience survey."
              subTitle="Click the button below to get an email with your report."
              extra={[
                <a>
                  {responseByResponseId?.survey && (
                    <Button type="primary" onClick={handleGetFunc}>
                      Get Your Report
                    </Button>
                  )}
                </a>,
                <a>
                  <Button
                    type="primary"
                    ghost
                    // onClick={generateReport}
                    onClick={() => history.push(ROUTE.allOrganizationSurvey)}
                  >
                    Cancel
                  </Button>
                </a>
              ]}
            />
          </Spin>
        )}
      </Spin>

      <Modal footer={null} centered visible={visible} onCancel={handleCancel} bodyStyle={{ padding: '0px' }}>
        <ModalBody>
          <EmailSent />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default compose(withApollo, withResponseByResponseId)(SurveyResponse);

// const MailSent = styled.div`
//   color: #bbd33e;
//   font-size: 21px;
//   margin-bottom: 10px;
// `;

const ModalBody = styled.div`
  padding: 24px 48px;
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;
