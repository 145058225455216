import gql from 'graphql-tag';
import { TAG_TYPE } from './TagType.gql';

export const ADD_TAG = gql`
  ${TAG_TYPE}
  mutation addTag($tagData: TagInput!) {
    createTag(tagData: $tagData) {
      tag {
        ...TagType
      }
    }
  }
`;
