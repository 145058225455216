import gql from 'graphql-tag';

export const LOOK_SURVEY_STATE_QUERY = gql`
  query lookSurveyState {
    lookSurveyState @client {
      filter {
        first
        name_Icontains
        questionText_Icontains
      }
      orderBy
    }
  }
`;
