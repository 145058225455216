import React from 'react';
import _ from 'lodash';
import { Spin } from 'antd';

import { graphql } from '@apollo/client/react/hoc';
import { compose } from '../../core';
// import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../look';

import EditCommitmentView from '../components/AddCommitmentView';
import { COMMITMENT_BY_ID_QUERY } from '../graphql/CommitmentById.gql';

import { withEditCommitment /* withSurveyState */ } from './ToolOperations';
import ROUTE from '../route';
import { EditIcon, getIntFromString, withMarkdownFieldValue } from '../../look';
import { CKEditorFieldName } from '../components/ToolFormComponent';
import { withAddCoachingCommitment, withEditCoachingCommitment } from '../../dashboard/containers/DashboardOperations';

const EditCommitment = props => {
  const {
    history,
    editCommitment,
    onSubmit,
    getCommitmentById,
    setSubmitLoading,
    addCoachingCommitment,
    editCoachingCommitment
  } = props;
  const [visible, setVisible] = React.useState(false);

  const handleSubmit = async (values, condition, coachingCommitment) => {
    setSubmitLoading(true);
    try {
      const response = await editCommitment(
        withMarkdownFieldValue(CKEditorFieldName, { id: getIntFromString(getCommitmentById.id), ...values, condition })
      );
      await Promise.all(
        coachingCommitment.map(async ({ node }) => {
          if (node.id) {
            await editCoachingCommitment(
              _.omit({
                ...withMarkdownFieldValue(CKEditorFieldName, {
                  ...node,
                  commitmentId: getIntFromString(response.id)
                })
              }),
              'index'
            );
          } else {
            await addCoachingCommitment({
              coachingCommitmentData: _.omit(
                withMarkdownFieldValue(CKEditorFieldName, {
                  ...node,
                  commitmentId: getIntFromString(response.id)
                }),
                'index'
              )
            });
          }
        })
      );
      response && setVisible(false);
      // response && onSubmit(response.id, response);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <>
      <EditIcon type="link" tooltipSuffix="Commitment" size="sm" onClick={() => setVisible(true)} />
      <EditCommitmentView
        title={'Edit Commitment'}
        onSubmit={handleSubmit}
        visible={visible}
        setVisible={setVisible}
        {...props}
      />
    </>
  );
};

export default compose(
  /* withSurveyState, */
  // graphql(COMMITMENT_BY_ID_QUERY, {
  //   options: props => {
  //     let id = '';
  //     if (props.commitmentId) {
  //       id = props.commitmentId;
  //     }
  //     return {
  //       variables: { commitmentId: Number(id) }
  //     };
  //   },
  //   props({ data: { loading, error, getCommitmentById, subscribeToMore, updateQuery } }) {
  //     if (error) {
  //       throw new Error(error.message);
  //     }
  //     return { loading, error, getCommitmentById, subscribeToMore, updateQuery };
  //   }
  // }),
  withEditCommitment,
  withEditCoachingCommitment,
  withAddCoachingCommitment
)(EditCommitment);
