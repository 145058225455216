import gql from 'graphql-tag';
import { DEFAULT_COMMITMENTS_PROGRESS_TYPE } from '../../dashboard/graphql/DefaultCommitmentProgressType.gql';

export const GET_ALL_DEFAULT_COMMITMENTS_PROGRESS_FOR_REPORTING_EMPLOYEE = gql`
  ${DEFAULT_COMMITMENTS_PROGRESS_TYPE}
  query getAllDefaultCommitmentsProgressForReportingEmployee(
    $employeeId: ID!
    $orderBy: [String]
    $offset: Int
    $first: Int
  ) {
    getAllDefaultCommitmentsProgressForReportingEmployee(
      employeeId: $employeeId
      orderBy: $orderBy
      offset: $offset
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...DefaultCommitmentProgressType
        }
        cursor
      }
      # edgeCount
      # totalCount
    }
  }
`;
