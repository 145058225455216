import * as React from 'react';
import { Spin } from 'antd';

import { withContactRequest } from './PageOperations';
import ContactUsView from '../components/ContactUsView';

const ContactUs = props => {
  const { contactRequest } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async values => {
    setSubmitLoading(true);
    try {
      const response = await contactRequest(values);
      setSubmitLoading(false);
      return response;
    } catch (e) {
      setSubmitLoading(false);
      throw new Error(e.message);
    }
  };

  return (
    <>
      <Spin spinning={submitLoading} size="large">
        <ContactUsView onSubmit={handleSubmit} {...props} />
      </Spin>
    </>
  );
};

export default withContactRequest(ContactUs);
