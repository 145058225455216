import React from 'react';

import { compose } from '../../core';
import { withMarkdownFieldValue } from '../../look';
import { withMe } from '../../user/containers/UserOperations';

import IncomingRequestView from '../components/IncomingRequestView';
import { CKEditorFieldName } from '../components/AcceptCompareRequest';

import {
  withAllComparisonRequestForUser,
  withDeleteResponseForCompareRequest,
  withResponseCompareRequest,
  withDashboardState,
  withDashboardFilterUpdating
} from './DashboardOperations';
import { subscribeToSurveyComparisonRequestForUser } from './DashboardSubscriptions';

const IncomingRequest = props => {
  const { me, respondToResponseCompareRequest, deleteResponseCompareRequest, subscribeToMore } = props;

  React.useEffect(() => {
    const subscribe = subscribeToSurveyComparisonRequestForUser(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  async function handleAccept(values) {
    const input = withMarkdownFieldValue(CKEditorFieldName, values);

    try {
      const response = await respondToResponseCompareRequest({
        ...input,
        message: input?.message?.replace('<p>', `<p>${me?.firstName || ''} ${me?.lastName || ''}: `)
      });
      return response;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  async function handleCancel(id) {
    try {
      await deleteResponseCompareRequest(id);
    } catch (err) {
      throw new Error(err.message);
    }
  }

  return <IncomingRequestView onAccept={handleAccept} onCancel={handleCancel} {...props} />;
};

export default compose(
  withDashboardState,
  withMe,
  withAllComparisonRequestForUser,
  withResponseCompareRequest,
  withDeleteResponseForCompareRequest,
  withDashboardFilterUpdating
)(IncomingRequest);
