import React from 'react';
import { Form, Row, Col, DatePicker, TimePicker } from 'antd';
import moment from 'moment';

const FormItem = Form.Item;

const DatePickerField = props => {
  const { margin, type, offset = 1, enabledisabledDate, ...rest } = props;
  function disabledDate(current) {
    let customDate = new Date();
    return !enabledisabledDate ? false : current && current < moment(customDate, 'YYYY-MM-DD');
  }
  return (
    <div style={{ marginBottom: margin ? '5vh' : '' }}>
      <Row>
        <Col span={margin ? 14 : 20}>
          <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset }} {...rest}>
            {type === 'time' ? (
              <TimePicker {...props} />
            ) : (
              <DatePicker
                style={{ width: '100%', height: '36px' }}
                format="YYYY/MM/DD"
                disabledDate={disabledDate}
                {...props}
              />
            )}
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

export default DatePickerField;
