import React, { useEffect, useState }  from 'react';
import { Row, Col, Card, Divider, Button, Spin, Empty } from 'antd';
import styled from 'styled-components';

import { compose } from '../../core';
import { Link } from 'react-router-dom';
import { withTeams } from '../../organization/containers/OrganizationOperation';
import STORY_ROUTES from '../../story/route';
import { getIntFromString } from '../../look';
import { IMG, USER_IMG } from '../../../config';
import '../css/ManagerTeamComponent.css'

const ManagerTeamComponent = props => {
  const { me, activeEmployee, setActiveEmployee, setActiveUser, selectedMember,setSelectedMember,activeUser } = props;
  console.log('me query data for the team member', me, activeEmployee, activeUser,selectedMember)

  return (
    <>
      <Col lg={{ span: 24 }} xs={{ span: 22 }} style={{padding:'0px'}}>
        <Card
        // className='t_mem_card'
          bodyStyle={{
            boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
            borderRadius: '2%'
          }}
          bordered={false}
        >
          <Row>
            <h2 style={{ fontWeight: 'normal',margin:'0 0 1em 0' }}>Team</h2>
          </Row>
          <Spin style={{maxWidth:'100%',overflow:'auto'}} spinning={false}>
            <Row wrap={false}
              style={{
                overflowX:'auto',gap:'1em'
              }}>
              {me?.employee?.teamManagerEmployee?.edges?.length ? (
                me?.employee?.teamManagerEmployee?.edges?.map(
                  ({ node }, index) =>
                    node.employees &&
                    node.employees.edges.length > 0 &&
                    node.employees.edges.map(({ node: emp }) => (
                      <div style={{width:'16%'}} >
                        <CutomCard
                        style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}
                          hoverable
                          bordered={false}
                          bodyStyle={{ padding: 4 }}
                          onClick={() => {
                            setActiveEmployee(getIntFromString(emp.id));
                            setActiveUser(getIntFromString(emp.user.id));
                            setSelectedMember({...selectedMember, userId: emp.user.id, employeeId: emp.id})
                          }}
                          active={activeEmployee === getIntFromString(emp.id)}
                        >
                              <img
                                src={USER_IMG}
                                alt=""
                                style={{
                                  width: '5em',
                                  
                                  borderRadius: '50%'
                                }}
                              />

                            <p style={{overflow:'hidden', wordWrap:'break-word',textAlign:'center',textOverflow:'ellipsis',whiteSpace:'nowrap',maxWidth:'6em'}}>{emp?.user?.username}</p>
                        </CutomCard>
                      </div>
                    ))
                )
              ) : (
                <Empty />
              )}
            </Row>
            <br />
          </Spin>
        </Card>
      </Col>

      <Col lg={0} xs={1}></Col>
    </>
  );
};

export default ManagerTeamComponent;

const CutomCard = styled(Card)`
  border-color: ${props => props.active && 'transparent'};
  box-shadow: ${props =>
    props.active && '0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)'};
  word-wrap: break-word;
`;
