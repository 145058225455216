import React from 'react';

import { AdminLayout } from '../../look';

import { token } from '../route';
import TokenFormComponent from './TokenFormComponent';

const AddTokenView = props => {
  return <AdminLayout table={false} active={token} title="Add Token" FormComponent={<TokenFormComponent {...props} />} />;
};

export default AddTokenView;
