import React from 'react';

import { MetaTags, UserLayout } from '../../look';

const Dashboard = props => {
  const { active } = props;

  return (
    <UserLayout active={active}>
      <MetaTags title="Resilience Toolkit" description="This is Dashboard page" />
      {props.children}
    </UserLayout>
  );
};

export default Dashboard;
