import gql from 'graphql-tag';
import { PROFILE_TYPE } from './ProfileType.gql';

export const GET_USER_IMPLICIT = gql`
  ${PROFILE_TYPE}
  query getProfileImplicit {
    getProfileImplicit {
      ...ProfileType
    }
  }
`;
