import { graphql } from '@apollo/client/react/hoc';

import { withOperation } from '../../look';
import { removeTypename } from '../../core';

// Query
import { TEAMS_RESILIENCE_ANCHORS } from '../graphql/TeamsResilienceAnchors.gql';
import { EMPLOYEE_RESILIENCE_ANCHORS } from '../graphql/EmployeeResilienceAnchors.gql';
import { GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE } from '../graphql/GetAllCustomUserCommitmentsForReportingEmployee.gql';
import { GET_ALL_DEFAULT_COMMITMENTS_PROGRESS_FOR_REPORTING_EMPLOYEE } from '../graphql/GetAllDefaultCommitmentsProgressForReportingEmployee.gql';
import { GET_MY_COACH_COMMITMENTS_PROGRESS } from '../graphql/GetMyCoachCommitmentsProgress.gql';

// Mutation
import { DELETE_COACHING_COMMITMENT_PROGRESS } from '../../dashboard/graphql/DeleteCoachingCommitmentProgress.gql';
import { ADD_COACHING_COMMITMENT_PROGRESS } from '../../dashboard/graphql/AddCoachingCommitmentProgress.gql';
import { EDIT_COACHING_COMMITMENT_PROGRESS } from '../../dashboard/graphql/EditCoachingCommitmentProgress.gql';

// State
import { REPORTING_TEAMS_RESILIENCE_ANCHORS_STATE_QUERY } from '../graphql/ReportingTeamsResilienceAnchorsStateQuery.client.gql';
import { UPDATE_REPORTING_TEAMS_RESILIENCE_ANCHORS_FILTER } from '../graphql/UpdateReportingTeamsResilienceAnchorsFilter.client.gql';
import { GET_MY_CUSTOMIZED_COACHING_COMMITMENTS_QUERY } from '../graphql/GetMyCustomizedCoachingCommitments.gql';
import { ADD_CUSTOMIZED_COACHING_COMMITMENT_MUTATION } from '../graphql/AddCustomizedCoachingCommitment.gql';

// Query
export const withReportingTeamsResilienceAnchorsState = Component =>
  graphql(REPORTING_TEAMS_RESILIENCE_ANCHORS_STATE_QUERY, {
    props({ data }) {
      const { ...rest } = data.reportingTeamsResilienceAnchorsState;
      const reportingTeamsResilienceAnchorsState = {
        ...removeTypename(rest)
      };
      return { ...reportingTeamsResilienceAnchorsState, stateLoading: data.loading };
    }
  })(Component);

export const withGetMyCoachingCommitmentsProgress = Component =>
  graphql(GET_MY_COACH_COMMITMENTS_PROGRESS, {
    options: ({ filter, orderBy, pagination, forUser }) => {
      return { variables: { ...pagination, ...filter, orderBy, forUser } };
    },
    props({ data }) {
      const { loading, error, getMyCoachingCommitmentsProgress, subscribeToMore, updateQuery } = data;
      return { loading, error, getMyCoachingCommitmentsProgress, subscribeToMore, updateQuery };
    }
  })(Component);

  export const withGetMyCoachingCommitmentsProgressWithUser = Component =>
  graphql(GET_MY_COACH_COMMITMENTS_PROGRESS, {
    options: ({ forUser }) => {
      console.log('foruser in method:',forUser)
      return { variables: { forUser } };
    },
    props({ data }) {
      const { loading, error, getMyCoachingCommitmentsProgress, subscribeToMore, updateQuery } = data;
      return { loading, error, getMyCoachingCommitmentsProgress, subscribeToMore, updateQuery };
    }
  })(Component);

export const withGetAllCustomUserCommitmentsForReportingEmployee = Component =>
  graphql(GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE, {
    props({ data }) {
      const { loading, error, getAllCustomUserCommitmentsForReportingEmployee, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllCustomUserCommitmentsForReportingEmployee, subscribeToMore, updateQuery };
    }
  })(Component);

export const withGetAllDefaultCommitmentsProgressForReportingEmployee = Component =>
  graphql(GET_ALL_DEFAULT_COMMITMENTS_PROGRESS_FOR_REPORTING_EMPLOYEE, {
    props({ data }) {
      const { loading, error, getAllDefaultCommitmentsProgressForReportingEmployee, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllDefaultCommitmentsProgressForReportingEmployee, subscribeToMore, updateQuery };
    }
  })(Component);

export const withTeamsResilienceAnchors = Component =>
  graphql(TEAMS_RESILIENCE_ANCHORS, {
    options: ({ filter }) => {
      return {
        variables: { ...filter }
      };
    },
    props({ data }) {
      const { loading, error, reportingTeamsResilienceAnchors, subscribeToMore, updateQuery } = data;
      return { loading, error, reportingTeamsResilienceAnchors, subscribeToMore, updateQuery };
    }
  })(Component);

export const withEmployeeResilienceAnchors = Component =>
  graphql(EMPLOYEE_RESILIENCE_ANCHORS, {
    options: ({ employeeId }) => {
      return {
        variables: { employeeId }
      };
    },
    props({ data }) {
      const { loading, error, reportingEmployeesResilienceAnchors, subscribeToMore, updateQuery } = data;
      return { loading, error, reportingEmployeesResilienceAnchors, subscribeToMore, updateQuery };
    }
  })(Component);

export const withDeleteCoachingCommitmentProgress = Component =>
  withOperation({
    mutation: DELETE_COACHING_COMMITMENT_PROGRESS,
    mutationVarName: 'id',
    funcName: 'deleteCoachingCommitmentProgress',
    query: GET_MY_COACH_COMMITMENTS_PROGRESS,
    queryName: 'getMyCoachingCommitmentsProgress',
    node: ['deleteCoachingCommitmentProgress', 'coachingCommitmentProgress'],
    type: 'delete',
    variable: { type: 'all', custom: false, varName: 'forUser' }
  })(Component);

export const withAddCoachingCommitmentProgress = Component =>
  withOperation({
    mutation: ADD_COACHING_COMMITMENT_PROGRESS,
    funcName: 'addCoachingCommitmentProgress',
    query: GET_MY_COACH_COMMITMENTS_PROGRESS,
    queryName: 'getMyCoachingCommitmentsProgress',
    node: ['createCoachingCommitmentProgress', 'coachingCommitmentProgress'],
    type: 'add',
    variable: { type: 'all', custom: false, varName: 'forUser' }
  })(Component);

export const withEditCoachingCommitmentProgress = Component =>
  withOperation({
    mutation: EDIT_COACHING_COMMITMENT_PROGRESS,
    funcName: 'editCoachingCommitmentProgress',
    query: GET_MY_COACH_COMMITMENTS_PROGRESS,
    queryName: 'getMyCoachingCommitmentsProgress',
    node: ['updateCoachingCommitmentProgress', 'coachingCommitmentProgress'],
    type: 'edit'
    // variable: { type: 'all', custom: false, varName: 'forUser' }
  })(Component);
export const withReportingTeamsResilienceAnchorsFilterUpdating = Component =>
  graphql(UPDATE_REPORTING_TEAMS_RESILIENCE_ANCHORS_FILTER, {
    props: ({ mutate }) => ({
      onIncludingManagerChange(includingManager) {
        mutate({ variables: { filter: { includingManager } } });
      }
    })
  })(Component);

export const withMyCustomizedCoachingCommitments = Component =>
  graphql(GET_MY_CUSTOMIZED_COACHING_COMMITMENTS_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getMyCustomizedCoachingCommitments, subscribeToMore, updateQuery } = data;
      return { loading, error, getMyCustomizedCoachingCommitments, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAddCustomizedCoachingCommitment = Component =>
  withOperation({
    mutation: ADD_CUSTOMIZED_COACHING_COMMITMENT_MUTATION,
    funcName: 'addCustomizedCoachingCommitment',
    query: GET_MY_CUSTOMIZED_COACHING_COMMITMENTS_QUERY,
    queryName: 'createCustomizedCoachingCommitment',
    node: ['createCustomizedCoachingCommitment', 'coachingCommitment'],
    type: 'add'
  })(Component);
