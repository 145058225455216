import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import { Tooltip } from 'antd';
import '../css/survey-look.css'
// import up_arrow from '../../assets/input-number-up.svg'
// import down_arrow from '../../assets/input-number-down.svg'
import {EyeInvisibleOutlined,EyeOutlined} from '@ant-design/icons'
const SurveyTextField = props => {
  const { type, value, onChange, disabled, offset = 1, onBlur,margin,placeholder,widthscale ,...rest } = props;
  const FormItem = Form.Item;
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [number_value,setNumberValue] = React.useState();
  const { TextArea } = Input;
  const customFormatter = (value) => {
    // Remove any non-numeric characters from the input value
    let numericValue = value.replace(/[^0-9.]/g, '');
    if(numericValue){
      setNumberValue(numericValue)
      return numericValue;
    }
    else{
      setNumberValue()
    }
  };
  const handleParser = (value) => {
    // Parse the input value as a number
    // If the input is empty or non-numeric, return null to clear the input
    return parseFloat(value);
  };

  return (
    <div style={{ marginBottom: margin?margin:'5vh', width:widthscale?widthscale:'100%' }}>
      <FormItem {...rest}>
        {type === 'textarea' ? (
          <TextArea className="generic-survey-input-filed"  style={{resize:"none"}}defaultValue={value} rows={6} onChange={onChange} disabled={disabled} />
        ) : type === 'number' ? (
          <NumericInput defaultValue={value} onChange={onChange} disabled={disabled} />
        ) :type ==='password'?
        <Input.Password 
        className="generic-survey-input-filed" 
        iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
        defaultValue={value} placeholder={placeholder||''} onChange={onChange} onBlur={onBlur} disabled={disabled}/>:
        type ==='url'?
        <Input type='url' className="generic-survey-input-filed" defaultValue={value} placeholder={placeholder||''} onChange={onChange} onBlur={onBlur} disabled={disabled} />:
         (
          <Input className="generic-survey-input-filed" defaultValue={value} placeholder={placeholder||''} onChange={onChange} onBlur={onBlur} disabled={disabled} />
        )}
      </FormItem>
    </div>
  );
};

export default SurveyTextField;

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e?.target;
    const reg = /^[0-9]+$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(Number(value));
      console.log("number",value)
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (!(typeof value === 'number' && isFinite(value))) {
      if (value?.charAt(value?.length - 1) === '.' || value === '-') {
        valueTemp = value?.slice(0, -1);
      }
      onChange(valueTemp?.replace(/0*(\d+)/, '$1'));
      if (onBlur) {
        onBlur();
      }
    }
  };

  render() {
    const { value,placeholder } = this.props;
    const reg = /^[0-9]+$/;
    const title = value ? (
      <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    ) : (
      'Input a number'
    );
    return (
      <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
        <Input className="generic-survey-input-filed" {...this.props} value={reg.test(value)?value:''}  onChange={this.onChange} onBlur={this.onBlur}  min={0} maxLength={25} 
         pattern="[0-9]*"
        />
      </Tooltip>
    );
  }
}

function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}
