import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Dropdown, Menu, message, Spin, Switch, Pagination, Empty } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { compose } from '../../core';
import { AdminLayout, CardLayout, displayDataCheck, GetColumnSearchProps, getIntFromString, getOrderBy } from '../../look';

import ROUTE from '../route';

import {
  withAllSurveysQuery,
  withDeleteSurvey,
  withEditSurvey,
  withSurveyState,
  withSurveyFilterUpdating
} from './SurveyOperations';
import { subscribeToSurvey } from './SurveySubscriptions';
import ReactMarkdown from '../../look/components/ReactMarkdown';
import { PRIMARY_CLR, PAGINATION_LIMIT, IMG } from '../../../config';
import SelectFilterField from '../../look/components/SelectFilterField';

const Survey = props => {
  const {
    loading,
    allSurveysForTokens,
    onPaginationChange,
    deleteSurvey,
    onNameChange,
    onDescriptionChange,
    orderBy,
    onOrderByChange,
    onFiltersRemove,
    subscribeToMore,
    editSurvey,
    pagination: { offset, first }
  } = props;
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  React.useEffect(() => {
    const subscribe = subscribeToSurvey(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onNameChange),
      render: (text, record) => (
        <>{record && <Link to={`${ROUTE.groupLink}${getIntFromString(record.id)}`}>{displayDataCheck(record.name)}</Link>}</>
      )
    },
    {
      title: 'Description',
      key: 'description',
      ellipsis: true,
      sorter: () => onOrderByChange(getOrderBy('description', orderBy)),
      ...GetColumnSearchProps('description', onDescriptionChange),
      render: (text, record) => <ReactMarkdown>{record && record.description}</ReactMarkdown>
    },
    {
      title: 'Date Published',
      key: 'pubDate',
      sorter: () => onOrderByChange(getOrderBy('pubDate', orderBy)),
      render: (text, record) => `${moment(record.pubDate, 'YYYY-M-DD HH:mm:ss')}`
    }
  ];
  const handleSelect = id => {
    setSelectedRowKeys(
      selectedRowKeys.indexOf(id) === -1 ? [...selectedRowKeys, id] : selectedRowKeys.filter(s => s !== id)
    );
  };
  const paginationProps = !(allSurveysForTokens && allSurveysForTokens.totalCount)
    ? {}
    : {
        defaultCurrent: 1,
        showTotal: (total, range) =>
          allSurveysForTokens.totalCount && (
            <>
              {/* {console.log(total, range, allSurveysForTokens.totalCount)} */}
              {total < PAGINATION_LIMIT
                ? `Showing 1 to ${total} of ${total} entries.`
                : `Showing ${offset + 1} to ${offset + first} of ${total} entries.`}
            </>
          ),
        onChange: (page, pageSize) =>
          onPaginationChange({
            offset: page * pageSize - pageSize,
            first: pageSize
          }),
        defaultPageSize: PAGINATION_LIMIT,
        total: allSurveysForTokens.totalCount,
        style: {
          textAlign: 'right'
        }
      };

  const handleDelete = async id => {
    setDeleteLoading(true);
    try {
      await deleteSurvey(id);
      // await Promise.all(
      //   selectedRowKeys.map(async i => {
      //   })
      // );
      message.success('Deleted!');
    } catch (e) {
      message.error('Failed, try again!');
      throw new Error(e.message);
    }
    setDeleteLoading(false);
  };
  const menu = id => (
    <Menu>
      <Menu.Item key="0">
        <Link to={`${ROUTE.editSurveyLink}${id}`}>Edit</Link>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => handleDelete(id)} danger={true}>
        Delete
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleSelect(id)}>
        Select
      </Menu.Item>
    </Menu>
  );
  const handleEditSurvey = async field => {
    try {
      await editSurvey({
        ...field
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };
  return (
    <CardLayout
      table={true}
      active={ROUTE.survey}
      title={[{ name: 'Survey' }]}
      loading={loading}
      tableData={allSurveysForTokens}
      columns={columns}
      onDelete={deleteSurvey}
      editLink={ROUTE.editSurveyLink}
      addLink={ROUTE.addSurvey}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      selectedRowKeys={selectedRowKeys}
      extra={
        <SelectFilterField
          placeholder="Search Survey by"
          onFiltersRemove={onFiltersRemove}
          options={[
            {
              label: 'Name',
              type: 'text',
              value: onNameChange
            },
            {
              label: 'Description',
              type: 'text',
              value: onDescriptionChange
            }
          ]}
        />
      }
    >
      <Spin spinning={deleteLoading} size="large">
        <div style={{ minHeight: '70vh' }}>
          <Row gutter={[24, 24]}>
            {allSurveysForTokens &&
              allSurveysForTokens.edges.map(({ node }) => (
                <>
                  <Col xs={24} md={12} lg={8}>
                    <Dropdown overlay={() => menu(getIntFromString(node.id))} placement="bottomLeft">
                      <MoreOutlined
                        style={{
                          // fontSize: 'large',
                          position: 'absolute',
                          right: 20,
                          zIndex: 1,
                          top: 20
                        }}
                      />
                    </Dropdown>
                    <Link to={`${ROUTE.surveyDetailLink}${getIntFromString(node.id)}`}>
                      <StyledCard
                        active={selectedRowKeys.indexOf(getIntFromString(node.id)) !== -1}
                        // title={<Row></Row>}
                      >
                        <Row>
                          <Col>
                            <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>{node.name}</h3>
                            <h5 /* style={{ color: '#CACACA' }} */>
                              {node && <ReactMarkdown>{node.description}</ReactMarkdown>}
                            </h5>
                          </Col>
                        </Row>
                        <div>
                          <Row style={{ marginTop: 10 }}>
                            <span style={{ marginBottom: 0, fontWeight: 'normal' }}>
                              {/* {console.log(node.activeFrom, moment(node.activeFrom).format("DD-MM-YYYY"))} */}
                              Active till : {`${moment(node.activeFrom).format('DD-MM-YYYY')}`}
                            </span>
                          </Row>
                        </div>
                      </StyledCard>
                    </Link>
                    <Row
                      justify="space-between"
                      align="bottom"
                      style={{ position: 'relative', bottom: 80, padding: '0 24px' }}
                    >
                      <Col>
                        <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Public</h4>
                        <Switch
                          checked={node.public}
                          size="small"
                          onClick={e => handleEditSurvey({ id: getIntFromString(node.id), public: e, name: node.name })}
                        />
                      </Col>
                      <Col>
                        <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Shuffle</h4>
                        <Switch
                          checked={node.shuffle}
                          size="small"
                          onClick={e => handleEditSurvey({ id: getIntFromString(node.id), shuffle: e, name: node.name })}
                        />
                      </Col>
                    </Row>
                  </Col>
                </>
              ))}
          </Row>
          {allSurveysForTokens && allSurveysForTokens.edgeCount === 0 && (
            <div className="HVCenter" style={{ width: '70vw' }}>
              <Empty description="No result found" />
            </div>
          )}
        </div>
        <br />
        <Pagination {...paginationProps} />
      </Spin>
    </CardLayout>
  );
};

export default compose(
  withSurveyState,
  withEditSurvey,
  withAllSurveysQuery,
  withDeleteSurvey,
  withSurveyFilterUpdating
)(Survey);

const StyledCard = styled.div`
  height: 100%;
  border-color: ${props => props.active && PRIMARY_CLR};
  border: ${props => props.active && '1px solid'};
  padding: 24px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
  &:hover {
    border-color: ${props => PRIMARY_CLR};
  }
`;
