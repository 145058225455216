import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Input, Divider, Modal, Spin, Col, Row, Button, DatePicker, Switch, List, Tooltip } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import Group from './Group';
import { compose } from '../../core';
import { CardLayout, ReactMarkdown, getIntFromString, OrganizationTransfer } from '../../look';
import ROUTE from '../route';
import { withEditSurvey, withSurveyById } from './SurveyOperations';
import OrganizationTree from './OrganizationTree';
import { vertical } from '../../organization/route';
import { IMG } from '../../../config';
import SurveyAssign from './SurveyAssign';

const SurveyDetail = props => {
  const { match, SurveyById, loading, editSurvey, onFiltersRemove } = props;
  const [assign, setAssign] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);

  const handleEditSurvey = async (e, field) => {
    setActionLoading(true);
    let data = {};
    if (field === 'public') data = { public: e };
    if (field === 'shuffle') data = { shuffle: e };
    if (field === 'activeTo') data = { activeTo: e };
    try {
      await editSurvey({
        id: Number(getIntFromString(SurveyById.id)),
        name: SurveyById.name,
        ...data
      });
    } catch (e) {
      throw new Error(e.message);
    }
    setActionLoading(false);
  };

  const handleEditDate = async (e, field) => {
    setActionLoading(true);
    var date = e;
    date.setDate(date.getDate());
    try {
      await editSurvey({
        id: Number(getIntFromString(SurveyById.id)),
        name: SurveyById.name,
        activeTo: date
      });
    } catch (e) {
      throw new Error(e.message);
    }
    setActionLoading(false);
  };

  return (
    <CardLayout
      active={ROUTE.survey}
      title={[
        { name: SurveyById?.name || '', link: `${ROUTE.surveyDetailLink}${SurveyById && getIntFromString(SurveyById.id)}` }
      ]}
      showTitle={false}
      editLink={ROUTE.editSurveyLink}
      addLink={ROUTE.addSurvey}
      backLink={ROUTE.survey}
      onFiltersRemove={onFiltersRemove}
      showBack={false}
      table={false}
      rowKey={SurveyById && SurveyById.id}
    >
      <Row>
        <Col span={24}>
          <Spin spinning={loading || actionLoading} size="large">
            <div style={{ minHeight: '70vh' }}>
              {SurveyById && (
                <>
                  <Row gutter={[24, 24]}>
                    <Col lg={12} md={12} xs={24}>
                      <Row gutter={[24, 24]} style={{ alignItems: 'center' }}>
                        <Col>
                          <h3 style={{ marginBottom: 0 }}>{SurveyById.name}</h3>
                        </Col>
                      </Row>
                      <br />
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline'
                        }}
                      >
                        <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>Description</h3>
                      </Row>
                      <div
                        style={{
                          border: '1px solid rgba(202, 202, 202, 0.25)',
                          margin: '7px',
                          padding: '15px',
                          minHeight: '140px',
                          borderRadius: '2px'
                        }}
                      >
                        <ReactMarkdown>{SurveyById.description}</ReactMarkdown>
                      </div>
                    </Col>
                    <Col span={1}>
                      <Divider type="vertical" style={{ height: '100%' }} />
                    </Col>
                    <Col lg={11} md={11} xs={24}>
                      <Row>
                        <Col lg={10} md={14} xs={24}>
                          <Row>
                            <SurveyAssign SurveyById={SurveyById} assign={assign} setAssign={setAssign} />
                          </Row>
                          <br />
                          <Row>
                            <Col>
                              <h4 /* style={{ marginBottom: 0 }} */>Active till</h4>
                              <DatePicker
                                allowClear={false}
                                defaultValue={moment.utc(SurveyById.activeTo).local()}
                                style={{ color: '#84A100', borderRadius: 5 }}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                size="large"
                                suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: '#84A100' }} />}
                                onChange={e => handleEditDate(new Date(e._d), 'activeTo')}
                              />
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <Row justify="space-between" align="bottom" gutter={[24, 24]}>
                            <Col>
                              Public
                              <br />
                              <Switch
                                checked={SurveyById.public}
                                size="small"
                                onClick={e => handleEditSurvey(e, 'public')}
                              />
                            </Col>
                            <Col>
                              Shuffle
                              <br />
                              <Switch
                                checked={SurveyById.shuffle}
                                size="small"
                                onClick={e => handleEditSurvey(e, 'shuffle')}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Divider style={{ width: '100%' }} />
                  </Row>

                  <Row justify="end" gutter={[24, 24]}>
                    <Col>
                      <Button type="primary" href={`${ROUTE.addGroupLink}${getIntFromString(SurveyById.id)}`}>
                        Add Group
                      </Button>
                    </Col>
                  </Row>
                  <br />

                  <Row justify="center" style={{ minHeight: '70vh' }}>
                    <Col span={24}>
                      <Group {...props} />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Spin>
        </Col>
      </Row>
    </CardLayout>
  );
};

export default compose(withEditSurvey, withSurveyById)(SurveyDetail);

// const SurveyAssign = props => {
//   const { assign, setAssign, SurveyById, editSurvey } = props;
//   const [visible, setVisible] = React.useState(false);
//   const [orgId, setOrgId] = React.useState(0);
//   const [tree, setTree] = React.useState(false);
//   const handleSubmit = async organizations => {
//     try {
//       await editSurvey({ id: getIntFromString(SurveyById.id), name: SurveyById.name, organizations });
//     } catch (e) {
//       throw new Error(e.message);
//     }
//   };
//   const allVerticals =
//     SurveyById && SurveyById.vertical && SurveyById.vertical.edges.map(({ node }) => getIntFromString(node.id));
//   const allTeams = SurveyById && SurveyById.team && SurveyById.team.edges.map(({ node }) => getIntFromString(node.id));
//   return (
//     <>
//       <Button type="primary" ghost onClick={() => setAssign(true)}>
//         Survey Assigned
//       </Button>
//       <Modal
//         destroyOnClose
//         visible={assign}
//         onCancel={() => setAssign(false)}
//         footer={null}
//         title="Survey Assigned"
//         width={'45%'}
//         bodyStyle={{ padding: 16, overflow: 'hidden' }}
//       >
//         {visible ? (
//           <>
//             {/* <OrganizationTransfer
//               visible={visible}
//               initialValues={SurveyById.organization}
//               setVisible={setVisible}
//               handleSubmit={handleSubmit}
//             /> */}
//             {SurveyById && SurveyById.organization && SurveyById.organization.edgeCount > 0 && (
//               <List
//                 header={<div align="center">Organizations</div>}
//                 // footer={<div>Footer</div>}
//                 bordered
//                 dataSource={
//                   SurveyById &&
//                   SurveyById.organization &&
//                   SurveyById.organization.edgeCount > 0 &&
//                   SurveyById.organization.edges.map(({ node }) => node)
//                 }
//                 renderItem={item => (
//                   <Selectable>
//                     {/* <OrganizationTree item={item} orgId={getIntFromString(item.id)} /> */}
//                     <List.Item>
//                       <Row justify="space-between">
//                         <Col
//                           type="default"
//                           ghost
//                           onClick={() => {
//                             setOrgId(item.id);
//                             setTree(true);
//                           }}
//                         >
//                           <img src={IMG} alt="" style={{ width: 40, borderRadius: 30, marginRight: 10 }} /> {`${item.name}`}
//                         </Col>
//                       </Row>
//                     </List.Item>
//                   </Selectable>
//                 )}
//               />
//             )}
//           </>
//         ) : (
//           <>
//             <Row>
//               <Button type="primary" onClick={() => setVisible(true)}>
//                 assigned Organizations
//               </Button>
//             </Row>
//             <br />
//             {SurveyById && SurveyById.organization && SurveyById.organization.edgeCount > 0 && (
//               <List
//                 header={<div align="center">Organizations</div>}
//                 // footer={<div>Footer</div>}
//                 bordered
//                 dataSource={
//                   SurveyById &&
//                   SurveyById.organization &&
//                   SurveyById.organization.edgeCount > 0 &&
//                   SurveyById.organization.edges.map(({ node }) => node)
//                 }
//                 renderItem={item => (
//                   <Selectable>
//                     {/* <OrganizationTree item={item} orgId={getIntFromString(item.id)} /> */}
//                     <List.Item>
//                       <Row justify="space-between">
//                         <Col
//                           type="default"
//                           ghost
//                           onClick={() => {
//                             setOrgId(item.id);
//                             setTree(true);
//                           }}
//                         >
//                           <img src={IMG} alt="" style={{ width: 40, borderRadius: 30, marginRight: 10 }} /> {`${item.name}`}
//                         </Col>
//                       </Row>
//                     </List.Item>
//                   </Selectable>
//                 )}
//               />
//             )}
//           </>
//         )}
//       </Modal>
//       <Modal
//         destroyOnClose
//         visible={tree}
//         onCancel={() => setTree(false)}
//         footer={null}
//         title="Survey Assigned"
//         width={'80%'}
//         bodyStyle={{ padding: 16 }}
//       >
//         {tree && (
//           <OrganizationTree
//             orgId={getIntFromString(orgId)}
//             surveyId={SurveyById.id}
//             surveyName={SurveyById.name}
//             allVerticals={allVerticals}
//             allTeams={allTeams}
//             editSurvey={editSurvey}
//           />
//         )}
//       </Modal>
//     </>
//   );
// };

// const Selectable = styled.div`
//   &:hover {
//     cursor: pointer;
//     background-color: #fffff0;
//   }
// `;
