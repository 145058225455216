import * as React from 'react';
import { Button, Col, Input, Row, Menu, Empty } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import faq1 from '../../assets/faq1.svg';
import { MetaTags, Loading } from '../../look';
import QuestionsView from './FaqQuestionsView';

const FaqPageView = props => {
  const [value, setValue] = React.useState(0);
  const { loading, allFaqCategories, onSearchTextChange, faqSearch } = props;
  const [search, setSearch] = React.useState(false);
  const handleSearch = e => {
    if (e.target.value) {
      setSearch(true);
    } else {
      setSearch(false);
    }
    onSearchTextChange(e.target.value);
  };
  return (
    <>
      <MetaTags title="FAQ" description="This is FAQ page" />
      {loading && <Loading />}

      {!loading && (
        <>
          <RowContainer justify="center">
            <Col span={22}>
              <Row>
                <Col lg={{ span: 7, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                  <h2>Frequently Asked Questions</h2>
                  {/* <h4 style={{ fontWeight: 'normal' }}>
                    Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for
                  </h4> */}
                  <br />
                  <Input
                    placeholder="Search for a question"
                    style={{
                      borderRadius: '20px',
                      // width: '75%',
                      boxShadow: '0px 4px 15px 5px rgba(175, 176, 162, 0.15)'
                    }}
                    onChange={e => handleSearch(e)}
                    size="large"
                    suffix={<SearchOutlined style={{ color: '#84A100' }} />}
                  />
                </Col>
                <Col lg={{ span: 12, offset: 4 }} xs={0}>
                  <Row justify="end" align="top">
                    <Col span={12}>
                      <img src={faq1} style={{ width: '100%', height: '100%' }} alt="" />
                    </Col>
                    <Col span={2} />
                  </Row>
                </Col>
              </Row>
            </Col>
          </RowContainer>
          <br />
          <br />
          <br />
          {/* {search && (
            <Row style={{ width: '90%', margin: '0 auto' }}>
              <Col lg={22} xs={24}>
                <Row>
                  <Col lg={5} xs={0} />
                  <Col lg={19} xs={24}>
                    <Row justify="end">
                      <Col lg={18} xs={24}>
                        <QuestionsView questions={faqSearch && faqSearch} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )} */}
          {true && (
            <Row justify="center" style={{ width: '90%', margin: '0 auto' }}>
              <Col lg={22} xs={24}>
                <Row justify="start">
                  <Col lg={{ span: 7, offset: 1 }} xs={0}>
                    <Row style={{ padding: '10px' }} gutter={[12, 12]}>
                      {!search
                        ? allFaqCategories &&
                          allFaqCategories.edges.length > 0 &&
                          allFaqCategories.edges.map(({ node }, index) => (
                            <Col lg={24} xs={12}>
                              <CustBtn shape="round" active={value === index} block onClick={() => setValue(index)}>
                                {node.name}
                              </CustBtn>
                            </Col>
                          ))
                        : faqSearch &&
                          faqSearch.edges.length > 0 &&
                          faqSearch.edges.map(({ node }, index) => (
                            <Col lg={24} xs={12}>
                              <CustBtn shape="round" active={value === index} block onClick={() => setValue(index)}>
                                {node.name}
                              </CustBtn>
                            </Col>
                          ))}
                    </Row>
                  </Col>
                  <Col lg={0} xs={{ span: 23, offset: 1 }}>
                    <Menu
                      mode="horizontal"
                      defaultSelectedKeys={['0']}
                      style={{ paddingBottom: '3vh' }}
                      onSelect={params => setValue(Number(params.key))}
                    >
                      {!search
                        ? allFaqCategories &&
                          allFaqCategories.edges.length > 0 &&
                          allFaqCategories.edges.map(({ node }, index) => <Menu.Item key={index}>{node.name}</Menu.Item>)
                        : faqSearch &&
                          faqSearch.edges.length > 0 &&
                          faqSearch.edges.map(({ node }, index) => <Menu.Item key={index}>{node.name}</Menu.Item>)}
                    </Menu>
                  </Col>
                  <Col lg={16} xs={24}>
                    <Row justify="end">
                      <Col lg={18} xs={24}>
                        <QuestionsView
                          category={{
                            name: !search ? allFaqCategories?.edges[value]?.node.name : faqSearch?.edges[value]?.node.name,
                            description: !search
                              ? allFaqCategories?.edges[value]?.node.description
                              : faqSearch?.edges[value]?.node.description
                          }}
                          questions={
                            !search
                              ? allFaqCategories?.edges[value]?.node.faqquestionSet
                              : faqSearch?.edges[value]?.node.faqquestionSet
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    {search && faqSearch?.edges?.length === 0 && (
                      <div align="center">
                        <Empty description="No matching question found!" />
                      </div>
                    )}
                    {allFaqCategories?.edges?.length === 0 && (
                      <div align="center">
                        <Empty description="No FAQ's found!" />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default FaqPageView;

const RowContainer = styled(Row)`
  @media only screen and (min-width: 480px) {
    padding-top: 5vh;
    width: 90%;
    margin: 0 auto;
  }

  @media only screen and (max-width: 480px) {
    background-size: cover;
    background-image: url(${faq1});
    padding-bottom: 7vh;
    opacity: 0.5;
  }
`;

const CustBtn = styled(Button)`
  border: 0;
  color: ${props => (props.active ? '#84a100 !important' : '#afb0a2')};
  background: ${props => props.active && '#f3f3f1 !important'};
  &:hover {
    color: #84a100;
    background: #f3f3f1 !important;
  }
  &:active {
    color: #84a100;
    background: #f3f3f1 !important;
  }
  &:focus {
    color: #84a100;
    background: #f3f3f1 !important;
  }
`;
