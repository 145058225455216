import gql from 'graphql-tag';
import { SURVEY_TYPE } from './SurveyType.gql';

export const EDIT_SURVEY = gql`
  ${SURVEY_TYPE}
  mutation editSurvey(
    $description: String
    $termsConditions: String
    $id: ID!
    $name: String
    $organizations: [Int]
    $teams: [Int]
    $verticals: [Int]
    $activeFrom: DateTime
    $activeTo: DateTime
    $public: Boolean
    $shuffle: Boolean
    $reportQuery: String
  ) {
    updateSurvey(
      description: $description
      termsConditions: $termsConditions
      id: $id
      name: $name
      organizations: $organizations
      teams: $teams
      verticals: $verticals
      shuffle: $shuffle
      public: $public
      activeFrom: $activeFrom
      activeTo: $activeTo
      reportQuery: $reportQuery
    ) {
      survey {
        ...SurveyType
      }
    }
  }
`;
