const baseName = '/survey';
const adminPanelBaseName = '/admin-panel';
const tokenBaseName = '/token';
const groupBaseName = '/group';
const questionBaseName = '/question';
const responseBaseName = '/response';

const organizationName = '/organization';
const verticalName = '/vertical';
const teamName = '/team';
const detailBaseName = '/detail';
const addBaseName = '/new';
const editBaseName = '/edit';

module.exports = {
  allOrganizationSurvey: baseName + '/all-organization-survey',
  token: baseName + tokenBaseName + adminPanelBaseName,
  group: baseName + groupBaseName + adminPanelBaseName + '/:id',
  question: baseName + questionBaseName + adminPanelBaseName + '/:id',
  questionLink: baseName + questionBaseName + adminPanelBaseName + '/',
  groupLink: baseName + groupBaseName + adminPanelBaseName + '/',
  survey: baseName + baseName + adminPanelBaseName,
  response: baseName + responseBaseName + adminPanelBaseName,

  // orgSurvey: baseName + baseName + adminPanelBaseName + organizationName + '/:id',
  // orgSurveyLink: baseName + baseName + adminPanelBaseName + organizationName + '/',
  // verSurvey: baseName + baseName + adminPanelBaseName + verticalName + '/:id',
  // verSurveyLink: baseName + baseName + adminPanelBaseName + verticalName + '/',
  // teaSurvey: baseName + baseName + adminPanelBaseName + teamName + '/:id',
  // teaSurveyLink: baseName + baseName + adminPanelBaseName + teamName + '/',
  surveyDetail: baseName + baseName + detailBaseName + '/admin-panel' + baseName + '/:id',
  surveyDetailLink: baseName + baseName + detailBaseName + '/admin-panel' + baseName + '/',

  surveyQuiz: baseName + baseName + '/:id',
  surveyQuizLink: baseName + baseName + '/',
  surveyQuizResponse: baseName + baseName + responseBaseName + '/:id',
  surveyQuizresponseLink: baseName + baseName + responseBaseName + '/',
  tokenSurvey: baseName + baseName + tokenBaseName + '/:token',
  tokenSurveyLink: baseName + baseName + tokenBaseName + '/',
  surveyResponse: baseName + responseBaseName + '/:id',
  surveyResponseLink: baseName + responseBaseName + '/',

  addToken: baseName + tokenBaseName + addBaseName,
  addMultipleToken: baseName + tokenBaseName + '/multiple' + addBaseName,
  editToken: baseName + tokenBaseName + editBaseName + '/:id',
  editTokenLink: baseName + tokenBaseName + editBaseName + '/',
  addGroup: baseName + groupBaseName + addBaseName + '/:id',
  addGroupLink: baseName + groupBaseName + addBaseName + '/',
  editGroup: baseName + groupBaseName + editBaseName + '/:id',
  editGroupLink: baseName + groupBaseName + editBaseName + '/',
  addQuestion: baseName + questionBaseName + addBaseName + '/:id',
  addQuestionLink: baseName + questionBaseName + addBaseName + '/',
  editQuestion: baseName + questionBaseName + editBaseName + '/:id',
  editQuestionLink: baseName + questionBaseName + editBaseName + '/',
  addSurvey: baseName + baseName + addBaseName,
  editSurvey: baseName + baseName + editBaseName + '/:id',
  editSurveyLink: baseName + baseName + editBaseName + '/',
  addResponse: baseName + responseBaseName + addBaseName,
  editResponse: baseName + responseBaseName + editBaseName + '/:id',
  editResponseLink: baseName + responseBaseName + editBaseName + '/'
};
