import gql from 'graphql-tag';
import { REFERENCE_FIELD_TYPE } from './ReferenceFieldType.gql';

export const REFERENCE_FIELD_TYPE_CONNECTION = gql`
  ${REFERENCE_FIELD_TYPE}
  fragment ReferenceFieldTypeConnection on ReferenceFieldTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...ReferenceFieldType
      }
    }
    totalCount
    edgeCount
  }
`;
