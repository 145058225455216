import React from 'react';
import { Row, Col, Button, Card, Divider } from 'antd';
import styled from 'styled-components';
import {
  SearchOutlined,
  HistoryOutlined,
  UserAddOutlined,
  SettingOutlined,
  ReconciliationOutlined,
  BarsOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import DASHBOARD_ROUTE from '../../dashboard/route';

import { getItem } from '../../core';
import { MetaTags } from '../../look';
import USER_ROUTE from '../../user/route';
import SURVEY_ROUTE from '../../survey/route';
import BLOG_ROUTE from '../../story/route';
import ROUTE from '../../survey/route';

import Logo from '../../assets/Group.svg';
import Photo from '../../assets/photo.jpg';

const NewH3 = styled.h3`
  @media only screen and (max-width: 480px) {
    font-size: 2vh;
    font-weight: bold;
    text-align: center;
  }
  font-size: 3vh;
`;

const NewH2 = styled.h2`
  @media only screen and (max-width: 480px) {
    font-size: 3vh;
    font-weight: bold;
    text-align: center;
  }
  font-size: 4vh;
`;
const NewRow = styled(Row)`
  background-color: #e5e5e5;
  border-radius: 0 0 0 25vh;
  margin: -5vh -5vh 0 -5vh;
  @media only screen and (max-width: 1000px) {
    margin-top: 0;
    padding-bottom: 6vh;
  }
`;
const NewDivider = styled(Divider)`
  border-color: #7cb305;
  width: 50%;
  min-width: 50%;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
const HomeView = ({isSurveyGiven}) => {
  const [accessToken, setaccessToken] = React.useState();
  async function getAccessToken() {
    const token = await getItem('accessToken');
    setaccessToken(token);
  }
  React.useEffect(() => {
    getAccessToken();
  }, []);

  return (
    <>
      <MetaTags title="Home" description="This is the home page" />
      <NewRow /* style={{ backgroundColor: '#E5E5E5', marginTop: '-5vh', borderRadius: '0 0 0 25vh' }} */>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 9, offset: 3 }}>
          <Row style={{ paddingTop: '10vh' }}>
            <Col lg={{ span: 24, offset: 0 }} xs={{ span: 16, offset: 4 }}>
              <br />
              <br />
              <NewH3>Welcome To Workplace Resilience</NewH3>
            </Col>
            <Col
              lg={{ span: 12, offset: 0 }}
              md={{ span: 8, offset: 4 }}
              sm={{ span: 8, offset: 4 }}
              xs={{ span: 16, offset: 4 }}
            >
              <NewH2>Exploring & Building Resilience</NewH2>
            </Col>
            <Col lg={{ span: 24, offset: 0 }} xs={{ span: 16, offset: 8 }}>
              {accessToken ? (                  
              <Button size="large" type="primary">
                  <Link to={isSurveyGiven ? USER_ROUTE.myResponse : SURVEY_ROUTE.allOrganizationSurvey}>
                    <BarsOutlined />
                    &nbsp; {`${isSurveyGiven? 'My Reports':'Give Survey'}`}
                  </Link>
                </Button> 
              ) : (
                <Button size="large" type="primary">
                  <Link to={USER_ROUTE.register}>
                    <UserAddOutlined />
                    &nbsp; Sign Up
                  </Link>
                </Button>
              )}
            </Col>
          </Row>
        </Col>
        <Col lg={12} xs={0} style={{ height: '65vh' }}>
          <img style={{ height: '100%', width: '100%' }} src={Logo} alt="logo" />
        </Col>
      </NewRow>

      <Row justify="center">
        <Col lg={16} xs={22} align="center">
          <br />
          <br />
          <br />
          <NewDivider style={{ borderColor: '#7cb305' }}>
            <h2>Workplace Resilience</h2>
          </NewDivider>
          <br />
          <br />

          <Row gutter={[24, 24]}>
            <Col lg={12} xs={24} align="left">
              <Card>
                <Card.Meta
                  avatar={<SearchOutlined style={{ fontSize: '2vw', color: 'rgba(202, 202, 202, 0.5)' }} />}
                  title={<h3 style={{ fontWeight: 'bold' }}>Resilience Research </h3>}
                  description={
                    <>
                      <div style={{ fontSize: '2vh' }}>
                        Resilience research has long history. Various researchers have been working on unraveling the secrets
                        of resilience for many years and we can learn a lot from them. Explore some of the key findings by
                        these researchers, as well as findings from the research Brainayan has been conducting.
                      </div>
                      <br />
                      <Button
                        ghost
                        type="primary"
                        style={{
                          color: 'rgba(78, 0, 128, 1)',
                          borderColor: 'rgba(78, 0, 128, 1)'
                        }}
                        disabled
                      >
                        Browse
                      </Button>
                    </>
                  }
                />
              </Card>
            </Col>

            <Col lg={12} xs={24} align="left">
              <Card>
                <Card.Meta
                  avatar={<ReconciliationOutlined style={{ fontSize: '2vw', color: 'rgba(202, 202, 202, 0.5)' }} />}
                  title={<h3 style={{ fontWeight: 'bold' }}>Resilience Assessment</h3>}
                  description={
                    <>
                      <div style={{ fontSize: '2vh' }}>
                        Self-awareness is the most important tool to be a better version of yourself. Our resilience
                        assessments will provide you with insights that will help you build or fine-tune your resilience
                        strategies.
                      </div>
                      <br />
                      <br />
                      <Button
                        ghost
                        type="primary"
                        style={{
                          color: 'rgba(78, 0, 128, 1)',
                          borderColor: 'rgba(78, 0, 128, 1)'
                        }}
                      >
                        <Link to={SURVEY_ROUTE.allOrganizationSurvey}>Browse</Link>
                      </Button>
                    </>
                  }
                />
              </Card>
            </Col>

            <Col lg={12} xs={24} align="left">
              <Card>
                <Card.Meta
                  avatar={<SettingOutlined style={{ fontSize: '2vw', color: 'rgba(202, 202, 202, 0.5)' }} />}
                  title={<h3 style={{ fontWeight: 'bold' }}>Resilience Toolkit</h3>}
                  description={
                    <>
                      <div style={{ fontSize: '2vh' }}>
                        Self-awareness is the first step. Next comes action. Our resilience toolkit will offers techniques
                        and tips to strengthen various aspects of your resilience. These practical tools will help find ways
                        to turn insights you gather from our assessments into actionable commitments, so you can develop your
                        resilience further.
                      </div>
                      <br />
                      <Button
                        ghost
                        type="primary"
                        style={{
                          color: 'rgba(78, 0, 128, 1)',
                          borderColor: 'rgba(78, 0, 128, 1)'
                        }}
                        href={DASHBOARD_ROUTE.personal}
                      >
                        Browse
                      </Button>
                    </>
                  }
                />
              </Card>
            </Col>

            <Col lg={12} xs={24} align="left">
              <Card>
                <Card.Meta
                  avatar={<HistoryOutlined style={{ fontSize: '2vw', color: 'rgba(202, 202, 202, 0.5)' }} />}
                  title={<h3 style={{ fontWeight: 'bold' }}>Resilience Stories</h3>}
                  description={
                    <>
                      <div style={{ fontSize: '2vh' }}>
                        Stories have been at the centre of success of the human species. We have transmitted knowledge and
                        made it stick with stories. You can find some stories of people that struggled with adversities and
                        dealt with them effectively, emerging resilient and learn from their experience. And if you feel
                        inspired, share your stories as well.
                      </div>
                      <br />
                      <Button
                        ghost
                        type="primary"
                        style={{
                          color: 'rgba(78, 0, 128, 1)',
                          borderColor: 'rgba(78, 0, 128, 1)'
                        }}
                        href={BLOG_ROUTE.allStories}
                      >
                        Browse
                      </Button>
                    </>
                  }
                />
              </Card>
            </Col>

            <Col lg={12} xs={24} align="left">
              <Card>
                <Card.Meta
                  avatar={<HistoryOutlined style={{ fontSize: '2vw', color: 'rgba(202, 202, 202, 0.5)' }} />}
                  title={<h3 style={{ fontWeight: 'bold' }}>Resilience Situational Judgement Test</h3>}
                  description={
                    <>
                      <div style={{ fontSize: '2vh' }}>
                      The Resilience SJT is like a backstage pass to your decision making journey. You’ll encounter realistic workplace scenarios and choose responses based on your past experiences. These experiences assess not only your knowledge but also how you handle stress and respond to different situations. In short, it will assess how your  amygdala hijack works. Be your authentic self while answering the questions. Resilience SJT helps employers to understand how you navigate the twists and turns one might encounter at the workplace. It will assess you on team work, capability, empathy etc. You will come across scenarios which will determine what trade-offs you have dealt with, what made you choose your choices.
                      </div>
                      <br />
                      <Button
                        ghost
                        type="primary"
                        style={{
                          color: 'rgba(78, 0, 128, 1)',
                          borderColor: 'rgba(78, 0, 128, 1)'
                        }}
                        href={`${ROUTE.surveyQuizLink}${7}`}
                      >
                        Start Quiz
                      </Button>
                    </>
                  }
                />
              </Card>
            </Col>
          </Row>
          <br />
          <br />

          <NewDivider style={{ borderColor: '#7cb305' }}>
            <h2>How it works</h2>
          </NewDivider>
          <br />
          <br />

          <Row>
            <Col lg={18} xs={22}>
              <Row>
                <Col span={2}>
                  <div
                    style={{
                      fontSize: '8vh',
                      color: 'rgba(202, 202, 202, 0.25)',
                      marginBottom: '-1vh',
                      marginTop: '-2vh'
                    }}
                  >
                    1
                  </div>
                </Col>
                <Col span={22} align="left">
                  <h3 style={{ marginBottom: '10px', fontWeight: 'bold' }}>Perception Survey</h3>
                  <h4 style={{ marginBottom: '2vh' }}>
                    The Perception Survey measures your perception of your resilience at the workplace, as well as in your
                    personal life. The first section of the survey requires you to rate yourself on how resilient you are
                    overall, in your workplace, and in your personal life. In the second section of the survey, you’ll rate
                    yourself on your use of various protective factors in your workplace and personal life. Your ratings are
                    then compared with various demographic groups relevant to you, like people of your age, gender,
                    education, and so on. These comparisons again allow you to see how you are placed with respect to these
                    groups.
                  </h4>
                </Col>
                {/* <Col span={1} />
                <Col span={23}>
                  <img src={Chart} style={{ width: '100%', height: '100%' }} alt="chart" />
                </Col> */}
              </Row>
            </Col>
          </Row>
          <br />
          <br />

          <Row justify="end">
            <Col lg={18} xs={22}>
              <Row>
                <Col span={2}>
                  <div
                    style={{
                      fontSize: '8vh',
                      color: 'rgba(202, 202, 202, 0.25)',
                      marginBottom: '3vh',
                      marginTop: '-2vh'
                    }}
                  >
                    2
                  </div>
                </Col>
                <Col span={22} align="left">
                  <h3 style={{ marginBottom: '10px', fontWeight: 'bold' }}>Prediction Survey</h3>
                  <h4 style={{ marginBottom: '2vh' }}>
                    Our prediction survey uses a five-anchor model based on a meta-study of resilience research. The survey
                    asks you to assess yourself on various behaviors, attitudes and beliefs that measure your strength in the
                    five anchors. It then measures and presents your overall resilience and also unpacks each anchor for you
                    calling out sub-factors in each acnhors that are your strengths or areas of opportunity. This survey
                    presents an opportunity for your to review your resilience in detail and fine-tune your strategies to be
                    resilient on a day to day basis.
                  </h4>
                </Col>
                {/* <Col span={1} />
                <Col span={23}>
                  <img src={Chart} style={{ width: '100%', height: '100%' }} alt="chart" />
                </Col> */}
              </Row>
            </Col>
          </Row>

          <Row style={{marginTop:'3em'}}>
            <Col lg={18} xs={22}>
              <Row>
                <Col span={2}>
                  <div
                    style={{
                      fontSize: '8vh',
                      color: 'rgba(202, 202, 202, 0.25)',
                      // marginBottom: '-1vh',
                      marginTop: '-2vh'
                    }}
                  >
                    3
                  </div>
                </Col>
                <Col span={22} align="left">
                  <h3 style={{ marginBottom: '10px', fontWeight: 'bold' }}>Resilience Situational Judgement Survey</h3>
                  <h4 style={{ marginBottom: '2vh' }}>
                  The Resilience SJT is like a backstage pass to your decision making journey. You’ll encounter realistic workplace scenarios and choose responses based on your past experiences. These experiences assess not only your knowledge but also how you handle stress and respond to different situations. In short, it will assess how your  amygdala hijack works. Be your authentic self while answering the questions. Resilience SJT helps employers to understand how you navigate the twists and turns one might encounter at the workplace. It will assess you on team work, capability, empathy etc. You will come across scenarios which will determine what trade-offs you have dealt with, what made you choose your choices.
                  </h4>
                </Col>
                {/* <Col span={1} />
                <Col span={23}>
                  <img src={Chart} style={{ width: '100%', height: '100%' }} alt="chart" />
                </Col> */}
              </Row>
            </Col>
          </Row>

          <Row justify="center" align="bottom" style={{ marginBottom: '7vh', marginTop: '10vh' }}>
            <Col span={22}>
              <div style={{ textAlign: 'start', height: '35px', fontSize: '64px', color: 'rgba(202, 202, 202, 0.5)' }}>
                “
              </div>
              <div
                style={{
                  textAlign: 'center',
                  color: 'rgba(137, 138, 130, 1)',
                  // fontFamily: 'Poppins',
                  marginBottom: '0px',
                  paddingLeft: '10px'
                }}
              >
                <img
                  src={Photo}
                  style={{ width: '80px', height: '80px', borderRadius: '40px', objectFit: 'cover', marginBottom: '15px' }}
                  alt="last"
                />
                <h2 style={{ color: 'rgba(137, 138, 130, 1)', fontWeight: 'normal' }}>
                  To fight for change tomorrow, we need to build resilience today.
                </h2>
                <h4 style={{ color: 'rgba(137, 138, 130, 1)' }}>- Sheryl Sandberg</h4>
              </div>
              <div style={{ textAlign: 'end', height: '35px', fontSize: '64px', color: 'rgba(202, 202, 202, 0.5)' }}>“</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default HomeView;
