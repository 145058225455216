import React, { useState } from 'react';

import { compose } from '../../core';
import { getIntFromString, withMarkdownFieldValue } from '../../look';
import AddCommitmentView from '../components/AddCommitmentView';

import { withAddCustomUserCommitment } from './DashboardOperations';
import CustomCommitFormComponentView, { CKEditorFieldName } from '../components/CustomCommitFormComponentView';
import { withMe } from '../../user/containers/UserOperations';
import ManagerAddCommitment from '../../manager/containers/ManagerAddCommitment';

const AddCommitmentContainer = props => {
  const { me, addCustomUserCommitment, visible, setVisible, selectedTip } = props;
  const [dueDate, setDueDate] = useState()
  const [descForm, setDescForm] = useState()

  const handleAdd = async values => {
    // console.log('va3flues ::-', values, dueDate, descForm)
    try {
      const response = await addCustomUserCommitment({
        customUserCommitmentData:  {
          ...values,
          userId: getIntFromString(me.id),
          dueDate: dueDate,
          description: descForm
        }
      });
      response && setVisible(false);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  return <AddCommitmentView setDescForm={setDescForm} selectedTip={selectedTip} setVisible={setVisible} visible={visible} onSubmit={handleAdd} setDueDate={setDueDate} {...props} />;
};

export default compose(withAddCustomUserCommitment, withMe)(AddCommitmentContainer);
