import React from 'react';
import _ from 'lodash';
import { Spin, Button } from 'antd';

import { compose } from '../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../look';

import AddCommitmentView from '../components/AddCommitmentView';
import { CKEditorFieldName } from '../components/ToolFormComponent';

import { withAddCommitment /* withSurveyState */ } from './ToolOperations';
import ROUTE from '../route';
import { withAddCoachingCommitment } from '../../dashboard/containers/DashboardOperations';

const AddCommitment = props => {
  const { history, addCommitment, handleEdit, setSubmitLoading, addCoachingCommitment } = props;
  const [visible, setVisible] = React.useState(false);

  const handleSubmit = async (values, condition, coachingCommitment) => {
    setSubmitLoading(true);
    try {
      const response = await addCommitment({ commitmentData: withMarkdownFieldValue(CKEditorFieldName, values), condition });
      await Promise.all(
        coachingCommitment.map(async ({ node }) => {
          await addCoachingCommitment({
            coachingCommitmentData: _.omit(
              withMarkdownFieldValue(CKEditorFieldName, {
                ...node,
                commitmentId: getIntFromString(response.id)
              }),
              'index'
            )
          });
        })
      );
      response && handleEdit(getIntFromString(response.id), response);
      response && setVisible(false);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <>
      {/* <Spin spinning={submitLoading} size="large"> */}
      <Button
        type={'primary'}
        onClick={() =>
          setVisible(true)
        } /* href={`${ROUTE.addCommitmentLink}${getIntFromString(getCommitmentByIdAdmin.id)}`} */
      >
        Add Commitment
      </Button>

      <AddCommitmentView onSubmit={handleSubmit} visible={visible} setVisible={setVisible} {...props} />
      {/* </Spin> */}
    </>
  );
};

export default compose(/* withCommitmentState, */ withAddCommitment, withAddCoachingCommitment)(AddCommitment);
