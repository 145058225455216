import * as React from 'react';

const PrivacyPolicy = () => {
  return (
    <>
      <h1>Privacy policy</h1>
      <h4>Last updated: April 04,2020</h4>
      <br />
      <h4>
        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information
        when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal
        data to provide and improve the Service. By using the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </h4>
      <br />
      <h2>Interpretation and Definitions</h2>
      <h4>Interpretation</h4>
      <h4>
        The words of which the initial letter is capitalized have meanings defined under the following conditions. The
        following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </h4>
      <br />
      <h3>Definitions</h3>
      <h4>
        For the purposes of this Privacy Policy: <br />
        <b>You </b>
        means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such
        individual is accessing or using the Service, as applicable.
        <br /> Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as
        you are the individual using the Service.
        <br />
        <b>Company </b>
        (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
        refers to Brainayan | resiliance, D606, Solitaire Society, Dhanori- Lohegao Road, Near Canara Bank,.
        <br /> For the purpose of the GDPR, the Company is the Data Controller.
        <br /> • <b>Affiliate </b>
        means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot;
        means ownership of 50% or mo re of the shares, equity interest or other securi ties entitled to vote for election of
        directors or other managing authority. <br />• <b>Account </b>
        means a unique account created for You to access our Service or parts of our Service.
        <br /> • <b>Website </b>
        refers to BrainAyan | Resilience, accessible from{' '}
        <a href="https://resilience.brainayan.com">https://resilience.brainayan.com</a>
        <br /> • <b>Service </b>
        refers to the Website.
        <br /> • <b>Country </b>
        refers to: Maharashtra, India
        <br />
        <b>Service Provider</b>
        means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies
        or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to
        perform services related to the Service or to assist the Company in analyzing how the Service is used. For the
        purpose of the GDPR, Service Providers are considered Data Processors.
      </h4>
    </>
  );
};

export default PrivacyPolicy;
