import gql from 'graphql-tag';
// import { ORGANIZATION_TYPE_CONNECTION } from './OrganizationTypeConnection.gql';

//  ${ORGANIZATION_TYPE_CONNECTION}
export const ALL_ORGANIZATION_QUERY = gql`
  query allOrganization(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
    $email_Icontains: String
    $poc: ID
    $ceo: ID
  ) {
    allOrganization(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
      email_Icontains: $email_Icontains
      poc: $poc
      ceo: $ceo
    ) {
      # ...OrganizationTypeConnection
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
          email
          logo
          poc {
            id
            user {
              id
              username
              firstName
              lastName
              email
            }
          }
          ceo {
            id
            user {
              id
              username
              firstName
              lastName
              email
            }
          }
        }
      }
      totalCount
      edgeCount
    }
  }
`;
