import React from 'react';
import gql from 'graphql-tag';
import { message, Spin, Empty } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

import * as ROUTE from '../../../organization/route';
import { compose } from '../../../core';

import SelectField from './SelectField';
import { getIntFromString } from '../functions';
import { withLookUserState, withLookUserFilterUpdating } from '../../containers/LookOperations';

const ALL_EMPLOYEES_QUERY = gql`
  query allEmployees($nameIcontains: String) {
    allEmployees(nameIcontains: $nameIcontains) {
      edges {
        node {
          id
          user {
            id
            username
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const EmployeeField = props => {
  const { allEmployees, loading, deleteEmployee, margin, onNameChange, onFiltersRemove } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const handleDelete = async id => {
    try {
      const response = await deleteEmployee(id);
      response ? message.success('Action Successfull!') : message.error('Cannot delete this employee!');
    } catch (e) {
      message.error('Action Failed!');
      throw new Error(e.message);
    }
  };

  const choices =
    allEmployees &&
    allEmployees.edges.map(({ node }) => ({
      label: node.user.firstName && node.user.lastName ? `${node.user.firstName} ${node.user.lastName}` : node.user.username,
      value: getIntFromString(node.id)
    }));

  return (
    <SelectField
      onAdd={ROUTE.addEmployee}
      onEdit={ROUTE.editEmployeeLink}
      onDelete={handleDelete}
      margin={margin}
      showSearch
      choices={choices}
      style={{ width: !margin && 300, marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onNameChange}
      onBlur={handleFilterRemove.current}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookUserState,
  graphql(ALL_EMPLOYEES_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data: { loading, error, allEmployees, subscribeToMore, updateQuery } }) {
      return { loading, error, allEmployees, subscribeToMore, updateQuery };
    }
  }),
  withLookUserFilterUpdating
)(EmployeeField);
