import gql from 'graphql-tag';
import { QUESTION_TYPE } from './QuestionType.gql';

export const QUESTION_TYPE_CONNECTION = gql`
  ${QUESTION_TYPE}
  fragment QuestionTypeConnection on QuestionTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...QuestionType
      }
    }
    totalCount
    edgeCount
  }
`;
