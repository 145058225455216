import gql from 'graphql-tag';
import { SURVEY_TYPE } from './SurveyType.gql';

export const ORG_SURVEY_BY_ID_QUERY = gql`
  ${SURVEY_TYPE}
  query orgSurveyById($id: ID!) {
    orgSurveyById(id: $id) {
      ...SurveyType
    }
  }
`;
