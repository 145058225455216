import { message } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

import pageResolvers from '../resolvers';
import { withOperation } from '../../look';

// Query
import { CONTACT_REQUEST_MUTATION } from '../graphql/ContactRequestMutation.gql';
import { ALL_CONTACT_REQUESTS_QUERY } from '../graphql/AllContactRequestsQuery.gql';
import { ALL_FAQ_CATEGORIES_QUERY } from '../graphql/AllFaqCategoriesQuery.gql';
import { FAQ_CATEGORY_BY_ID_QUERY } from '../graphql/FaqCategoryQuery.gql';
import { FAQ_QUESTION_QUERY } from '../graphql/FaqQuestionQuery.gql';
import { FAQ_SEARCH_QUERY } from '../graphql/FaqSearchQuery.gql';

// Mutation
import { TOGGLE_CONTACT_STATUS } from '../graphql/ToggleContactStatus.gql';
import { ADD_FAQ_CATEGORY } from '../graphql/AddFaqCategory.gql';
import { ADD_FAQ_QUESTION } from '../graphql/AddFaqQuestion.gql';
import { EDIT_FAQ_CATEGORY } from '../graphql/EditFaqCategory.gql';
import { EDIT_FAQ_QUESTION } from '../graphql/EditFaqQuestion.gql';
import { DELETE_FAQ_CATEGORY } from '../graphql/DeleteFaqCategory.gql';
import { DELETE_FAQ_QUESTION } from '../graphql/DeleteFaqQuestion.gql';

// Client
import { PAGE_STATE_QUERY } from '../graphql/PageStateQuery.client.gql';
import { UPDATE_PAGE_FILTER } from '../graphql/UpdatePageFilter.client.gql';

// Mutation
import { removeTypename } from '../../core/clientStorage';

// Query
export const withContactRequest = Component =>
  graphql(CONTACT_REQUEST_MUTATION, {
    props: ({ mutate, history }) => ({
      contactRequest: async values => {
        try {
          const {
            data: { contactRequest }
          } = await mutate({
            variables: {
              contactRequestData: values
            }
          });

          message.destroy();
          message.success('Sent!!');
          return contactRequest.contactRequest;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

export const withAllContactRequests = Component =>
  graphql(ALL_CONTACT_REQUESTS_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allContactRequests, subscribeToMore, updateQuery } = data;
      return { loading, error, allContactRequests, subscribeToMore, updateQuery };
    }
  })(Component);

// Mutation
export const withToggleContactStatus = Component =>
  graphql(TOGGLE_CONTACT_STATUS, {
    props: ({ mutate }) => ({
      toggleContactStatus: async values => {
        try {
          const {
            data: { updateContactRequest }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateContactRequest.contactRequest;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withFaqCatagoryById = Component =>
  graphql(FAQ_CATEGORY_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, faqCatagoryById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, faqCatagoryById, subscribeToMore, updateQuery };
    }
  })(Component);
export const withAllFaqCategories = Component =>
  graphql(ALL_FAQ_CATEGORIES_QUERY, {
    options: ({ filter, orderBy, pagination, addFilter }) => {
      return { variables: { ...pagination, ...filter, ...addFilter, orderBy } };
    },
    props({ data }, history) {
      const { loading, error, allFaqCategories, subscribeToMore, updateQuery } = data;
      return { loading, error, allFaqCategories, subscribeToMore, updateQuery };
    }
  })(Component);

export const withFaqQuestion = Component =>
  graphql(FAQ_QUESTION_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, faqQuestionById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, faqQuestionById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withFaqSearchQuery = Component =>
  graphql(FAQ_SEARCH_QUERY, {
    options: ({ filter, addFilter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, ...addFilter, orderBy } };
    },
    props({ data: { loading, error, faqSearch, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, faqSearch, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAddFaqCategory = Component =>
  withOperation({
    mutation: ADD_FAQ_CATEGORY,
    funcName: 'addFaqCategory',
    query: ALL_FAQ_CATEGORIES_QUERY,
    queryName: 'allFaqCategories',
    node: ['createFaqCategory', 'category'],
    type: 'add'
  })(Component);

export const withAddFaqQuestion = Component =>
  withOperation({
    mutation: ADD_FAQ_QUESTION,
    funcName: 'addFaqQuestion',
    query: FAQ_CATEGORY_BY_ID_QUERY,
    queryName: ['faqCatagoryById', 'faqquestionSet'],
    node: ['createFaqQuestion', 'question'],
    type: 'add',
    variable: { type: 'single', varName: 'id' }
  })(Component);

export const withEditFaqCategory = Component =>
  graphql(EDIT_FAQ_CATEGORY, {
    props: ({ mutate }) => ({
      editFaqCatgory: async values => {
        try {
          const {
            data: { updateFaqCategory }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateFaqCategory.category;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditFaqQuestion = Component =>
  graphql(EDIT_FAQ_QUESTION, {
    props: ({ mutate }) => ({
      editFaqQuestion: async values => {
        try {
          const {
            data: { updateFaqQuestion }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateFaqQuestion.question;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withDeleteFaqCategory = Component =>
  withOperation({
    mutation: DELETE_FAQ_CATEGORY,
    mutationVarName: 'categoryId',
    funcName: 'deleteFaqCategory',
    query: ALL_FAQ_CATEGORIES_QUERY,
    queryName: 'allFaqCategories',
    node: ['deleteFaqCategory', 'category'],
    type: 'delete'
  })(Component);

export const withDeleteFaqQuestion = Component =>
  withOperation({
    mutation: DELETE_FAQ_QUESTION,
    mutationVarName: 'questionId',
    funcName: 'deleteFaqQuestion',
    query: FAQ_CATEGORY_BY_ID_QUERY,
    queryName: ['faqCatagoryById', 'faqquestionSet'],
    node: ['deleteFaqQuestion', 'question'],
    type: 'delete',
    variable: { type: 'single', varName: 'id' }
  })(Component);

// Client
export const withPageState = Component =>
  graphql(PAGE_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.pageState;
      const pageState = { ...removeTypename(rest), orderBy };
      return { ...pageState, stateLoading: data.loading };
    }
  })(Component);

export const withPageFilterUpdating = Component =>
  graphql(UPDATE_PAGE_FILTER, {
    props: ({ mutate }) => ({
      onOrderByChange(orderBy) {
        mutate({ variables: { orderBy } });
      },
      onPaginationChange(pagination) {
        mutate({ variables: { pagination } });
      },
      onFiltersRemove() {
        mutate({ variables: { ...pageResolvers.defaults.pageState } });
      },
      onNameChange(name_Icontains) {
        mutate({ variables: { filter: { name_Icontains } } });
      },
      onEmailChange(email_Icontains) {
        mutate({ variables: { filter: { email_Icontains } } });
      },
      onMessageChange(message_Icontains) {
        mutate({ variables: { filter: { message_Icontains } } });
      },
      onSearchTextChange(searchText) {
        mutate({ variables: { filter: { searchText } } });
      }
    })
  })(Component);
