import React from 'react';

import { compose } from '../../core';

import { getIntFromString } from '../../look';
import ROUTES from '../route';

import { withAllPublishedStories, withStoryState, withStoryFilterUpdating } from './StoryOperations';
import { subscribeToAllPublishedStory } from './StorySubscriptions';
import { Row, Col, Empty } from 'antd';
import { Link } from 'react-router-dom';

const SimilarStoryByAuthor = props => {
  const { allPublishedStories, author, hide, subscribeToMore } = props;
  // React.useEffect(() => {
  //   const subscribe = subscribeToAllPublishedStory(subscribeToMore /* , props.filter */);
  //   return () => subscribe();
  // });

  return (
    allPublishedStories?.edges?.length > 0 && (
      <>
        <h3>More from {author?.split(',')[0]}</h3>
        <br />
        <Row gutter={[44, 44]}>
          {allPublishedStories?.edges
            ?.filter(({ node }) => node.id !== hide)
            .map(({ node }) => (
              <Col lg={12} md={12} xs={24}>
                <Link to={`${ROUTES.storyDetailLink}${getIntFromString(node.id)}`}>
                  <img
                    src="https://brainayan.com/wp-content/uploads/2020/04/Brainayan-Blog_Resilience-in-Trying-Times.jpg"
                    style={{ width: '100%', height: '70%', borderRadius: '15px' }}
                    alt="col-8"
                  />
                  <h3 /* style={{ opacity: blog.id !== 1 && 0 }} */>
                    <br />
                    {node.title}
                  </h3>
                </Link>
              </Col>
            ))}
          {allPublishedStories && allPublishedStories.edgeCount === 1 && (
            <div style={{ width: '70vw' }}>
              <br />
              <br />
              <Empty description="No result found" />
            </div>
          )}
        </Row>
      </>
    )
  );
};

export default compose(withAllPublishedStories)(SimilarStoryByAuthor);
