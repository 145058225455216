import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { compose } from '../../core';
import { AdminLayout, displayDataCheck, GetColumnSearchProps, getOrderBy } from '../../look';

import ROUTE from '../route';

import {
  withAllEmployees,
  withDeleteEmployee,
  withOrganizationState,
  withOrganizationFilterUpdating
} from './OrganizationOperation';
import { subscribeToEmployee } from './OrganizationSubscriptions';

const Employee = props => {
  const {
    loading,
    allEmployees,
    onPaginationChange,
    deleteEmployee,
    onOrderByChange,
    orderBy,
    onFiltersRemove,
    onUserChange,
    subscribeToMore
  } = props;

  React.useEffect(() => {
    const subscribe = subscribeToEmployee(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const columns = [
    {
      title: 'User',
      key: 'user',
      sorter: () => onOrderByChange(getOrderBy('user__firstName', orderBy)),
      ...GetColumnSearchProps('name', onUserChange, 'user'),
      render: (text, record) => (
        <>{record && displayDataCheck(record.user.firstName ? record.user.firstName : record.user.username)}</>
      )
    },
    {
      title: 'Created By',
      key: 'createdBy',
      sorter: () => onOrderByChange(getOrderBy('createdBy', orderBy)),
      render: (text, record) => <>{record && record.createdBy && displayDataCheck(record.createdBy.email)}</>
    },
    {
      title: 'Updated By',
      key: 'updatedBy',
      sorter: () => onOrderByChange(getOrderBy('updatedBy', orderBy)),
      render: (text, record) => <>{record && record.updatedBy && displayDataCheck(record.updatedBy.email)}</>
    }
  ];

  return (
    <AdminLayout
      active={ROUTE.employee}
      table={true}
      title="Employees"
      loading={loading}
      tableData={allEmployees}
      columns={columns}
      onDelete={deleteEmployee}
      editLink={ROUTE.editEmployeeLink}
      addLink={ROUTE.addEmployee}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      extra={
        <Button size="md" type="primary" ghost>
          <Link to={{ pathname: ROUTE.addEmployee, state: { type: 'multiple' } }}>
            <PlusOutlined />
            Add Multiple Employees
          </Link>
        </Button>
      }
    />
  );
};

export default compose(
  withOrganizationState,
  withDeleteEmployee,
  withAllEmployees,
  withOrganizationFilterUpdating
)(Employee);
