import React from 'react';
import { Table } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import { PAGINATION_LIMIT } from '../../../config';
import arrayMove from 'array-move';

import { getIntFromString } from './functions';

const RenderTable = props => {
  const {
    tableData,
    loading,
    rowKey = 'id',
    onPaginationChange,
    columns,
    selectedRowKeys,
    handleChange,
    components,
    onSort
  } = props;
  const [dataSource, setDataSource] = React.useState([]);
  React.useEffect(() => {
    if (components) {
      const edges = [];
      tableData &&
        tableData.edges.length > 0 &&
        tableData.edges.map(({ node }, index) => {
          edges.push({ ...node, index: index + 1 });
        });
      setDataSource(edges);
    } else {
      setDataSource(tableData);
    }
  }, [components, tableData]);

  const rowSelection = {
    // type: 'radio',
    selectedRowKeys,
    onChange: handleChange
  };
  const SortableItem = sortableElement(props => <tr {...props} />);
  const SortableContainer = sortableContainer(props => (
    <tbody {...props} style={{ background: '#fafafa', border: '1px solid #ccc' }} />
  ));
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      const indexData = [];
      newData.map((node, index) => {
        const data = newData[index];
        indexData.push({ ...data, index: index });
      });
      onSort && onSort(indexData);
      setDataSource(indexData);
    }
  };
  const DraggableContainer = props => (
    <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource && dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      style={{ overflowX: 'auto' }}
      size={!(tableData && tableData.totalCount) && 'middle'}
      loading={loading}
      rowKey={record => (rowKey === 'id' ? getIntFromString(record[rowKey]) : record[rowKey])}
      pagination={
        !(tableData && tableData.totalCount)
          ? false
          : {
              defaultCurrent: 1,
              showTotal: (total, range) =>
                tableData &&
                tableData.totalCount && (
                  <>
                    Showing {range[0]} to {tableData.edgeCount} of {total} entries.
                  </>
                ),
              onChange: (page, pageSize) => onPaginationChange({ offset: page * pageSize - pageSize, first: pageSize }),
              defaultPageSize: PAGINATION_LIMIT,
              total: tableData && tableData.totalCount
            }
      }
      rowSelection={rowSelection}
      dataSource={
        dataSource
          ? components
            ? dataSource.sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
            : dataSource.edges && dataSource.edges.map(({ node }) => node)
          : []
      }
      columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
      components={
        components && {
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }
      }
    />
  );
};

export default RenderTable;
