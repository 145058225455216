import gql from 'graphql-tag';
import { CONDITION_TYPE } from './ConditionType.gql';

export const ADD_CONDITION_MUTATION = gql`
  ${CONDITION_TYPE}
  mutation addCondition($conditionData: ConditionInput!) {
    createCondition(conditionData: $conditionData) {
      condition {
        ...ConditionType
      }
    }
  }
`;
