import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';
import { getIntFromString, ButtonsCatch, withMarkdownFieldValue } from '../../look';

import AddGroupView from '../components/AddGroupView';
import ROUTE from '../route';
import { CKEditorFieldName1, CKEditorFieldName2 } from '../components/GroupFormComponent';

import { withAddGroup, withSurveyState } from './SurveyOperations';

const AddGroup = props => {
  const { history, match, addGroup } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    var groupData = {
      ...withMarkdownFieldValue(CKEditorFieldName2, {
        ...withMarkdownFieldValue(CKEditorFieldName1, { ...values, surveyId: match.params.id }),
        surveyId: match.params.id
      })
    };
    // groupData = ;
    try {
      const response = await addGroup({ groupData });
      // console.log(response);
      response &&
        ButtonsCatch(
          item,
          `${ROUTE.surveyDetailLink}${getIntFromString(response.survey.id)}`,
          history,
          item === undefined ? `${ROUTE.editGroupLink}${getIntFromString(response.id)}` : window.location.pathname
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddGroupView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withSurveyState, withAddGroup)(AddGroup);
