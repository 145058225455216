import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import { setItem } from '../../core';

const RedirectAuthenticateView = props => {
  const { location } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const paramsMatch = window.location.href.match(/\?.+/);
    if (paramsMatch) {
      const params = new URLSearchParams(paramsMatch[0]);
      setItem('accessToken', params.get('accessToken'));
      setItem('refreshToken', params.get('refreshToken'));
      setLoading(false);
      if (location) {
        window.location = params.get('redirectTo');
      }
    }
  }, []);

  return (
    <Spin size="large" spinning={loading} tip="Authenticating...">
      <div style={{ width: '80vh', height: '80vh' }}></div>
    </Spin>
  );
};

export default RedirectAuthenticateView;
