import React from 'react';
import { Form, Radio, Row, Col } from 'antd';
import chunk from 'lodash/chunk';

import ReactMarkdown from '../ReactMarkdown';

const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const RadioField = props => {
  const { vertical = true, choices, onChange, disabled, ...rest } = props;

  const choice = choices.map((choice, key) => (
    <Radio
      key={key}
      style={{ display: vertical && 'flex', marginBottom: '1vh' }}
      value={
        choice.value || (typeof choice.label === 'string' ? <ReactMarkdown>{choice.label}</ReactMarkdown> : choice.label)
      }
    >
      {typeof choice.label === 'string' ? <ReactMarkdown>{choice.label}</ReactMarkdown> : choice.label}
    </Radio>
  ));

  const parts = chunk(choice, 5);
  return (
    <div style={{ marginBottom: '5vh' }}>
      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} {...rest}>
        <RadioGroup disabled={disabled} onChange={onChange} style={{ width: '100%' }}>
          <Row>{choices.length && parts?.map((chunk, i) => <Col span={24 / parts?.length}>{chunk}</Col>)}</Row>
        </RadioGroup>
      </FormItem>
    </div>
  );
};

export default RadioField;
