import * as React from 'react';
import { Row, Col, Form, Input, Button, Card, Modal, Divider } from 'antd';
import styled from 'styled-components';
import { HomeOutlined, ContactsOutlined, MailOutlined, CheckCircleFilled } from '@ant-design/icons';

import { MetaTags } from '../../look';
import ContactUs1 from '../../assets/contactus1.svg';

const layout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 24
  }
};
const ContactUsView = props => {
  const { onSubmit } = props;
  const [visible, setVisible] = React.useState(false);

  const handleSubmit = async values => {
    const response = await onSubmit(values);
    response && setVisible(true);
  };

  return (
    <>
      <MetaTags title="Contact Us" description="This is Contact us page" />
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <div style={{ height: '8vh' }} />
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col lg={12} md={20}>
          <Card bodyStyle={{ padding: '0' }} style={{ boxShadow: '0px 4px 20px 10px rgba(84, 84, 84, 0.1)' }}>
            <Row /* justify="space-between" */ align="middle">
              <Col lg={12} md={12} sm={0} xs={0}>
                <SignUpContainer>
                  <SignUpTitle>
                    <HomeOutlined /> &nbsp; &nbsp;Address
                  </SignUpTitle>
                  <SignUpDetails>
                    D-606, Solitaire co-operative Society, Dhanori-Lohegao road, Dhanori, Pune-411015
                  </SignUpDetails>
                  <br />
                  <br />
                  <SignUpTitle>
                    <ContactsOutlined /> &nbsp; &nbsp;Let&apos;s Talk
                  </SignUpTitle>
                  <SignUpDetails>+91 9848524333</SignUpDetails>
                  <br />
                  <br />
                  <SignUpTitle>
                    <MailOutlined /> &nbsp; &nbsp;General Support
                  </SignUpTitle>
                  <SignUpDetails>info@brain-Ayan.com</SignUpDetails>
                  <br />
                </SignUpContainer>
                <img src={ContactUs1} height="100%" alt="contact" style={{ height: '60vh' }} />
              </Col>
              <FormCol lg={12} md={12} sm={24} xs={24}>
                <Row justify="center" align="middle">
                  <Col>
                    <Welcome>Send Us a Message</Welcome>
                    <Form {...layout} requiredMark={false} name="login-form" onFinish={handleSubmit}>
                      <Form.Item
                        name="name"
                        label="Your Name"
                        rules={[
                          {
                            required: true,
                            message: 'Name is required'
                          }
                        ]}
                      >
                        <Input placeholder="Name" />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        label="Your Email"
                        rules={[
                          { required: true, message: 'E Mail is required!' },
                          {
                            required: true,
                            type: 'email',
                            message: 'The input is not valid E-mail!'
                          }
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        name="message"
                        label="Your Message"
                        rules={[
                          {
                            required: true,
                            message: 'Message is required'
                          }
                        ]}
                      >
                        <Input.TextArea />
                      </Form.Item>
                      <Button block type="primary" htmlType="submit">
                        Send Message
                      </Button>
                    </Form>
                  </Col>
                </Row>
                <Modal
                  footer={null}
                  centered
                  visible={visible}
                  onCancel={() => {
                    setVisible(false);
                    window.location.reload();
                  }}
                  // bodyStyle={{ padding: '24px 64px' }}
                >
                  <Col />
                  <LessDivider />
                  <br />
                  <div style={{ textAlign: 'center' }}>
                    <MailSent>
                      <CheckCircleFilled /> &nbsp;
                      <span>Thank You</span>
                    </MailSent>
                    <br />
                    <Row justify="center">
                      <Col span={15}>
                        <h3 style={{ textAlign: 'center' }}>We’ve recieved your message and we’ll get back to you soon.</h3>
                      </Col>
                    </Row>
                    {/* <Row justify="center">
                      <Col span={18}>
                        <br />
                        <h4 style={{ fontWeight: 'bold' }}>Have you checked our latest blog on Resilience?</h4>
                        <br />
                      </Col>
                    </Row>
                    <LessDivider /> */}
                    <br />
                    <Row justify="center" gutter={24}>
                      <Col>
                        <Button
                          type="primary"
                          ghost
                          onClick={() => {
                            setVisible(false);
                            window.location.reload();
                          }}
                          size="medium"
                        >
                          Close
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Modal>
              </FormCol>
              <Col lg={0} md={0} sm={24} xs={24}>
                <SignUpContainer>
                  <SignUpTitle>
                    <HomeOutlined /> &nbsp; &nbsp;Address
                  </SignUpTitle>
                  <SignUpDetails>
                    D-606, Solitaire co-operative Society, Dhanori-Lohegao road, Dhanori, Pune-411015
                  </SignUpDetails>
                  <br />
                  <br />
                  <SignUpTitle>
                    <ContactsOutlined /> &nbsp; &nbsp;Let&apos;s Talk
                  </SignUpTitle>
                  <SignUpDetails>+91 9848524333</SignUpDetails>
                  <br />
                  <br />
                  <SignUpTitle>
                    <MailOutlined /> &nbsp; &nbsp;General Support
                  </SignUpTitle>
                  <SignUpDetails>info@brain-Ayan.com</SignUpDetails>
                  <br />
                </SignUpContainer>
                <img src={ContactUs1} height="100%" width="100%" alt="contact" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ContactUsView;
const FormCol = styled(Col)`
  /* padding: 48px; */
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;
const MailSent = styled.div`
  color: #009c4a;
  font-size: 20px;
`;

const LessDivider = styled(Divider)`
  margin: 0;
  margin-bottom: 0.5em;
`;

const Welcome = styled.h3`
  font-weight: bold;
`;
const SignUpTitle = styled.h3`
  color: white;
  margin-bottom: 0;
`;

const SignUpDetails = styled.div`
  padding-left: 20px;
`;
const SignUpContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 50%;
  color: white;
  margin: 8vh 12vh;
`;
