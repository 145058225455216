import React from 'react';
import { Row, Col, Button, Result } from 'antd';

import Group from '../../assets/Group68.svg';
import Group1 from '../../assets/Group69.svg';
import ROUTE from '../../home/route';

const NotFound = props => {
  const { status = '404', up = true } = props;
  return (
    <div className="HVCenter">
      <Row>
        <Col span={24}>
          {up ? (
            <Result
              status={status}
              title={status}
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Button href={ROUTE.home} type="primary">
                  Back Home
                </Button>
              }
            />
          ) : (
            <>
              <Row justify="center" align="middle" style={{ textAlign: 'center' }}>
                <Col span={24}>
                  <h1>
                    The site is currently <br />
                    down for maintenace
                  </h1>
                  <h3 style={{ fontWeight: 'normal', color: '#898A82' }}>We apologize for any inconvenience caused</h3>
                  <h3 style={{ fontWeight: 'normal', color: '#898A82' }}>Service will resume in some time</h3>
                  <Button href={ROUTE.home} type="primary" ghost>
                    Back Home
                  </Button>
                </Col>
              </Row>
              <br />
              <br />
              <br />
              <Row style={{ paddingTop: '4vh', width: '100%' }}>
                <Col span={11}>
                  <img src={Group} alt="group" style={{ width: '100%', height: '100%' }} />
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <img src={Group1} alt="group" style={{ width: '100%', height: '100%' }} />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NotFound;
