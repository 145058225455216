import React from 'react';
import _ from 'lodash';
import { Button, Modal, Tabs, Form, Row, Col, Upload, Input, Card } from 'antd';
import { /* UploadOutlined, */ PlusOutlined, EditFilled } from '@ant-design/icons';
import moment from 'moment';

// import { NODE_ENV } from '../../../config';

import { SelectField, DatePickerField, TextField, CheckBoxField, RenderUploadWithCrop } from '../../look';

const EditProfileView = props => {
  const { onSubmit, getProfileImplicit, onPassChange, delErrors, onDelete, passErrors } = props;
  const [visible, setVisible] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [form] = Form.useForm();
  const initialValue = {
    gender: getProfileImplicit && getProfileImplicit.gender,
    married: getProfileImplicit && getProfileImplicit.married,
    dob:
      (getProfileImplicit && getProfileImplicit.dob && moment(getProfileImplicit.dob, 'YYYY-MM-DD')) ||
      moment(new Date(), 'YYY-MM-DD'),
    village: getProfileImplicit && getProfileImplicit.village,
    district: getProfileImplicit && getProfileImplicit.district,
    phoneNumber: getProfileImplicit && getProfileImplicit.phoneNumber,
    residenceDuringSecondarySchool: getProfileImplicit && getProfileImplicit.residenceDuringSecondarySchool,
    residenceDuringCollegeUniversity: getProfileImplicit && getProfileImplicit.residenceDuringCollegeUniversity,
    residenceDuringMajorPartOfCareer: getProfileImplicit && getProfileImplicit.residenceDuringMajorPartOfCareer,
    address: getProfileImplicit && getProfileImplicit.address
  };

  const handleFinish = values => {
    const data = {
      ...initialValue,
      ...values,
      dob: values.dob && values.dob._d && new Date(values.dob._d).toJSON().slice(0, 10).replace(/-/g, '-')
    };

    setVisible(false);
    onSubmit(data);
  };
  const handleDelete = async values => {
    const status = await onDelete(values);
    status && setVisible(false);
  };
  const handleChangePassword = async values => {
    setVisible(false);
    const response = await onPassChange(values);
  };

  function disabledDate(current) {
    let customDate = new Date();
    return current && current > moment(customDate, 'YYYY-MM-DD');
  }

  const dynamicProps = pass => {
    return passErrors
      ? {
          validateStatus: passErrors && passErrors[pass] ? 'error' : '',
          help: passErrors && passErrors[pass] ? passErrors[pass].length > 0 && passErrors[pass][0].message : ''
        }
      : {};
  };

  return (
    <>
      <Row>
        <Col lg={24} xs={0}>
          <Card bodyStyle={{ padding: 0 }} hoverable style={{ borderRadius: '10px' }}>
            <Button
              block
              size="large"
              style={{ height: '110%', border: '0px', borderRadius: '10px' }}
              onClick={() => setVisible(true)}
            >
              Edit Profile
            </Button>
          </Card>
        </Col>
        <Col lg={0} xs={24}>
          <Button icon={<EditFilled />} shape="circle" type="primary" ghost onClick={() => setVisible(true)} />
        </Col>
      </Row>

      <Modal
        footer={null}
        centered
        visible={visible}
        width="70%"
        onCancel={() => setVisible(false)}
        bodyStyle={{ height: '70vh' }}
      >
        <Form form={form} onFinish={handleFinish} initialValues={initialValue}>
          <Tabs tabPosition="left">
            <Tabs.TabPane tab="Bio 1" key="1" style={{ height: '50vh' }}>
              <Row justify="start">
                {/* <Col span={24}>
                  <h3>Bio1</h3>
                  <br />
                </Col> */}
                <Col span={24}>
                  <SelectField
                    // {...layout}
                    style={{ width: '100%' }}
                    margin={false}
                    name="gender"
                    label="Gender"
                    choices={[
                      { label: 'Male', value: 'M' },
                      { label: 'Female', value: 'F' },
                      { label: 'Other', value: 'O' }
                    ]}
                  />
                  {/* </Col> */}
                  {/* <Col span={20}> */}
                  <DatePickerField
                    // size="large"
                    // style={{ width: '80%',height: '32'}}
                    // labelCol={{ span: 5 }}
                    // wrapperCol={{ span: 19 }}
                    // {...layout}
                    margin={false}
                    name="dob"
                    label="DOB"

                    // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                  />
                  {/* </Col> */}
                  {/* <Col span={20}> */}
                  <CheckBoxField
                    // {...layout}
                    // style={{ width: '70%' }}
                    name="married"
                    label="Married"
                  />
                </Col>
              </Row>
              <Row justify="end" align="bottom">
                <Col>
                  <Button type="link" htmlType="submit">
                    Done
                  </Button>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Bio 2" key="2" style={{ height: '50vh' }}>
              {/* <Col span={24}>
                <h3>Bio2</h3>
                <br />
              </Col> */}
              <Row justify="start">
                <Col span={20}>
                  <TextField /* {...layout} */ name="village" label="Village" />
                  {/* </Col> */}
                  {/* <Col span={20}> */}
                  <TextField /* {...layout} */ name="district" label="District" />
                  {/* </Col> */}
                  {/* <Col span={20}> */}
                  <TextField /* {...layout} */ name="phoneNumber" label="Phone Number" />
                </Col>
              </Row>
              <Row justify="end">
                <Button type="link" htmlType="submit">
                  Done
                </Button>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Education" key="3" style={{ height: '55vh', overflowY: 'auto' }}>
              <Row justify="start">
                {/* <Col span={24}>
                  <h3>Education</h3>
                  <br />
                </Col> */}
                <Col span={20}>
                  <TextField name="residenceDuringSecondarySchool" label="Residence during secondary school :" />
                  <TextField name="residenceDuringCollegeUniversity" label="Residence during college university :" />
                  <TextField name="residenceDuringMajorPartOfCareer" label="Residence during major part of career :" />
                  <TextField name="address" label="Address :" />
                </Col>
              </Row>
              <Row justify="end">
                <Button type="link" htmlType="submit">
                  Done
                </Button>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Add Avatar" key="4" style={{ height: '50vh' }}>
              <Row>
                {/* <Col span={24}>
                  <h3>Add Avatar</h3>
                  <br />
                </Col> */}
                {/* <Upload
                  name="logo"
                  className="avatar-uploader"
                  listType="picture-card"
                  action="/upload.do"
                  showUploadList={false}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload> */}
                <RenderUploadWithCrop
                  width={300}
                  height={250}
                  setUrl={e => form.setFieldsValue(_.set(form.getFieldsValue(true), ['profileImage'], e))}
                  setLoad={setLoad}
                  name={'profileImage'}
                  label="Image"
                  value={initialValue?.profileImage}
                />
                <Row justify="end" align="bottom">
                  <Col>
                    <Button type="link" htmlType="submit">
                      Done
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Change Password" key="5" style={{ height: '100%', overflowY: 'auto' }}>
              <Form onFinish={handleChangePassword}>
                <Row>
                  {/* <Col span={24}>
                    <h3>Change Password</h3>
                    <br />
                  </Col> */}
                  <Col span={20}>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24, offset: 1 }}
                      // labelCol={{ span: 8 }}
                      // wrapperCol={{ span: 16 }}
                      name="oldPassword"
                      label="Current password"
                      {...dynamicProps('oldPassword')}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24, offset: 1 }}
                      // labelCol={{ span: 8 }}
                      // wrapperCol={{ span: 16 }}
                      name="newPassword1"
                      label="New password"
                      {...dynamicProps('newPassword1')}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!'
                        },
                        {
                          min: 8,
                          message: 'Password length should be atleast 8.'
                        }
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24, offset: 1 }}
                      // labelCol={{ span: 8 }}
                      // wrapperCol={{ span: 16 }}
                      name="newPassword2"
                      label="Confirm new password"
                      {...dynamicProps('newPassword2')}
                      dependencies={['newPassword1']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!'
                        },
                        {
                          min: 8,
                          message: 'Password length should be atleast 8.'
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('newPassword1') === value) return Promise.resolve();

                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                          }
                        })
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                  <Row justify="end">
                    <Col span={24}>
                      <Button type="primary" htmlType="submit">
                        Change the Password
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Form>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Delete Account" key="6" style={{ height: '50vh' }}>
              {/* <Row>
                <Col span={18}>
                  <h4>
                    This action cannot be undone. Once you delete your account, there is no going back. Please type your
                    <strong> password </strong>
                    to confirm.
                  </h4>
                </Col>
              </Row> */}
              {/* <br /> */}
              <Form onFinish={handleDelete}>
                <Row>
                  <Col span={24}>
                    <h3>
                      <strong>Delete Account</strong>
                    </h3>
                    <br />
                  </Col>
                  <Col span={18}>
                    <h4>
                      This action cannot be undone. Once you delete your account, there is no going back. Please type your
                      <strong> password </strong>
                      to confirm.
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col span={18}>
                    <Form.Item
                      wrapperCol={{ span: 22 }}
                      name="password"
                      validateStatus={delErrors && delErrors.password ? 'error' : ''}
                      help={delErrors ? delErrors && delErrors.password[0].message : null}
                      rules={[
                        {
                          required: true,
                          message: 'Password is required'
                        }
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                  <Col span={20} align="right">
                    <Button block type="primary" htmlType="submit" danger>
                      Delete your account
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Modal>
    </>
  );
};

export default EditProfileView;
