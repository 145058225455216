import { subscribeToQuery } from '../../look';

import { SURVEY_COMPARISON_REQUEST_SUBSCRIPTION } from '../graphql/SurveyComparisonRequestSubscription.gql';

export const subscribeToSurveyComparisonRequestForUser = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_COMPARISON_REQUEST_SUBSCRIPTION,
      subscriptionName: 'surveyComparisonRequestSubscription',
      nodeName: 'comparisonRequest',
      queryName: 'allComparisonRequestForUser'
    },
    variables
  );
};

export const subscribeToSurveyComparisonRequestFromUser = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_COMPARISON_REQUEST_SUBSCRIPTION,
      subscriptionName: 'surveyComparisonRequestSubscription',
      nodeName: 'comparisonRequest',
      queryName: 'allComparisonRequestFormUser'
    },
    variables
  );
};
