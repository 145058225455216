import gql from 'graphql-tag';
import { RESPONSE_TYPE } from './ResponseType.gql';

export const EDIT_RESPONSE_MUTATION = gql`
  ${RESPONSE_TYPE}
  mutation editResponse($answers: [AnswerInput]!, $responseId: ID!) {
    updateResponse(answers: $answers, responseId: $responseId) {
      response {
        ...ResponseType
      }
    }
  }
`;
