import React from 'react';
import { Form, Input, Row, Col, Select, Button } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
// import DeleteIcon from '../DeleteIcon';
// import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
import { ReactMarkdown } from '../../look';

const FormItem = Form.Item;
const Option = Select.Option;

const SurveySelectField = props => {
  const {
    choices,
    onAdd,
    onEdit,
    onDelete,
    margin = true,
    otherOption = false,
    onChangeSelect,
    newTab = false,
    offset,
    onSelectChange,
    onBlur,
    onSearch,
    disabled = false,
    selectedValue,
    ...rest
  } = props;
  const [value, setValue] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref && ref.current && ref.current.props && ref.current.props.value !== '') {
      setValue(ref.current.props.value);
    }
  }, []);

  const choice =
    choices &&
    choices.map((choice, key) => (
      <Option
        key={key}
        className='selected-survey'
        value={String(choice.value) === 'false' ? false : choice.value === '' ? '' : choice.value || choice.label}
      >
        {typeof choice.label === 'string' ? <ReactMarkdown>{choice.label}</ReactMarkdown> : choice.label}
      </Option>
    ));
  const otherField = (
    <Option key="other" value="" className='selected-survey'>
      Other...
    </Option>
  );
  const handleChange = params => {
    if (params === '') {
      setVisible(true);
    } else {
      setVisible(false);
      onSelectChange && onSelectChange(params);
    }
  };
  const handleSelect = params => { 
    setValue(params);
    onChangeSelect && onChangeSelect(params);
  };

  const extraLinkProps = newTab
    ? {
        target: '_blank'
      }
    : {};

  return (
    <div style={{ marginBottom: margin ? '2vh' : '' }} className='generic-survey-selected-row'>
      <Row gutter={[15, 5]} className='generic-survey-selected-row'>
        <Col span={onAdd ? (onEdit ? 18 : 21) : 24}>
          <FormItem
            onChange={onSelectChange}
            {...rest}
          >
            <Select className="generic-survey-select-filed" defaultValue={selectedValue}  onChange={handleChange} style={{textOverflow:"ellipsis",whiteSpace:"nowrap"}} suffixIcon={<img src={"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1707736493/WorkplaceResilienceProduction/Survey/Images/dropdown_Arrow_tjs71a.svg"} alt="" style={{width:"10px",height:"10px"}}/>} {...props} ref={ref} onSelect={handleSelect} onSearch={onSearch} disabled={disabled}
             filterOption={(input,option)=> option} 
             >
              {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice}
              {/* {choice} */}
            </Select>
          </FormItem>
        </Col>
        {onEdit && (
          <Col lg={2} md={3} style={{ paddingTop: '30px' }}>
            <Button shape="circle" disabled={!value} /* style={{ marginTop: '10px' }} */>
              <Link to={`${onEdit}${value}`}>
                <EditOutlined />
              </Link>
            </Button>
          </Col>
        )}
        {onAdd && (
          <Col lg={2} md={3} style={{ paddingTop: '30px' }}>
            <Button shape="circle" type="primary" ghost /* style={{ marginTop: '10px' }} */>
              <Link to={`${onAdd}`} {...extraLinkProps}>
                <PlusOutlined />
              </Link>
            </Button>
          </Col>
        )}
        {/* {onDelete && (
          <Col span={3} style={{ paddingTop: '3vh' }}>
            <DeleteIcon onClick={() => onDelete(value)} style={{ marginTop: '10px' }} />
          </Col>
        )} */}
      </Row>
      {visible && (
        <FormItem onChange={onSelectChange} {...rest} label={null}>
          <Input className="generic-survey-input-filed" onBlur={onBlur} />
        </FormItem>
      )}
    </div>
  );
};

export default SurveySelectField;