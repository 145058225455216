import React from "react";
import { Spin } from "antd";

import { compose } from "../../core";
import { ButtonsCatch, getIntFromString } from "../../look";

import ROUTE from "../route";
import AddTagView from "../components/AddTagView";

import { withAddTag, withStoryState } from "./StoryOperations";

const AddTag = props => {
  const { history, addTag } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (tagData, item) => {
    setSubmitLoading(true);
    try {
      const response = await addTag({ tagData });
      response && window.location.href.includes("closeonsave") && window.close();
      response &&
        ButtonsCatch(
          item,
          ROUTE.tag,
          history,
          item === undefined ? `${ROUTE.editTagLink}${getIntFromString(response.id)}` : window.location.pathname
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddTagView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withStoryState, withAddTag)(AddTag);
