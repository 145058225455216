import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { compose } from '../../core';
import { AdminLayout, displayBoolean, displayDataCheck, getOrderBy, GetColumnSearchProps } from '../../look';

import ROUTES from '../route';

import { withListAllUsers, withUserState, withUserFilterUpdating } from './UserOperations';

const User = props => {
  const {
    loading,
    listAllUsers,
    deleteUser,
    onPaginationChange,
    onOrderByChange,
    orderBy,
    onUsernameChange,
    onFirstNameChange,
    onLastNameChange,
    onEmailChange,
    onFiltersRemove
  } = props;

  const columns = [
    {
      title: 'Username',
      key: 'username',
      sorter: () => onOrderByChange(getOrderBy('username', orderBy)),
      ...GetColumnSearchProps('username', onUsernameChange),
      render: (text, record) => record && displayDataCheck(record.username)
    },
    {
      title: 'Email',
      key: 'email',
      sorter: () => onOrderByChange(getOrderBy('email', orderBy)),
      ...GetColumnSearchProps('email', onEmailChange),
      render: (text, record) => record && displayDataCheck(record.email)
    },
    {
      title: 'First Name',
      key: 'firstName',
      sorter: () => onOrderByChange(getOrderBy('firstName', orderBy)),
      ...GetColumnSearchProps('firstName', onFirstNameChange),
      render: (text, record) => record && displayDataCheck(record.firstName)
    },
    {
      title: 'Last Name',
      key: 'lastName',
      sorter: () => onOrderByChange(getOrderBy('lastName', orderBy)),
      ...GetColumnSearchProps('lastName', onLastNameChange),
      render: (text, record) => record && displayDataCheck(record.lastName)
    },
    {
      title: 'Is staff',
      key: 'isStaff',
      sorter: () => onOrderByChange(getOrderBy('isStaff', orderBy)),
      render: (text, record) => record && displayBoolean(record.isStaff)
    }
  ];

  return (
    <AdminLayout
      table={true}
      active={ROUTES.user}
      title="Users"
      loading={loading}
      tableData={listAllUsers}
      columns={columns}
      onDelete={deleteUser}
      addLink={ROUTES.addUser}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      extra={
        <Button size="md" type="primary" ghost>
          <Link to={ROUTES.addMultipleUser}>
            <PlusOutlined />
            Add Multiple User
          </Link>
        </Button>
      }
    />
  );
};

export default compose(withUserState, withListAllUsers /* , withDeleteUser */, withUserFilterUpdating)(User);
