import gql from 'graphql-tag';
import { CUSTOM_USER_COMMITMENT_TYPE } from './CustomUserCommitmentType.gql';

export const ADD_CUSTOM_USER_COMMITMENT = gql`
  ${CUSTOM_USER_COMMITMENT_TYPE}
  mutation addCustomUserCommitment($customUserCommitmentData: CustomUserCommitmentInput!) {
    createCustomUserCommitment(customUserCommitmentData: $customUserCommitmentData) {
      customUserCommitment {
        ...CustomUserCommitmentType
      }
    }
  }
`;
