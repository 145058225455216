import React from "react";
import { Col, Dropdown, Row, Button, List, Menu, Comment, Avatar, Spin, Input, Tooltip } from "antd";
import { MoreOutlined, DeleteOutlined, UserOutlined } from "@ant-design/icons";

import { getIntFromString } from "../../look";

const { TextArea } = Input;

const CommentSectionView = props => {
  const [value, setValue] = React.useState("");
  const { me, loading, allCommentsForStoryByStoryId, onSubmit, commentRef, onDelete, admin } = props;
  const menu = id => (
    <Menu>
      <Menu.Item key="1" onClick={() => onDelete(getIntFromString(id))} danger={true}>
        Delete
      </Menu.Item>
      {/* <Menu.Item key="2" >Select</Menu.Item> */}
    </Menu>
  );
  return (
    <Spin spinning={loading} size="small">
      <Row>
        {!admin && <Col lg={2} xs={0} />}
        <Col lg={admin ? 24 : 20} xs={24}>
          {!admin && (
            <>
              <br />
              <TextArea ref={commentRef} rows={4} onChange={e => setValue(e.target.value)} value={value} />
              <br />
              <br />
              <Row justify="start">
                <Button
                  htmlType="submit"
                  /* loading={submitting} */ onClick={() => {
                    onSubmit(value);
                    setValue("");
                  }}
                  type="primary"
                  ghost
                >
                  Add Comment
                </Button>
              </Row>
              <br />
            </>
          )}
          <Row>
            {allCommentsForStoryByStoryId?.edges.length > 0 &&
              allCommentsForStoryByStoryId?.edges?.map(({ node: item }) => (
                <Col span={admin ? 12 : 24}>
                  <Comment
                    datetime={
                      getIntFromString(item.user.id) === getIntFromString(me.id) && (
                        <Dropdown overlay={() => menu(item.id)} placement="bottomLeft">
                          <MoreOutlined
                            style={{
                              fontSize: "large"
                            }}
                          />
                        </Dropdown>
                      )
                    }
                    author={
                      <a>
                        {item.user.firstName && item.user.lastName
                          ? `${item.user.firstName} ${item.user.lastName}`
                          : item.user.username}
                      </a>
                    }
                    avatar={<Avatar icon={<UserOutlined />} alt="Han Solo" />}
                    content={<p align="left">{item.commentText}</p>}
                    // datetime={
                    //   <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                    //     <span>{moment().fromNow()}</span>
                    //   </Tooltip>
                    // }
                  />
                </Col>
              ))}
          </Row>
        </Col>
        {!admin && <Col lg={2} xs={0} />}
      </Row>
    </Spin>
  );
};

export default CommentSectionView;
