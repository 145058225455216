import gql from "graphql-tag";
import { USER_NODE } from "./UserNode.gql";

export const ME_QUERY = gql`
  ${USER_NODE}
  query me {
    me {
      ...UserNode
    }
  }
`;

export const TIPS_QUERY = gql`
  query tips {
    tips {
      edges {
        node {
          id
          anchor
          subFactor
          category
          tipFor
          content
        }
      }
    }
  }
`;
