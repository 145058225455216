import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Empty, Spin } from 'antd';

import { compose } from '../../../core';
import { getIntFromString } from '../functions';
import { withLookVerticalFilterUpdating, withLookVerticalState } from '../../containers/LookOperations';

import SelectField from './SelectField';

const VERTICALS_QUERY = gql`
  query verticals($first: Int, $orderBy: [String], $name_Icontains: String, $organization: ID) {
    verticals(first: $first, orderBy: $orderBy, name_Icontains: $name_Icontains, organization: $organization) {
      edges {
        cursor
        node {
          id
          name
          organization {
            id
            name
          }
        }
      }
    }
  }
`;

export const VERTICALS_BY_ORG_ID_QUERY = gql`
  query verticalsByOrgId(
    $orgId: ID!
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
    $employees_Icontains: [ID]
    $organization: ID
    $subvertical: [ID]
  ) {
    verticalsByOrgId(
      orgId: $orgId
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
      employees_Icontains: $employees_Icontains
      organization: $organization
      subvertical: $subvertical
    ) {
        edges {
          node {
            edges {
              node {
                id
                name
                description
                organization {
                  id
                  name
                  description
                  email
                  logo
                }
              }
            }
          }
        }
      }
  }
`;

const SubverticalAutoComplete = props => {
  const { loading, verticalsByOrgId, id = true, margin = true, onVerticalNameChange, onAdd, onFiltersRemove } = props;
  const handleFilterRemove = React.useRef(() => { });

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const choices =
    verticalsByOrgId &&
    verticalsByOrgId.edges.length > 0 &&
    verticalsByOrgId.edges.map(({ node }) => ({
      label: node.organization ? node.organization.name + ' | ' + node.name : node.name || '',
      value: Number(getIntFromString(node.id))
    }));

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      onAdd={onAdd}
      newTab={true}
      style={{ width: !margin && 300, marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onVerticalNameChange}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookVerticalState,
  graphql(VERTICALS_BY_ORG_ID_QUERY, {
    options: ({ filter, orderBy, pagination, organizationId }) => {
      return {
        variables: {
          ...pagination,
          ...filter,
          orgId: organizationId,
          orderBy
        }
      };
    },
    props({ data }) {
      const { loading, error, verticalsByOrgId, subscribeToMore, updateQuery } = data;
      return { loading, error, verticalsByOrgId, subscribeToMore, updateQuery };
    }
  }),
  withLookVerticalFilterUpdating
)(SubverticalAutoComplete);
