import React, { useState } from 'react';
import { Spin, Col, Row, Switch, Form } from 'antd';

import PerceptionReportComponent from './PerceptionReportComponent';
import PredictionReportComponent from './PredictionReportComponent';
import { useParams } from 'react-router-dom';
import { getBase64StringFromInt, ResponseAutoComplete, getIntFromString } from '../../look';

const SurveyReport = props => {
  const { loading, me, actionLoading, onReport, userReport } = props;
  const { id, uid, username } = useParams();
  const [isBar, setIsBar] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const handleChange = () => {
    setIsBar(!isBar);
  };
  const allowComparison = me?.employee?.organizationSet?.edges[0]?.node?.allowComparison;
  return (
    <Spin spinning={loading}>
      <Row justify="center" style={{ minHeight: '70vh' }}>
        <Col span={20} style={{ border: '1px solid #EBEBEB', borderRadius: 10, padding: '50px 80px', paddingBottom: 80 }}>
          <Row justify="space-between" align="middle">
            <Col lg={12} md={24} xs={24}>
              <h1>
                {id === '2' && 'Workplace Resilience Prediction Survey'}
                {id === '1' && 'Workplace Resilience Perception Survey'}
              </h1>
            </Col>
            <Col lg={12} md={24} xs={24}>
              <Row gutter={[24, 24]} justify="end">
                <Col lg={{ span: 18, offset: 0 }}>
                  <ResponseAutoComplete
                    survey={getBase64StringFromInt('SurveyType', id)}
                    name="sharedResponseId"
                    offset={1}
                    validateStatus={isValidate ? '' : 'error'}
                    help={isValidate ? '' : 'Choose one.'}
                    label="Select your reponse to compare with"
                    placeholder="Select your reponse to compare with"
                    onChange={e => {
                      setIsValidate(true);
                      onReport(e);
                    }}
                  />
                </Col>
                {id === '2' && (
                  <Col lg={{ span: 4, offset: 2 }}>
                    <Form.Item label={'Bar Graph'} labelCol={{ span: 24 }} wrapperCol={{ span: 20, offset: 4 }}>
                      <Switch checked={isBar} onClick={handleChange} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {me && me.employee && (
            <>
              {/* 'Workplace Resilience Perception Survey' */}
              {id === '1' && (
                <PerceptionReportComponent
                  allowComparison={allowComparison}
                  requestedUserId={Number(uid)}
                  userReport={userReport}
                  actionLoading={actionLoading}
                  receiver={username}
                  name={`${me?.firstName} ${me?.lastName}`}
                  // report={{ ...report, name: `${me?.firstName} ${me?.lastName}` }}
                />
              )}

              {/* 'Workplace Resilience Prediction Survey' */}
              {id === '2' && (
                <PredictionReportComponent
                  allowComparison={allowComparison}
                  isBar={isBar}
                  requestedUserId={Number(uid)}
                  userReport={userReport}
                  actionLoading={actionLoading}
                  receiver={username}
                  name={`${me?.firstName} ${me?.lastName}`}
                  // report={{ ...report, name: `${me?.firstName} ${me?.lastName}` }}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default SurveyReport;
