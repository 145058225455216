import React from 'react';

import { compose } from '../../core';

import { AdminLayout, displayDataCheck, getOrderBy } from '../../look';
import ROUTE from '../route';
import { withFaqCatagoryById, withDeleteFaqQuestion, withPageState, withPageFilterUpdating } from './PageOperations';
import { subscribeToFaqQuestion } from './PageSubscriptions';
import ReactMarkdown from '../../look/components/ReactMarkdown';

const FaqQuestion = props => {
  const { loading, faqCatagoryById, onPaginationChange, deleteFaqQuestion, onFiltersRemove, subscribeToMore } = props;

  const columns = [
    {
      title: 'Question',
      key: 'question',
      // sorter: () => onOrderByChange(getOrderBy('question', orderBy)),
      render: (text, record) => <>{record && <ReactMarkdown>{record.question}</ReactMarkdown>}</>
    },
    {
      title: 'Answer',
      key: 'answer',
      // sorter: () => onOrderByChange(getOrderBy('answer', orderBy)),
      render: (text, record) => <>{record && <ReactMarkdown>{record.answer}</ReactMarkdown>}</>
    }
  ];

  React.useEffect(() => {
    const subscribe = subscribeToFaqQuestion(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  return (
    <AdminLayout
      table={true}
      active={ROUTE.faq}
      title="FAQ Question"
      loading={loading}
      tableData={faqCatagoryById && faqCatagoryById.faqquestionSet}
      columns={columns}
      onDelete={deleteFaqQuestion}
      editLink={ROUTE.editFaqQuestionLink}
      addLink={`${ROUTE.addFaqQuestionLink}${props.match.params.id}`}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      showBack={true}
    />
  );
};

export default compose(withPageState, withFaqCatagoryById, withDeleteFaqQuestion, withPageFilterUpdating)(FaqQuestion);
