import React from 'react';
import { Form, Radio } from 'antd';

const MLSelectField = props => {
  const { choices, disabled, ...rest } = props;
  const FormItem = Form.Item;
  const RadioGroup = Radio.Group;

  const choice = choices.map((choice, key) => (
    <Radio
      key={key}
      value={choice.value || choice.label}
      style={{ /* background: 'green', */ width: '50%', justifyContent: 'center' }}
    >
      {/* {choice.label} */}
    </Radio>
  ));
  return (
    <>
      <FormItem
        labelCol={{ span: 8, style: { textAlign: 'left', whiteSpace: 'normal', height: '40px' } }}
        wrapperCol={{ span: 16 }}
        {...rest}
      >
        <RadioGroup disabled={disabled} style={{ /* backgroundColor: 'yellow', */ display: 'flex' }}>
          {choice}
        </RadioGroup>
      </FormItem>
    </>
  );
};

export default MLSelectField;
