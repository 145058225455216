import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Dropdown, Menu, message, Spin, Empty } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { compose } from '../../core';
import {
  AdminLayout,
  CardLayout,
  displayDataCheck,
  GetColumnSearchProps,
  ReactMarkdown,
  getIntFromString,
  getOrderBy,
  Pagination
} from '../../look';
import SURVEY_ROUTE from '../../survey/route';

import ROUTE from '../route';

import { subscribeToOrganization } from './OrganizationSubscriptions';
import {
  withOrganizationState,
  withAllOrganization,
  withDeleteOrganization,
  withOrganizationFilterUpdating
} from './OrganizationOperation';
import { PRIMARY_CLR, PAGINATION_LIMIT, IMG } from '../../../config';
import SelectFilterField from '../../look/components/SelectFilterField';

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  /* height: 100%; */
  /* padding: 0 0 20px 0;
  margin: 15px 0; */
`;

const StyledCard = styled(Card)`
  height: 100%;
  border-color: ${props => props.active && PRIMARY_CLR};
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
  &:hover {
    border-color: ${props => PRIMARY_CLR};
  }
`;

const Organization = props => {
  const {
    loading,
    allOrganization,
    deleteOrganization,
    onOrderByChange,
    onPaginationChange,
    onNameChange,
    onDescriptionChange,
    onEmailChange,
    orderBy,
    onFiltersRemove,
    onPocChange,
    onCeoChange,
    subscribeToMore,
    pagination
  } = props;
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  React.useEffect(() => {
    const subscribe = subscribeToOrganization(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const handleSelect = id => {
    setSelectedRowKeys(
      selectedRowKeys.indexOf(id) === -1 ? [...selectedRowKeys, id] : selectedRowKeys.filter(s => s !== id)
    );
  };

  const handleDelete = async id => {
    setDeleteLoading(true);
    try {
      await deleteOrganization(id);
      message.success('Deleted!');
    } catch (e) {
      message.error('Failed, try again!');
      throw new Error(e.message);
    }
    setDeleteLoading(false);
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onNameChange),
      render: (text, record) =>
        record && (
          // <Link to={`${SURVEY_ROUTE.orgSurveyLink}${getIntFromString(record.id)}`}>{displayDataCheck(record.name)}</Link>
          <Link to={`${ROUTE.organizationDetailLink}${getIntFromString(record.id)}`}>{displayDataCheck(record.name)}</Link>
        )
    },
    {
      title: 'Description',
      key: 'description',
      sorter: () => onOrderByChange(getOrderBy('description', orderBy)),
      ...GetColumnSearchProps('description', onDescriptionChange),
      render: (text, record) => record && <ReactMarkdown>{record.description}</ReactMarkdown>
    },
    {
      title: 'Email',
      key: 'email',
      sorter: () => onOrderByChange(getOrderBy('email', orderBy)),
      ...GetColumnSearchProps('email', onEmailChange),
      render: (text, record) => record && displayDataCheck(record.email)
    },
    {
      title: 'POC',
      key: 'poc',
      sorter: () => onOrderByChange(getOrderBy('poc', orderBy)),
      // ...GetColumnSearchProps('poc', onPocChange, 'user'),
      render: (text, record) =>
        record && displayDataCheck(record.poc && `${record.poc?.user?.firstName} ${record.poc?.user?.lastName}`)
    },
    {
      title: 'CE0',
      key: 'ceo',
      sorter: () => onOrderByChange(getOrderBy('ceo', orderBy)),
      // ...GetColumnSearchProps('ceo', onCeoChange, 'user'),
      render: (text, record) =>
        record && displayDataCheck(record.poc && `${record.ceo?.user?.firstName} ${record.ceo?.user?.lastName}`)
    }
  ];

  const menu = id => (
    <Menu>
      <Menu.Item key="0">
        <Link to={`${ROUTE.editOrganizationLink}${id}`}>Edit</Link>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => handleDelete(id)} danger={true}>
        Delete
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleSelect(id)}>
        Select
      </Menu.Item>
    </Menu>
  );

  return (
    <CardLayout
      table={true}
      active={ROUTE.organization}
      title={[{ name: 'Organizations' }]}
      loading={loading}
      tableData={allOrganization}
      columns={columns}
      onDelete={deleteOrganization}
      editLink={ROUTE.editOrganizationLink}
      addLink={ROUTE.addOrganization}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      selectedRowKeys={selectedRowKeys}
      extra={
        <SelectFilterField
          placeholder="Search Organization by"
          onFiltersRemove={onFiltersRemove}
          options={[
            {
              label: 'Name',
              type: 'text',
              value: onNameChange
            },
            {
              label: 'Description',
              type: 'text',
              value: onDescriptionChange
            },
            {
              label: 'Email',
              type: 'text',
              value: onEmailChange
            }
          ]}
        />
      }
    >
      <Spin spinning={deleteLoading} size="large">
        <div style={{ minHeight: '70vh' }}>
          <Row gutter={[24, 24]}>
            {allOrganization &&
              allOrganization.edges.map(({ node }) => (
                <>
                  <Col xs={24} md={12} lg={8}>
                    <Dropdown overlay={() => menu(getIntFromString(node.id))} placement="bottomLeft">
                      <MoreOutlined
                        style={{
                          fontSize: 'x-large',
                          position: 'absolute',
                          right: 20,
                          zIndex: 1,
                          top: 10
                        }}
                      />
                    </Dropdown>

                    <Link to={`${ROUTE.organizationDetailLink}${getIntFromString(node.id)}`}>
                      <StyledCard
                        active={selectedRowKeys.indexOf(getIntFromString(node.id)) !== -1}
                        title={<Row></Row>}
                        bodyStyle={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <img
                            src={node.logo || IMG}
                            alt=""
                            style={{
                              width: 80,
                              height: 80,
                              borderRadius: 50,
                              backgroundColor: '#f3f3f3',
                              position: 'absolute',
                              top: 10,
                              border: '1px solid #f0f0f0'
                            }}
                          />
                          <br />
                          <h4
                            style={{
                              marginBottom: 0,
                              fontWeight: 'normal',
                              color: '#898A82'
                            }}
                          >
                            {node.email}
                          </h4>
                          <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>{node.name}</h3>
                          {/* <Container> */}
                          <h5 /* style={{ color: '#CACACA' }} */>
                            {node && <ReactMarkdown>{node.description}</ReactMarkdown>}
                          </h5>
                        </div>
                        <div style={{ position: 'absolute', bottom: 12 }}>
                          <Link>POC: {`${node.poc?.user?.firstName} ${node.poc?.user?.lastName}`}</Link>
                        </div>
                        {/* </Container> */}
                      </StyledCard>
                    </Link>
                  </Col>
                </>
              ))}
            {allOrganization && allOrganization.edgeCount === 0 && (
              <div className="HVCenter" style={{ width: '70vw' }}>
                <Empty description="No result found" />
              </div>
            )}
          </Row>
        </div>
        <br />
        <Pagination data={allOrganization} pagination={pagination} onPaginationChange={onPaginationChange} />
      </Spin>
    </CardLayout>
  );
};

export default compose(
  withOrganizationState,
  withAllOrganization,
  withDeleteOrganization,
  withOrganizationFilterUpdating
)(Organization);
