import React, { useEffect, useState } from 'react';
import { Progress, Spin } from 'antd';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import '../css/CoachingCommitmentProgressBar.css';

import { compose } from '../../core';

const GET_ALL_DEFAULT_COMMITMENTS_PROGRESS = gql`
  query getAllDefaultCommitmentsProgressForReportingEmployee($employeeId: ID!, $completed: Boolean) {
    getAllDefaultCommitmentsProgressForReportingEmployee(employeeId: $employeeId, completed: $completed) {
      # totalCount
      edges {
        node {
          id
        }
      }
    }
  }
`;

const GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE = gql`
  query getAllCustomUserCommitmentsForReportingEmployee($employeeId: ID!, $completed: Boolean) {
    getAllCustomUserCommitmentsForReportingEmployee(employeeId: $employeeId, completed: $completed) {
      totalCount
    }
  }
`;

const ManagerCommitmentProgressBar = props => {
  const {
    loading,
    defaultCommitmentsProgressCompleted,
    defaultCommitmentsProgress,
    customUserCommitmentsCompleted,
    customUserCommitments,
    updated,
    visible,
    refetchCommitmentsProgressCompleted,
    refetchCommitmentsProgressForReportingEmployee,
    refetchCustomUserCommitmentsForReportingEmployee,
    refetchAllCustomUserCommitmentsForReportingEmployee
  } = props;
  const totalCompleted = customUserCommitmentsCompleted?.totalCount + defaultCommitmentsProgressCompleted?.edges?.length;
  const totalCount = customUserCommitments?.totalCount + defaultCommitmentsProgress?.edges?.length;
  const progress = React.useRef(() => { });
  const [animate, setAnimate] = useState(false);

  const triggerAnimation = () => {
    setAnimate(true); // Set animate state to true to trigger the animation
    setTimeout(() => {
      setAnimate(false); // Set animate state back to false after the animation duration (1.5s in your CSS)
    }, 1500);
  };

  useEffect(() => {
    // progress.current();
    progress.current = () => {
      refetchCommitmentsProgressCompleted();
      refetchCommitmentsProgressForReportingEmployee();
      refetchCustomUserCommitmentsForReportingEmployee();
      refetchAllCustomUserCommitmentsForReportingEmployee();
    };
  }, [updated]);

  useEffect(() => {
    if (visible) {
      // scrollToElement()
      triggerAnimation()
    }
  }, [visible])

  console.log('customUserCommi4ftmentsCompleted ::', totalCompleted, totalCount)

  return (
    <Spin spinning={loading} size="small">
      {/* <Progress
        className='progress_circle_ui'
        type="circle"
        percent={Math.floor((totalCompleted * 100) / totalCount || 0)}
        strokeColor="#B0CB1F"
        strokeWidth="14"
        width={185}
      /> */}
      <div style={{ width: '100%', height: '21em', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', borderBottom: '1px solid gray' }}>
        <div ref={progress} style={{ visibility: `${visible ? 'visible' : 'hidden'}` }} className='barcontainer'>
          <div className={animate ? 'bar animate' : 'bar'} style={{ height: `${animate ? '0%' : `${((totalCompleted * 100) / totalCount || 0).toFixed(2)}%`}`, '--final-height': `${((totalCompleted * 100) / totalCount || 0).toFixed(2)}%`, paddingTop: '0.4em', fontWeight: '600', fontFamily: 'poppins, sans-serif', color: 'white','--inner-color': 'rgba(196, 151, 43, 1)' }}>{`${((totalCompleted * 100) / totalCount || 0).toFixed(2)}%`}</div>
        </div>
      </div>


      <p>2</p>
    </Spin>
  );
};

export default compose(
  graphql(GET_ALL_DEFAULT_COMMITMENTS_PROGRESS, {
    options: ({ employeeId }) => {
      return { variables: { completed: true, employeeId } };
    },
    props({ data }) {
      const { loading, error, getAllDefaultCommitmentsProgressForReportingEmployee, subscribeToMore, updateQuery, refetch } =
        data;
      return {
        loading,
        error,
        defaultCommitmentsProgressCompleted: getAllDefaultCommitmentsProgressForReportingEmployee,
        subscribeToMore,
        updateQuery,
        refetchCommitmentsProgressCompleted: refetch
      };
    }
  }),
  graphql(GET_ALL_DEFAULT_COMMITMENTS_PROGRESS, {
    options: ({ employeeId }) => {
      return { variables: { employeeId } };
    },
    props({ data }) {
      const { loading, error, getAllDefaultCommitmentsProgressForReportingEmployee, subscribeToMore, updateQuery, refetch } =
        data;
      return {
        loading,
        error,
        defaultCommitmentsProgress: getAllDefaultCommitmentsProgressForReportingEmployee,
        subscribeToMore,
        updateQuery,
        refetchCommitmentsProgressForReportingEmployee: refetch
      };
    }
  }),

  graphql(GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE, {
    options: ({ employeeId }) => {
      return { variables: { completed: true, employeeId } };
    },
    props({ data }) {
      const { loading, error, getAllCustomUserCommitmentsForReportingEmployee, subscribeToMore, updateQuery, refetch } =
        data;
      return {
        loading,
        error,
        customUserCommitmentsCompleted: getAllCustomUserCommitmentsForReportingEmployee,
        subscribeToMore,
        updateQuery,
        refetchCustomUserCommitmentsForReportingEmployee: refetch
      };
    }
  }),
  graphql(GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE, {
    options: ({ employeeId }) => {
      return { variables: { employeeId } };
    },
    props({ data }) {
      const { loading, error, getAllCustomUserCommitmentsForReportingEmployee, subscribeToMore, updateQuery, refetch } =
        data;
      return {
        loading,
        error,
        customUserCommitments: getAllCustomUserCommitmentsForReportingEmployee,
        subscribeToMore,
        updateQuery,
        refetchAllCustomUserCommitmentsForReportingEmployee: refetch
      };
    }
  })
)(ManagerCommitmentProgressBar);
