import React from 'react';

import { compose } from '../../core';
import { withDeleteTextSection } from '../../text-section/containers/TextSectionOperations';
import { withDeleteFormSection } from '../../form-section/containers/FormSectionOperation';

import ToolDetailView from '../components/ToolDetailView';
import AddToolToRecent from '../components/AddToolToRecent';

import { withToolByIdAdmin, withEditTool, withDeleteCommitment } from './ToolOperations';
import { subscribeToToolDetail } from './ToolSubscription';

const ToolDetail = props => {
  const { deleteCommitment, subscribeToMore, deleteTextSection, deleteFormSection } = props;
  const [actionLoading, setActionLoading] = React.useState(false);

  React.useEffect(() => {
    const subscribe = subscribeToToolDetail(subscribeToMore /* , props.filter */);
    return () => {
      subscribe();
    };
  });

  const handleCommitmentDelete = async id => {
    setActionLoading(true);
    try {
      await deleteCommitment(id);
      setActionLoading(false);
    } catch (e) {
      setActionLoading(false);
      throw new Error(e.message);
    }
  };

  const handleTextSectionDelete = async id => {
    setActionLoading(true);
    try {
      await deleteTextSection(id);
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  };

  const handleFormSectionDelete = async id => {
    setActionLoading(true);
    try {
      await deleteFormSection(id);
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  };

  return (
    <>
      <AddToolToRecent />
      <ToolDetailView
        onCommitmentDelete={handleCommitmentDelete}
        actionLoading={actionLoading}
        setActionLoading={setActionLoading}
        onTextSectionDelete={handleTextSectionDelete}
        onFormSectionDelete={handleFormSectionDelete}
        {...props}
      />
    </>
  );
};

export default compose(
  withToolByIdAdmin,
  withEditTool,
  withDeleteCommitment,
  withDeleteTextSection,
  withDeleteFormSection
)(ToolDetail);
