import React from 'react';
import styled from 'styled-components';
import { Card, Checkbox, Row, Col, Form, Input, Button } from 'antd';
import { UserAddOutlined, LoginOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import SignInImg from '../../assets/SignInImg.svg';
import LogoImg from '../../assets/LogoImg.png';
import PAGE_ROUTE from '../../page/route';
import { MetaTags } from '../../look';

import ForgotPassword from '../containers/ForgotPassword';
import ROUTE from '../route';

const Login = props => {
  const { incorrectPass, onSubmit, msg, location, nonFieldErrors } = props;
  const form = React.useRef();
  React.useEffect(() => {
    if (incorrectPass || (nonFieldErrors && nonFieldErrors.length > 0)) form.current.resetFields(['password']);
  });
  return (
    <>
      <MetaTags title="Login" description="This is Login page" />
      <Row justify="center" align="middle">
        <Col xs={22} lg={15} md={22}>
          <br />
          <Card bodyStyle={{ padding: '0' }} style={{ boxShadow: '0px 4px 20px 10px rgba(84, 84, 84, 0.1)' }}>
            <Row /* justify="center" */ align="middle">
              <Col lg={13} md={13} sm={0} xs={0} style={{ overflow: 'hidden' }}>
                <SignUpContainer>
                  <SignUpTitle>{`Don’t have account?`}</SignUpTitle>
                  {/* <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                  <br /> */}
                  <br />
                  <a href={ROUTE.register}>
                    <Button type="primary" size="large">
                      <UserAddOutlined />
                      Sign up
                    </Button>
                  </a>
                </SignUpContainer>
                <img src={SignInImg} height="100%" alt="" /* width="100%" */ style={{ height: '80vh' }} />
              </Col>

              <FormCol lg={11} md={11} sm={24} xs={24}>
                <Row justify="center" align="middle">
                  <Col span={22}>
                    <Row>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        {/* <Logo>Logo</Logo> */}
                        <img src={LogoImg} alt="" height={'50px'} style={{ marginBottom: '10px' }} />
                        {msg ? (
                          <div style={{ margin: '10px 0' }}>
                            <h4>
                              If you have taken our resilience survey using a token,
                              <br /> please use the email ID associated with that survey as your user ID <br /> to login and
                              use "Forgot Password?"
                            </h4>
                          </div>
                        ) : (
                          <>
                            <br />
                            <br />
                          </>
                        )}
                      </Col>
                    </Row>
                    <Welcome>Welcome Back</Welcome>

                    <Form {...layout} requiredMark={false} name="login-form" onFinish={onSubmit} ref={form}>
                      <Form.Item
                        name="email"
                        label="Enter your username"
                        validateStatus={incorrectPass ? 'error' : nonFieldErrors ? 'error' : ''}
                        help={
                          incorrectPass
                            ? 'Email or password is incorrect!!'
                            : nonFieldErrors
                            ? nonFieldErrors.length > 0 && nonFieldErrors[0].message
                            : null
                        }
                        rules={[{ required: true, message: 'Email/username is required' }]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                          {
                            required: true,
                            message: 'Enter password to login'
                            // string: {
                            //   min: 'Password must be as least 8 characters'
                            // }
                          }
                          // {
                          //   min: 8,
                          //   message: 'Password must be as least 8 characters'
                          // }
                        ]}
                        hasFeedback
                        // extra={
                        //   <a className="login-form-forgot" href="">
                        //     Forgot password
                        //   </a>
                        // }
                      >
                        <Input.Password />
                      </Form.Item>
                      <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                          <Checkbox>Keep me logged in</Checkbox>
                        </Form.Item>
                        <ForgotPassword location={location} />
                      </Form.Item>
                      <Button block type="primary" htmlType="submit">
                        <LoginOutlined />
                        Login
                      </Button>
                    </Form>
                    {/* <br />
                    <Button block type="default">
                      <GoogleOutlined />
                      Login with Google
                    </Button> */}
                    <Row>
                      <Col lg={0} md={0} sm={24} xs={24}>
                        <br />
                        <span style={{ color: 'grey' }}>Don’t have account?</span>
                        &nbsp;<a href={ROUTE.register}>Sign up</a>
                      </Col>
                    </Row>
                    <br />
                    <PrivacyLink to={PAGE_ROUTE.termsAndCondition}>Privacy policy and Terms of Services</PrivacyLink>
                  </Col>
                </Row>
              </FormCol>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Login;

// const SingUpBtn = styled(Button)`
//   background-color: #fff;
//   color: #4e0080;
// `;

const FormCol = styled(Col)`
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;

const PrivacyLink = styled(Link)`
  text-decoration: underline;
  color: #898a82;
`;

const Logo = styled.h3`
  font-weight: bold;
`;

const Welcome = styled.h2`
  font-weight: bold;
`;

const SignUpTitle = styled.h1`
  color: white;
  margin-bottom: 0;
  /* font-size: 35px; */
  font-weight: bold;
`;

const SignUpContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 65%;
  color: white;
  margin: 50px;
  bottom: 80px;
`;

const layout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 24
  }
};
