import gql from 'graphql-tag';
import { CUSTOM_USER_COMMITMENT_TYPE } from './CustomUserCommitmentType.gql';

export const GET_ALL_CUSTOM_USER_COMMITMENTS = gql`
  ${CUSTOM_USER_COMMITMENT_TYPE}
  query getAllCustomUserCommitments(
    $orderBy: [String]
    $offset: Int
    $first: Int
    $title_Icontains: String
    $description_Icontains: String
  ) {
    getAllCustomUserCommitments(
      orderBy: $orderBy
      offset: $offset
      first: $first
      title_Icontains: $title_Icontains
      description_Icontains: $description_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...CustomUserCommitmentType
        }
        cursor
      }
      edgeCount
      totalCount
    }
  }
`;
