import React from 'react';

import { compose } from '../../core';

import {
  withAllOrgSurveysTitlesByImplicitOrgIdQuery,
  withAllPublicSurveysQuery,
  withSurveyFilterUpdating,
  withSurveyState
} from './SurveyOperations';
import { subscribeToAllOrgSurveysByImplicitOrgId, subscribeToAllPublicSurveysSubscribeToMore } from './SurveySubscriptions';

import AllOrganizationSurveysView from '../components/AllOrganizationSurveysView';

const AllOrganizationSurveys = props => {
  const { allOrgSurveysByImplicitOrgIdSubscribeToMore, allPublicSurveysSubscribeToMore } = props;

  React.useEffect(() => {
    const subscribeAllOrgSurveysByImplicitOrgId = subscribeToAllOrgSurveysByImplicitOrgId(
      allOrgSurveysByImplicitOrgIdSubscribeToMore
    );
    const subscribeAllPublicSurveysSubscribeToMore =
      subscribeToAllPublicSurveysSubscribeToMore(allPublicSurveysSubscribeToMore);
    return () => {
      subscribeAllOrgSurveysByImplicitOrgId();
      subscribeAllPublicSurveysSubscribeToMore();
    };
  });

  return <AllOrganizationSurveysView {...props} />;
};

export default compose(
  withSurveyState,
  withAllOrgSurveysTitlesByImplicitOrgIdQuery,
  withAllPublicSurveysQuery,
  withSurveyFilterUpdating
)(AllOrganizationSurveys);
