import React from 'react';
import { Form, Transfer, Row, Col, Button, Modal } from 'antd';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { IMG } from '../../../../config';
import Loading from '../Loading';
import { getIntFromString } from '../functions';

export const ORGANIZATION_BY_ID_QUERY = gql`
  query organizationById($id: ID!) {
    organizationById(id: $id) {
      id
      name
      description
      email
      employees {
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
              username
            }
          }
        }
        edgeCount
      }
    }
  }
`;

const VerticalEmployeeTransfer = props => {
  const { organizationById, initialValues, handleSubmit } = props;
  const { ...rest } = props;
  const FormItem = Form.Item;
  const [mockData, setmockData] = React.useState([]);
  const [targetKeys, setTargetKeys] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {
    const targetKeys = [];
    // const mockData = [];
    // for (let i = 0; i < 20; i++) {
    //   const data = {
    //     key: i.toString(),
    //     title: `content${i + 1}`,
    //     description: `description of content${i + 1}`,
    //     chosen: Math.random() * 2 > 1,
    //   };
    // if (data.chosen) {
    //   targetKeys.push(data.key);
    // }
    // mockData.push(data);
    // }
    const mockData =
      organizationById &&
      organizationById.employees &&
      organizationById.employees.edgeCount > 0 &&
      organizationById.employees.edges.map(({ node }) => ({
        title:
          node.user.firstName && node.user.lastName ? `${node.user.firstName} ${node.user.lastName}` : node.user.username,
        key: getIntFromString(node.id)
      }));

    initialValues &&
      initialValues.edgeCount > 0 &&
      initialValues.edges.map(({ node }) => {
        targetKeys.push(getIntFromString(node.id));
      });
    setmockData(mockData);
    setTargetKeys(targetKeys);
  }, [organizationById, initialValues]);

  const filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  const handleChange = targetkeys => {
    setTargetKeys(targetkeys);
  };
  // console.log(mockData, targetKeys);
  return (
    <>
      <Button type="primary" ghost onClick={() => setVisible(true)}>
        Add Employee
      </Button>
      <Modal
        destroyOnClose
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        title="Employee"
        width="45%"
        bodyStyle={{ padding: 12 }}
      >
        <div>
          <Row>
            <Col span={24}>
              {organizationById ? (
                <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} {...rest}>
                  <Transfer
                    listStyle={{ height: '50vh', width: '18vw' }}
                    dataSource={mockData}
                    showSearch
                    filterOption={filterOption}
                    targetKeys={targetKeys}
                    onChange={handleChange}
                    // onSearch={handleSearch}
                    render={item => (
                      <>
                        <Row
                          style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          <img src={IMG} alt="" style={{ width: 30, borderRadius: 30, marginRight: 10 }} />
                          {item.title}
                        </Row>
                      </>
                    )}
                  />
                </FormItem>
              ) : (
                <Loading size="small" />
              )}
            </Col>
          </Row>
          <Row justify="end">
            <Button
              type="primary"
              onClick={async () => {
                await handleSubmit(targetKeys);
                setVisible(false);
              }}
            >
              Save
            </Button>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default graphql(ORGANIZATION_BY_ID_QUERY, {
  options: props => {
    return {
      variables: {
        id: props.orgId
      },
      fetchPolicy: 'network-only'
    };
  },
  props({ data: { loading, error, organizationById, subscribeToMore, updateQuery } }) {
    return { loading, error, organizationById, subscribeToMore, updateQuery };
  }
})(VerticalEmployeeTransfer);
