import React, { useState } from 'react';
import { Form } from 'antd';
import '../css/survey-look.css'
import {
    // AlphaPicker,
    // BlockPicker,
    // ChromePicker,
    // CirclePicker,
    // CompactPicker,
    // GithubPicker,
    // HuePicker,
    // MaterialPicker,
    // PhotoshopPicker,
    SketchPicker
    // SliderPicker,
    // SwatchesPicker,
    // TwitterPicker
} from 'react-color';

const FormItem = Form.Item;

const SurveyColorField = props => {
    const { type, offset = 1, value, disabled, ...rest } = props;
    const [ background, setBackground ] = useState( props.value );
    const [colorButtonBg,setColorButtonBg] = useState( props.value);
    const [showPicker,setShowPicker] = useState(false);
    React.useEffect( () => {
        if ( background !== value ) setBackground( value );
    }, [ value ] );
const backgroundChange =(value)=>{
    if(value){
        setBackground(value)
        setColorButtonBg(value?.hex)
        if (typeof (props.onChange) === 'function') {
        props.onChange( value.hex )
        }
    }
}
    return (
        <div style={{ marginBottom: '10px',height:showPicker?'350px':"fit-content"}}>
            <FormItem {...rest}>
                <div className='generic-survey-input-filed color-picker-container-max-width' style={{ display: 'flex', flexDirection: "row", gap: "10px", position: "relative", height: "2rem", padding: "0px 10px"}}>
                    <p className='picked-color-text'>Pick a color</p>
                    <div style={{ position: "absolute", right: "0px", top: "4px", display: 'flex', justifyContent: 'end', alignItems: 'end', flexDirection: 'column' }}>
                        <button id='colorButton' className='sketch-trigger-button' style={{background:colorButtonBg}} onClick={()=>setShowPicker(!showPicker)}></button>
                        {showPicker&&<SketchPicker
                            className='sketch-piker'
                            color={background}
                            onChangeComplete={color => props.onChange && props.onChange( color.hex )}
                            onChange={e=>{backgroundChange(e)}}
                            disabled={disabled}
                        />}
                    </div>

                </div>
            </FormItem>
        </div>
    );
};

export default SurveyColorField;
