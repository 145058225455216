import gql from 'graphql-tag';
import { STORY_TYPE } from './StoryType.gql';

export const ADD_STORY = gql`
  ${STORY_TYPE}
  mutation addStory($storyData: StoryInput!, $storyTags: [Int]) {
    createStory(storyData: $storyData, storyTags: $storyTags) {
      story {
        ...StoryType
      }
    }
  }
`;
