import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../look';

import EditSurveyView from '../components/EditSurveyView';
import ROUTE from '../route';

import { withEditSurvey, withSurveyById } from './SurveyOperations';
import { CKEditorFieldName, CKEditorFieldName1 } from '../components/SurveyFormComponent';

const EditSurvey = props => {
  const { history, editSurvey, SurveyById } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);

    try {
      const response = await editSurvey({
        id: getIntFromString(SurveyById.id),
        ...values,
        ...withMarkdownFieldValue(CKEditorFieldName[1], withMarkdownFieldValue(CKEditorFieldName1[1], values.surveyData))
      });

      response &&
        ButtonsCatch(
          item,
          `${ROUTE.surveyDetailLink}${getIntFromString(SurveyById.id)}`,
          history,
          item === undefined ? window.location.pathname : `${ROUTE.surveyDetailLink}${getIntFromString(SurveyById.id)}`
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditSurveyView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withSurveyById, withEditSurvey)(EditSurvey);
