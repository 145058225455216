import React from 'react';
import { Form, Radio } from 'antd';

const SurveyMLSelectField = props => {
  const { choices, disabled,onChange, ...rest } = props;
  const FormItem = Form.Item;
  const RadioGroup = Radio.Group;
console.log('choices8983e3',choices)
  const choice = choices?.map((choice, key) => (
    <Radio
      key={key}
      className='survey-radio-choice'
      value={choice.value || choice.label}
      style={{ width: '60%', justifyContent: 'center',alignItems:'center' }}
    >
      {/* {choice.label} */}
    </Radio>
  ));

  const onChangeValue =(e)=>{
    console.log('onChange', e);
  }
  return (
    <>
      <FormItem
        className='generic-survey-radio-choice-container'
        labelCol={{ span: 6,}}
        {...rest}
      >
        <RadioGroup disabled={disabled} onChange={onChange} style={{ display: 'flex' }}>
          {choice}
        </RadioGroup>
      </FormItem>
    </>
  );
};

export default SurveyMLSelectField;
