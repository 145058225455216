import React from 'react';
import { Form, Input } from 'antd';
import { Tooltip } from 'antd';

const TextField = props => {
  const { type = 'input', onChange, disabled, offset = 1, onBlur, ...rest } = props;

  const FormItem = Form.Item;
  const { TextArea } = Input;

  return (
    <div style={{ marginBottom: '5vh', width: '100%' }}>
      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset }} {...rest}>
        {type === 'textarea' ? (
          <TextArea wrap='soft' rows={6} onChange={onChange} disabled={disabled} />
        ) : type === 'number' ? (
          <NumericInput onChange={onChange} disabled={disabled} type={'number'} />
        ) : (
          <Input onChange={onChange} onBlur={onBlur} disabled={disabled} />
        )}
      </FormItem>
    </div>
  );
};

export default TextField;

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(Number(value));
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (!(typeof value === 'number' && isFinite(value))) {
      if (value.charAt(value.length - 1) === '.' || value === '-') {
        valueTemp = value.slice(0, -1);
      }
      onChange(valueTemp.replace(/0*(\d+)/, '$1'));
      if (onBlur) {
        onBlur();
      }
    }
  };

  render() {
    const { value } = this.props;
    const title = value ? (
      <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    ) : (
      'Input a number'
    );
    return (
      <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
        <Input {...this.props} onChange={this.onChange} onBlur={this.onBlur} placeholder="Input a number" maxLength={25} />
      </Tooltip>
    );
  }
}

function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}
