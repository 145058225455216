import { subscribeToQuery } from '../../look';
import { TEXT_SECTION_SUBSCRIPTION } from '../graphql/TextSectionSubscription.gql';

export const subscribeToTextSection = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: TEXT_SECTION_SUBSCRIPTION,
      subscriptionName: 'toolsTextSectionSubscription',
      nodeName: 'textSection',
      queryName: ['getToolByIdAdmin', 'textsection']
    },
    variables
  );
};
