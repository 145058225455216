import React from 'react';

import { compose } from '../../core';
import { AdminLayout, displayDataCheck, GetColumnSearchProps, getOrderBy } from '../../look';

import ROUTE from '../route';

import { withAllStoryTags, withDeleteTag, withStoryFilterUpdating, withStoryState } from './StoryOperations';
import { subscribeToStoryTag } from './StorySubscriptions';

const Tag = props => {
  const {
    loading,
    allStoryTags,
    onPaginationChange,
    deleteTag,
    onOrderByChange,
    orderBy,
    onNameChange,
    onDescriptionChange,
    onFiltersRemove,
    subscribeToMore
  } = props;

  React.useEffect(() => {
    const subscribe = subscribeToStoryTag(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onNameChange),
      render: (text, record) => record && displayDataCheck(record.name)
    },
    {
      title: 'Description',
      key: 'description',
      sorter: () => onOrderByChange(getOrderBy('description', orderBy)),
      ...GetColumnSearchProps('description', onDescriptionChange),
      render: (text, record) => record && displayDataCheck(record.description)
    }
  ];

  return (
    <AdminLayout
      table={true}
      active={ROUTE.tag}
      title="Tags"
      loading={loading}
      tableData={allStoryTags}
      columns={columns}
      onDelete={deleteTag}
      editLink={ROUTE.editTagLink}
      addLink={ROUTE.addTag}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
    />
  );
};

export default compose(withStoryState, withAllStoryTags, withDeleteTag, withStoryFilterUpdating)(Tag);
