import { graphql } from '@apollo/client/react/hoc';
import { withOperation } from '../../look';
import toolResolvers from '../resolvers';

import { GET_ALL_TOOLS_ADMIN_QUERY } from '../graphql/AllToolsAdminQuery.gql';
import { GET_ALL_COMMITMENT_QUERY } from '../graphql/AllCommitments.gql';
import { GET_COMMITMENT_RECOMMENDATION_QUERY } from '../graphql/GetCommitmentRecommendations.gql';
import { COMMITMENT_BY_ID_QUERY } from '../graphql/CommitmentById.gql';
import { TOOL_BY_ID_ADMIN_QUERY } from '../graphql/ToolByIdAdmin.gql';
import { TOOL_BY_ID_QUERY } from '../graphql/ToolById.gql';

// Client
import { TOOL_STATE_QUERY } from '../graphql/ToolStateQuery.client.gql';
import { UPDATE_TOOL_FILTER } from '../graphql/UpdateToolFilter.client.gql';

// Mutation
import { ADD_TOOL } from '../graphql/AddTool.gql';
import { ADD_COMMITMENT } from '../graphql/AddCommitment.gql';

import { EDIT_TOOL } from '../graphql/EditTool.gql';
import { EDIT_COMMITMENT } from '../graphql/EditCommitment.gql';

import { DELETE_TOOL } from '../graphql/DeleteTool.gql';
import { DELETE_COMMITMENT } from '../graphql/DeleteCommitment.gql';
import { removeTypename } from '../../core/clientStorage';

export const withAllToolsAdminQuery = Component =>
  graphql(GET_ALL_TOOLS_ADMIN_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      // console.log(filter);
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllToolsAdmin, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllToolsAdmin, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllCommitments = Component =>
  graphql(GET_ALL_COMMITMENT_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllCommitments, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllCommitments, subscribeToMore, updateQuery };
    }
  })(Component);

export const withGetCommitmentRecommendations = Component =>
  graphql(GET_COMMITMENT_RECOMMENDATION_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getCommitmentRecommendations, subscribeToMore, updateQuery } = data;
      return { loading, error, getCommitmentRecommendations, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAddCommitment = Component =>
  withOperation({
    mutation: ADD_COMMITMENT,
    funcName: 'addCommitment',
    query: GET_ALL_COMMITMENT_QUERY,
    queryName: 'getAllCommitments',
    node: ['createCommitment', 'commitment'],
    type: 'add'
  })(Component);

export const withAddTool = Component =>
  withOperation({
    mutation: ADD_TOOL,
    funcName: 'addTool',
    query: GET_ALL_TOOLS_ADMIN_QUERY,
    queryName: 'getAllToolsAdmin',
    node: ['createTool', 'tool'],
    type: 'add'
  })(Component);

export const withEditTool = Component =>
  graphql(EDIT_TOOL, {
    props: ({ mutate }) => ({
      editTool: async values => {
        try {
          const {
            data: { updateTool }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateTool.tool;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditCommitment = Component =>
  graphql(EDIT_COMMITMENT, {
    props: ({ mutate }) => ({
      editCommitment: async values => {
        try {
          const {
            data: { updateCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateCommitment.commitment;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withCommitmentById = Component =>
  graphql(COMMITMENT_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { commitmentId: Number(id) }
      };
    },
    props({ data: { loading, error, getCommitmentById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, getCommitmentById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withToolByIdAdmin = Component =>
  graphql(TOOL_BY_ID_ADMIN_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { toolId: Number(id) },
        notifyOnNetworkStatusChange: true
      };
    },
    props({ data: { loading, error, getToolByIdAdmin, subscribeToMore, updateQuery, networkStatus } }) {
      // console.log(networkStatus)
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, getToolByIdAdmin, subscribeToMore, updateQuery };
    }
  })(Component);

export const withToolById = Component =>
  graphql(TOOL_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { toolId: Number(id || props.id), user: props?.me?.id }
      };
    },
    props({ data: { loading, error, getToolById, subscribeToMore, updateQuery, refetch } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, getToolById, subscribeToMore, updateQuery, refetch };
    }
  })(Component);

export const withToolDeleting = Component =>
  withOperation({
    mutation: DELETE_TOOL,
    mutationVarName: 'id',
    funcName: 'deleteTool',
    query: GET_ALL_TOOLS_ADMIN_QUERY,
    queryName: 'getAllToolsAdmin',
    node: ['deleteTool', 'tool'],
    type: 'delete'
  })(Component);

export const withDeleteCommitment = Component =>
  withOperation({
    mutation: DELETE_COMMITMENT,
    mutationVarName: 'id',
    funcName: 'deleteCommitment',
    query: TOOL_BY_ID_ADMIN_QUERY,
    queryName: ['getToolByIdAdmin', 'commitment'],
    node: ['deleteCommitment', 'commitment'],
    type: 'delete',
    variable: { type: 'all', custom: true, /* omit: ['sequence_Icontains'], */ varName: 'toolId' }
  })(Component);

// Client
export const withToolState = Component =>
  graphql(TOOL_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.toolState;
      const toolState = { ...removeTypename(rest), orderBy };
      return { ...toolState, stateLoading: data.loading };
    }
  })(Component);

export const withToolFilterUpdating = Component =>
  graphql(UPDATE_TOOL_FILTER, {
    props: ({ mutate }) => ({
      onOrderByChange(orderBy) {
        mutate({ variables: { orderBy } });
      },
      onPaginationChange(pagination) {
        mutate({ variables: { pagination } });
      },
      onFiltersRemove() {
        mutate({ variables: { ...toolResolvers.defaults.toolState } });
      },
      onTitleChange(title_Icontains) {
        mutate({ variables: { filter: { title_Icontains } } });
      },
      onDescriptionChange(description_Icontains) {
        mutate({ variables: { filter: { description_Icontains } } });
      }
    })
  })(Component);
