import React from 'react';

import { compose } from '../../core';

import GenericToolView from '../components/GenericToolView';

import { withToolById } from './ToolOperations';

const GenericTool = props => {
  return <GenericToolView {...props} />;
};

export default compose(withToolById)(GenericTool);
