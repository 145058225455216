import gql from 'graphql-tag';
import { SURVEY_TYPE } from './SurveyType.gql';

export const SURVEY_SUBSCRIPTION = gql`
  ${SURVEY_TYPE}
  subscription surveySubscription {
    surveySubscription {
      mutation
      survey {
        ...SurveyType
      }
    }
  }
`;
