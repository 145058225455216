import React from 'react';
import { Switch, Spin, message } from 'antd';

import { compose } from '../../core';
import { AdminLayout, displayDataCheck, getIntFromString, GetColumnSearchProps, getOrderBy } from '../../look';

import { contact, addGroup } from '../route';

import { withAllContactRequests, withPageState, withToggleContactStatus, withPageFilterUpdating } from './PageOperations';
import { subscribeToContact } from './PageSubscriptions';

const Contact = props => {
  const {
    loading,
    allContactRequests,
    onPaginationChange,
    toggleContactStatus,
    onOrderByChange,
    orderBy,
    onNameChange,
    onEmailChange,
    onMessageChange,
    onFiltersRemove,
    subscribeToMore
  } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  React.useEffect(() => {
    const subscribe = subscribeToContact(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const handleToggle = async value => {
    setSubmitLoading(true);
    try {
      const response = await toggleContactStatus(value); // shoud be { id, status }

      response && message.success('Status Updated!');
      setSubmitLoading(false);
    } catch (e) {
      setSubmitLoading(false);
      message.error('Failed ! try again.');

      throw new Error(e.message);
    }
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onNameChange),
      render: (text, record) => <>{record && displayDataCheck(record.name)}</>
    },
    {
      title: 'Email',
      key: 'email',
      sorter: () => onOrderByChange(getOrderBy('email', orderBy)),
      ...GetColumnSearchProps('email', onEmailChange),
      render: (text, record) => <>{record && displayDataCheck(record.email)}</>
    },
    {
      title: 'Message',
      key: 'message',
      sorter: () => onOrderByChange(getOrderBy('message', orderBy)),
      ...GetColumnSearchProps('message', onMessageChange),
      render: (text, record) => <>{record && displayDataCheck(record.message)}</>
    },
    {
      title: 'Done',
      key: 'done',
      sorter: () => onOrderByChange(getOrderBy('done', orderBy)),
      render: (text, record) => (
        <>
          <Switch
            checked={record.done}
            onClick={value => handleToggle({ id: getIntFromString(record.id), status: value })}
          />
        </>
      )
    },
    {
      title: 'Request Date',
      key: 'requestDate',
      sorter: () => onOrderByChange(getOrderBy('requestDate', orderBy)),
      render: (text, record) => (
        <>{`${new Date(record.requestDate).toDateString()} ${new Date(record.requestDate).toTimeString()}`}</>
      )
    }
  ];

  return (
    <AdminLayout
      active={contact}
      table={true}
      title="Contact"
      loading={loading || submitLoading}
      tableData={allContactRequests}
      columns={columns}
      // onDelete={() => {}}
      addLink={addGroup}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
    />
  );
};

export default compose(withPageState, withToggleContactStatus, withAllContactRequests, withPageFilterUpdating)(Contact);
