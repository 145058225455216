import gql from 'graphql-tag';
import { RESPONSE_COMPARE_REQUEST_TYPE } from './ResponseCompareRequestType.gql';

export const ALL_COMPARISON_REQUEST_FOR_USER = gql`
  ${RESPONSE_COMPARE_REQUEST_TYPE}
  query allComparisonRequestForUser($orderBy: [String], $offset: Int, $first: Int) {
    allComparisonRequestForUser(orderBy: $orderBy, offset: $offset, first: $first) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...ResponseCompareRequestType
        }
        cursor
      }
      edgeCount
      totalCount
    }
  }
`;
