import React, { useState } from 'react';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { compose } from '../../core';
import { withMe, withUserFilterUpdating } from '../../user/containers/UserOperations';
import { withOrganizationState, withOrganizationFilterUpdating } from '../../organization/containers/OrganizationOperation';

import CompareYourScoreView from '../components/CompareYourScoreView';
import { getIntFromString, withMarkdownFieldValue } from '../../look';
import { CKEditorFieldName } from '../components/CompareRequest';
import { withAddResponseCompareRequest } from './DashboardOperations';
import { UPDATE_USER_FILTER } from '../../user/graphql/UpdateUserFilter.client.gql';
import { Form } from 'antd';
import SURVEY_ROUTE from '../../survey/route';

const compareRequestFailMsg = surveyId => (
  <>
    Could not send the request because you have not taken the survey for which you are requesting the comparison.
    <a href={`${SURVEY_ROUTE.surveyQuizLink}${surveyId}`}> Go ahead </a>
    and take the survey first.
  </>
);

const CompareYourScore = props => {
  const { me, addResponseCompareRequest } = props;
  const [form] = Form.useForm();

  async function handleSubmit(values) {
    delete values.name;
    const compareRequestData = { ...withMarkdownFieldValue(CKEditorFieldName, values), senderId: getIntFromString(me.id) };

    try {
      const response = await addResponseCompareRequest({
        compareRequestData: {
          ...compareRequestData,
          message: compareRequestData?.message?.replace('<p>', `<p>${me?.firstName || ''} ${me?.lastName || ''}: `)
        }
      });

      return response;
    } catch (err) {
      form.setFields([
        {
          name: 'surveyId',
          errors: [compareRequestFailMsg(values.surveyId)]
        }
      ]);
    }
  }

  return <CompareYourScoreView form={form} onSubmit={handleSubmit} {...props} />;
};

export default compose(
  withOrganizationState,
  withMe,
  withOrganizationFilterUpdating,
  withApollo,
  graphql(UPDATE_USER_FILTER, {
    props: ({ mutate }) => ({
      onUserFirstNameChange(firstName_Icontains) {
        mutate({ variables: { filter: { firstName_Icontains } } });
      }
    })
  }),
  withAddResponseCompareRequest
)(CompareYourScore);
