import React from 'react';
import { Spin } from 'antd';

import { compose, removeItem } from '../../core';
import ROUTE from '../route';
import USER_ROUTE from '../../user/route';
import { getIntFromString } from '../../look';

import QuizView from '../components/QuizView';
import { withCreateResponse, withPublicSurveyById, withOrgSurveyById } from './SurveyOperations';

const SurveyQuiz = props => {
  const { error, history, createResponse, orgSurveyById, publicSurveyById } = props;
  const startTime = new Date().getTime();
  const [prevTotalDuration, setPrevTotalDuration] = React.useState(0);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  if (error) {
    if (error.message === 'GraphQL error: Permission Denied.') {
      // setRedirect(true);
      setTimeout(3000, history.push(USER_ROUTE.login));
    }
  }

  const handleSubmit = async answers => {
    setSubmitLoading(true);
    const endTime = new Date().getTime();
    try {
      const response = await createResponse({
        answers,
        surveyId:
          (orgSurveyById && getIntFromString(orgSurveyById.id)) ||
          (publicSurveyById && getIntFromString(publicSurveyById.id)),
        totalDuration: prevTotalDuration + (endTime - startTime) / 1000 // returns time in seconds
      });
      response && setSubmitted(true);
      response && removeItem(orgSurveyById ? orgSurveyById.id : publicSurveyById.id);
      if (response) history.push(`${ROUTE.surveyResponseLink}${getIntFromString(response.id)}`);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <QuizView
        submitted={submitted}
        onSubmit={handleSubmit}
        setPrevTotalDuration={setPrevTotalDuration}
        quizStartTime={startTime}
        {...props}
      />
    </Spin>
  );
};

export default compose(withCreateResponse, withPublicSurveyById, withOrgSurveyById)(SurveyQuiz);
