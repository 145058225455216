import gql from 'graphql-tag';

export const GET_USER_EMAIL_BY_USERNAME_OR_EMAIL_QUERY = gql`
  query getUserEmailByUsernameOrEmail($email: String, $username: String) {
    getUserEmailByUsernameOrEmail(email: $email, username: $username) {
      username
      email
    }
  }
`;
