import React from 'react';
import _ from 'lodash';
import { Row, Col, Form, Button, Space, Divider, Empty, Spin, message } from 'antd';

import { TextField, CKEditorField, ButtonsComponent, DeleteIcon, getIntFromString, ReactMarkdown } from '../../look';
import AddCondition from '../../text-section/containers/AddCondition';
import ConditionOperator from '../../text-section/helpers/helpers';
import AddCoachingCommitment from '../containers/AddCoachingCommitment';

export const CKEditorFieldName = 'description';

const FormItem = Form.Item;

const CommitmentFormComponent = props => {
  const {
    getCommitmentById,
    onSubmit,
    data,
    setData,
    deleteCondition,
    deleteCoachingCommitment,
    setVisible,
    handleCancel,
    coachingCommitmentData,
    setCoachingCommitmentData
  } = props;
  const [actionLoading, setActionLoading] = React.useState(false);
  const initialValue = {
    title: getCommitmentById && getCommitmentById?.title,
    description: getCommitmentById && getCommitmentById?.description,
    condition: getCommitmentById && getCommitmentById?.condition
  };

  const handleDelete = async id => {
    setActionLoading(true);
    try {
      const response = await deleteCondition(id);
      response && setData(data?.filter(({ node }) => getIntFromString(node.id) !== id));
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw Error(err.message);
    }
  };

  const handleDeleteCoachingCommitment = async (id, index) => {
    if (id) {
      setActionLoading(true);
      try {
        const response = await deleteCoachingCommitment(id);
        // console.log(response);
        setActionLoading(false);
      } catch (err) {
        setActionLoading(false);
        throw Error(err.message);
      }
    }
    setCoachingCommitmentData(
      coachingCommitmentData
        ?.filter(({ node }) => node?.index !== index)
        .map(({ node }, idx) => ({ node: { ...node, index: idx } }))
    );
  };

  const handleEditCommitment = async condition => {
    setActionLoading(true);
    try {
      if (!getCommitmentById?.id) {
        setData(data ? [...data, { node: condition }] : [{ node: condition }]);
      } else {
        setData(data ? [...data, { node: condition }] : [{ node: condition }]);
        // const response = await editTextSection({
        //   id: getIntFromString(getCommitmentById.id),
        //   condition: [...data?.map(({ node }) => getIntFromString(node.id)), getIntFromString(condition.id)],
        //   sequence: getCommitmentById.sequence
        // });
        // response && setData(response?.condition?.edges);
      }
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  };

  const handleEditCoachingCommitment = async coachingCommitment => {
    setActionLoading(true);

    try {
      if (typeof coachingCommitment.index !== 'undefined') {
        setCoachingCommitmentData(
          [
            ...coachingCommitmentData?.filter(({ node }) => node.index !== coachingCommitment.index),
            { node: coachingCommitment }
          ].map(({ node }, index) => ({ node: { ...node, index } }))
        );
      } else {
        setCoachingCommitmentData(
          coachingCommitmentData
            ? [...coachingCommitmentData, { node: coachingCommitment }].map(({ node }, index) => ({
                node: { ...node, index }
              }))
            : [{ node: { ...coachingCommitment, index: 0 } }]
        );
      }
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  };

  // console.log('coachingCommitmentData', coachingCommitmentData);
  return (
    <Row>
      <Col span={24}>
        <Spin spinning={actionLoading} size="medium">
          <Form
            layout="vertical"
            initialValues={initialValue}
            destroyOnClose={true}
            onFinish={values =>
              onSubmit(values, data ? data?.map(({ node }) => getIntFromString(node.id)) : [], coachingCommitmentData)
            }
            required={false}
            scrollToFirstError={true}
          >
            <TextField name="title" label="Title" rules={[{ required: true, message: 'Title is required!' }]} />
            <CKEditorField
              name={CKEditorFieldName}
              label="Description"
              initialValue={getCommitmentById && getCommitmentById.description}
              // rules={[{ required: true, message: 'All questions are required!' }]}
            />

            <FormItem label={'Conditions'} labelCol={{ span: 24 }} wrapperCol={{ span: 24 /* , offset */ }}>
              {data?.length > 0 ? (
                data?.map(({ node }) => (
                  <>
                    <Row justify="space-between" align="middle">
                      <Col span={7} align="left">
                        <ReactMarkdown>{node?.leftOperand?.note}</ReactMarkdown>
                      </Col>
                      <Col span={6} align="left">
                        {ConditionOperator?.filter(c => c.value === node?.operator)[0]?.label}
                      </Col>
                      <Col span={7} align="left">
                        <span>{node?.rightOperand?.operation}</span>
                      </Col>

                      <Col span={4} align="right">
                        <AddCondition
                          btn="edit"
                          condition={node}
                          // onSubmit={handleEditCommitment}
                          btnText={'Add new condition'}
                        />
                        <Divider type="vertical" />
                        <DeleteIcon
                          tooltipSuffix="Condition"
                          type="link"
                          size="sm"
                          onClick={() => handleDelete(getIntFromString(node.id))}
                        />
                      </Col>
                    </Row>
                    <Divider />
                  </>
                ))
              ) : (
                <div align="center">
                  <Empty />
                  <br />
                </div>
              )}
            </FormItem>

            <FormItem label={'Coaching commitment'} labelCol={{ span: 24 }} wrapperCol={{ span: 24 /* , offset */ }}>
              {coachingCommitmentData?.length > 0 ? (
                coachingCommitmentData?.map(({ node }, index) => (
                  <>
                    <Row justify="space-between" align="middle">
                      <Col span={20} align="left">
                        <span>{node?.title}</span>
                      </Col>

                      <Col span={4} align="right">
                        <AddCoachingCommitment
                          btn="edit"
                          coachingCommitment={node}
                          onSubmit={e => handleEditCoachingCommitment(_.set(e, ['index'], index))}
                          btnText={'Add new coaching commitment'}
                        />
                        <Divider type="vertical" />
                        <DeleteIcon
                          type="link"
                          size="sm"
                          onClick={() => handleDeleteCoachingCommitment(getIntFromString(node.id), index)}
                        />
                      </Col>
                    </Row>
                    <Divider />
                  </>
                ))
              ) : (
                <div align="center">
                  <Empty />
                  <br />
                </div>
              )}
            </FormItem>

            <Row justify="space-between">
              <Col>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Add
                  </Button>

                  <AddCondition btn="add" onSubmit={handleEditCommitment} btnText={'Add new condition'} />

                  <AddCoachingCommitment
                    btn="add"
                    onSubmit={handleEditCoachingCommitment}
                    btnText={'Add new coaching commitment'}
                  />
                </Space>
              </Col>
              <Col>
                <Button type="primary" ghost onClick={handleCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
            {/* <ButtonsComponent setButton={setButton} /> */}
          </Form>
        </Spin>
      </Col>
    </Row>
  );
};

export default CommitmentFormComponent;
