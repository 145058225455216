import React from 'react';
import Helmet from 'react-helmet';

const MataTags = props => {
  const { title, description } = props;
  return (
    <Helmet>
      <title>{`Resilience - ${title || ''}`}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default MataTags;
