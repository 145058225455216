import gql from 'graphql-tag';
import { COACHING_COMMITMENT_TYPE_CONNECTION } from '../../dashboard/graphql/CoachingCommitmentTypeConnection.gql';

export const GET_MY_CUSTOMIZED_COACHING_COMMITMENTS_QUERY = gql`
  ${COACHING_COMMITMENT_TYPE_CONNECTION}
  query getMyCustomizedCoachingCommitments($first: Int, $offset: Int, $orderBy: [String], $description_Icontains: String) {
    getMyCustomizedCoachingCommitments(
      first: $first
      offset: $offset
      orderBy: $orderBy
      description_Icontains: $description_Icontains
    ) {
      ...CoachingCommitmentTypeConnection
    }
  }
`;
