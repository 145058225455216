import React from "react";
import { Spin } from "antd";

import { compose } from "../../../core";
import { ButtonsCatch, getIntFromString } from "../../../look";

import ROUTE from "../../route";

import { withAddFaqCategory, withPageState } from "../PageOperations";

import AddFaqView from "./components/AddFaqView";

const AddFaq = props => {
  const { history, addFaqCategory } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (categoryData, item) => {
    setSubmitLoading(true);
    try {
      const response = await addFaqCategory({ categoryData });

      response &&
        ButtonsCatch(
          item,
          ROUTE.faq,
          history,
          item === undefined ? `${ROUTE.editFaqLink}${getIntFromString(response.id)}` : window.location.pathname
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddFaqView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withPageState, withAddFaqCategory)(AddFaq);
