import gql from 'graphql-tag';
import { ORGANIZATION_TYPE } from './OrganizationType.gql';

export const ORGANIZATION_SUBSCRIPTION = gql`
  ${ORGANIZATION_TYPE}
  subscription organizationSubscription {
    organizationSubscription {
      mutation
      organization {
        ...OrganizationType
      }
    }
  }
`;
