import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Carousel, Card, Progress, Skeleton, Row, Button, Col, Form, Empty, Modal, Tooltip, Space } from 'antd';
import { LogoutOutlined, UpCircleTwoTone, DownCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import { ShortcutProvider, ShortcutConsumer } from 'react-keybind';

import SectionComponent, { getTypeFromQuestion, TIME_LIMIT } from './QuizResponseSectionComponent';
import { MetaTags, getIntFromString, InputValuesObject, getUniqueListBy } from '../../look';

import ROUTE from '../route';
import QuizLoading from './QuizLoading';
import SurveyQuizShortcuts, { getShortCutObj } from '../components/SurveyQuizShortcuts';

const { confirm } = Modal;

const QuizView = props => {
  const { loading, onSubmit, responseByResponseId, history } = props;
  const ref = React.useRef();
  const currentSectionRef = React.useRef();

  const [showOk, setShowOk] = React.useState([false]);
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [currentSection, setCurrentSection] = React.useState(1);
  const [formValues, setFormValues] = React.useState([]);
  const [startTime, setStartTime] = React.useState(new Date().getTime());
  const [isForw, setIsForw] = React.useState(true);
  const [currentMatrixQuestion, setCurrentMatrixQuestion] = useState(0);
  const [enableShortCuts, setEnableShortCuts] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!enableShortCuts) {
      setEnableShortCuts(true);
    }
  }, [enableShortCuts]);

  const question = [].concat.apply(
    [],
    responseByResponseId?.survey?.groupSet?.edges
      .sort(function (a, b) {
        return a.node.sequence - b.node.sequence;
      })
      .map(({ node: grpSet }, idx) =>
        grpSet.matrix
          ? { ...grpSet }
          : grpSet?.questionSet?.edges?.map(({ node: questionSet }) => ({
              ...grpSet,
              questionSet: [questionSet]
            }))
      )
  );
  const initialValues = {};
  [].concat.apply(
    [],
    responseByResponseId?.answerSet?.edges.map(({ node: grpSet }, idx) => {
      initialValues[grpSet.question.id] = grpSet.answer;
    })
  );
  // console.log(initialValues);
  const currentIsMatrix = question[currentSection - 1]?.matrix;
  let totalLength = question.length;

  const shortcutObj = question?.map(q =>
    Array.isArray(q?.questionSet)
      ? q?.questionSet?.map(getShortCutObj)
      : q?.questionSet?.edges?.map(({ node }) => getShortCutObj(node))
  );
  // console.log(shortcutObj);

  function showDeleteConfirm() {
    confirm({
      title: 'Are you sure you want to exit this survey?',
      icon: <LogoutOutlined />,
      content: 'All your responses for the current survey will be lost.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        history.push(ROUTE.allOrganizationSurvey);
      },
      onCancel() {
        // console.log('Cancel');
      }
    });
  }

  const renderFormSections = survey => {
    function timeoutFunc(srNo) {
      const id = setTimeout(() => nextStep(form.getFieldsValue(true), srNo), TIME_LIMIT * 1000);
      // console.log('Sup!', id);
      setTimeoutId(id);
    }
    function closetimer() {
      // console.log('Sup!!', timeoutId);
      clearTimeout(timeoutId);
    }

    function getValue(value, name) {
      if (!currentIsMatrix) {
        const questionFiltered = question.filter(q => {
          return Array.isArray(q?.questionSet) && q?.questionSet?.filter(qs => qs.id === name).length > 0;
        })[0]?.questionSet[0];
        switch (questionFiltered?.choiceSet?.edges[0]?.node?.inputType) {
          case InputValuesObject.date:
            return value?.format('YYYY/MM/DD');
          case InputValuesObject.datetime:
            return value?.format('YYYY/MM/DD HH:mm:ss');
          case InputValuesObject.month:
            return value?.format('M');
          case InputValuesObject.time:
            return value?.format('HH:mm:ss');
          case InputValuesObject.week:
            return value?.week();
          default:
            return value || '';
        }
      } else {
        return value || '';
      }
    }

    function getResponseDuration(endTime, value, k) {
      let time = 0;
      const existingValue = formValues.filter(f => f.questionId === getIntFromString(k))[0];

      if (!currentIsMatrix) {
        time =
          question[currentSection - 1]?.questionSet[0].id === k
            ? existingValue
              ? existingValue?.responseDuration + (endTime - startTime) / 1000 // returns time in seconds
              : (endTime - startTime) / 1000 // returns time in seconds
            : existingValue?.responseDuration || 0;
      } else {
        time = existingValue
          ? existingValue?.answer === '' || existingValue?.answer === undefined
            ? value !== '' || value !== undefined
              ? 0
              : (endTime - startTime) / 1000
            : existingValue?.responseDuration
          : value === '' || value === undefined
          ? 0
          : (endTime - startTime) / 1000;
      }
      return time;
    }

    const nextStep = values => {
      setIsForw(true);
      if (currentSection !== totalLength) ref.current.next();
      closetimer();
      setShowOk(question.map(() => false));
      const endTime = new Date().getTime();

      let input = [];
      Object.keys(values).map((k, i) => {
        input.push({
          questionId: getIntFromString(k),
          answer: String(getValue(values[k], k)),
          responseDuration: getResponseDuration(endTime, values[k], k)
        });
      });
      setFormValues(getUniqueListBy([...formValues, ...input], 'questionId'));
      if (document) {
        if (document.body) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      }
      setStartTime(endTime);
      return getUniqueListBy([...formValues, ...input], 'questionId');
    };

    // console.log(currentSection, currentIsMatrix, currentMatrixQuestion);
    return (
      <>
        <ShortcutProvider>
          <span ref={currentSectionRef} style={{ display: 'none' }}>
            1
          </span>
          <ShortcutConsumer>
            {({ keys: allKeys }) =>
              survey?.groupSet.edges.length > 0 ? (
                <>
                  <MetaTags title={survey[currentSection]?.label || survey?.name} description="This is survey page." />

                  <Form
                    form={form}
                    name={survey?.name}
                    // layout={'vertical'}
                    onFinish={values => (currentSection === totalLength ? onSubmit(nextStep(values)) : nextStep(values))}
                    requiredMark={false}
                    // scrollToFirstError={true}
                    initialValues={initialValues}
                  >
                    <h3 style={{ fontWeight: 'bold', margin: '5vh' }}>{survey[currentSection]?.label || survey?.name}</h3>
                    <div
                      style={{ minHeight: '75vh', /* display: 'grid', */ margin: 'auto', maxWidth: '800px', width: '100%' }}
                    >
                      <div
                        style={{ margin: 'auto', minHeight: '60vh', maxWidth: '40vw', padding: !currentIsMatrix && '24px' }}
                      >
                        <div style={{ margin: !currentIsMatrix ? '5vh' : '0 5vh' }}>
                          <Row gutter={[24, 24]}>
                            {question?.map((grpSet, idx) => (
                              <Col>
                                <SectionComponent
                                  key={idx}
                                  srNo={idx + 1}
                                  form={form}
                                  currentIsMatrix={currentIsMatrix}
                                  currentMatrixQuestion={currentMatrixQuestion}
                                  currentSection={currentSection}
                                  matrix={grpSet?.matrix}
                                  timeoutFunc={timeoutFunc}
                                  closetimer={closetimer}
                                  nextStep={e => nextStep(form.getFieldsValue(true), e)}
                                  sectionLabel={grpSet?.label}
                                  sectionSequence={grpSet?.sequence}
                                  questionList={
                                    Array.isArray(grpSet?.questionSet)
                                      ? grpSet?.questionSet?.map(node => node)
                                      : grpSet?.questionSet?.edges?.map(({ node }) => node)
                                  }
                                  showOk={showOk[currentSection - 1]}
                                  setShowOk={e =>
                                    setShowOk(question.map((q, qi) => (e ? qi === currentSection - 1 : false)))
                                  }
                                />
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              ) : survey ? (
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Empty />
                </div>
              ) : null
            }
          </ShortcutConsumer>
        </ShortcutProvider>
      </>
    );
  };

  return (
    <MobilePadding>
      <div>
        {loading && <QuizLoading />}
        {!loading && responseByResponseId?.survey
          ? renderFormSections(responseByResponseId?.survey)
          : renderFormSections(responseByResponseId?.survey)}
      </div>
    </MobilePadding>
  );
};

export default QuizView;

const MobilePadding = styled.div`
  /* background: #f5f5f5; */
  margin: -5vh;
  /* padding: 5vh 0; */
  min-height: 100vh;

  @media only screen and (max-width: 480px) {
    padding: 0 10px;
  }
`;
