import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { Spin, message } from 'antd';

import { compose } from '../../core';
import { getItem, setItem } from '../../core';
import ORGANIZATION_ROUTE from '../../organization/route';
import SURVEY_ROUTE from '../../survey/route';

import LoginView from '../components/LoginView';
import ROUTE from '../route';

import { withLogin } from './UserOperations';
import SendEmailVerify from './SendEmailVerify';
import { GET_USER_EMAIL_BY_USERNAME_OR_EMAIL_QUERY } from '../graphql/GetUserEmailByUsernameOrEmailQuery.gql';
import { validateEmail } from '../../look';

const Login = props => {
  const [jwt, setJwt] = React.useState(null);
  const [user, setUser] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [incorrectPass, setIncorrectPass] = React.useState(false);
  const [nonFieldErrors, setNonFieldErrors] = React.useState(null);
  const { login, history, client, location /* , passwordReset */ } = props;
  const [emailVerify, setEmailVerify] = React.useState(false);
  // const [userData, setUserData] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [username, setUsername] = React.useState(null);

  const [counter, setCounter] = React.useState(true);
  const [counter1, setCounter1] = React.useState(true);
  const [msg, setMsg] = React.useState(false);
  const handlePasswordResetState = React.useRef(() => {});
  const handleLoggedIn = React.useRef(() => {});

  handleLoggedIn.current = async () => {
    const storedJwt = await getItem('accessToken');
    setTimeout(3000, setJwt(storedJwt));
  };

  handlePasswordResetState.current = async () => {
    setMsg(true);
    setCounter(false);
  };

  React.useEffect(() => {
    handleLoggedIn.current();
  }, []);

  // React.useEffect(() => {
  //   const pathnameArray = location.pathname.split('/');

  //   if (counter && pathnameArray.indexOf('login-after-token-survey') > -1) {
  //     handlePasswordResetState.current();
  //   }
  // });

  // const sendVerificationEmail = async (email, username) => {
  //   setLoading(true);
  //   try {
  //     const { data } = await client.query({
  //       query: GET_USER_EMAIL_BY_USERNAME_OR_EMAIL_QUERY,
  //       variables: { email, username }
  //     });
  //     setUserData(data.getUserEmailByUsernameOrEmail)
  //
  //   } catch (e) {
  //     throw new Error(e.message);
  //   }
  //   setLoading(false);
  // };

  const handleSubmit = async values => {
    const isValidEmail = validateEmail(values.email);
    setIncorrectPass(false);
    setLoading(true);
    if (isValidEmail) setEmail(values.email);
    else setUsername(values.email);

    try {
      delete values.remember;
      const response = await login(isValidEmail ? values : { username: values.email, password: values.password });
      // authentication.doLogin({ token: response.token, tokenExpiry: response.payload.exp });
      if (response.success) {
        if (typeof window !== 'undefined') {
          setItem('accessToken', response.token);
          setItem('refreshToken', response.refreshToken);
        }
        if (counter1) {
          setUser(response.user);
          setJwt(response.token);
          setCounter1(false);
        }
        message.success('Logged you in!');
      } else {
        if (
          response.errors.nonFieldErrors.length > 0 &&
          response.errors.nonFieldErrors[0].message === 'Please verify your account.'
        ) {
          setEmailVerify(true);
          // sendVerificationEmail(values.email, values.username);
        }
        message.error('Login failed');
      }
      if (response.errors) {
        setNonFieldErrors(response.errors.nonFieldErrors);
      }
    } catch (e) {
      setIncorrectPass(true);

      // throw new Error(e.message);
    }
    setLoading(false);
  };

  if (
    user &&
    user !== {} &&
    // !(user && Object.keys(user).length === 0)
    jwt
  ) {
    msg
      ? history.push(ROUTE.myResponse)
      : user.isSuperuser
      ? history.push(ORGANIZATION_ROUTE.organization)
      : history.push(`${SURVEY_ROUTE.allOrganizationSurvey}`);
    // if (msg) {
    // } else {
    // }
    // history.push(ROUTE.profile);
  }

  return (
    <Spin spinning={loading} size="large">
      {(email || username) && (
        <SendEmailVerify
          visible={emailVerify}
          setVisible={setEmailVerify}
          /* userData={userData} */ email={email}
          username={username}
        />
      )}

      <LoginView
        msg={msg}
        nonFieldErrors={nonFieldErrors}
        incorrectPass={incorrectPass}
        {...props}
        onSubmit={handleSubmit}
      />
    </Spin>
  );
};

export default compose(withApollo, withLogin)(Login);
