import React from 'react';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Spin } from 'antd';

import SelectField from './SelectField';
import { getIntFromString } from '../functions';

export const LIST_ALL_USERS_QUERY = gql`
  query listAllUsers {
    listAllUsers {
      edges {
        cursor
        node {
          id
          firstName
          lastName
          username
          email
        }
      }
    }
  }
`;

const UsersField = props => {
  const { loading, listAllUsers, id = true } = props;

  const choices =
    listAllUsers &&
    listAllUsers.edges.length > 0 &&
    listAllUsers.edges.map(({ node }) => ({
      label: node.firstName && node.lastName ? `${node.firstName} ${node.lastName}` : node.username,
      value: id ? getIntFromString(node.id) : `${node.email},${node.firstName},${node.lastName},${node.username}`
    }));

  return (
    <Spin spinning={loading} size="small">
      <SelectField {...props} choices={choices} />
    </Spin>
  );
};

export default graphql(LIST_ALL_USERS_QUERY, {
  props({ data: { loading, error, fetchMore, listAllUsers, subscribeToMore, updateQuery } }) {
    return { loading, error, listAllUsers, subscribeToMore, updateQuery };
  }
})(UsersField);
