import React from 'react';
import { Button, Modal, Spin } from 'antd';

import { compose } from '../../core';
import { EditIcon, getIntFromString, withMarkdownFieldValue } from '../../look';

import ConditionFormComponent from '../components/ConditionFormComponent';
import { CKEditorFieldName } from '../components/ConditionFormComponent';

import { withEditCondition, withAddCondtion } from './TextSectionOperations';

const AddCondtion = props => {
  const { btn, onSubmit, addCondition, editCondition, btnText, condition } = props;
  const [visible, setVisible] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);

  const handleSubmit = async values => {
    setActionLoading(true);
    try {
      if (condition?.id) {
        const response = await editCondition(
          withMarkdownFieldValue(CKEditorFieldName, { id: getIntFromString(condition.id), ...values })
        );
        setActionLoading(false);
        response && setVisible(false);
      } else {
        const response = await addCondition({ conditionData: withMarkdownFieldValue(CKEditorFieldName, values) });
        setActionLoading(false);
        response && setVisible(false);
        response && onSubmit(response);
      }
    } catch (e) {
      setActionLoading(false);
      throw Error(e.message);
    }
  };

  return (
    <>
      {btn === 'add' ? (
        <Button block type={'primary'} ghost onClick={() => setVisible(true)}>
          {btnText}
        </Button>
      ) : (
        <EditIcon tooltipSuffix="Condition" color="primary" size="sm" onClick={() => setVisible(true)} />
      )}

      <Modal
        destroyOnClose
        centered
        visible={/* true || */ visible || window.location.href.includes('openmodal')}
        onCancel={() => setVisible(false)}
        // footer={null}
        width="75%"
        title={btn === 'add' ? 'Add Condtion' : 'Edit Condition'}
        footer={null}
      >
        <Spin spinning={actionLoading} size="medium">
          <ConditionFormComponent
            btnText={btn === 'add' ? 'Add' : 'Save'}
            condition={condition}
            setVisible={setVisible}
            onSubmit={handleSubmit}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default compose(withEditCondition, withAddCondtion)(AddCondtion);
