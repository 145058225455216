import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Divider, Modal, Input, Col, Spin, Card, Button, Empty, Form, Space, Tooltip } from 'antd';
import { SearchOutlined, MessageOutlined, PullRequestOutlined, CaretLeftOutlined } from '@ant-design/icons';

import CompareRequest from './CompareRequest';
import IncomingRequest from '../containers/IncomingRequest';
import RequestStatus from '../containers/RequestStatus';
import OrganizationEmployeesRender from '../containers/OrganizationEmployeesRender';
import AllNonEmployeeUsersRender from '../containers/AllNonEmployeeUsersRender';
import { getIntFromString, handleGetReport } from '../../look';
import REPORT_ROUTE from '../../report/route';
const TAB1 = 'status';
const TAB2 = 'message';

const CompareYourScoreView = props => {
  const { loading, me, onNameChange, onSubmit, client, onUserFirstNameChange, form } = props;

  const [button, setButton] = React.useState('');
  const [visible, setVisible] = useState(false);
  const [request, setRequest] = useState(null);
  // const [report, setReport] = useState(null);

  const [tab, setTab] = useState(null /* TAB1 */);
  const [actionLoading, setActionLoading] = useState(false);

  const allowComparison = me?.employee?.organizationSet?.edges[0]?.node?.allowComparison;
  const isEmployee = me?.employee;

  function handleCompare(employee) {
    setRequest({ ...employee, compare: true });
  }

  function handleRequest(employee) {
    setRequest({ ...employee, compare: false });
  }

  async function handleSubmit(values) {
    if (allowComparison) {
      setActionLoading(true);

      if (!(Number(values?.surveyId) === 1 || Number(values?.surveyId) === 2)) {
        form.setFields([
          {
            name: 'surveyId',
            errors: ['Sorry! Comparison report for this survey does not exist.']
          }
        ]);
      } else {
        const { success } = await handleGetReport(
          Number(values?.surveyId),
          client,
          allowComparison
            ? { requestedUserId: getIntFromString(values?.receiverId) }
            : { requestId: getIntFromString(values?.receiverId) }
        );
        if (success) {
          setRequest(null);
          form.resetFields();
          window.open(
            `${REPORT_ROUTE.reportLink}${Number(values?.surveyId)}${REPORT_ROUTE.repotUserLink}${getIntFromString(
              values?.receiverId
            )}${REPORT_ROUTE.reportComparisonLink}${`${values?.name}`}`,
            '_blank'
          );
        } else {
          form.setFields([
            {
              name: 'surveyId',
              errors: ['Sorry! Comparison report for this survey does not exist.']
            }
          ]);
        }
      }
      setActionLoading(false);
    } else {
      const response = await onSubmit(values, button);
      response && setRequest(null);
      response && form.resetFields();
    }
  }

  return (
    <Row>
      <>
        {/* <Tooltip title="This feature is comming soon!"> */}
        <H3Btn style={{}} onClick={() => setVisible(true)}>
          Compare your score
        </H3Btn>
        {/* </Tooltip> */}
        <Divider />
      </>

      <Modal
        footer={null}
        /* centered */ visible={visible}
        width={'60%'}
        destroyOnClose={true}
        bodyStyle={{ minHeight: '60vh' }}
        onCancel={() => {
          setVisible(false);
          setRequest(null);
        }}
      >
        <Spin spinning={actionLoading}>
          {!request ? (
            <>
              <br />
              <br />
              <Row justify={'space-between'}>
                <Col>
                  <h2>Compare your score</h2>
                </Col>

                <Col>
                  <Space>
                    <Button icon={<CaretLeftOutlined />} shape="circle" onClick={() => setTab(null)} />

                    <Tooltip title="Request Status">
                      <Button
                        icon={<PullRequestOutlined />}
                        shape="circle"
                        onClick={() => setTab(TAB1)}
                        ghost={tab !== TAB1}
                        type={'primary'}
                      />
                    </Tooltip>

                    <Tooltip title="Incoming Request">
                      <Button
                        icon={<MessageOutlined />}
                        shape="circle"
                        onClick={() => setTab(TAB2)}
                        type={'primary'}
                        ghost={tab !== TAB2}
                      />
                    </Tooltip>
                  </Space>
                </Col>
              </Row>

              {tab === TAB1 ? (
                <RequestStatus
                  allowComparison={allowComparison}
                  // setReport={setReport}
                  setActionLoading={setActionLoading}
                />
              ) : tab === TAB2 ? (
                <IncomingRequest />
              ) : (
                <Row>
                  <Col span={16}>
                    <Input
                      placeholder="Search for user"
                      style={{
                        borderRadius: '20px',
                        border: 0,
                        boxShadow: '0px 4px 15px 5px rgba(175, 176, 162, 0.15)'
                      }}
                      onChange={e => (isEmployee ? onNameChange(e?.target?.value) : onUserFirstNameChange(e?.target?.value))}
                      size="large"
                      suffix={<SearchOutlined style={{ color: '#84A100' }} />}
                    />
                  </Col>

                  <Col span={24}>
                    <br />
                    <h3 style={{ color: '#616358' }}>Employee</h3>

                    <Spin spinning={loading}>
                      <Row gutter={[24, 24]} style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                        {isEmployee ? (
                          <OrganizationEmployeesRender
                            handleCompare={handleCompare}
                            handleRequest={handleRequest}
                            allowComparison={allowComparison}
                          />
                        ) : (
                          <AllNonEmployeeUsersRender
                            handleCompare={handleCompare}
                            handleRequest={handleRequest}
                            allowComparison={allowComparison}
                            employee={false}
                          />
                        )}
                      </Row>
                    </Spin>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <CompareRequest
              employee={request}
              isEmployee={isEmployee}
              onSubmit={handleSubmit}
              setButton={setButton}
              form={form}
              setRequest={setRequest}
            />
          )}
        </Spin>
      </Modal>
    </Row>
  );
};

export default CompareYourScoreView;

const H3Btn = styled.h3`
  font-weight: normal;
  text-decoration: underline;
  color: #616358;

  :hover {
    cursor: pointer;
  }
`;
