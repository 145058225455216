import Home from './containers/Home';
import ROUTE from './route';

export default [
  {
    name: 'Home',
    path: ROUTE.home,
    component: Home,
    exact: true
  }
];
