import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Modal, Row, Col } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';

import { compose } from '../../core';
import {
  RenderTable,
  getOrderBy,
  generateAdminReport,
  displayDataCheck,
  getIntFromString,
  GetColumnSearchProps,
  readableTime
} from '../../look';

import { withAllResponsesByUserId, withSurveyFilterUpdating, withSurveyState, withDeleteResponse } from './SurveyOperations';
import { subscribeToResponses } from './SurveySubscriptions';

const ResponsesByUserIdTable = props => {
  const {
    userId,
    username,
    onPaginationChange,
    onOrderByChange,
    orderBy,
    loading,
    allResponsesByUserId,
    selectedRowKeys,
    handleChange,
    onSurveyChange,
    client,
    subscribeToMore
  } = props;
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const subscribe = subscribeToResponses(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });
  const columns = [
    {
      title: 'Survey Name',
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onSurveyChange, 'survey'),
      render: (text, record) => <>{record && displayDataCheck(record.survey.name)}</>
    },
    {
      title: 'Respone Date',
      key: 'responseDate',
      sorter: () => onOrderByChange(getOrderBy('responseDate', orderBy)),
      render: (text, record) => `${moment(record.responseDate, 'YYYY-M-DD HH:mm:ss')}`
    },
    {
      title: 'Response Duration',
      key: 'responseDuration',
      sorter: () => onOrderByChange(getOrderBy('responseDuration', orderBy)),
      render: (text, record) => record?.responseDuration && <>{`${readableTime(record?.responseDuration)}`}</>
    },
    {
      title: 'Action',
      key: 'action',
      // sorter: () => onOrderByChange(getOrderBy('action', orderBy)),
      render: (text, record) => (
        <>
          {record && (
            <Button
              type="primary"
              onClick={() => {
                generateAdminReport(getIntFromString(record.id), getIntFromString(record.survey.id), userId, client);
                setVisible(true);
              }}
            >
              Get Report
            </Button>
          )}
        </>
      )
    }
  ];
  const EmailSent = () => (
    <>
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        {/* <MailSent>
          <CheckCircleFilled style={{ color: '#009c4a', fontSize: '25px' }} /> &nbsp; Email Sent! <br />
        </MailSent> */}
        <h3>
          This could take a minute.
          <br />
          Generated report will be sent to your registered email.
        </h3>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                setVisible(false);
              }}
              size="middle"
            >
              Close
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Button type="primary" ghost onClick={() => setVisible(false)} size="middle">
              Close
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  return (
    <>
      <h2>Responses for {username}</h2>
      <RenderTable
        tableData={allResponsesByUserId}
        loading={loading}
        onPaginationChange={onPaginationChange}
        columns={columns}
        selectedRowKeys={selectedRowKeys}
        handleChange={handleChange}
      />
      <Modal
        destroyOnClose
        footer={null}
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        bodyStyle={{ padding: '0px' }}
      >
        <ModalBody>
          <EmailSent />
        </ModalBody>
      </Modal>
    </>
  );
};

export default compose(
  withApollo,
  withSurveyState,
  withAllResponsesByUserId,
  withSurveyFilterUpdating
)(ResponsesByUserIdTable);

const ModalBody = styled.div`
  padding: 24px 48px;
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;
