import gql from 'graphql-tag';
import { QUESTION_TYPE_CONNECTION } from './QuestionTypeConnection.gql';

export const GROUP_TYPE = gql`
  ${QUESTION_TYPE_CONNECTION}
  fragment GroupType on GroupType {
    id
    name
    label
    helpText
    sequence
    matrix
    showInResult
    showAvgInResult
    survey {
      id
    }
    questionSet {
      ...QuestionTypeConnection
    }
  }
`;
