import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';
import ROUTE from '../route';
import USER_ROUTE from '../../user/route';
import { getIntFromString } from '../../look';

import QuizView from '../components/SurveyQuizResponseView';
import { withCreateResponse, withPublicSurveyById, withResponseByResponseId } from './SurveyOperations';

const SurveyQuizResponse = props => {
  const { error, history, createResponse, orgSurveyById, publicSurveyById } = props;
  const startTime = new Date().getTime();
  const [submitLoading, setSubmitLoading] = React.useState(false);
  if (error) {
    if (error.message === 'GraphQL error: Permission Denied.') {
      // setRedirect(true);
      setTimeout(3000, history.push(USER_ROUTE.login));
    }
  }

  return (
    <Spin spinning={submitLoading} size="large">
      <QuizView {...props} />
    </Spin>
  );
};

export default compose(withResponseByResponseId)(SurveyQuizResponse);
