import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Row, Col, Divider, Empty, Card } from 'antd';
import AddCommitment from '../containers/AddCommitment';
import EditCommitment from '../containers/EditCommitment';
import { ReactMarkdown, getIntFromString, DeleteIcon } from '../../look';
import { subscribeToCommitment } from '../containers/ToolSubscription';
import { compose } from '../../core';

// export const ALL_COMMITMENT_QUERY = gql`
//   query getAllCommitments($first: Int, $orderBy: [String]) {
//     getAllCommitments(first: $first, orderBy: $orderBy) {
//       totalCount
//     }
//   }
// `;

const CommitmentSection = props => {
  const { commitment, handleEditTool, setActionLoading, onCommitmentDelete, subscribeToMore } = props;
  // const [data, setData] = React.useState(commitment || {});
  // const handleEdit = (e, obj) => {
  //   const com = data.edges.filter(({ node }) => node?.id === obj?.id);
  //   console.log(com);
  //   if (com.length > 0) {
  //     handleEditTool(e, 'commitment');
  //     const index = data.edges?.findIndex(dataEdge => dataEdge.node.id === com[0].id);
  //     data.edges = data.edges.splice(index, 1, obj);
  //     console.log(data);
  //     setData(data);
  //   } else {
  //     data.edges = [...data.edges, { node: obj }];
  //     setData(data);
  //   }
  // };

  React.useEffect(() => {
    const subscribe = subscribeToCommitment(subscribeToMore /* , props.filter */);
    return () => {
      subscribe();
    };
  });

  return (
    <Row>
      <Col lg={12} xs={24} align="left">
        <h2>Commitments</h2>
      </Col>

      <Col lg={12} xs={24} align="right">
        <AddCommitment handleEdit={e => handleEditTool(e, 'commitment')} setSubmitLoading={setActionLoading} />
      </Col>
      <Divider style={{ width: '100%', marginTop: '10px' }} />

      <Col lg={24}>
        {commitment?.edges?.map(({ node }) => (
          <>
            <Card
              bordered={false}
              style={{
                display: 'grid',
                width: '100%',
                boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
                borderRadius: '10px'
              }}
              // bodyStyle={{ padding: "10px" }}
            >
              <Row>
                <Col span={20} align="left">
                  <h3>{node.title}</h3>
                </Col>

                <Col span={4} align="right">
                  <EditCommitment /* onSubmit={handleEdit} */ setSubmitLoading={setActionLoading} getCommitmentById={node} />
                  <Divider type="vertical" />
                  <DeleteIcon
                    tooltipSuffix="Commitment"
                    type="link"
                    size="sm"
                    onClick={() => onCommitmentDelete(getIntFromString(node.id))}
                  />
                </Col>
                <Col span={24}>
                  <ReactMarkdown>{node.description}</ReactMarkdown>
                  <br />
                </Col>
              </Row>
            </Card>
            <br />
          </>
        ))}

        {commitment?.edges?.length === 0 && (
          <div align="center">
            <br />
            <br />
            <br />
            <br />
            <Empty />
            <br />
            <br />
            <br />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default compose()(CommitmentSection);
// graphql(ALL_COMMITMENT_QUERY, {
//   options: ({ filter, orderBy }) => {
//     return { variables: { first: 0, orderBy: ['-id'] } };
//   },
//   props({ data }) {
//     const { loading, error, getAllCommitments, subscribeToMore, updateQuery } = data;
//     return { loading, error, getAllCommitments, subscribeToMore, updateQuery };
//   }
// })
