
export const tipsData = [
  {
    node: {
      id: "VGlwVHlwZTo0",
      content: "Reflect on the situation where you felt proud of your actions and felt true to yourself. What values did you uphold?",
      anchor: "IDENTITY",
      subFactor: "VALUES",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo1",
      content: "Maintain a journal of the challenging experiences you went through to identify how your values played a role in decision-making.",
      anchor: "IDENTITY",
      subFactor: "VALUES",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo2",
      content: "Make a vision board that represents your values through images, words, and symbols to keep at your work station. ",
      anchor: "IDENTITY",
      subFactor: "VALUES",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo3",
      content: "Understand your personality type and how it contributes towards resilience. Find ways to flex your type to break rigid patterns and cope better.",
      anchor: "IDENTITY",
      subFactor: "PERSONALITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo4",
      content: "Identify and adopt healthy coping mechanisms for stress and emotional distress caused due to difficult situations at work.",
      anchor: "IDENTITY",
      subFactor: "PERSONALITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo5",
      content: "Challenge negative thoughts logically and replace it with a more optimistic and constructive self-talk.",
      anchor: "IDENTITY",
      subFactor: "PERSONALITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToxMA==",
      content: "Use your strengths to meet your workpalce goals.",
      anchor: "IDENTITY",
      subFactor: "STRENGTHS",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToxMQ==",
      content: "Use your strengths to help your fellow workers through tough times.",
      anchor: "IDENTITY",
      subFactor: "STRENGTHS",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToxMg==",
      content: "Think of how you can overcome a situation using your strengths and find intentional ways to apply them. ",
      anchor: "IDENTITY",
      subFactor: "STRENGTHS",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToxNQ==",
      content: "Update your knowledge about the work that you do and the industry you work in to predict and prepare for any changes ahead of time.",
      anchor: "IDENTITY",
      subFactor: "KNOWLEDGE_SKILLS_EXPERIENCE",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToxNg==",
      content: "Take on more experiences that will allow you to gain hands-on knowledge and skills. ",
      anchor: "IDENTITY",
      subFactor: "KNOWLEDGE_SKILLS_EXPERIENCE",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToxNw==",
      content: "Create a realsitic action plan and reward yourself for achieving significant milestones.",
      anchor: "FUTUREORIENTATION",
      subFactor: "ACTION",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToxOA==",
      content: "Begin by taking small, manageable actions toward your goals. Gradually increase the complexity of tasks as your confidence grows.",
      anchor: "FUTUREORIENTATION",
      subFactor: "ACTION",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToxOQ==",
      content: "Keep your dreams and aspirations in sight and consider each action you take as a purposeful step taking you closer to turning those dreams into reality.",
      anchor: "FUTUREORIENTATION",
      subFactor: "ACTION",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToyMA==",
      content: "Start each day with a clear intention related to your goals. This can help you maintain a sense of purpose and focus.",
      anchor: "FUTUREORIENTATION",
      subFactor: "ACTION",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToyMQ==",
      content: "Regularly visualize yourself achieving your goals. This mental imagery can boost your commitment by making success feel more attainable.",
      anchor: "FUTUREORIENTATION",
      subFactor: "COMMITMENT",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToyMg==",
      content: "Keep a record of your progress and accomplishments. Seeing how far you've come can reinforce your commitment and provide a sense of achievement.",
      anchor: "FUTUREORIENTATION",
      subFactor: "COMMITMENT",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToyMw==",
      content: "Reflect on your past experiences and try to find the positives associated to difficult situations.",
      anchor: "FUTUREORIENTATION",
      subFactor: "COMMITMENT",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToyNA==",
      content: "Clearly articulate why your goals are important to you. Knowing your 'why' can strengthen your commitment during challenging times.",
      anchor: "FUTUREORIENTATION",
      subFactor: "COMMITMENT",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToyNQ==",
      content: "Seek guidance from mentors or experts for delivering high-quality work.",
      anchor: "ABILITY",
      subFactor: "QUALITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToyNg==",
      content: "Specialize in a specific niche within your field. Deepening your knowledge in a particular area can make you an expert in that domain.",
      anchor: "ABILITY",
      subFactor: "QUALITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToyNw==",
      content: "Uphold the responsibility for your actions and decisions.",
      anchor: "ABILITY",
      subFactor: "INTEGRITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToyOA==",
      content: "Periodically assess your actions and choices to ensure they align with your values and ethical standards. Use this reflection as a guide for improvement.",
      anchor: "ABILITY",
      subFactor: "INTEGRITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZToyOQ==",
      content: "Respect your company's ethics, moral standards, and policies to find a rationale behind evolving situations.",
      anchor: "ABILITY",
      subFactor: "INTEGRITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTozMA==",
      content: "Practice transparency in your communication with colleagues, clients, and stakeholders.",
      anchor: "ABILITY",
      subFactor: "INTEGRITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTozMQ==",
      content: "Set learning goals to acquire new skills and knowledge to be equipped during adversities.",
      anchor: "ABILITY",
      subFactor: "LEARNABILITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTozMg==",
      content: "Look for alternative approaches of navigating through difficult situations.",
      anchor: "ABILITY",
      subFactor: "LEARNABILITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTozMw==",
      content: "Cultivate a growth mindset. This mindset can make you resilient during significant changes by reminding you that you have the capacity to adapt and acquire new skills as needed.",
      anchor: "ABILITY",
      subFactor: "LEARNABILITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTozNA==",
      content: "Explore practices and techniques that build grit and resilience, such as mindfulness, and mental toughness exercises.",
      anchor: "EXECUTION",
      subFactor: "PERSEVERANCE",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTozNQ==",
      content: "Track your journey and reflect on how far you've come.",
      anchor: "EXECUTION",
      subFactor: "PERSEVERANCE",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTozNg==",
      content: "Engage in self-care activities to de-stress.",
      anchor: "EXECUTION",
      subFactor: "PERSEVERANCE",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTozNw==",
      content: "Find alternatives to overcome challenging situations.",
      anchor: "EXECUTION",
      subFactor: "POSITIVITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTozOA==",
      content: "Practice gratitude by regularly reflecting on the positive aspects of your journey and the progress you've made.",
      anchor: "EXECUTION",
      subFactor: "POSITIVITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTozOQ==",
      content: "Acknowledge and celebrate small wins.",
      anchor: "EXECUTION",
      subFactor: "POSITIVITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo0MA==",
      content: "Anticipate potential scenarios and develop contingency plans. Being prepared for different outcomes can enhance your adaptability in uncertain situations.",
      anchor: "EXECUTION",
      subFactor: "ADAPTABILITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo0MQ==",
      content: "Learn the skill of flexing and embrace stepping out of the comfort zone to stay resilient.",
      anchor: "EXECUTION",
      subFactor: "ADAPTABILITY",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo0Mg==",
      content: "Develop your ability to understand and empathize with others. Take time to listen actively and show genuine interest in their concerns.",
      anchor: "SOCIALIMMUNITY",
      subFactor: "PEOPLEORIENTATION",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo0Mw==",
      content: "Begin by sharing smaller, less personal details about yourself before delving into more significant vulnerabilities.",
      anchor: "SOCIALIMMUNITY",
      subFactor: "PEOPLEORIENTATION",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo0NA==",
      content: "Include and accomodate people who have different styles of working.",
      anchor: "SOCIALIMMUNITY",
      subFactor: "PEOPLEORIENTATION",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo0NQ==",
      content: "Offering support and assistance to others in their times of need can boost your sense of purpose and strengthen your own resilience.",
      anchor: "SOCIALIMMUNITY",
      subFactor: "PEOPLEORIENTATION",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo0OQ==",
      content: "Nurture existing connections (like family, friends, colleagues, acquaintances) to keep them strong and supportive by checking in on them frequently.",
      anchor: "SOCIALIMMUNITY",
      subFactor: "PEOPLEORIENTATION",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo1MA==",
      content: "Encourage your team members to reflect on the personal and professional moments they are proud of. Help them identify the specific values and strengths they upheld during that proud moment.",
      anchor: "IDENTITY",
      subFactor: "VALUES",
      tipFor: "MANAGERS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo1MQ==",
      content: "Support your team members in finding strategies that will help them understadnd and flex their personality to be able to adapt to cope during situations better.",
      anchor: "IDENTITY",
      subFactor: "PERSONALITY",
      tipFor: "MANAGERS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo1Mw==",
      content: "Practice vulnerability by sharing your thoughts, feelings, and concerns with trusted friends or colleagues as it fosters deeper connections.",
      anchor: "SOCIALIMMUNITY",
      subFactor: "VULNERABILITYCOMFORT",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo1NA==",
      content: "Establish safe and nonjudgmental spaces where you and others can express vulnerabilities without fear of criticism or rejection.",
      anchor: "SOCIALIMMUNITY",
      subFactor: "VULNERABILITYCOMFORT",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "MAINTENACE"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo1NQ==",
      content: "Challenge negative thoughts logically and replace it with a more optimistic and constructive self-talk.",
      anchor: "IDENTITY",
      subFactor: "STRENGTHS",
      tipFor: "MANAGERS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo1Ng==",
      content: "Guide your team members to set realistic and achievable goals. Recognise and award them for their significant milestones.",
      anchor: "FUTUREORIENTATION",
      subFactor: "ACTION",
      tipFor: "MANAGERS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo1Nw==",
      content: "Ask your team members to visualise success to help them look forward to the evolving situations.",
      anchor: "FUTUREORIENTATION",
      subFactor: "COMMITMENT",
      tipFor: "MANAGERS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo1OA==",
      content: "Assisst your team member in finding and learning from a mentor.",
      anchor: "ABILITY",
      subFactor: "QUALITY",
      tipFor: "MANAGERS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo1OQ==",
      content: "Help your team members to overcome learning resistance by assigning them a variety of tasks.",
      anchor: "ABILITY",
      subFactor: "LEARNABILITY",
      tipFor: "MANAGERS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo2MA==",
      content: "Discuss with your team members what they'd have done differently previously to overcome a difficult situation.",
      anchor: "ABILITY",
      subFactor: "LEARNABILITY",
      tipFor: "MANAGERS",
      category: "DEVELOPING"
    }
  },
  {
    node: {
      id: "VGlwVHlwZTo2MQ==",
      content: "Maintain a journal of the challenging experiences you went through to identify how your values played a role in decision-making.",
      anchor: "IDENTITY",
      subFactor: "VALUES",
      tipFor: "INDIVIDUALCONTRIBUTORS",
      category: "DEVELOPING"
    }
  }
]