import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Col, Card, Row, Space, Spin, Progress, Form, Breadcrumb, Empty, Switch, Tooltip, Modal } from 'antd';

import { getStrokeColor } from '../../look';
import { CloseOutlined } from '@ant-design/icons';
import '../css/ResilienceAnchorsView.css'
import closeBtn from '../../assets/close_btn.png'

const FormItem = Form.Item;
const BreadcrumbItem = Breadcrumb.Item;

const ResilienceAnchorsView = props => {
  const { loading, resilienceAnchors, filter, onIncludingManagerChange, reportingTeamsResiliencePulseCheck } = props;

  const [visible, setVisible] = useState(null);
  const [errModal, setErrmodal] = useState({ isVisible: false, employeeName: '' })
  const [showClose, setShowClose] = useState(false)
  const [peopleAvg, setPeopleAvg] = useState()
  const [selectedFactor, setSelectedFactor] = useState()
  const [pageLoading, setPageLoading] = useState(false)
  const [selectedValidEmplo, setSelectedValidEmplo] = useState()


  const getFactor = (factor, employee = visible?.employee) =>
    resilienceAnchors[visible?.team].Employees[employee].socres[factor];

  function setFactor(factor) {
    setSelectedFactor(factor)
    calcAverages(factor)

    if (visible?.factor === factor)
      setVisible({
        ..._.omit(visible, ['factor']),
        anchorData: resilienceAnchors[visible?.team].Overall
      });
    else setVisible({ ...visible, factor, anchorData: getFactor(factor, visible.employee) });
  }

  useEffect(() => {
    if (resilienceAnchors) {

      setPageLoading(false)
    } else {
      setPageLoading(true)
    }
  }, [resilienceAnchors])

  function handlePaneClose() {
    setVisible(null)
    setShowClose(false)
  }
  function setEmployee(employee, factor) {
    if (resilienceAnchors) {
      let selectedValidEmp = resilienceAnchors[visible?.team]?.Employees
      const filteredEmpBySocres = Object.fromEntries(
        Object.entries(selectedValidEmp).filter(([key, value]) => value?.Message !== 'No Response found.'))
      const finalValidEmp = Object.keys(filteredEmpBySocres)[0]
      // console.log('resilienceAnchors?.length:', pageLoading, resilienceAnchors,employee,finalValidEmp, visible?.team)

      calcAverages(factor)
      if (resilienceAnchors[visible?.team].Employees[finalValidEmp].socres === undefined) {
        setErrmodal({ ...errModal, isVisible: true, employeeName: finalValidEmp })
      } else {
        // const factor = Object.keys(resilienceAnchors[visible?.team].Employees[employee].socres)[2];
        // if (visible?.employee === employee) setVisible(null);
        // else
        setVisible({
          ...visible,
          employee: finalValidEmp,
          factor,
          anchorData: getFactor(factor, finalValidEmp)
        });
      }
    }

  }

  function setTeam(team) {

    setShowClose(true)
    if (visible?.team === team) setVisible(null);
    else setVisible({ team, anchorData: resilienceAnchors[team].Overall });
  }

  function calcAverages(factor) {
    let data = Object.values(Object.values(resilienceAnchors)[0]?.Employees)?.filter(item => item?.Error === false)?.map(item => item?.socres[factor].factors)
    const numObjects = data.length;

    // Calculate the sum of each property
    const sum = data.reduce((acc, obj) => {
      Object.keys(obj).forEach(key => {
        acc[key] = (acc[key] || 0) + obj[key];
      });
      return acc;
    }, {});

    // Calculate the averages
    const averages = {};
    Object.keys(sum).forEach(key => {
      averages[key] = sum[key] / numObjects;
    });

    // Create a new array with the object containing average values
    const resultArray = [averages];

    // console.log(resultArray);
    // console.log('resilie3nce77Anchors', Object.values(Object.values(resilienceAnchors)[0]?.Employees)?.filter(item => item?.Error === false)?.map(item => item?.socres?.Ability?.factors), resultArray)
    setPeopleAvg(...resultArray)
  }
  console.log('resilience65Ancho9rs', resilienceAnchors)
  return (
    <Col lg={visible ? 24 : 24} md={24} xs={24}>
      <Modal
        footer={null}
        centered
        className="ckeditor-parent"
        visible={errModal?.isVisible}
        width={'100%'}
        destroyOnClose={true}
        onCancel={() => { setErrmodal({ ...errModal, isVisible: false, employeeName: '' }) }}
        style={{ padding: '2em' }}
      >
        <Row style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
          <p style={{ fontSize: '20px' }}>Oops! We can't show the resilience anchors as <span style={{ fontWeight: 'bold' }}>{errModal?.employeeName}</span> hasn't taken the survey yet.</p>
          <button style={{ width: '30%', alignSelf: 'center', height: '9%' }} onClick={() => setErrmodal({ ...errModal, isVisible: false, employeeName: '' })}>Close</button>
        </Row>
      </Modal>
      <Card
        style={{ display: 'grid', height: '100%' }}
        bodyStyle={{
          boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
          borderRadius: '2%'
          // paddingBottom: 0
        }}
        bordered={false}
      >
        <Spin style={{ display: 'flex' }} spinning={typeof resilienceAnchors !== 'object'}>

          <Row style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Col span={24}>
              <Col style={{ display: 'flex', flexDirection: 'column' }} gutter={24} /* align="middle" */>

                {/* <Col span={20}>
                      <Breadcrumb separator=">">
                        <BreadcrumbItem onClick={() => setFactor(visible?.factor)}>
                          <a>{visible?.team}</a>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{visible?.employee}</BreadcrumbItem>
                        <BreadcrumbItem>{visible?.factor}</BreadcrumbItem>
                      </Breadcrumb>
                      <br />
                    </Col> */}
                <Col span={visible ? 24 : 24}>
                  <div align="center">
                    <Row align="middle" >
                      <Col style={{ display: 'flex', justifyContent: `${visible ? 'flex-start' : 'center'}`, alignItems: 'center', marginBottom: '1.4em' }} span={visible ? 20 : 24}>
                        {visible ? <h2 style={{ fontWeight: 'normal', margin: '0 1em 0 0' }}>
                          {visible && visible?.team} {!visible && `My `} Team's Resilience Anchors
                        </h2> :
                          <h1 style={{ fontWeight: 'normal', margin: '0 1em 0 0' }}>
                            {visible && visible?.team} {!visible && `My `} Team's Resilience Anchors
                          </h1>
                        }
                        <Tooltip align='right' title={'Include Manager'}>
                          <Switch checked={filter.includingManager} onChange={value => onIncludingManagerChange(value)} />
                        </Tooltip>
                      </Col>
                      {visible && <Col span={visible ? 20 : 24}>
                        <p style={{ textAlign: 'left', marginBottom: '1.3em' }}>Choose a resilience anchor to see the team's average score for each factor!</p>
                      </Col>}
                      {showClose &&
                        <Col span={4} align="right" justify="right" style={{ position:'absolute', top:'0', right:'0' }}>
                          <div style={{ width: '1em', height: '1em' }} onClick={() => visible?.factor ? setFactor(visible?.factor) : handlePaneClose()}>
                            <CloseOutlined style={{ height: '100%', width: '100%', cursor: 'pointer',fontSize: "20px" }} src={closeBtn} />
                          </div>
                        </Col>}
                    </Row>
                    <Col span={24}>

                      {resilienceAnchors && Object.keys(resilienceAnchors)?.length ? (
                        Object.keys(resilienceAnchors)?.map((team, index) =>
                          team !== 'Error' && Object.keys(resilienceAnchors)?.length === 1 ? (
                            // list of teams
                            <Row>
                              <Col span={24} align="left">
                                <Row style={{ display: 'flex', margin: '0 0 1em 0', justifyContent: 'space-between', alignItems: 'center' }} justify="center">
                                  {!visible?.team ? (
                                    <HoverSpace size="middle" align="center" onClick={() => setTeam(team)}>
                                      <SequenceNo active={visible?.team === team}>{index + 1}</SequenceNo>

                                      <Colordiv active={visible?.team === team}>{team}</Colordiv>
                                    </HoverSpace>
                                  ) : visible?.factor ? (
                                    Object.keys(resilienceAnchors[visible?.team]?.Employees[visible?.employee]?.socres).map(
                                      // list of  factors
                                      (factor, index) => (
                                        <Col >
                                          <HoverSpace size="middle" align="center" onClick={() => setFactor(factor)}>
                                            <SequenceNo active={visible?.factor === factor}>{index + 1}</SequenceNo>

                                            <Colordiv active={visible?.factor === factor}>{factor}</Colordiv>
                                          </HoverSpace>
                                        </Col>
                                      )
                                    )
                                  ) : (
                                    // Object.keys(resilienceAnchors[visible?.team]?.Employees).map((employee, index) => (
                                    //   // list of employees
                                    //   <Col span={24}>
                                    //     <HoverSpace size="middle" align="center" onClick={() => setEmployee(employee)}>
                                    //       <SequenceNo active={visible?.employee === employee}>{index + 1}</SequenceNo>

                                    //       <Colordiv active={visible?.employee === employee}>{employee}</Colordiv>
                                    //     </HoverSpace>
                                    //   </Col>
                                    // ))
                                    <></>
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          ) : (
                            <>

                            </>
                          )
                        )
                      ) : (
                        <>

                        </>
                      )}
                    </Col>
                  </div>
                </Col>

                <Col span={visible ? 24 : 0}>
                  {visible &&
                    (!visible?.factor ? (
                      <Row justify={'space-between'}>
                        {/* <Col span={20}>
                      <Breadcrumb separator=">">
                        <BreadcrumbItem>{visible?.team}</BreadcrumbItem>
                      </Breadcrumb>
                      <br />
                    </Col> */}
                        {/* <Col span={4} align="right">
                      <CloseOutlined onClick={() => visible?.factor ? setFactor(visible?.factor) : setVisible(null)} />
                    </Col> */}

                        <Col span={24} style={{ minHeight: '25vh' }}>
                          <Row align={'middle'} style={{ minHeight: '40vh' }}>
                            <Col span={24}>
                              {Object.keys(visible?.anchorData)?.map(factor => (
                                <FormItem onClick={() => { setEmployee(selectedValidEmplo, factor) }} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
                                  <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-0.5em' }}>
                                    <p style={{ fontWeight: '600', cursor: 'pointer' }}>{factor}</p>
                                    <p style={{ fontWeight: '600', cursor: 'pointer' }}>{(visible?.anchorData[factor] * 10)?.toFixed(0)}%</p>
                                  </div>
                                  <Progress
                                    style={{ cursor: 'pointer' }}
                                    showInfo={false}
                                    className='horizontal_prog'
                                    percent={(visible?.anchorData[factor] * 10)?.toFixed(0)}
                                    strokeColor={getStrokeColor(visible?.anchorData[factor]?.toFixed(2) || 0)}
                                  />
                                </FormItem>
                              ))}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <Row justify={'space-between'}>
                        {/* <Col span={20}>
                      <Breadcrumb separator=">">
                        <BreadcrumbItem onClick={() => setFactor(visible?.factor)}>
                          <a>{visible?.team}</a>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{visible?.employee}</BreadcrumbItem>
                        <BreadcrumbItem>{visible?.factor}</BreadcrumbItem>
                      </Breadcrumb>
                      <br />
                    </Col> */}
                        {/* <Col span={4} align="right">
                      <CloseOutlined onClick={() => setFactor(visible?.factor)} />
                    </Col> */}

                        <Col span={24} style={{ minHeight: '25vh' }}>
                          <Row align={'middle'} style={{ minHeight: 'fit-content' }}>
                            <Col span={24} >
                              {visible?.factor && peopleAvg &&
                                Object.keys(visible?.anchorData?.factors)?.map(factor => (
                                  <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
                                    <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-0.5em' }}>
                                      <p style={{ fontWeight: '600' }}>{factor}</p>
                                      <p style={{ fontWeight: '600' }}>{(peopleAvg[factor] * 10).toFixed(0)}%</p>
                                    </div>
                                    <Progress
                                      showInfo={false}
                                      className='horizontal_prog'
                                      percent={(peopleAvg[factor] * 10).toFixed(0)}

                                      strokeColor={getStrokeColor((peopleAvg[factor]).toFixed(2) || 0)}
                                    />
                                  </FormItem>
                                ))}
                            </Col>
                            {/* <Col span={8}>
                              <Progress
                                type="circle"
                                percent={(visible?.anchorData?.overall * 10).toFixed(0)}
                                strokeColor="#B0CB1F"
                                strokeWidth="10"
                              />
                            </Col> */}
                          </Row>
                        </Col>
                      </Row>
                    ))}
                </Col>

              </Col>
            </Col>
            {visible &&
              <Col style={{ height: 'fit-content', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)", borderRadius: '0.4em', padding: '1.5em 1em' }} span={`${visible ? 24 : 0}`}>
                {visible &&
                  <Col span={23}>
                    <p style={{ fontWeight: '600' }}>Reporting Teams Resilience Pulse  check</p>
                    <Progress
                      className='team_pulse_bar'
                      style={{ width: '100%', height: '3em', position: 'relative' }}
                      format={percent => percent / 10 + '%'}
                      percent={
                        (reportingTeamsResiliencePulseCheck && JSON.parse(reportingTeamsResiliencePulseCheck)?.overall?.toFixed(2) * 10) || 0
                      }
                      strokeColor={getStrokeColor(
                        (reportingTeamsResiliencePulseCheck && JSON.parse(reportingTeamsResiliencePulseCheck)?.overall?.toFixed(2)) || 0
                      )}
                    />
                  </Col>
                }
              </Col>}
          </Row>
        </Spin>
      </Card>
    </Col>
  );
};

export default ResilienceAnchorsView;

const SequenceNo = styled.div`
  border-radius: 50%;
  width: 28px;
  color: ${props => (props.active ? '#B0CB1F' : '#222')};
  height: 28px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  border: 1px solid #cacaca;
`;

const HoverSpace = styled(Space)`
  margin-bottom: 10px;
  &:hover {
    color: PRIMARY_CLR;
    cursor: pointer;
  }
`;
const Colordiv = styled.div`
  color: ${props => (props.active ? '#B0CB1F' : '#222')};
`;
