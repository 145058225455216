import React from 'react';
import { Form, Row, Col, DatePicker, TimePicker } from 'antd';
import moment from 'moment-timezone';
import { getMomentUserTimezoneWithFormat, getMomentUtcToUserTimezone } from '../../look';
// import calender from '../../assets/Calendar-survey-calender.svg'
const FormItem = Form.Item;

const SurveyDatePickerField = props => {
  const { margin, type, offset = 1, enabledisabledDate, ...rest } = props;
  const [val,setval] = React.useState(undefined)
React.useEffect(() => {
  if (props?.value){ 
  setval(getMomentUtcToUserTimezone(props.value))
  }
}, [props.value])  

  let today=moment().subtract(18, 'year');
  function disabledDate(current) {
    let customDate = new Date();
    return !enabledisabledDate ? false : current && current < moment(customDate, 'YYYY-MM-DD');
  }

  function handleChangeToUtc(value) {
    //Vals to be submitted to backend must be in utc
    if (typeof (props.onChange) === 'function') {
      setval(value)
      console.log(value);
      props.onChange(value ? moment.utc(value) : value)
    }
  }

  const tzAwareTimeFormat = () => {
    if (!val) {
      return ""
    }
    if (props.format !== undefined) {
      return `${getMomentUserTimezoneWithFormat(val, props.format)}`;
    }
    return `${getMomentUserTimezoneWithFormat(val, "HH:mm")}`;
  }


  return (
    <div style={{ marginBottom: margin ? '5vh' : '' ,maxWidth:"290px"}}>
      <Row className='survey-custon-datepicker' >
        <Col span={margin ? 14 : 24} style={{position:"relative"}}>
          <FormItem  {...rest}>
            {type === 'time' ? (
              <TimePicker className='generic-survey-date-picker-custom' style={{ width: '100%', height: '40px' }} {...props} onChange={handleChangeToUtc} format={tzAwareTimeFormat}/>
            ) :type === 'month' ?(
              <DatePicker  
              className='generic-survey-date-picker-custom'
              dropdownClassName = {'generic-survey-date-picker-date'}  
              format={'MMMM'}
               picker="month"
              //  disabledDate={current=>current.isBefore(moment().subtract(1,"day"))}
               style={{ width: '100%', height: '40px' }}
               onChange={handleChangeToUtc} />
            ):type === 'week' ?(
              <DatePicker  
              className='generic-survey-date-picker-custom'
              dropdownClassName = {'generic-survey-date-picker-date'}  
              // format={'MMMM'}
               picker="week"
              //  disabledDate={current=>current.isBefore(moment().subtract(1,"day"))}
               style={{ width: '100%', height: '40px' }}
               onChange={handleChangeToUtc} />
            ): (
              <DatePicker
                className='generic-survey-date-picker-custom'
                dropdownClassName = {'generic-survey-date-picker-date'}
                style={{ width: '100%', height: '40px' }}
                format={'DD/MM/YYYY'}
                // disabledDate={current=>current.isBefore(moment().subtract(1,"day"))}
                {...props}
                showTime={false}
                onChange={handleChangeToUtc}
              />
            )}
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

export default SurveyDatePickerField;
