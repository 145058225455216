import React from 'react';
import _ from 'lodash';
import { Col, Space, Tooltip, Modal } from 'antd';

import { getMarks, ReactMarkdown, readableTime, RenderField } from '../../../look';
import { InfoCircleOutlined } from '@ant-design/icons';

const RenderSimpleReferenceField = ({
  type,
  inputType,
  label,
  helpText,
  id,
  value,
  question,
  simplefieldchoiceSet,
  responseDates,
  form,
  ...rest
}) => {
  const [helptext, setHelptext] = React.useState(null);

  return (
    <>
      {type === 'simplefield' ? (
        <Col span={24}>
          <RenderField
            inputType={inputType}
            id={id}
            label={
              <Space align="start">
                <ReactMarkdown>{label}</ReactMarkdown>
                {helpText && (
                  <Tooltip title={'Help Text'}>
                    <InfoCircleOutlined onClick={() => setHelptext(helpText)} />
                  </Tooltip>
                )}
                {value && value !== '' && Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[3]) !== '' && (
                  <span style={{ color: '#999' }}>{new Date(responseDates()[id])?.toDateString() || ''}</span>
                )}
              </Space>
            }
            choices={simplefieldchoiceSet.edges
              .map(({ node }) => node)
              .map(c => ({
                label: (
                  <Space align="baseline">
                    {c?.label}
                    <ReactMarkdown>{c?.graphic}</ReactMarkdown>
                  </Space>
                ),
                value: c?.value
              }))}
            value={value}
            marks={getMarks([
              Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[0]),
              Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[1])
            ])}
            min={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[0])}
            max={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[1])}
            step={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[2])}
            defaultValue={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[3])}
            onChange={e => form.setFieldsValue(_.set(form.getFieldsValue(true), [id], e))}
          />
        </Col>
      ) : (
        <Col span={24}>
          <RenderField
            inputType={question?.choiceSet?.edges[0]?.node?.inputType}
            id={id}
            label={
              <Space align="start">
                <ReactMarkdown>{question?.questionText}</ReactMarkdown>
                {helpText && (
                  <Tooltip title={'Help Text'}>
                    <InfoCircleOutlined onClick={() => setHelptext(helpText)} />
                  </Tooltip>
                )}
                {value && value !== '' && Number(question?.choiceSet?.edges[0]?.node?.value?.split(':')[3]) !== '' && (
                  <span style={{ color: '#999' }}>{new Date(responseDates()[id])?.toDateString() || ''}</span>
                )}
              </Space>
            }
            value={value}
            choices={question?.choiceSet.edges
              .map(({ node }) => node)
              .map(c => ({
                label: (
                  <Space align="baseline">
                    <ReactMarkdown>{c?.label}</ReactMarkdown>
                    <ReactMarkdown>{c?.graphic}</ReactMarkdown>
                  </Space>
                ),
                value: c?.value
              }))}
            marks={getMarks([
              Number(question?.choiceSet?.edges[0]?.node?.value.split(':')[0]),
              Number(question?.choiceSet?.edges[0]?.node?.value.split(':')[1])
            ])}
            min={Number(question?.choiceSet?.edges[0]?.node?.value.split(':')[0])}
            max={Number(question?.choiceSet?.edges[0]?.node?.value.split(':')[1])}
            step={Number(question?.choiceSet?.edges[0]?.node?.value.split(':')[2]) || 1}
            defaultValue={Number(question?.choiceSet?.edges[0]?.node?.value?.split(':')[3])}
            onChange={e => form.setFieldsValue(_.set(form.getFieldsValue(true), [id], e))}
          />
        </Col>
      )}
      <Modal centered visible={helptext} footer={null} onCancel={() => setHelptext(null)}>
        <ReactMarkdown>{helptext}</ReactMarkdown>
      </Modal>
    </>
  );
};

export default RenderSimpleReferenceField;
