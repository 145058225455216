import * as React from 'react';

import { compose } from '../../core';

import ForgotPasswordView from '../components/ForgotPasswordView';
import { withPasswordResetMail, withPasswordReset } from './UserOperations';

const ForgotPassword = props => {
  const { passwordResetMail, passwordReset } = props;
  const handleForgotPasswordMail = async email => {
    try {
      const response = await passwordResetMail(email);
      return response;
    } catch (e) {
      // throw new Error(e.message);
      return { success: false };
    }
  };

  const handleForgotPassword = async values => {
    try {
      const response = await passwordReset(values);

      return response;
    } catch (e) {
      // throw new Error(e.message);
      return { success: false };
    }
  };

  return (
    <ForgotPasswordView
      onForgotPasswordMailSubmit={handleForgotPasswordMail}
      onForgotPasswordSubmit={handleForgotPassword}
      {...props}
    />
  );
};

export default compose(withPasswordResetMail, withPasswordReset)(ForgotPassword);
