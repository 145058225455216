import React from 'react';
import _ from 'lodash';
import { Form, Row, Col, Button, Space } from 'antd';
import { SelectField, CKEditorField, getIntFromString, OperandAutoComplete } from '../../look';

import ConditionOperator from '../helpers/helpers';
import AddOperation from '../containers/AddOperation';

export const CKEditorFieldName = 'note';

const ConditionFormComponent = props => {
  const { btnText, condition, onSubmit, setVisible } = props;
  const [form] = Form.useForm();
  const [isRefetch, setIsRefetch] = React.useState(false);
  const initialValue = {
    leftOperandId: condition && getIntFromString(condition.leftOperand?.id),
    note: condition && condition.note,
    operator: condition && ConditionOperator.filter(c => condition.operator === c.value)[0]?.value,
    rightOperandId: condition && getIntFromString(condition.rightOperand?.id)
  };
  const handleAdd = (field, e) => {
    setIsRefetch(true);
    form.setFieldsValue(_.set(form.getFieldsValue(true), field, getIntFromString(e)));
  };
  return (
    <Form
      onFinish={onSubmit}
      form={form}
      layout="vertical"
      initialValues={initialValue}
      required={false}
      scrollToFirstError={true}
    >
      <Row gutter={[24, 24]}>
        <Col span={23}>
          {/* <QuestionAutoComplete
            // {...layout}
            name="leftOperandId"
            // margin={false}
            offset={1}
            label="Question"
            // onAdd={"add"}
            // onEdit={"edit"}
            rules={[{ required: true, message: 'Question is required!' }]}
          /> */}

          <Row justify="space-between" align="middle">
            <Col span={23}>
              <OperandAutoComplete
                // {...layout}
                name="leftOperandId"
                isRefetch={isRefetch}
                setIsRefetch={setIsRefetch}
                // margin={false}
                offset={1}
                label="Right Operated"
                // onAdd={"add"}
                // onEdit={"edit"}
                rules={[{ required: true, message: 'Question is required!' }]}
              />
            </Col>
            <Col span={1}>
              <Row justify="end">
                <AddOperation btn={'add'} btnText={'Add Operation'} handleAdd={e => handleAdd('leftOperandId', e)} />
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <SelectField
            style={{ width: '100%' }}
            // margin={false}
            offset={2}
            name="operator"
            label="Operator"
            rules={[{ required: true, message: 'Operator is required!' }]}
            choices={ConditionOperator}
          />
        </Col>

        <Col span={11}>
          {/* <TextField
            type="number"
            // {...layout}
            name="rightOperand"
            offset={2}
            label="Right Operand"
            rules={[
              { required: true, message: 'Value is required' },
              { type: 'number', message: 'Value should be a number.' }
            ]}
          /> */}

          <Row justify="space-between" align="middle">
            <Col span={22}>
              <OperandAutoComplete
                // {...layout}
                name="rightOperandId"
                // margin={false}
                isRefetch={isRefetch}
                setIsRefetch={setIsRefetch}
                offset={1}
                label="Right Operand"
                // onAdd={"add"}
                // onEdit={"edit"}
                rules={[{ required: true, message: 'Value is required' }]}
              />{' '}
            </Col>
            <Col span={2}>
              <Row justify="end">
                <AddOperation btn={'add'} btnText={'Add Operation'} handleAdd={e => handleAdd('rightOperandId', e)} />
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={23}>
          <CKEditorField
            name={CKEditorFieldName}
            label="Note"
            initialValue={condition && condition.note}
            // rules={[{ required: true, message: 'All questions are required!' }]}
          />
        </Col>
      </Row>

      <Row justify="space-between">
        <Col>
          <Space>
            <Button htmlType="submit" type="primary">
              {btnText}
            </Button>
          </Space>
        </Col>
        <Col>
          <Button ghost type="primary" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ConditionFormComponent;
