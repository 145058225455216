import gql from 'graphql-tag';
import { COACHING_COMMITMENT_TYPE } from './CoachingCommitmentType.gql';

export const ADD_COACHING_COMMITMENT = gql`
  ${COACHING_COMMITMENT_TYPE}
  mutation addCoachingCommitment($coachingCommitmentData: CoachingCommitmentInput!) {
    createCoachingCommitment(coachingCommitmentData: $coachingCommitmentData) {
      coachingCommitment {
        ...CoachingCommitmentType
      }
    }
  }
`;
