import { graphql } from '@apollo/client/react/hoc';
import { message } from 'antd';

import { removeTypename } from '../../core/clientStorage';
import { withOperation } from '../../look';

import storyResolvers from '../resolvers';

// Query
import { ALL_PUBLISHED_STORIES_QUERY } from '../graphql/AllPublishedStories.gql';
import { ALL_STORY_TAGS_QUERY } from '../graphql/AllStoryTags.gql';
import { ALL_STORIES_QUERY } from '../graphql/AllStories.gql';
import { ALL_COMMENTS_FOR_STORY_BY_ID_QUERY } from '../graphql/AllCommentsForStoryByStoryId.gql';
import { PUBLISHED_STORY_BY_ID_QUERY } from '../graphql/PublishedStoryById.gql';
import { STORY_BY_ID_QUERY } from '../graphql/StoryById.gql';
import { TAG_BY_ID_QUERY } from '../graphql/TagById.gql';

// Mutation
import { ADD_STORY } from '../graphql/AddStory.gql';
import { ADD_TAG } from '../graphql/AddTag.gql';
import { ADD_COMMENT } from '../graphql/AddComment.gql';
import { LIKE_STORY } from '../graphql/LikeStory.gql';
import { EDIT_STORY } from '../graphql/EditStory.gql';
import { EDIT_TAG } from '../graphql/EditTag.gql';
import { DELETE_STORY } from '../graphql/DeleteStory.gql';
import { DELETE_TAG } from '../graphql/DeleteTag.gql';
import { DELETE_COMMENT } from '../graphql/DeleteComment.gql';
import { DELETE_USER_COMMENT } from '../graphql/DeleteUserComment.gql';

// Client
import { STORY_STATE_QUERY } from '../graphql/StoryStateQuery.client.gql';
import { UPDATE_STORY_FILTER } from '../graphql/UpdateStoryFilter.client.gql';

// Query
export const withAllPublishedStories = Component =>
  graphql(ALL_PUBLISHED_STORIES_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allPublishedStories, subscribeToMore, updateQuery } = data;
      return { loading, error, allPublishedStories, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllStoryTags = Component =>
  graphql(ALL_STORY_TAGS_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allStoryTags, subscribeToMore, updateQuery } = data;
      return { loading, error, allStoryTags, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllStories = Component =>
  graphql(ALL_STORIES_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allStories, subscribeToMore, updateQuery } = data;
      return { loading, error, allStories, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllCommentsForStoryByStoryId = Component =>
  graphql(ALL_COMMENTS_FOR_STORY_BY_ID_QUERY, {
    options: ({ match, navigation, filter, orderBy, pagination }) => {
      let id = '';
      if (match) {
        id = match.params.id;
      } else if (navigation) {
        id = navigation.state.params.id;
      }

      return {
        variables: {
          storyId: Number(id),
          ...pagination,
          ...filter,
          orderBy
        }
      };
    },
    props({ data }) {
      const { loading, error, allCommentsForStoryByStoryId, subscribeToMore, updateQuery } = data;
      return { loading, error, allCommentsForStoryByStoryId, subscribeToMore, updateQuery };
    }
  })(Component);

export const withPublishedStoryById = Component =>
  graphql(PUBLISHED_STORY_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, publishedStoryById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, publishedStoryById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withTagById = Component =>
  graphql(TAG_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { tagId: Number(id) }
      };
    },
    props({ data: { loading, error, tagById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, tagById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withStoryById = Component =>
  graphql(STORY_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, storyById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, storyById, subscribeToMore, updateQuery };
    }
  })(Component);

// Mutation
export const withAddStory = Component =>
  withOperation({
    mutation: ADD_STORY,
    funcName: 'addStory',
    query: ALL_STORIES_QUERY,
    queryName: 'allStories',
    node: ['createStory', 'story'],
    type: 'add'
  })(Component);

export const withAddTag = Component =>
  withOperation({
    mutation: ADD_TAG,
    funcName: 'addTag',
    query: ALL_STORY_TAGS_QUERY,
    queryName: 'allStoryTags',
    node: ['createTag', 'tag'],
    type: 'add'
  })(Component);

export const withAddComment = Component =>
  withOperation({
    mutation: ADD_COMMENT,
    funcName: 'addComment',
    query: ALL_COMMENTS_FOR_STORY_BY_ID_QUERY,
    queryName: 'allCommentsForStoryByStoryId',
    node: ['createComment', 'comment'],
    variable: { type: 'all', varName: 'storyId' },
    type: 'add'
  })(Component);

export const withLikeStory = Component =>
  graphql(LIKE_STORY, {
    props: ({ mutate }) => ({
      likeStory: async id => {
        try {
          const {
            data: { likeStory }
          } = await mutate({
            variables: {
              id
            }
          });
          return likeStory.story;
        } catch (e) {
          if (e.message.includes('Duplicate entry')) {
            message.error('Comment already exists!');
          }
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditStory = Component =>
  graphql(EDIT_STORY, {
    props: ({ mutate }) => ({
      editStory: async values => {
        try {
          const {
            data: { updateStory }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateStory.story;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditTag = Component =>
  graphql(EDIT_TAG, {
    props: ({ mutate }) => ({
      editTag: async values => {
        try {
          const {
            data: { updateTag }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateTag.tag;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withDeleteStory = Component =>
  withOperation({
    mutation: DELETE_STORY,
    mutationVarName: 'id',
    funcName: 'deleteStory',
    query: ALL_STORIES_QUERY,
    queryName: 'allStories',
    node: ['deleteStory', 'story'],
    type: 'delete'
  })(Component);

export const withDeleteTag = Component =>
  withOperation({
    mutation: DELETE_TAG,
    mutationVarName: 'id',
    funcName: 'deleteTag',
    query: ALL_STORY_TAGS_QUERY,
    queryName: 'allStoryTags',
    node: ['deleteTag', 'tag'],
    type: 'delete'
  })(Component);

export const withDeleteComment = Component =>
  withOperation({
    mutation: DELETE_COMMENT,
    mutationVarName: 'commentId',
    funcName: 'deleteComment',
    query: ALL_COMMENTS_FOR_STORY_BY_ID_QUERY,
    queryName: 'allCommentsForStoryByStoryId',
    node: ['deleteComment', 'comment'],
    variable: { type: 'all', varName: 'storyId' },
    type: 'delete'
  })(Component);

export const withDeleteUserComment = Component =>
  withOperation({
    mutation: DELETE_USER_COMMENT,
    mutationVarName: 'commentId',
    funcName: 'deleteUserComment',
    query: ALL_COMMENTS_FOR_STORY_BY_ID_QUERY,
    queryName: 'allCommentsForStoryByStoryId',
    node: ['deleteUserComment', 'comment'],
    variable: { type: 'all', varName: 'storyId' },
    type: 'delete'
  })(Component);

export const withStoryState = Component =>
  graphql(STORY_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.storyState;
      const storyState = { ...removeTypename(rest), orderBy };
      return { ...storyState, stateLoading: data.loading };
    }
  })(Component);

export const withStoryFilterUpdating = Component =>
  graphql(UPDATE_STORY_FILTER, {
    props: ({ mutate }) => ({
      onOrderByChange(orderBy) {
        mutate({ variables: { orderBy } });
      },
      onPaginationChange(pagination) {
        mutate({ variables: { pagination } });
      },
      onFiltersRemove() {
        mutate({ variables: { ...storyResolvers.defaults.storyState } });
      },
      onTitleChange(title_Icontains) {
        mutate({ variables: { filter: { title_Icontains } } });
      },
      onContentChange(content_Icontains) {
        mutate({ variables: { filter: { content_Icontains } } });
      },
      onNameChange(name_Icontains) {
        mutate({ variables: { filter: { name_Icontains } } });
      },
      onDescriptionChange(description_Icontains) {
        mutate({ variables: { filter: { description_Icontains } } });
      },
      onCommentTextChange(commentText_Icontains) {
        mutate({ variables: { filter: { commentText_Icontains } } });
      },
      onUserChange(user) {
        mutate({ variables: { filter: { user } } });
      },
      onAuthorChange(author) {
        mutate({ variables: { filter: { author } } });
      }
    })
  })(Component);
