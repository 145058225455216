import gql from 'graphql-tag';

export const GET_USER_BY_USERNAME_OR_EMAIL_QUERY = gql`
  query getUserByUsernameOrEmail($email: String, $username: String) {
    getUserByUsernameOrEmail(email: $email, username: $username) {
      id
      firstName
      lastName
      username
      email
    }
  }
`;
