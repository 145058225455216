import gql from 'graphql-tag';
import { WEIGHTED_QUESTION_TYPE } from './WeightedQuestionType.gql';

export const ADD_WEIGHTED_QUESTION_MUTATION = gql`
  ${WEIGHTED_QUESTION_TYPE}
  mutation addWeightedQuestion($weightedQuestionData: WeightedQuestionInput!) {
    createWeightedQuestion(weightedQuestionData: $weightedQuestionData) {
      weightedQuestion {
        ...WeightedQuestionType
      }
    }
  }
`;
