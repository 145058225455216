import React from 'react';
import moment from 'moment';
import { graphql } from '@apollo/client/react/hoc';
import update from 'immutability-helper';
import _ from 'lodash';
import { Button, Input, Space, message } from 'antd';
import { SearchOutlined, UndoOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
// import showdown from 'showdown';
import gql from 'graphql-tag';

import { EMAIL_PERCEPTION_REPORT_QUERY } from '../../survey/graphql/EmailPerceptionReport.gql';
import { EMAIL_PREDICTION_REPORT_QUERY } from '../../survey/graphql/EmailPredictionReport.gql';
import { EMAIL_PERCEPTION_REPORT_TO_ADMIN_QUERY } from '../../survey/graphql/EmailPerceptionReportToAdmin.gql';
import { EMAIL_PREDICTION_REPORT_TO_ADMIN_QUERY } from '../../survey/graphql/EmailPredictionReportToAdmin.gql';
import {
  CheckBoxField,
  SelectField,
  OrganizationAutoComplete,
  SubverticalAutoComplete,
  SurveyAutoComplete,
  UserAutoComplete
} from '..';

const { Search } = Input;
// const converter = new showdown.Converter();

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()];
}

export const readableTime = secs => {
  secs = Math.round(secs);
  var hours = Math.floor(secs / (60 * 60));

  var divisor_for_minutes = secs % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);

  var divisor_for_seconds = divisor_for_minutes % 60;
  var seconds = Math.ceil(divisor_for_seconds);

  var obj = {
    h: hours,
    m: minutes,
    s: seconds
  };
  return hours === 0
    ? minutes === 0
      ? `${seconds} secs`
      : `${minutes} mins ${seconds} secs`
    : `${hours} hr ${minutes} mins ${seconds} secs`;
};

export const displayDataCheck = (text, bool = false) => {
  if (bool) {
    return text ? 'True' : 'False';
  }
  return text ? text : ' ';
};

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const displayBoolean = bool => {
  if (bool) {
    return <CheckCircleFilled style={{ color: '#009fff', margin: '2%', fontSize: 'large' }} />;
  } else return <CloseCircleFilled style={{ color: '#ff4d4f', margin: '2%', fontSize: 'large' }} />;
};

export function getIntFromString(base64String) {
  if (typeof base64String === 'string' && base64String.length % 4 === 0) {
    try {
      return Number(window.atob(base64String).split(':')[1]);
    } catch (error) {
      console.error('Error decoding base64 string:', error);
      return null; // Handle decoding error gracefully
    }
  } else {
    return base64String;
  }
}

export function getTypeFromBase64String(base64String) {
  return typeof base64String === 'string' ? window.atob(base64String).split(':')[0] : base64String;
}

export function getBase64StringFromInt(string, int) {
  return window.btoa(`${string}:${int}`);
}

export function getCursorFromInt(int) {
  return getBase64StringFromInt('arrayconnection', int);
}

export function getOrganizationBase64StringFromInt(int) {
  return getBase64StringFromInt('OrganizationType', int);
}

export function getVerticalBase64StringFromInt(int) {
  return getBase64StringFromInt('VerticalType', int);
}

export function getTeamBase64StringFromInt(int) {
  return getBase64StringFromInt('TeamType', int);
}

export function getEmployeeBase64StringFromInt(int) {
  return getBase64StringFromInt('EmployeeType', int);
}

export function getSurveyBase64StringFromInt(int) {
  return getBase64StringFromInt('SurveyType', int);
}

export function getResponsesBase64StringFromInt(int) {
  return getBase64StringFromInt('ResponseType', int);
}

export function getContactBase64StringFromInt(int) {
  return getBase64StringFromInt('ContactType', int);
}

export function getUserBase64StringFromInt(int) {
  return getBase64StringFromInt('UserType', int);
}

export function getStoryBase64StringFromInt(int) {
  return getBase64StringFromInt('StoryType', int);
}

export function getSurveyTokenBase64StringFromInt(int) {
  return getBase64StringFromInt('DetailedTokenListTypeEdge', int);
}

export function getQuestionBase64StringFromInt(int) {
  return getBase64StringFromInt('QuestionType', int);
}

export function getFaqCategoriesBase64StringFromInt(int) {
  return getBase64StringFromInt('FAQCategoryType', int);
}

export function getTagBase64StringFromInt(int) {
  return getBase64StringFromInt('TagType', int);
}

export function withMarkdownFieldValue(field, values) {
  // return _.set(values, field, converter.makeMarkdown(_.get(values, field)?.editor?.getData() || '')); // convert to markdown
  return _.set(values, field, _.get(values, field)?.editor?.getData() || '');
}

export function withHTMLFieldValue(value) {
  // return converter.makeHtml(value);
  return value;
}

export const GetColumnSearchProps = (dataIndex, searchFunc, type) => {
  const searchInput = React.useRef(null);
  function filterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
    let field;
    switch (type) {
      case 'user':
        field = (
          <Space size={0}>
            <UserAutoComplete
              margin={false}
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              onChange={userId => searchFunc(getUserBase64StringFromInt(userId))}
              size="small"
              enterButton
            />
            <Button
              type="primary"
              size="small"
              icon={<UndoOutlined />}
              onClick={() => {
                searchFunc('');
                clearFilters();
              }}
            />
          </Space>
        );
        break;
      case 'survey':
        field = (
          <Space size={0}>
            <SurveyAutoComplete
              margin={false}
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              onChange={surveyId => searchFunc(getSurveyBase64StringFromInt(surveyId))}
              size="small"
              enterButton
            />
            <Button
              type="primary"
              size="small"
              icon={<UndoOutlined />}
              onClick={() => {
                searchFunc('');
                clearFilters();
              }}
            />
          </Space>
        );
        break;
      case 'organization':
        field = (
          <Space size={0}>
            <OrganizationAutoComplete
              margin={false}
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              onChange={organizationId => searchFunc(getOrganizationBase64StringFromInt(organizationId))}
              size="small"
              enterButton
            />
            <Button
              type="primary"
              size="small"
              icon={<UndoOutlined />}
              onClick={() => {
                searchFunc('');
                clearFilters();
              }}
            />
          </Space>
        );
        break;
      case 'subvertical':
        field = (
          <Space size={0}>
            <SubverticalAutoComplete
              margin={false}
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              onChange={organizationId => searchFunc(getOrganizationBase64StringFromInt(organizationId))}
              size="small"
              enterButton
            />
            <Button
              type="primary"
              size="small"
              icon={<UndoOutlined />}
              onClick={() => {
                searchFunc('');
                clearFilters();
              }}
            />
          </Space>
        );
        break;
      case 'binary':
        field = (
          <CheckBoxField
            label={_.upperFirst(_.camelCase(dataIndex))}
            defaultChecked={true}
            margin={false}
            onChange={e => searchFunc(e.target.checked)}
          />
        );
        break;
      case 'ternary':
        field = (
          <SelectField
            // label={_.upperFirst(_.camelCase(dataIndex))}
            style={{ width: 100, marginBottom: 0 }}
            margin={false}
            onChange={searchFunc}
            size="small"
            choices={[
              { value: '', label: 'All' },
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
          />
        );
        break;
      default:
        field = (
          <Space size={0}>
            <Search
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              onSearch={searchText => {
                searchFunc(searchText);
                confirm({ closeDropdown: true });
              }}
              size="small"
              // loading={loading}
              enterButton
            />
            <Button
              type="primary"
              size="small"
              icon={<UndoOutlined />}
              onClick={() => {
                searchFunc('');
                clearFilters();
              }}
            />
          </Space>
        );
    }
    return <div style={{ padding: 8 }}>{field}</div>;
  }

  return (
    searchFunc && {
      filterDropdown,
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilterDropdownVisibleChange: visible => {
        if (visible && !type) {
          setTimeout(() => searchInput.current.select(), 100);
        }
      }
    }
  );
};

export function getOrderBy(name, orderBy) {
  return orderBy?.indexOf(name) !== -1 ? [`-${name}`] : [name];
}

export const generateReport = async (id, surveId, client) => {
  try {
    if (Number(surveId) === 1) {
      client.query({
        query: EMAIL_PERCEPTION_REPORT_QUERY,
        variables: { responseId: id }
      });
    } else if (Number(surveId) === 2) {
      client.query({
        query: EMAIL_PREDICTION_REPORT_QUERY,
        variables: { responseId: id }
      });
    }
  } catch (e) {
    console.error(e.message);
    // throw new Error(e.message);
  }
};

export const generateReportDataForRequestedUsersSurvey = async (mutation, variables, client) => {
  try {
    if (mutation) {
      const { data } = await client.query({
        query: gql`
        query ${mutation}($requestId: ID, $requestedUserId: ID) {
            ${mutation}(requestId: $requestId, requestedUserId: $requestedUserId)
          }
        `,
        variables
      });
      return data[mutation] && JSON.parse(data[mutation]);
    }
  } catch (e) {
    console.error(e.message);
    // throw new Error(e.message);
  }
};

export const generateReportDataForRequestedUsersIdSurvey = async (isVariables, mutation, variables, client) => {
  try {
    if (mutation) {
      if (isVariables) {
        const { data } = await client.query({
          query: gql`
           query ${mutation}($requestId: ID, $requestedUserId: ID) {
            ${mutation}(requestId: $requestId, requestedUserId: $requestedUserId)
            }
          `,
          variables
        });
        return data[mutation] && JSON.parse(data[mutation]);
      } else {
        const { data } = await client.query({
          query: gql`
          query ${mutation}($responseId: ID!) {
              ${mutation}(responseId: $responseId)
            }
          `,
          variables
        });
        return data[mutation] && JSON.parse(data[mutation]);
      }
    }
  } catch (e) {
    console.error(e.message);
    // throw new Error(e.message);
  }
};

export async function handleGetReport(surveyId, client, variables, responseId) {
  let mutation;
  let usermutaion;
  switch (surveyId) {
    case 1: //'Workplace Resilience Perception Survey'
      mutation = 'getReportDataForRequestedUsersPerceptionSurvey';
      usermutaion = 'getPerceptionReportDataForUserByResponseId';
      break;
    case 2: //'Workplace Resilience Prediction Survey'
      mutation = 'getReportDataForRequestedUsersPredictionSurvey';
      usermutaion = 'getPredictionReportDataForUserByResponseId';
      break;
    default:
      usermutaion = false;
  }

  const report = await generateReportDataForRequestedUsersIdSurvey(
    variables,
    variables ? mutation : usermutaion,
    variables ? variables : { responseId },
    client
  );
  if (report) {
    return { report, success: true };
  } else {
    return { errors: 'Sorry! Comparison report for this survey does not exist.', sucess: false };
  }
}

export const generateAdminReport = async (id, surveId, userId, client) => {
  try {
    if (Number(surveId) === 1) {
      client.query({
        query: EMAIL_PERCEPTION_REPORT_TO_ADMIN_QUERY,
        variables: { responseId: id, userId }
      });
    } else if (Number(surveId) === 2) {
      client.query({
        query: EMAIL_PREDICTION_REPORT_TO_ADMIN_QUERY,
        variables: { responseId: id, userId }
      });
    }
  } catch (e) {
    console.error(e.message);
    // throw new Error(e.message);
  }
};

export const operationUpdate = (updateArgs, addInfo) => {
  const {
    cache,
    mutationResult: { data }
  } = updateArgs;
  const {
    query,
    ownProps: { pagination, filter, orderBy, navigation, match, ...rest },
    getFunc,
    node,
    variable
  } = addInfo;
  let id = '';
  if (match) {
    id = match.params.id;
  } else if (navigation) {
    id = navigation.state.params.id;
  }
  const variables = variable
    ? variable.type === 'all'
      ? variable.custom
        ? _.set(
            _.omit(
              {
                ...pagination,
                ...filter,
                orderBy
              },
              variable.omit
            ),
            variable.varName,
            Number(id) || _.get(rest, variable.varName)
          )
        : _.set(
            {
              ...pagination,
              ...filter,
              orderBy
            },
            variable.varName,
            Number(id) || _.get(rest, variable.varName)
          )
      : _.set({}, variable.varName, Number(id) || _.get(rest, variable.varName))
    : orderBy
    ? {
        ...pagination,
        ...filter,
        orderBy
      }
    : {
        ...pagination,
        ...filter
      };
  // Get previous data from cache
  const queryArgs = {
    query,
    variables
  };
  // console.log('queryArgs', queryArgs);
  const prev = cache.readQuery(queryArgs);
  // console.log('queryArgs-prev', prev);
  // console.log('prev', prev);
  const updatedData = getFunc(prev, _.get(data, node));
  // console.log('updatedData', updatedData);
  // Write data to cache
  cache.writeQuery({
    ...queryArgs,
    data: {
      ...updatedData
    }
  });
};

// Mutation
export const withOperation = ({
  funcName,
  mutation,
  mutationVarName,
  query,
  queryName,
  node,
  type,
  variable,
  dataEdge = true
}) =>
  graphql(mutation, {
    props: ({ mutate, ownProps }) =>
      _.set({}, funcName, async values => {
        const variables = mutationVarName ? _.set({}, mutationVarName, values) : values;
        // console.log(variables);
        try {
          const { data } = await mutate({
            variables: {
              ...variables
            },

            update: (cache, mutationResult) =>
              operationUpdate(
                { cache, mutationResult },
                {
                  query,
                  ownProps,
                  getFunc: (prev, node) => {
                    switch (type) {
                      case 'add':
                        return onAdd(prev, node, queryName);
                      case 'edit':
                        return onEdit(prev, node, queryName, dataEdge);
                      case 'delete':
                        return onDelete(prev, node.id, queryName, dataEdge);
                      default:
                        console.log('Mutation type un-recognised!');
                    }
                  },
                  node,
                  variable
                }
              )
          });
          message.destroy();
          message.success('Success!');
          return _.get(data, node);
        } catch (e) {
          message.destroy();
          message.error('Failed!');
          console.error(e);
          throw new Error(e);
        }
      })
  });

// Subscription
export const subscribeToQuery = (
  subscribeToMore,
  { document, subscriptionName, nodeName, queryName, dataEdge = true, shouldSubscrbe },
  variables
) =>
  subscribeToMore({
    document,
    variables,
    updateQuery: (prev, { subscriptionData: { data } }) => {
      let newResult = prev;
      const subscriptionData = _.get(data, subscriptionName);
      const mutation = _.get(subscriptionData, 'mutation');
      const node = _.get(subscriptionData, nodeName);

      let path = queryName;
      const edgesIndex = queryName.indexOf('edges');
      if (edgesIndex !== -1) {
        const subEdgeIndex = _.get(prev, queryName.slice(0, edgesIndex + 1))
          .map(
            ({ node: subEdge }) =>
              _.get(subEdge, [...queryName.slice(edgesIndex + 1), 'edges']).filter(
                ({ node: subSubEdge }) => subSubEdge.id === node.id
              ).length > 0
          )
          .indexOf(true);
        path.splice(edgesIndex + 1, 0, String(subEdgeIndex));
        path.splice(edgesIndex + 2, 0, String('node'));
      }

      // console.log('bleh', mutation, node);
      if (shouldSubscrbe) {
        if (shouldSubscrbe(node)) {
          if (mutation === 'CREATE') {
            newResult = onAdd(prev, node, path);
          } else if (mutation === 'UPDATE') {
            newResult = onEdit(prev, node, path, dataEdge);
          } else if (mutation === 'DELETE') {
            newResult = onDelete(prev, node.id, path, dataEdge);
          }
          return newResult;
        }
      } else {
        if (mutation === 'CREATE') {
          newResult = onAdd(prev, node, path);
        } else if (mutation === 'UPDATE') {
          newResult = onEdit(prev, node, path, dataEdge);
        } else if (mutation === 'DELETE') {
          newResult = onDelete(prev, node.id, path, dataEdge);
        }
        return newResult;
      }
    }
  });

export function onAdd(prev, node, dataIndex) {
  // console.log('prev', prev, node);
  const data = _.get(prev, dataIndex);
  // console.log(prev, dataIndex, data);
  if (data.edges?.some(dataEdge => node.id === dataEdge.cursor)) {
    const updatedData = _.set({}, dataIndex, {
      edgeCount: {
        $set: data.edges.length
      },
      edges: {
        $set: data.edges
      }
    });
    return update(prev, updatedData);
  }

  const filteredData = data.edges.filter(dataEdge => dataEdge.node.id !== node.id);

  const dataEdge = {
    cursor: getCursorFromInt(getIntFromString(node.id)),
    node,
    __typename: (data.edges[0] && data.edges[0].__typename) || ''
  };

  data?.pageInfo?.hasNextPage && filteredData.pop(); // to remove last element from array.
  const updatedData = _.set({}, dataIndex, {
    edgeCount: {
      $set: [dataEdge, ...filteredData].length
    },
    edges: {
      $set: [dataEdge, ...filteredData]
    }
  });
  return update(prev, updatedData);
}

export function onEdit(prev, node, dataIndex, edge) {
  // console.log('prev', prev, node, dataIndex, edge);
  const data = _.get(prev, dataIndex);
  const index = data.edges?.findIndex(dataEdge => dataEdge.node.id === node.id);
  const dataEdge = {
    cursor: node.id,
    node
  };
  if (index !== -1) {
    data.edges && data.edges.splice(index, 1, dataEdge);
    const updatedData = _.set(
      {},
      dataIndex,
      edge
        ? {
            edges: {
              $set: [...data.edges]
            }
          }
        : {
            $set: node
          }
    );
    return update(prev, updatedData);
  } else onAdd(prev, node, dataIndex, edge);
}

export const onDelete = (prev, id, dataIndex, edge) => {
  if (!edge && typeof window !== undefined) window.location = '/';

  // console.log('called', prev, id);
  const data = _.get(prev, dataIndex);
  // console.log(dataIndex, data, id);
  const index = data.edges?.findIndex(dataEdge => getIntFromString(dataEdge.node.id) === getIntFromString(id));
  // console.log('index', index);

  // ignore if not found
  if (index < 0) {
    return prev;
  }
  const updatedData = _.set({}, dataIndex, {
    totalCount: {
      $set: data.totalCount - 1
    },
    edgeCount: {
      $set: data.edgeCount - 1
    },
    edges: {
      $splice: [[index, 1]]
    }
  });
  // console.log(dataIndex);
  return update(prev, updatedData);
};

export const removeDuplicate = data => {
  let dataList = {
    edgeCount: 0,
    edges: []
  };
  data.map(d => dataList.edges.indexOf(d.node.id) === -1 && dataList.edges.push(d.node.id));
  dataList.edges = dataList.edges.map(dL => data.filter(d => d.node.id === dL)[0]);
  dataList.edgeCount = dataList.edges.length;
  return dataList;
};

export const getOrgListFromVer = ver => {
  let orgList = {
    edgeCount: 0,
    edges: []
  };
  ver.edges.map(({ node }) => orgList.edges.push({ node: node.organization }));
  return removeDuplicate(orgList.edges);
};

export const getOrgListFromTeam = team => {
  let orgList = {
    edgeCount: 0,
    edges: []
  };
  team.edges.map(({ node }) => orgList.edges.push({ node: node.vertical.organization }));
  return removeDuplicate(orgList.edges);
};

export function getMarks(marks) {
  const obj = {};
  obj[marks[0]] = marks[0];
  obj[marks[1]] = marks[1];
  return obj;
}
export function getStrokeColor(value) {
  if (value > 9) {
    return '#009C4A';
  } else if (value > 7.5) {
    return '#B0CB1F';
  } else if (value > 5) {
    return '#CBA51F';
  } else {
    return '#E83D2A';
  }
}

export function calculateDaysBetweenDates(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  const duration = moment.duration(end.diff(start));
  const days = duration.asDays();
  return days;
}

export function interpolate(t, c) {
  return t?.replace(/\${([^}]+)}/g, (m, p) => p.split('.').reduce((a, f) => (a ? a[f] : undefined), c) ?? m);
}
// const a = "my name is ${name}"
// interpolate(a,{name:'ishan'})
