import React, { useState } from 'react';

import { compose } from '../../core';
import { getIntFromString } from '../../look';
import { withMe } from '../../user/containers/UserOperations';

import CommitmentView from '../components/CommitmentView';

import {
  withGetAllDefaultCommitmentsProgress,
  withGetAllCustomUserCommitments,
  withEditCustomUserCommitment,
  withEditDefaultToolCommitment,
  withDeleteDefaultToolCommitment,
  withDeleteCustomUserCommitment
} from './DashboardOperations';
import { withDeleteCoachingCommitmentProgress } from '../../manager/containers/ManagerOperations';

const PersonalCommitment = props => {
  const {
    setSubmitLoading,
    setPerCommOpen,
    deleteCustomUserCommitment,
    activeDashboard,
    getAllCustomUserCommitments,
    getAllDefaultCommitmentsProgress,
    editDefaultToolCommitment,
    editCustomUserCommitment,
    deleteDefaultToolCommitment,
    deleteCoachingCommitmentProgress
  } = props;

  async function handleDefaultCommitmentProgress(values) {
    setSubmitLoading(true);
    try {
      const response = await editDefaultToolCommitment(values);
      response && setSubmitLoading(false);
    } catch (err) {
      setSubmitLoading(false);
      throw new Error(err.message);
    }
  }

  const handleDeleteCoachingCommitmentProgress = async (id) => {
    try {
      // console.log(id);
      const response = await deleteCoachingCommitmentProgress(id);
      console.log("deleted");
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleDeleteDefaultToolCommitment = async (id) => {
    try {
      const response = await deleteDefaultToolCommitment(id);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleDeleteCustomUserCommitment = async (id) => {
    try {
      const response = await deleteCustomUserCommitment(id);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  async function handleCustomUserCommitment(values) {
    setSubmitLoading(true);
    try {
      const response = await editCustomUserCommitment(values);
      response && setSubmitLoading(false);
    } catch (err) {
      setSubmitLoading(false);
      throw new Error(err.message);
    }
  }
console.log('custom user commitments ::', getAllCustomUserCommitments)
  return (
    <CommitmentView
    setPerCommOpen={setPerCommOpen}
    delPersonalComm={
      handleDeleteCustomUserCommitment
    }
      customUserCommitments={getAllCustomUserCommitments}
      personalCommitmentList={getAllCustomUserCommitments}
      defaultCustomUserCommitments={getAllDefaultCommitmentsProgress}
      onDefaultCommitmentProgress={handleDefaultCommitmentProgress}
      onCustomUserCommitment={handleCustomUserCommitment}
      {...props}
    />
  );
};

export default compose(
  withEditDefaultToolCommitment,
  withGetAllDefaultCommitmentsProgress,
  withGetAllCustomUserCommitments,
  withEditCustomUserCommitment,
  withDeleteCoachingCommitmentProgress,
  withDeleteDefaultToolCommitment,
  withDeleteCustomUserCommitment
)(PersonalCommitment);
