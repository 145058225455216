import React from 'react';
import moment from 'moment';
import { Input, Space, Button, message, Modal, Progress } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { compose } from '../../core';
import { AdminLayout, displayDataCheck, displayBoolean, DeleteIcon, getOrderBy, GetColumnSearchProps } from '../../look';

import ROUTE from '../route';

import { withGetAllTokensListQuery, withDeleteToken, withSurveyFilterUpdating, withSurveyState } from './SurveyOperations';
import { subscribeToSurveyToken } from './SurveySubscriptions';

import ExportTokens from './ExportTokens';

export function getToken(link) {
  const link1 = link && link.split('/');
  return link1 && `${link1[link1.length - 1]}`;
}

export function getLink(link) {
  const link1 = link && link.split('/');
  return link1 && `${ROUTE.tokenSurveyLink}${link1[link1.length - 1]}`;
}

const Token = props => {
  const {
    loading,
    getAllTokensList,
    onPaginationChange,
    deleteToken,
    onOrderByChange,
    orderBy,
    onFiltersRemove,
    onUserChange,
    onSurveyChange,
    onCreatedByChange,
    filter,
    onInvitedChange,
    onValidChange,
    subscribeToMore
  } = props;

  React.useEffect(() => {
    const subscribe = subscribeToSurveyToken(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });
  const [exportLoading, setExportLoading] = React.useState(false);

  const columns = [
    {
      title: 'User',
      key: 'user',
      sorter: () => onOrderByChange(getOrderBy('user__firstName', orderBy)),
      ...GetColumnSearchProps('name', onUserChange, 'user'),
      render: (text, record) => record && displayDataCheck(`${record.user.firstName} ${record.user.lastName}`)
    },
    {
      title: 'Username',
      key: 'username',
      sorter: () => onOrderByChange(getOrderBy('user__username', orderBy)),
      render: (text, record) => record && displayDataCheck(record.user.username)
    },
    {
      title: 'Survey',
      key: 'surveyId',
      sorter: () => onOrderByChange(getOrderBy('survey__name', orderBy)),
      ...GetColumnSearchProps('name', onSurveyChange, 'survey'),
      render: (text, record) => record && record.survey && displayDataCheck(record.survey.name)
    },
    {
      title: 'Invited',
      key: 'invited',
      sorter: () => onOrderByChange(getOrderBy('invited', orderBy)),
      ...GetColumnSearchProps('invited', onInvitedChange, 'ternary'),
      render: (text, record) => displayBoolean(record.invited)
    },
    {
      title: 'Created By',
      key: 'createdBy',
      sorter: () => onOrderByChange(getOrderBy('createdBy__firstName', orderBy)),
      ...GetColumnSearchProps('name', onCreatedByChange, 'user'),
      render: (text, record) =>
        record && record.createdBy && displayDataCheck(`${record.createdBy.firstName} ${record.createdBy.lastName}`)
    },
    {
      title: 'Valid',
      key: 'valid',
      sorter: () => onOrderByChange(getOrderBy('valid', orderBy)),
      ...GetColumnSearchProps('valid', onValidChange, 'ternary'),
      render: (text, record) => displayBoolean(record.valid)
    },
    {
      title: 'Date Created',
      key: 'dateCreated',
      sorter: () => onOrderByChange(getOrderBy('dateCreated', orderBy)),
      render: (text, record) => `${moment(record.dateCreated, 'YYYY-M-DD HH:mm:ss')}`
    },
    {
      title: 'Link',
      key: 'link',
      align: 'center',
      render: (text, record) => (
        <Button type="link" target="_blank" href={getLink(record.link)} disabled={!getLink(record.link)}>
          Link
        </Button>
      )
    }
  ];

  return (
    <AdminLayout
      table={true}
      active={ROUTE.token}
      title="Token"
      loading={loading || exportLoading}
      tableData={
        getAllTokensList && {
          ...getAllTokensList,
          edges: getAllTokensList.edges.map(e => ({ ...e, node: { ...e.node, token: getToken(e.node.link) } }))
        }
      }
      columns={columns}
      onDelete={deleteToken}
      addLink={ROUTE.addToken}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      rowKey="token"
      extra={
        <Space size="large">
          <Button size="md" type="primary" ghost>
            <Link to={ROUTE.addMultipleToken}>
              <PlusOutlined />
              Add Multiple Token
            </Link>
          </Button>
          <ExportTokens filter={filter} getAllTokensList={getAllTokensList} setLoading={setExportLoading} />
        </Space>
      }
    />
  );
};

export default compose(withSurveyState, withDeleteToken, withGetAllTokensListQuery, withSurveyFilterUpdating)(Token);
