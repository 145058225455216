import React from 'react';
import { Divider, Row, Col } from 'antd';

import { HomeOutlined, LinkOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../App';
import { MetaTags } from '../../look';

const Links = () => {
  return (
    <>
      <MetaTags title="Links" description="This is links page" />
      <Row>
        <Col span={24}>
          <Row>
            <Col span={3} />
            <Col span={21}>
              <br />
              <br />
              <h1>
                <HomeOutlined /> &nbsp; Resilience home
              </h1>
              <Divider />
              <br />
              <Row>
                <Col span={2}></Col>
                <Col span={22}>
                  {ROUTES.map(route => (
                    <Row>
                      <h1>
                        <LinkOutlined /> &nbsp;<Link to={route.path}>{route.name}</Link>
                      </h1>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Links;
