import React from 'react';
import { Progress, Form } from 'antd';

import { getStrokeColor } from '../../../look';

import { withMyVerticalResiliencePulseCheck } from '../../containers/DashboardOperations';

const FormItem = Form.Item;

const RenderMyVerticalResiliencePulseCheck = ({ loading, myVerticalResiliencePulseCheck }) => (
  <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} label={'User’s Vertical’s Resilience Pulse Check​'}>
    <Progress
      format={percent => percent / 10 + '%'}
      percent={(myVerticalResiliencePulseCheck && JSON.parse(myVerticalResiliencePulseCheck)?.overall?.toFixed(2) * 10) || 0}
      strokeColor={getStrokeColor(
        (myVerticalResiliencePulseCheck && JSON.parse(myVerticalResiliencePulseCheck)?.overall?.toFixed(2)) || 0
      )}
    />
  </FormItem>
);

export default withMyVerticalResiliencePulseCheck(RenderMyVerticalResiliencePulseCheck);
