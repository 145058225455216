import React from 'react';
import { ExclamationCircleOutlined, LoginOutlined } from '@ant-design/icons';
import { Modal, Form, Input, message, Button, Row, Col } from 'antd';
import styled from 'styled-components';

import { validateEmail } from '../../look';

import { LOGIN } from '../graphql/LoginQuery.gql';
import HOME_ROUTE from '../../home/route';
import { setItem, removeItem } from '../../core';

const SignInAgain = (client, resolvePendingRequests) => {
  // if (typeof window !== 'undefined' && window.location?.pathname?.split('/')?.indexOf('password-reset') === -1) {
  if (true) {
    Modal.confirm({
      title: 'Your session has expired please login again.',
      icon: <ExclamationCircleOutlined />,
      content: <LoginForm client={client} resolvePendingRequests={resolvePendingRequests} />,
      okText: 'Login',
      cancelText: 'Cancel',
      bodyStyle: { maring: 0 },
      // onOk: () => ,
      okButtonProps: {
        // form: 'loginForm',
        // key: 'submit',
        // htmlType: 'submit'
        style: { display: 'none' }
      },
      cancelButtonProps: {
        style: { display: 'none' }
      }
      // onCancel: () => console.log('Cancel called!')
    });
  } else {
    removeItem('accessToken');
    removeItem('refreshToken');
  }
};

export default SignInAgain;

const LoginForm = ({ client, resolvePendingRequests }) => {
  const [form] = Form.useForm();

  const handleSubmit = async values => {
    const isValidEmail = validateEmail(values.email);
    const {
      data: { tokenAuth }
    } = await client.mutate({
      mutation: LOGIN,
      variables: isValidEmail ? values : { username: values.email, password: values.password }
    });
    if (tokenAuth.success) {
      if (typeof window !== 'undefined') {
        setItem('accessToken', tokenAuth.token);
        setItem('refreshToken', tokenAuth.refreshToken);
      }
      message.success('Logged you in!');
      resolvePendingRequests();
      window && window.location.reload();
    } else {
      if (tokenAuth.errors.nonFieldErrors.length > 0) {
        form.setFields([
          {
            name: 'email',
            errors: [tokenAuth.errors.nonFieldErrors[0].message]
          }
        ]);
      }
      message.error('Login failed');
      return false;
    }
  };

  const removeTokens = () => {
    removeItem('accessToken');
    removeItem('refreshToken');
    window.location.pathname = HOME_ROUTE.home;
  };

  return (
    <>
      <br />

      <Form
        layout="vertical"
        id="loginForm"
        form={form}
        /* {...layout} */ requiredMark={false}
        name="login-form"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="email"
          label="Enter your username"
          // validateStatus={incorrectPass ? 'error' : nonFieldErrors ? 'error' : ''}
          // help={
          //   incorrectPass
          //     ? 'Email or password is incorrect!!'
          //     : nonFieldErrors
          //     ? nonFieldErrors.length > 0 && nonFieldErrors[0].message
          //     : null
          // }
          rules={[
            { required: true, message: 'Email/username is required' },
            {
              required: true
            }
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true
            }
          ]}
          // hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Row gutter={[12, 12]}>
          <Col lg={12} md={24}>
            <Button block type="primary" htmlType="submit">
              <LoginOutlined />
              Login
            </Button>
          </Col>
          <Col lg={12} md={24}>
            <Button block type="primary" ghost onClick={removeTokens}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
