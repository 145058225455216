import gql from 'graphql-tag';
import { CONDITION_TYPE_CONNECTION } from './ConditionTypeConnection.gql';

export const GET_ALL_CONDITION = gql`
  ${CONDITION_TYPE_CONNECTION}
  query getAllCondition($first: Int, $offset: Int, $orderBy: [String]) {
    getAllCondition(first: $first, offset: $offset, orderBy: $orderBy) {
      ...ConditionTypeConnection
    }
  }
`;
