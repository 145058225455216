import gql from 'graphql-tag';
import { COMMENT_TYPE_CONNECTION } from './CommentTypeConnection.gql';

export const ALL_COMMENTS_FOR_STORY_BY_ID_QUERY = gql`
  ${COMMENT_TYPE_CONNECTION}
  query allCommentsForStoryByStoryId(
    $storyId: ID!
    $user: ID
    $first: Int
    $offset: Int
    $orderBy: [String]
    $commentText_Icontains: String
  ) {
    allCommentsForStoryByStoryId(
      storyId: $storyId
      user: $user
      first: $first
      offset: $offset
      orderBy: $orderBy
      commentText_Icontains: $commentText_Icontains
    ) {
      ...CommentTypeConnection
    }
  }
`;
