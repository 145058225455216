const baseName = '/user';
const newBaseName = '/new';
const editBaseName = '/edit';

module.exports = {
  user: baseName + '/admin-panel',

  login: baseName + '/login',
  loginAfterTokenSurvey: baseName + '/login' + '/login-after-token-survey',
  register: baseName + '/register',
  profile: baseName + '/profile',
  activateAccount: baseName + '/activate' + '/:token',

  myResponse: baseName + '/my-response',
  redirectAuthenticate: baseName + '/redirect-authenticate',

  addUser: baseName + newBaseName,
  addMultipleUser: baseName + '/multiple' + newBaseName,
  editUser: baseName + editBaseName + '/:id',
  editUserLink: baseName + editBaseName + '/'
};
