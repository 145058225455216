import gql from 'graphql-tag';
import { ORGANIZATION_TYPE } from './OrganizationType.gql';

export const ORGANIZATION_BY_ID_QUERY = gql`
  ${ORGANIZATION_TYPE}
  query organizationById($id: ID!) {
    organizationById(id: $id) {
      ...OrganizationType
    }
  }
`;
