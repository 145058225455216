import React from "react";
import styled from "styled-components";
import { Row, Col, Card, Spin, Empty } from "antd";
import { DeleteIcon, getIntFromString, AddIcon } from "../../look";
const CommitmentsListView = (props) => {
  const {
    defaultCustomUserCommitments,
    loading,
    customUserCommitments,
    dashboard,
    onDeleteDefaultToolCommitment,
    onDeleteCustomUserCommitment,
    handleSelectDefaultToolCommitment,
    commitmentRecommendations,
    commitmentKey = "commitment",
  } = props;

  // <>
  // <h3>
  //     {props.dashboard === "manager-personal"
  //       ? "Recommended Coaching Commitments"
  //       : "My Commitments"}
  //   </h3>
  //   <br />
  //   <Spin spinning={loading}>
  //     <Row style={{ maxHeight: "30vh", overflow: "auto" }}>
  //       <Col span={24} style={{ padding: "1vw" }}>
  //         {defaultCustomUserCommitments?.edges?.length > 0
  //           ? defaultCustomUserCommitments?.edges?.map(({ node }, idx) => (
  //               <>
  //                 <Row gutter={24} align="middle">
  //                   <Col span={2} align="center">
  //                     <span
  //                       style={{
  //                         borderRadius: "50%",
  //                         padding: "10px 13px",
  //                         border: "1px solid #CACACA",
  //                         display:'flex',
  //                         justifyContent:'center',
  //                         alignItems: 'center'
  //                       }}
  //                     >
  //                       {idx + 1}
  //                     </span>
  //                   </Col>
  //                   <Col span={22}>
  //                     <Card
  //                       bordered={false}

  //                       style={{
  //                         boxShadow:
  //                           "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
  //                         borderRadius: "10px",
  //                       }}
  //                       bodyStyle={{ padding: "12px" }}
  //                     >
  //                       <Row justify="space-between" gutter={[12, 12]}>
  //                         <Col span={22}>
  //                           <Hoverable /* style={{ whiteSpace: 'nowrap', overflow: 'scroll' }} */
  //                           >
  //                             {node[commitmentKey]?.title}
  //                           </Hoverable>
  //                         </Col>

  //                         <Col span={2} style={{cursor:'pointer'}}>
  //                           <DeleteIcon
  //                             size="sm"
  //                             tooltipSuffix="Commitment"
  //                             onClick={() =>
  //                               onDeleteDefaultToolCommitment(
  //                                 getIntFromString(node?.id)
  //                               )
  //                             }
  //                           />
  //                         </Col>
  //                       </Row>
  //                     </Card>
  //                   </Col>
  //                 </Row>
  //                 <br />
  //               </>
  //             ))
  //           : customUserCommitments?.edges?.length === 0 && (
  //               <div align="center">
  //                 <Empty />
  //               </div>
  //             )}

  //         {customUserCommitments?.edges?.map(({ node }, idx) => (
  //           <>
  //             <Row gutter={24} align="middle">
  //               <Col span={2} align="center">
  //                 <span
  //                   style={{
  //                     borderRadius: "50%",
  //                     padding: "10px 13px",
  //                     border: "1px solid #CACACA",
  //                     display:'flex',
  //                     justifyContent:'center',
  //                     alignItems:'center'
  //                   }}
  //                 >
  //                   {(defaultCustomUserCommitments?.edges?.length || 0) +
  //                     idx +
  //                     1}
  //                 </span>
  //               </Col>
  //               <Col span={22}>
  //                 <Card
  //                   bordered={false}
  //                   style={{
  //                     boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
  //                     borderRadius: "10px",
  //                   }}
  //                   bodyStyle={{ padding: "12px" }}
  //                 >
  //                   <Row justify="space-between" gutter={[12, 12]}>
  //                     <Col span={22} >
  //                       <Hoverable /* style={{ whiteSpace: 'nowrap', overflow: 'scroll' }} */
  //                       >
  //                         {node?.title}
  //                       </Hoverable>
  //                     </Col>

  //                     <Col span={2} style={{cursor:'pointer'}}>
  //                       <DeleteIcon
  //                         size="sm"
  //                         tooltipSuffix="Commitment"
  //                         onClick={() =>
  //                           onDeleteCustomUserCommitment(
  //                             getIntFromString(node.id)
  //                           )
  //                         }
  //                       />
  //                     </Col>
  //                   </Row>
  //                 </Card>
  //               </Col>
  //             </Row>
  //             <br />
  //           </>
  //         ))}
  //       </Col>
  //     </Row>
  //   </Spin>
  // </>
  
  console.log('dashboardcoachcomm4', dashboard,defaultCustomUserCommitments)

  return (
    <>
      <h3 style={{fontFamily:'poppins, sans-serif',marginBottom:'-1em'}}>Recommended {dashboard === 'manager-personal' ? 'Coaching' : ''}  Commitment​s</h3>
      <br />
      <Row gutter={[24, 24]} style={{ maxHeight: "46vh", overflow: "auto" }}>
        <Col span={24} style={{ padding: "1vw",paddingTop:'1.2em' }}>
          {commitmentRecommendations?.edges?.length > 0
            ? commitmentRecommendations?.edges?.map(({ node }, idx) => (
              <>
                <Row gutter={24}  align="middle">
                  <Col span={24} >
                    <Card
                      bordered={false}
                      style={{
                        // boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
                      boxShadow: '0px 4px 11.800000190734863px 0px rgba(0, 0, 0, 0.1)',
                        borderRadius: "0.4em",
                        padding:'0.42em 0'
                      }}
                      bodyStyle={{ padding: "12px" }}
                    >
                      <Row justify="space-between" gutter={[12, 12]}>
                        <Col span={22} style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                          <Hoverable /* style={{ whiteSpace: 'nowrap', overflow: 'scroll' }} */
                          style={{fontFamily:'poppins, sans-serif'}}
                          >
                            {node?.title}
                          </Hoverable>
                        </Col>

                        <Col span={2} style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                          <img
                          style={{width:'1.5em',height:'1.5em'}}
                            src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1702890685/plus_sign_add_cj3chn.svg"
                            size="sm"
                            tooltipSuffix="Commitment"
                            onClick={() =>
                              handleSelectDefaultToolCommitment(
                                getIntFromString(node?.id)
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <br />
              </>
            ))
            : commitmentRecommendations?.edges?.length === 0 && (
              <div align="center">
                <Empty />
              </div>
            )}
        </Col>
      </Row>
    </>
  );
};

export default CommitmentsListView;

const Hoverable = styled.div`
  white-space: wrap;
  overflow: ellipsis;
  :hover {
    cursor: default;
  }
`;
