import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { Input, Divider, Modal, Spin, Col, Row, Button, Card, Select, Switch } from 'antd';
import { Tree, TreeNode } from 'react-organizational-chart';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { compose } from '../../core';
import { CardLayout, ReactMarkdown, getIntFromString, EmployeeTransfer, SelectFilterField } from '../../look';
import { IMG } from '../../../config';
import ROUTE from '../route';
import AddVertical from './AddVertical';

import {
  withOrganizationState,
  withOrganizationFilterUpdating,
  withOrganizationById,
  withDeleteVertical,
  withVerticalsByOrgIdQuery,
  withEditOrganization
} from './OrganizationOperation';
import { subscribeToOrganizationById, subscribeToVerticalByOrgId } from './OrganizationSubscriptions';
import { VERTICAL_BY_VERTICAL_Id_QUERY } from '../graphql/VerticalByVerticalIdQuery.gql';
import { TEAMS_BY_VERTICAL_ID_QUERY } from '../graphql/TeamsByVerticalIdQuery.gql';
import { TEAM_BY_TEAM_ID_QUERY } from '../graphql/TeamByTeamIdQuery.gql';

const OrganizationDetail = props => {
  const {
    loading,
    organizationById,
    verticalsByOrgId,
    editOrganization,
    onOrderByChange,
    orderBy,
    onNameChange,
    onDescriptionChange,
    onFiltersRemove,
    deleteVertical,
    onPaginationChange,
    onSubverticalChange,
    subscribeToMore,
    subscribeToMoreVerticalsByOrgId,
    match,
    navigation
  } = props;
  const [visible, setVisible] = React.useState(false);
  const [employee, setEmployee] = React.useState(false);
  const [fetchLoading, setFetchLoading] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [member, setMember] = React.useState({ ver: false, tem: false });
  const [verticalId, setVerticalId] = React.useState('');
  const [teamId, setTeamId] = React.useState('');
  const [Treevisible, setTreeVisible] = React.useState({ step1: false, step2: false, verticalId: 0 });
  const [actionLoading, setActionLoading] = React.useState(false);

  const { id } = useParams();

  React.useEffect(() => {
    const subscribe = subscribeToVerticalByOrgId(subscribeToMoreVerticalsByOrgId /* , props.filter */);
    const subscribe1 = subscribeToOrganizationById(subscribeToMore /* , props.filter */);
    return () => {
      subscribe();
      subscribe1();
    };
  });

  const handleEmployeeSubmit = async employees => {
    try {
      await editOrganization({
        id: getIntFromString(organizationById.id),
        name: organizationById.name,
        employees
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const handleEditOrganization = async (e, field) => {
    setActionLoading(true);
    let data = {};
    if (field === 'allowComparison') data = { allowComparison: e };
    try {
      await editOrganization({
        id: Number(getIntFromString(organizationById.id)),
        name: organizationById.name,
        ...data
      });
    } catch (e) {
      throw new Error(e.message);
    }
    setActionLoading(false);
  };

  return (
    <CardLayout
      active={ROUTE.organization}
      title={[
        {
          name: organizationById?.name,
          link: `${ROUTE.organizationDetailLink}${organizationById && getIntFromString(organizationById.id)}`
        }
      ]}
      showTitle={false}
      editLink={ROUTE.editOrganizationLink}
      addLink={ROUTE.addOrganization}
      backLink={`${ROUTE.organization}`}
      showBack={false}
      onFiltersRemove={onFiltersRemove}
      table={false}
      rowKey={organizationById && organizationById.id}
    >
      <Row>
        <Col span={24}>
          <Spin spinning={loading || actionLoading} size="large">
            <div style={{ minHeight: '70vh' }}>
              {organizationById && (
                <>
                  <Row gutter={[24, 24]}>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <Row gutter={[24, 24]} style={{ alignItems: 'center' }}>
                        <Col>
                          <img
                            src={organizationById.logo || IMG}
                            alt=""
                            style={{
                              width: 70,
                              height: 70,
                              borderRadius: 50,
                              marginRight: 10,
                              backgroundColor: '#f3f3f3'
                            }}
                          />
                        </Col>
                        <Col>
                          <h3 style={{ marginBottom: 0 }}>{organizationById.name}</h3>
                          <h5 style={{ color: '#898A82' }}>{organizationById.email}</h5>
                        </Col>
                      </Row>
                      <br />
                      <Row justify="space-between" align="middle">
                        <Col>
                          <Button type="primary" ghost onClick={() => setEmployee(true)}>
                            <Link>Add /Edit Employees</Link>
                          </Button>
                        </Col>
                        <Col>
                          <Row gutter={[24, 24]}>
                            <Col>Allow Comparison</Col>
                            <Col>
                              <Switch
                                checked={organizationById.allowComparison}
                                size="small"
                                onClick={e => handleEditOrganization(e, 'allowComparison')}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <br />
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline'
                        }}
                      >
                        <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>Description</h3>
                        {/* <Button type="link">Edit</Button> */}
                      </Row>
                      <div
                        style={{
                          border: '1px solid rgba(202, 202, 202, 0.25)',
                          margin: '7px',
                          padding: '15px',
                          minHeight: '140px',
                          borderRadius: '2px'
                        }}
                      >
                        <ReactMarkdown>{organizationById.description}</ReactMarkdown>
                      </div>
                    </Col>
                    <Col lg={1} md={1} sm={24} xs={24}>
                      <Divider type="vertical" style={{ height: '100%' }} />
                    </Col>
                    <Col lg={11} md={11} sm={24} xs={24}>
                      <Row justify="space-between">
                        <Col>
                          <h3>CEO</h3>
                        </Col>
                        {/* <Col>
                        <Button type="link">Edit</Button>
                      </Col> */}
                      </Row>
                      <Row gutter={[24, 24]}>
                        <Col>
                          <img
                            src={IMG}
                            alt=""
                            style={{
                              width: 70,
                              height: 80,
                              backgroundColor: '#f3f3f3'
                            }}
                          />
                        </Col>
                        <Col>
                          <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Name</h4>
                          <h4
                            style={{
                              marginBottom: 0,
                              fontWeight: 'normal',
                              color: '#898A82'
                            }}
                          >
                            {organizationById.ceo &&
                              organizationById.ceo.user &&
                              `${organizationById.ceo.user.firstName} ${organizationById.ceo.user.lastName}`}
                          </h4>
                          <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Email</h4>
                          <h4
                            style={{
                              marginBottom: 0,
                              fontWeight: 'normal',
                              color: '#898A82'
                            }}
                          >
                            {organizationById.ceo && organizationById.ceo.user && organizationById.ceo.user.email}
                          </h4>
                        </Col>
                      </Row>
                      <br />
                      <Row justify="space-between">
                        <Col>
                          <h3>POC</h3>
                        </Col>
                        {/* <Col>
                        <Button type="link">Edit</Button>
                      </Col> */}
                      </Row>
                      <Row gutter={[24, 24]}>
                        <Col>
                          <img
                            src={IMG}
                            alt=""
                            style={{
                              width: 70,
                              height: 80,
                              backgroundColor: '#f3f3f3'
                            }}
                          />
                        </Col>
                        <Col>
                          <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Name</h4>
                          <h4
                            style={{
                              marginBottom: 0,
                              fontWeight: 'normal',
                              color: '#898A82'
                            }}
                          >
                            {organizationById.poc &&
                              organizationById.poc.user &&
                              `${organizationById.poc.user.firstName} ${organizationById.poc.user.lastName}`}
                          </h4>
                          <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Email</h4>
                          <h4
                            style={{
                              marginBottom: 0,
                              fontWeight: 'normal',
                              color: '#898A82'
                            }}
                          >
                            {organizationById.poc.user.email}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Divider style={{ width: '100%' }} />
                  </Row>

                  <Row justify="end" gutter={[24, 24]}>
                    <Col>
                      <Button type="primary" onClick={() => setVisible(true)}>
                        Add Vertical
                      </Button>
                    </Col>
                    <Col>
                      <SelectFilterField
                        placeholder="Search Vertical by"
                        onFiltersRemove={onFiltersRemove}
                        options={[
                          {
                            label: 'Name',
                            type: 'text',
                            value: onNameChange
                          },
                          {
                            label: 'Description',
                            type: 'text',
                            value: onDescriptionChange
                          },
                          // {
                          //   label: "Employees",
                          //   value: null
                          // },
                          {
                            label: 'Subvertical',
                            type: 'subvertical',
                            value: onSubverticalChange,
                            organizationId: id
                          }
                        ]}
                      />
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}

                  {verticalsByOrgId && (
                    <Row justify="center" style={{ minHeight: '70vh' }}>
                      <Col span={1}>
                        <Button
                          type="ghost"
                          icon={<LeftOutlined />}
                          shape="circle"
                          style={{ marginTop: '4em' }}
                          onClick={() => setTotal(total - 1)}
                          disabled={total === 0}
                        />
                      </Col>
                      <Col span={22} style={{ overflow: 'hidden' }}>
                        <Spin spinning={fetchLoading} size="medium">
                          <Tree
                            label={
                              <Row>
                                <Col span={24}>
                                  <img
                                    src={organizationById.logo || IMG}
                                    alt=""
                                    style={{
                                      width: 70,
                                      height: 70,
                                      borderRadius: 50,
                                      marginBottom: 10,
                                      backgroundColor: '#f3f3f3'
                                    }}
                                  />
                                </Col>
                                <Col span={24}>{organizationById && organizationById.name}</Col>
                              </Row>
                            }
                            lineColor={'#CACACA'}
                            lineBorderRadius={'5px'}
                          >
                            {[...Array(verticalsByOrgId.edgeCount > 7 ? 7 : verticalsByOrgId.edgeCount).keys()].map(i => (
                              <TreeNodeComponent
                                node={verticalsByOrgId.edges[total + i].node}
                                setVerticalId={setVerticalId}
                                setMember={setMember}
                                visible={Treevisible}
                                setVisible={setTreeVisible}
                                teamId={teamId}
                                setTeamId={setTeamId}
                                setFetchLoading={setFetchLoading}
                              />
                            ))}
                          </Tree>
                        </Spin>
                      </Col>
                      <Col span={1}>
                        <Button
                          type="ghost"
                          icon={<RightOutlined />}
                          shape="circle"
                          style={{ marginTop: '4em' }}
                          onClick={() => setTotal(total + 1)}
                          disabled={total + 7 >= verticalsByOrgId.edgeCount}
                        />
                      </Col>
                    </Row>
                  )}
                  {/* </div> */}
                </>
              )}
            </div>
          </Spin>
        </Col>
      </Row>

      <Modal
        destroyOnClose
        visible={employee}
        onCancel={() => setEmployee(false)}
        footer={null}
        title="Employee"
        width="25%"
        bodyStyle={{ padding: 12, minHeight: '40vh', maxHeight: '65vh', overflow: 'auto' }}
      >
        <Row>
          {organizationById && organizationById.employees && (
            <EmployeeTransfer
              orgId={getIntFromString(organizationById.id)}
              initialValues={organizationById.employees}
              handleSubmit={handleEmployeeSubmit}
            />
          )}
        </Row>
        <br />
        <Row gutter={[24, 24]}>
          {organizationById &&
            organizationById.employees &&
            organizationById.employees.edgeCount > 0 &&
            organizationById.employees.edges.map(({ node }) => (
              <Col span={24}>
                <Row
                  justify="space-between"
                  style={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
                  {`${node.user.firstName} ${node.user.lastName}`}
                </Row>
              </Col>
            ))}
        </Row>
      </Modal>

      <Modal
        destroyOnClose
        visible={member.ver}
        onCancel={() => setMember({ ver: false, tem: false })}
        footer={null}
        title="Members"
        width="25%"
        bodyStyle={{ padding: 12 }}
        centered
      >
        <VerticalMember setMember={setMember} verticalId={getIntFromString(verticalId)} />
      </Modal>

      <Modal
        destroyOnClose
        visible={member.tem}
        onCancel={() => setMember({ ver: false, tem: false })}
        footer={null}
        title="Members"
        width="25%"
        bodyStyle={{ padding: 12 }}
        centered
      >
        <TeamMember setMember={setMember} teamId={getIntFromString(teamId)} />
      </Modal>

      {organizationById && organizationById.id && (
        <AddVertical
          match={match}
          navigation={navigation}
          visible={visible}
          setVisible={setVisible}
          orgId={getIntFromString(organizationById.id)}
        />
      )}
    </CardLayout>
  );
};

export default compose(
  withOrganizationState,
  withOrganizationById,
  withVerticalsByOrgIdQuery,
  withDeleteVertical,
  withOrganizationFilterUpdating,
  withEditOrganization
)(OrganizationDetail);

const TreeNodeComponent = props => {
  const { node, setVerticalId, setMember, visible, setVisible, setTeamId, setFetchLoading } = props;
  // const [visible, setVisible] = React.useState({ step1: false, step2: false });
  const [isTeam, setIsTeam] = React.useState(false);
  return (
    <TreeNode
      label={
        <Link to={`${ROUTE.verticalDetailLink}${getIntFromString(node.id)}`}>
          <Button
            type="link"
            style={{
              boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
              margin: 5
            }}
            onMouseEnter={() => setVisible({ step1: true, step2: false, verticalId: node.id })}
            // onMouseLeave={() => setVisible(false)}
          >
            {node.name}
          </Button>
        </Link>
      }
    >
      {visible.verticalId === node.id && visible.step1 ? (
        <>
          {node.subvertical
            ? node?.subvertical?.edges?.map(({ node: sv }) => (
                <>
                  <TreeNode
                    label={
                      <Link to={`${ROUTE.verticalDetailLink}${getIntFromString(sv.id)}`}>
                        <Button
                          type="link"
                          style={{
                            boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
                            margin: 5
                          }}
                          onMouseEnter={() => setVisible({ step1: true, step2: true, verticalId: node.id, isMember: sv.id })}
                          // onMouseLeave={() => setVisible(false)}
                        >
                          {sv.name}
                        </Button>
                      </Link>
                    }
                  >
                    {visible.step2 && visible.isMember === sv.id ? (
                      <>
                        <TreeNode
                          label={
                            <Button
                              type="link"
                              style={{
                                boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
                                margin: 5
                              }}
                              onClick={() => {
                                setVerticalId(sv.id);
                                setMember({ ver: true, tem: false });
                              }}
                              onMouseLeave={() => setVisible({ step1: false, step2: false, verticalId: node.id })}
                            >
                              Members
                            </Button>
                          }
                        ></TreeNode>

                        {isTeam ? null : (
                          <TeamNode
                            setIsTeam={setIsTeam}
                            setVisible={setVisible}
                            setTeamId={setTeamId}
                            setMember={setMember}
                            setFetchLoading={setFetchLoading}
                            verticalId={getIntFromString(sv.id)}
                          />
                        )}
                      </>
                    ) : null}
                  </TreeNode>
                </>
              ))
            : null}

          {isTeam ? null : (
            <TeamNode
              setIsTeam={setIsTeam}
              setTeamId={setTeamId}
              setMember={setMember}
              setVisible={setVisible}
              verticalId={getIntFromString(node.id)}
              setFetchLoading={setFetchLoading}
            />
          )}

          <TreeNode
            label={
              <Button
                type="link"
                style={{
                  boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
                  margin: 5
                }}
                onClick={() => {
                  setVerticalId(node.id);
                  setMember({ ver: true, tem: false });
                }}
                onMouseLeave={() => setVisible({ step1: false, step2: false })}
              >
                Members
              </Button>
            }
          ></TreeNode>
        </>
      ) : null}
    </TreeNode>
  );
};
const VerticalMemberComponent = props => {
  const { verticalByVerticalId, setTeam } = props;
  return (
    <>
      {verticalByVerticalId &&
        verticalByVerticalId.employees &&
        verticalByVerticalId.employees.edgeCount > 0 &&
        verticalByVerticalId.employees.edges.map(({ node }) => (
          <Row justify="space-between" style={{ paddingTop: 10 }}>
            <Col>
              <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
              {`${node.user.firstName} ${node.user.lastName}`}
            </Col>
            {/* <Col>
                <Button type="link">Edit</Button>
              </Col> */}
          </Row>
        ))}
    </>
  );
};

const VerticalMember = compose(
  graphql(VERTICAL_BY_VERTICAL_Id_QUERY, {
    options: props => {
      let id = '';
      if (props.verticalId) {
        id = props.verticalId;
      }
      return { variables: { verticalId: id } };
    },
    props({ data }) {
      const { loading, error, verticalByVerticalId, subscribeToMore, updateQuery } = data;
      return { loading, error, verticalByVerticalId, subscribeToMore, updateQuery };
    }
  })
)(VerticalMemberComponent);

const VerticalTeamNode = props => {
  const { TeamsByVerticalId, setIsTeam, setVisible, verticalId, setTeamId, setMember } = props;
  const [isMemeber, setIsMember] = React.useState({ visible: false, id: '' });
  if (TeamsByVerticalId && TeamsByVerticalId.edges && TeamsByVerticalId.edges.length > 0) {
    setIsTeam(false);
    return TeamsByVerticalId.edges.map(({ node }) => (
      <>
        <TreeNode
          label={
            // <Link to={`${ROUTE.verticalDetailLink}${getIntFromString(node.subvertical.id)}`}>
            <Button
              type={'link'}
              style={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)', margin: 5 }}
              onMouseEnter={() => setIsMember({ visible: true, id: node.id })}
            >
              {node.name}
            </Button>
            // </Link>
          }
        >
          {isMemeber.visible && isMemeber.id === node.id ? (
            <TreeNode
              label={
                <Button
                  type="link"
                  style={{
                    boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
                    margin: 5
                  }}
                  onClick={() => {
                    setTeamId(node.id);
                    setMember({ ver: false, tem: true });
                  }}
                  onMouseLeave={() => setVisible({ step1: false, step2: false, verticalId })}
                >
                  Members
                </Button>
              }
            ></TreeNode>
          ) : null}
        </TreeNode>
      </>
    ));
  } else {
    // setIsTeam(true);
    return null;
  }
};
const TeamNode = compose(
  graphql(TEAMS_BY_VERTICAL_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.verticalId) {
        id = props.verticalId;
      }
      return { variables: { verticalId: id } };
    },
    props({ data, ownProps: { setFetchLoading } }) {
      const { loading, error, TeamsByVerticalId, subscribeToMore, updateQuery } = data;
      setFetchLoading(loading);
      return { loading, error, TeamsByVerticalId, subscribeToMore, updateQuery };
    }
  })
)(VerticalTeamNode);

const TeamMemberComponent = props => {
  const { TeamByTeamId, setTeam } = props;
  return (
    <>
      {TeamByTeamId &&
        TeamByTeamId.employees &&
        TeamByTeamId.employees.edgeCount > 0 &&
        TeamByTeamId.employees.edges.map(({ node }) => (
          <Row justify="space-between" style={{ paddingTop: 10 }}>
            <Col>
              <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
              {`${node.user.firstName} ${node.user.lastName}`}
            </Col>
            {/* <Col>
                <Button type="link">Edit</Button>
              </Col> */}
          </Row>
        ))}
    </>
  );
};

const TeamMember = compose(
  graphql(TEAM_BY_TEAM_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.teamId) {
        id = props.teamId;
      }
      return { variables: { teamId: id } };
    },
    props({ data }) {
      const { loading, error, TeamByTeamId, subscribeToMore, updateQuery } = data;
      return { loading, error, TeamByTeamId, subscribeToMore, updateQuery };
    }
  })
)(TeamMemberComponent);
