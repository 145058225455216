import React, { useState, useContext, useEffect } from 'react';
import '../css/ManagerAddCommitmentView.css'
import { Contextual } from '../../dashboard/components/CommitmentView';
import _ from 'lodash';
import moment from 'moment'
import { Input } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { Button, Modal, Row, Col, Form, Divider, Switch, Card, DatePicker, Collapse } from 'antd';
import { ArrowRightOutlined, CloseOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { CKEditorField, getIntFromString, SelectField, TextField } from '../../look';
import { ReactMarkdown } from '../../look/';
import '../css/ManagerAddCommitmentView.css';

const { TextArea } = Input;

export const CKEditorFieldName = ['coachingCommitmentData', 'description', 'dueDate'];

const ManagerAddCommitmentView = props => {
  const { getMyCustomizedCoachingCommitments, onSubmit, modal2, setTitle, recoCommitments, setModal2, setDescription, changeHandler, commitment, handleSelectDefaultToolCommitment, setDueDate } = props;
  const { defaultCustomUserCommitments, callByUserId, coachCommByUser, selectedMember, me } = useContext(Contextual)
  const [coachCommForIc, setCoachCommForIc] = useState()
  const [recoModal, setRecoModal] = useState(false)
  const [memberName, setMemberName] = useState()
  // const [isNew, setIsNew] = useState(false);
  const [form] = Form.useForm();

  // const choices =
  //   getMyCustomizedCoachingCommitments &&
  //   getMyCustomizedCoachingCommitments.edges.length > 0 &&
  //   getMyCustomizedCoachingCommitments.edges.map(({ node }) => ({
  //     label: node.title || '',
  //     value: Number(getIntFromString(node.id))
  //   }));
  // console.log('defaultCustomUserCommitments ::', commitment?.user?.firstName)

  useEffect(() => {
    if (selectedMember) {
      let selectedMem = me?.employee?.teamMembersEmployee?.edges[0]?.node?.employees?.edges?.filter(emp => emp?.node?.user?.id === selectedMember?.userId)
      let selectedMemFirstName = selectedMem[0]?.node?.user?.firstName
      console.log('selectedMemNam98d9e', me, selectedMemFirstName)
      setMemberName(selectedMemFirstName)
    }
  }, [selectedMember])

  useEffect(() => {
    console.log('commitment?.user?.id', modal2.visible, commitment?.user?.id, commitment)
    if (commitment?.user?.id) {
      callByUserId(getIntFromString(commitment?.user?.id))

    }
  }, [])

  useEffect(() => {
    console.log('perfbon2dw', coachCommByUser?.data?.getMyCoachingCommitmentsProgress?.edges?.map(item => item?.node?.coachingcommitment))
    let sortedData = coachCommByUser?.data?.getMyCoachingCommitmentsProgress?.edges?.map(item => item?.node?.coachingcommitment)
    let forThisCommitment = sortedData?.filter(item => item?.customusercommitment?.id === commitment?.id)
    setCoachCommForIc(forThisCommitment)
  }, [coachCommByUser])
  console.log('coachCommF4v4orIc', recoCommitments)
  // date logic for the date picker
  const maxDate = commitment?.dueDate ? moment(new Date(commitment?.dueDate)) : moment(new Date().toISOString())

  // Function to disable dates after the specified maximum date
  const disabledDate = current => {
    return current && current > maxDate;
  };

  return (
    <>
      <Modal
        bodyStyle={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'space-between', gap: '1em', boxShadow: 'none' }}
        footer={null}
        closable={false}
        centered
        className="ckeditor-parent"
        visible={modal2.visible}
        width={recoModal ? '85%' : '50%'}
        destroyOnClose={true}
        onCancel={() => {
          setModal2({ visible: false, id: 0, default: false });
        }}
      >
        <Row style={{ width: '100%', display: 'flex', gap: '18px' }}>

          {/* code for the add coaching committment modal */}
          <Col span={recoModal ? 15 : 24} style={{ backgroundColor: '#fff', padding: '1.65em', borderRadius: '1.2em' }}>
            <div style={{ position: 'relative', width: '100%' }}>
              <CloseOutlined onClick={() => setModal2({ visible: false, id: 0, default: false })} className='main_close_btn' style={{ position: 'absolute', right: '0', width: '2em', height: '2em' }} />

            </div>
            {commitment && <div style={{ display: `${commitment ? 'block' : 'none'}` }}>
              <h5 style={{ marginBottom: '1em', fontWeight: '600', fontSize: '1.1em' }}>Give a Coaching Commitment for :</h5>
              <Collapse
                accordion
                bordered={false}
                expandIconPosition="right"
                // expandIcon={<ArrowUpOutlined />}
                expandIcon={({ isActive }) => isActive ? <UpOutlined /> : <DownOutlined />}
                defaultActiveKey={[]}
                style={{ backgroundColor: '#f5f5f5', width: '100%', borderRadius: '0.6em', marginBottom: '2em' }}

              >
                {
                  commitment &&
                  <Collapse.Panel
                    className='collapse_main'
                    header={
                      <>
                        <div style={{ borderRadius: '0.6em', padding: '0.5em 0.5em 0.2em 0.5em', backgroundColor: '#f5f5f5' }}>

                          <div>
                            <p style={{ fontWeight: '600' }}>{`${memberName}'s Commitment`}</p>
                            <p >{commitment?.title}</p>
                            {/* <p >Description : {commitment?.description}</p> */}
                            {/* <p >Description : {<ReactMarkdown>{commitment?.description}</ReactMarkdown>}</p> */}


                          </div>

                        </div>
                      </>
                    }
                  >
                    <div style={{ borderRadius: '0.6em', padding: '0em 0.5em 0.2em 0.5em', backgroundColor: '#f5f5f5' }}>

                      <div>
                        <Row style={{ display: 'flex', justifyContent: 'flex-start', gap: '0.5em', width: '100%', overflow: 'hidden' }}>
                          <p style={{ fontWeight: '400' }}>Description :</p>
                          <Col span={19}>
                            {/* <p style={{ whiteSpace: 'initial', overflow: 'break-word', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>{commitment?.title ? commitment?.title : 'Title not provided'}</p> */}
                            <ReactMarkdown>{commitment?.description}</ReactMarkdown>
                          </Col>
                        </Row>

                      </div>

                    </div>
                  </Collapse.Panel>
                }

              </Collapse>


            </div>}

            <Form form={form} layout="vertical" className="ckeditor-parent" onFinish={values => onSubmit(values)} required={false} scrollToFirstError={true}>
              {/* <Row gutter={24} justify="space-between">
            <Col span={16}>
              <SelectField
                choices={choices}
                name={['coachingCommitmentData', 'customusercommitmentId']}
                label="Select from your custom coaching commitments"
                disabled={isNew}
                onChangeSelect={id => {
                  const coachingCommitment =
                    getMyCustomizedCoachingCommitments.edges.length > 0 &&
                    getMyCustomizedCoachingCommitments.edges.filter(({ node }) => getIntFromString(node.id) === id)[0];
                  console.log(coachingCommitment);
                  form.setFieldsValue(
                    _.set(
                      form.getFieldsValue(true),
                      ['coachingCommitmentData', 'title'],
                      coachingCommitment.node.title || ''
                    )
                  );
                  form.setFieldsValue(
                    _.set(
                      form.getFieldsValue(true),
                      ['coachingCommitmentData', 'commitmentId'],
                      getIntFromString(coachingCommitment.node.commitment.id) || ''
                    )
                  );
                }}
              />
              <Col span={0}>
                <TextField
                  name={['coachingCommitmentData', 'commitmentId']}
                  label="Title for your commitment"
                  disabled={!isNew}
                />
              </Col>
            </Col>
            <Col span={4} align="right" style={{ alignItems: 'center' }}>
              {'Add New '}
              <Switch
                checked={isNew}
                onChange={v => {
                  setIsNew(v);
                  form.resetFields();
                }}
              />
            </Col>
          </Row>
          <Row>
            <Divider style={{ width: '100%', alignItems: 'flex-start' }}>OR</Divider>
          </Row> */}
              <Row className="ckeditor-parent">
                <Col span={24} style={{ marginBottom: '17px', marginTop: '6px' }}>
                  <label >Title for your coaching commitment</label>
                  <input type="text" label="Title for your commitment" className='title-input-coach-commitment' onChange={(e) => setTitle(e.target.value)} />
                  <Row style={{ marginTop: '1.7em', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <label >Set a Deadline for your Coaching Commitment</label>
                    <DatePicker
                      // defaultPickerValue={moment(new Date(commitment?.dueDate))}
                      // disabledDate={disabledDate}
                      disabledDate={disabledDate}
                      defaultValue={commitment?.dueDate ? moment(new Date(commitment?.dueDate)) : moment(new Date().toISOString())}
                      name='dueDate'
                      label="dueDate"
                      allowClear={false}
                      style={{ color: '#84A100', borderRadius: 5, width: '30%' }}
                      format="YYYY-MM-DD"
                      size="small"
                      onChange={e => setDueDate(new Date(e._d))}
                      suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: '#84A100' }} />}
                      className='dpick_add_comm_coach'
                    />
                  </Row>
                  <Row className="ckeditor-parent">
                    <Col className="ckeditor-parent" lg={16} md={16} xs={24}>
                      {/* <TextField
                  className="ckeditor-parent"
                    name={['coachingCommitmentData', 'title']}
                    label="Title for your commitment"
                    // disabled={!isNew}
                  /> */}
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{ marginBottom: '20px' }} className="ckeditor-parent">
                  <div className="ckeditor-parent"  >
                    {/* <CKEditorField
              name={CKEditorFieldName}
              label="Description for your commitment"
              // initialValue={SurveyById && SurveyById.termsConditions}
              offset={0}
              // disabled={!isNew}
              // rules={[{ required: true, message: 'All questions are required!' }]}
              /> */}
                    <label >Description for your coaching commitment</label>
                    <textarea type="text" className='description-input-coach-commitment' onChange={(e) => setDescription(e.target.value)} />
                    {/* <TextField
            
                          name={CKEditorFieldName}
                          label="Description for your commitment"
                          offset={0}
                          // rules={[{ required: true, message: 'Value is required!' }]}
                          // onChange={e => handleChange(e?.target?.value, 0)}
            /> */}
                  </div>

                </Col>
                <Col span={24} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Button style={{ borderRadius: '0.4em', fontWeight: '600' }} type="primary" htmlType="submit">
                    Add
                  </Button>
                  <Button onClick={() => setRecoModal(true)} className='reco_btn' style={{ borderRadius: '0.4em', fontWeight: '600' }} type="primary" >
                    Recommended Coaching Commitments
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>

          {recoModal && <Col
            span={8}
            style={{ backgroundColor: '#fff', padding: '1.65em', borderRadius: '1.2em' }}
            visible={recoModal}
          >
            <>
              <div style={{ position: 'relative', width: '100%' }}>
                <CloseOutlined onClick={() => setRecoModal(false)} className='main_close_btn' style={{ position: 'absolute', right: '0', width: '2em', height: '2em' }} />
              </div>
              <h5 style={{ marginBottom: '1em', fontWeight: '600', fontSize: '1.1em' }}>Recommended Coaching Commitments</h5>
              {recoCommitments?.edges?.map(comm => (
                <Card
                  bordered={false}
                  style={{
                    // boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
                    boxShadow: '0px 4px 11.800000190734863px 0px rgba(0, 0, 0, 0.1)',
                    borderRadius: "0.4em",
                    padding: '0.42em 0'
                  }}
                  bodyStyle={{ padding: "12px" }}
                >
                  <Row justify="space-between" gutter={[12, 12]}>
                    <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Hoverable /* style={{ whiteSpace: 'nowrap', overflow: 'scroll' }} */
                        style={{ fontFamily: 'poppins, sans-serif' }}
                      >
                        {comm?.node?.title}
                      </Hoverable>
                    </Col>

                    <Col span={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <img
                        style={{ width: '1.5em', height: '1.5em' }}
                        src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1702890685/plus_sign_add_cj3chn.svg"
                        size="sm"
                        tooltipSuffix="Commitment"
                        onClick={() =>
                          handleSelectDefaultToolCommitment(
                            getIntFromString(comm?.node?.id)
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              ))}

            </>
          </Col>}
        </Row>

      </Modal>


    </>
  );
};

export default ManagerAddCommitmentView;

const Hoverable = styled.div`
  white-space: wrap;
  overflow: ellipsis;
  :hover {
    cursor: default;
  }
`;
