import React from 'react';
import { Row, Col, Form, Button } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';
import { getBase64StringFromInt, getEmployeeBase64StringFromInt, getUserBase64StringFromInt } from '../../look';
import { ButtonsComponent, CKEditorField, getIntFromString, SurveyAutoCompleteEndUser } from '../../look';

export const CKEditorFieldName = 'message';

const CompareRequest = props => {
  const { employee, onSubmit, setButton, form, isEmployee, setRequest } = props;

  // React.
  const name = isEmployee
    ? `${employee?.user?.firstName} ${employee?.user?.lastName}`
    : employee?.firstName && employee?.lastName
    ? `${employee?.firstName} ${employee?.lastName}`
    : `${employee.username}`;
  return (
    <>
      <br />
      <br />
      <Row justify="space-between">
        <h3>Comparison request to {name}</h3>
        <Button icon={<CaretLeftOutlined />} shape="circle" onClick={() => setRequest(null)} />
      </Row>

      <br />
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={values =>
              onSubmit({
                ...values,
                receiverId: getIntFromString(isEmployee ? employee?.user?.id  : getEmployeeBase64StringFromInt(employee?.id)),
                name
              })
            }
            required={false}
            scrollToFirstError={true}
          >
            {/* <Col span={10} offset={1}>
                      <Row>
                        <Col span={14}>
                          <TextField
                            type={'textarea'} 
                            name="message"
                            label="message"
                          />
                        </Col>
                      </Row>
                    </Col> */}

            {!employee?.compare && (
              <Row>
                <Col span={24}>
                  <SurveyAutoCompleteEndUser
                    name="surveyId"
                    label="Survey"
                    offset={2}
                    rules={[{ required: true, message: 'Survey is required!' }]}
                    margin={true}
                  />
                </Col>

                <Col span={24}>
                  <CKEditorField name={CKEditorFieldName} label="Message" />
                </Col>
              </Row>
            )}

            {employee?.compare && (
              <Row>
                <Col span={10}>
                  <SurveyAutoCompleteEndUser
                    name="surveyId"
                    label="Survey"
                    offset={2}
                    rules={[{ required: true, message: 'Survey is required!' }]}
                    margin={true}
                  />
                </Col>
              </Row>
            )}

            <ButtonsComponent title={'Send'} edit={true} setButton={setButton} saveadd={false} saveedit={false} />
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CompareRequest;
