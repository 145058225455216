import React from 'react';
import { Button, Modal, Spin } from 'antd';

import { compose } from '../../core';
import { AddIcon, EditIcon, getIntFromString, withMarkdownFieldValue } from '../../look';

import WeightedQuestionFormComponent from '../components/WeightedQuestionFormComponent';
import { CKEditorFieldName } from '../components/WeightedQuestionFormComponent';

import { withEditWeightedQuestion, withAddWeightedQuestion } from './TextSectionOperations';

const AddWeightedQuestion = props => {
  const { btn, /* onSubmit, */ addWeightedQuestion, editWeightedQuestion, btnText, weightedQuestion } = props;
  const [visible, setVisible] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);

  const handleSubmit = async ({ ...values }) => {
    setActionLoading(true);
    try {
      if (weightedQuestion?.id) {
        const response = await editWeightedQuestion({ id: getIntFromString(weightedQuestion.id), ...values });
        setActionLoading(false);
        response && setVisible(false);
      } else {
        const response = await addWeightedQuestion({
          weightedQuestionData: values
        });
        setActionLoading(false);
        response && setVisible(false);
        // response && onSubmit(response);
      }
    } catch (e) {
      setActionLoading(false);
      throw Error(e.message);
    }
  };

  return (
    <>
      {btn === 'add' ? (
        <AddIcon tooltipSuffix="Weighted Question" color="primary" size="sm" onClick={() => setVisible(true)} />
      ) : (
        <EditIcon tooltipSuffix="Weighted Question" color="primary" size="sm" onClick={() => setVisible(true)} />
      )}

      <Modal
        destroyOnClose
        centered
        visible={/* true || */ visible}
        onCancel={() => setVisible(false)}
        // footer={null}
        width="75%"
        title={btn === 'add' ? 'Add weighted question' : 'Edit weighted question'}
        footer={null}
      >
        <Spin spinning={actionLoading} size="medium">
          <WeightedQuestionFormComponent
            btnText={btn === 'add' ? 'Add' : 'Save'}
            weightedQuestion={weightedQuestion}
            setVisible={setVisible}
            onSubmit={handleSubmit}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default compose(withEditWeightedQuestion, withAddWeightedQuestion)(AddWeightedQuestion);
