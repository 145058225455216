import { graphql } from '@apollo/client/react/hoc';

import { withOperation } from '../../look/components/functions';

import { TOOL_BY_ID_ADMIN_QUERY } from '../../tool/graphql/ToolByIdAdmin.gql';

import { GET_ALL_CONDITION } from '../graphql/GetAllCondition.gql';

import { ADD_CONDITION_MUTATION } from '../graphql/AddCondition.gql';
import { ADD_TEXT_SECTION_MUTATION } from '../graphql/AddTextSection.gql';
import { ADD_OPERAND_MUTATION } from '../graphql/AddOperand.gql';
import { ADD_WEIGHTED_QUESTION_MUTATION } from '../graphql/AddWeightedQuestion.gql';

import { EDIT_TEXT_SECTION } from '../graphql/EditTextSection.gql.js';
import { EDIT_CONDITION } from '../graphql/EditCondition.gql';
import { EDIT_OPERAND } from '../graphql/EditOperand.gql';
import { EDIT_WEIGHTED_QUESTION } from '../graphql/EditWeightedQuestion.gql';

import { DELETE_CONDITION } from '../graphql/DeleteCondition.gql';
import { DELETE_TEXT_SECTION } from '../graphql/DeleteTextSection.gql';

export const withAddCondtion = Component =>
  withOperation({
    mutation: ADD_CONDITION_MUTATION,
    funcName: 'addCondition',
    query: GET_ALL_CONDITION,
    queryName: 'getAllCondition',
    node: ['createCondition', 'condition'],
    type: 'add'
    // variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withAddTextSection = Component =>
  withOperation({
    mutation: ADD_TEXT_SECTION_MUTATION,
    funcName: 'addTextSection',
    query: GET_ALL_CONDITION,
    queryName: 'getAllTextSection',
    node: ['createTextSection', 'textSection'],
    type: 'add'
    // variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withAddOperand = Component =>
  withOperation({
    mutation: ADD_OPERAND_MUTATION,
    funcName: 'addOperand',
    query: GET_ALL_CONDITION,
    queryName: 'getAllTextSection',
    node: ['createOperand', 'operand'],
    type: 'add'
    // variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withAddWeightedQuestion = Component =>
  withOperation({
    mutation: ADD_WEIGHTED_QUESTION_MUTATION,
    funcName: 'addWeightedQuestion',
    query: GET_ALL_CONDITION,
    queryName: 'getAllTextSection',
    node: ['createWeightedQuestion', 'weightedQuestion'],
    type: 'add'
    // variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withEditTextSection = Component =>
  graphql(EDIT_TEXT_SECTION, {
    props: ({ mutate }) => ({
      editTextSection: async values => {
        try {
          const {
            data: { updateTextSection }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateTextSection.textSection;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditCondition = Component =>
  graphql(EDIT_CONDITION, {
    props: ({ mutate }) => ({
      editCondition: async values => {
        try {
          const {
            data: { updateCondition }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateCondition.condition;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditOperand = Component =>
  graphql(EDIT_OPERAND, {
    props: ({ mutate }) => ({
      editOperand: async values => {
        try {
          const {
            data: { updateOperand }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateOperand.operand;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditWeightedQuestion = Component =>
  graphql(EDIT_WEIGHTED_QUESTION, {
    props: ({ mutate }) => ({
      editWeightedQuestion: async values => {
        try {
          const {
            data: { updateWeightedQuestion }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateWeightedQuestion.weightedQuestion;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withDeleteCondition = Component =>
  withOperation({
    mutation: DELETE_CONDITION,
    mutationVarName: 'id',
    funcName: 'deleteCondition',
    query: GET_ALL_CONDITION,
    queryName: 'getAllCondition',
    node: ['deleteCondition', 'condition'],
    type: 'delete'
    // variable: { type: 'all' }
  })(Component);

export const withDeleteTextSection = Component =>
  withOperation({
    mutation: DELETE_TEXT_SECTION,
    mutationVarName: 'id',
    funcName: 'deleteTextSection',
    query: TOOL_BY_ID_ADMIN_QUERY,
    queryName: ['getToolByIdAdmin', 'textsection'],
    node: ['deleteTextSection', 'textSection'],
    type: 'delete',
    variable: { type: 'all', custom: true, varName: 'toolId' }
  })(Component);
