import React from 'react';

// import { withMe } from './UserOperations';
import { compose } from '../../core';
import PersonalDashboardView from '../components/PersonalDashboardView';
import { withMyResiliencePulseCheck } from './DashboardOperations';
import { withMe } from '../../user/containers/UserOperations';

const PersonalDashboard = props => {
  return <PersonalDashboardView {...props} />;
};

export default compose(withMe, withMyResiliencePulseCheck)(PersonalDashboard);
