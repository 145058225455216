import { message } from 'antd';
import _ from 'lodash';
import { graphql } from '@apollo/client/react/hoc';

import surveyResolvers from '../resolvers';

// Query
// import { EMAIL_PERCEPTION_REPORT_QUERY } from '../graphql/EmailPerceptionReport.gql';
import { ALL_SURVEY_QUERY } from '../graphql/AllSurveysQuery.gql';
import { ALL_ORG_SURVEY_BY_ORG_ID_QUERY } from '../graphql/AllOrgSurveysByOrgIdQuery.gql';
import { ORG_SURVEY_BY_VERTICAL_ID_QUERY } from '../graphql/OrgSurveysByVerticalIdQuery.gql';
import { ALL_ORG_SURVEY_BY_VERTICAL_QUERY } from '../graphql/AllOrgSurveysByVerticalQuery.gql';
import { ORG_SURVEY_BY_TEAM_ID_QUERY } from '../graphql/OrgSurveysByTeamIdQuery.gql';
import { ALL_ORG_SURVEY_BY_TEAM_QUERY } from '../graphql/AllOrgSurveysByTeamQuery.gql';
import { ALL_ORG_SURVEY_BY_IMPLICIT_ORG_ID_QUERY, ALL_ORG_SURVEY_BY_IMPLICIT_ORG_ID_QUERY_FOR_TA, ALL_ORG_SURVEY_TITLES_BY_IMPLICIT_ORG_ID_QUERY } from '../graphql/AllOrgSurveysByImplicitOrgId.gql';
import { ALL_PUBLIC_SURVEY_QUERY } from '../graphql/AllPublicSurveysQuery.gql';
import { ALL_GROUPS_FOR_SURVEY_BY_SURVEY_ID_QUERY } from '../graphql/AllGroupsForSurveyBySurveyIdQuery.gql';
import { ALL_QUESTIONS_FOR_A_GROUP_BY_GROUP_ID_QUERY } from '../graphql/AllQuestionsForAGroupByGroupIdQuery.gql';
import { GET_ALL_TOKENS_LIST_QUERY } from '../graphql/GetAllTokensListQuery.gql';
import { ALL_RESPONSES_BY_USER_ID_QUERY } from '../graphql/AllResponsesByUserId.gql';
import { SURVEY_BY_ID_QUERY } from '../graphql/SurveyByIdQuery.gql';
import { ORG_SURVEY_BY_ID_QUERY } from '../graphql/OrgSurveyByIdQuery.gql';
import { PUBLIC_SURVEY_BY_ID_QUERY } from '../graphql/PublicSurveyByIdQuery.gql';
import { QUESTION_BY_ID_QUERY } from '../graphql/QuestionByIdQuery.gql';
import { GROUP_BY_ID_QUERY } from '../graphql/GroupByIdQuery.gql';
import { RESPONSE_BY_RESPONSE_ID_QUERY } from '../graphql/ResponseByResponseId.gql';

// Client
import { SURVEY_STATE_QUERY } from '../graphql/SurveyStateQuery.client.gql';
import { UPDATE_SURVEY_FILTER } from '../graphql/UpdateSurveyFilter.client.gql';

// Mutation
import { ADD_GROUP_MUTATION } from '../graphql/AddGroupMutation.gql';
import { ADD_QUESTION_MUTATION } from '../graphql/AddQuestionMutation.gql';
import { ADD_CHOICE_MUTATION } from '../graphql/AddChoiceMutation.gql';
import { ADD_SURVEY_MUTATION } from '../graphql/AddSurveyMutation.gql';
import { CREATE_TOKEN_MUTATION } from '../graphql/CreateTokenMutation.gql';
import { CREATE_RESPONSE_MUTATION } from '../graphql/CreateResponseMutations.gql';
import { EDIT_RESPONSE_MUTATION } from '../graphql/EditResponseMutations.gql';
import { SUBMIT_TOKEN_RESPONSE_MUTATION } from '../graphql/SubmitTokenResponseMutation.gql';
import { EDIT_GROUP } from '../graphql/EditGroup.gql';
import { EDIT_SURVEY } from '../graphql/EditSurvey.gql';
import { EDIT_QUESTION } from '../graphql/EditQuestion.gql';
import { EDIT_CHOICE } from '../graphql/EditChoice.gql';
import { DELETE_TOKEN } from '../graphql/DeleteToken.gql';
import { DELETE_SURVEY } from '../graphql/DeleteSurvey.gql';
import { DELETE_GROUP } from '../graphql/DeleteGroup.gql';
import { DELETE_QUESTION } from '../graphql/DeleteQuestion.gql';
import { DELETE_CHOICE } from '../graphql/DeleteChoice.gql';
import { DELETE_RESPONSE } from '../graphql/DeleteResponse.gql';

import { PAGINATION_LIMIT } from '../../../config';
import { removeTypename } from '../../core/clientStorage';
import { withOperation } from '../../look';

// Query
export const withAllResponsesByUserId = Component =>
  graphql(ALL_RESPONSES_BY_USER_ID_QUERY, {
    options: ({ userId, filter, orderBy, pagination }) => {
      return {
        variables: {
          id: userId,
          ...pagination,
          ...filter,
          orderBy
        }
      };
    },
    props({ data }) {
      const { loading, error, allResponsesByUserId, subscribeToMore, updateQuery } = data;
      return { loading, error, allResponsesByUserId, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllSurveysQuery = Component =>
  graphql(ALL_SURVEY_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allSurveysForTokens, subscribeToMore, updateQuery } = data;
      return { loading, error, allSurveysForTokens, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllOrgSurveysByOrgIdQuery = Component =>
  graphql(ALL_ORG_SURVEY_BY_ORG_ID_QUERY, {
    options: ({ filter, orderBy, pagination, match, navigation }) => {
      let id = '';
      if (match) {
        id = match.params.id;
      } else if (navigation) {
        id = navigation.state.params.id;
      }

      return {
        variables: {
          orgId: Number(id),
          orderBy,
          ...filter,
          ...pagination
        }
      };
    },
    props({ data }) {
      const { loading, error, allOrgSurveysByOrgId, subscribeToMore, updateQuery } = data;
      return { loading, error, allOrgSurveysByOrgId, subscribeToMore, updateQuery };
    }
  })(Component);

export const withOrgSurveysByVerticalIdQuery = Component =>
  graphql(ORG_SURVEY_BY_VERTICAL_ID_QUERY, {
    options: ({ filter, orderBy, pagination, match, navigation }) => {
      let id = '';
      if (match) {
        id = match.params.id;
      } else if (navigation) {
        id = navigation.state.params.id;
      }
      return {
        variables: {
          verticalId: Number(id),
          orderBy,
          ...filter,
          ...pagination
        }
      };
    },
    props({ data }, history) {
      const { loading, error, orgSurveyByVerticalId, subscribeToMore, updateQuery } = data;
      return { loading, error, orgSurveyByVerticalId, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllOrgSurveysByVerticalQuery = Component =>
  graphql(ALL_ORG_SURVEY_BY_VERTICAL_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: {
          id: Number(id),
          first: PAGINATION_LIMIT
        }
      };
    },
    props({ data }, history) {
      const { loading, error, orgSurveyByVertical, subscribeToMore, updateQuery } = data;
      return { loading, error, orgSurveyByVertical, subscribeToMore, updateQuery };
    }
  })(Component);

export const withOrgSurveysByTeamIdQuery = Component =>
  graphql(ORG_SURVEY_BY_TEAM_ID_QUERY, {
    options: ({ filter, orderBy, pagination, match, navigation }) => {
      let id = '';
      if (match) {
        id = match.params.id;
      } else if (navigation) {
        id = navigation.state.params.id;
      }
      return {
        variables: {
          teamId: Number(id),
          orderBy,
          ...filter,
          ...pagination
        }
      };
    },
    props({ data }, history) {
      const { loading, error, orgSurveyByTeamId, subscribeToMore, updateQuery } = data;
      return { loading, error, orgSurveyByTeamId, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllOrgSurveysByTeamQuery = Component =>
  graphql(ALL_ORG_SURVEY_BY_TEAM_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: {
          id: Number(id),
          first: PAGINATION_LIMIT
        }
      };
    },
    props({ data }, history) {
      const { loading, error, orgSurveyByTeam, subscribeToMore, updateQuery } = data;
      return { loading, error, orgSurveyByTeam, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllOrgSurveysByImplicitOrgIdQuery = Component =>
  graphql(ALL_ORG_SURVEY_BY_IMPLICIT_ORG_ID_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return {
        variables: {
          ...filter,
          ...pagination,
          orderBy
        }
      };
    },
    props({ data }) {
      const { loading, error, allOrgSurveysByImplicitOrgId, subscribeToMore, updateQuery } = data;
      return {
        allOrgSurveysByImplicitOrgIdLoading: loading,
        error,
        allOrgSurveysByImplicitOrgId,
        allOrgSurveysByImplicitOrgIdSubscribeToMore: subscribeToMore,
        updateQuery
      };
    }
  })(Component);

  // the below query is for take assessment in dashboard with only the required data 

  export const withAllOrgSurveysByImplicitOrgIdQueryForTA = Component =>
  graphql(ALL_ORG_SURVEY_BY_IMPLICIT_ORG_ID_QUERY_FOR_TA, {
    options: ({ filter, orderBy, pagination }) => {
      return {
        variables: {
          ...filter,
          ...pagination,
          orderBy
        }
      };
    },
    props({ data }) {
      const { loading, error, allOrgSurveysByImplicitOrgId, subscribeToMore, updateQuery } = data;
      return {
        allOrgSurveysByImplicitOrgIdLoading: loading,
        error,
        allOrgSurveysByImplicitOrgId,
        allOrgSurveysByImplicitOrgIdSubscribeToMore: subscribeToMore,
        updateQuery
      };
    }
  })(Component);

// This query below is for the dashboard
  export const withAllOrgSurveysTitlesByImplicitOrgIdQuery = Component =>
  graphql(ALL_ORG_SURVEY_TITLES_BY_IMPLICIT_ORG_ID_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return {
        variables: {
          ...filter,
          ...pagination,
          orderBy
        }
      };
    },
    props({ data }) {
      const { loading, error, allOrgSurveysByImplicitOrgId, subscribeToMore, updateQuery } = data;
      return {
        allOrgSurveysByImplicitOrgIdLoading: loading,
        error,
        allOrgSurveysByImplicitOrgId,
        allOrgSurveysByImplicitOrgIdSubscribeToMore: subscribeToMore,
        updateQuery
      };
    }
  })(Component);

export const withAllPublicSurveysQuery = Component =>
  graphql(ALL_PUBLIC_SURVEY_QUERY, {
    options: props => {
      return {
        variables: {
          first: PAGINATION_LIMIT
        }
      };
    },
    props({ data }, history) {
      const { loading, error, allPublicSurveys, subscribeToMore, updateQuery } = data;
      return { loading, error, allPublicSurveys, allPublicSurveysSubscribeToMore: subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllGroupsForSurveyBySurveyIdQuery = Component =>
  graphql(ALL_GROUPS_FOR_SURVEY_BY_SURVEY_ID_QUERY, {
    options: ({ filter, match, navigation, orderBy, pagination }) => {
      let id = '';
      if (match) {
        id = match.params.id;
      } else if (navigation) {
        id = navigation.state.params.id;
      }

      if (filter.sequence_Icontains === '') delete filter.sequence_Icontains;
      else filter.sequence_Icontains = Number(filter.sequence_Icontains);
      return {
        variables: {
          ...pagination,
          ...filter,
          orderBy,
          id: Number(id)
        }
      };
    },
    props({ data }, history) {
      const { loading, error, fetchMore, allGroupsForSurveyBySurveyId, subscribeToMore, updateQuery } = data;
      const loadData = (startCursor, dataDelivery, isEdge, endCursor, pageSize) => {
        // isEdge ->  last, next, prev
        const variables =
          dataDelivery === 'add'
            ? { after: startCursor }
            : isEdge === 'custom-cursor'
            ? { first: pageSize, after: startCursor, before: endCursor }
            : isEdge === 'last'
            ? { first: undefined, last: PAGINATION_LIMIT }
            : isEdge === 'first'
            ? { first: PAGINATION_LIMIT }
            : isEdge === 'next'
            ? { after: startCursor }
            : { first: undefined, last: PAGINATION_LIMIT, before: startCursor };

        return fetchMore({
          variables,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const totalCount = fetchMoreResult.allGroupsForSurveyBySurveyId.totalCount;
            const newEdges = fetchMoreResult.allGroupsForSurveyBySurveyId.edges;
            const pageInfo = fetchMoreResult.allGroupsForSurveyBySurveyId.pageInfo;
            const displayedEdges =
              dataDelivery === 'add' ? [...previousResult.allGroupsForSurveyBySurveyId.edges, ...newEdges] : newEdges;

            return {
              // By returning `cursor` here, we update the `fetchMore` function
              // to the new cursor.
              allGroupsForSurveyBySurveyId: {
                totalCount,
                edges: displayedEdges,
                pageInfo,
                __typename: 'AllPublicSurveys'
              }
            };
          }
        });
      };
      return { loading, error, allGroupsForSurveyBySurveyId, subscribeToMore, updateQuery, loadData };
    }
  })(Component);

export const withAllQuestionsForAGroupByGroupIdQuery = Component =>
  graphql(ALL_QUESTIONS_FOR_A_GROUP_BY_GROUP_ID_QUERY, {
    options: ({ filter, match, navigation, orderBy, pagination }) => {
      let id = '';
      if (match) {
        id = match.params.id;
      } else if (navigation) {
        id = navigation.state.params.id;
      }

      if (filter.sequence_Icontains === '') delete filter.sequence_Icontains;
      else filter.sequence_Icontains = Number(filter.sequence_Icontains);

      return {
        variables: {
          ...pagination,
          ...filter,
          orderBy,
          id: Number(id)
        }
      };
    },
    props({ data }, history) {
      const { loading, error, allQuestionsForAGroupByGroupId, subscribeToMore, updateQuery } = data;
      return { loading, error, allQuestionsForAGroupByGroupId, subscribeToMore, updateQuery };
    }
  })(Component);

export const withGetAllTokensListQuery = Component =>
  graphql(GET_ALL_TOKENS_LIST_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      let newFilter = filter;
      if (filter.invited === '') newFilter = _.omit(newFilter, 'invited');
      if (filter.valid === '') newFilter = _.omit(newFilter, 'valid');
      return { variables: { ...pagination, ...newFilter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllTokensList, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllTokensList, subscribeToMore, updateQuery };
    }
  })(Component);

export const withSurveyById = Component =>
  graphql(SURVEY_BY_ID_QUERY, {
    options: props => {
      let id = '0';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, SurveyById, subscribeToMore, updateQuery } }) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, SurveyById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withOrgSurveyById = Component =>
  graphql(ORG_SURVEY_BY_ID_QUERY, {
    options: props => {
      let id = '0';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, orgSurveyById, subscribeToMore, updateQuery } }) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, orgSurveyById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withGroupById = Component =>
  graphql(GROUP_BY_ID_QUERY, {
    options: props => {
      let id = '0';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, groupById, subscribeToMore, updateQuery } }) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, groupById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withQuestionById = Component =>
  graphql(QUESTION_BY_ID_QUERY, {
    options: props => {
      let id = '0';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, questionById, subscribeToMore, updateQuery } }) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, questionById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withPublicSurveyById = Component =>
  graphql(PUBLIC_SURVEY_BY_ID_QUERY, {
    options: props => {
      let id = '0';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, publicSurveyById, subscribeToMore, updateQuery } }) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, publicSurveyById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withResponseByResponseId = Component =>
  graphql(RESPONSE_BY_RESPONSE_ID_QUERY, {
    options: props => {
      let id = '0';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, responseByResponseId, subscribeToMore, updateQuery } }) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, responseByResponseId, subscribeToMore, updateQuery };
    }
  })(Component);

// export const withEmailPerceptionReport = Component =>
//   graphql(EMAIL_PERCEPTION_REPORT_QUERY, {
//     options: props => {
//       let id = '0';
//       if (props.match) {
//         id = props.match.params.id;
//       } else if (props.navigation) {
//         id = props.navigation.state.params.id;
//       }
//       return {
//         variables: { id: Number(id) }
//       };
//     },
//     props({ data: { loading, error, orgSurveyById, subscribeToMore, updateQuery } }) {
//       // if (error) {
//       //   throw new Error(error.message);
//       // }
//       return { loading, error, orgSurveyById, subscribeToMore, updateQuery };
//     }
//   })(Component);

// Mutation
export const withCreateResponse = Component =>
  graphql(CREATE_RESPONSE_MUTATION, {
    props: ({ mutate, history }) => ({
      createResponse: async values => {
        try {
          const {
            data: { createResponse }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Submitted response!!');
          return createResponse.response;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

export const withAddGroup = Component =>
  withOperation({
    mutation: ADD_GROUP_MUTATION,
    funcName: 'addGroup',
    query: ALL_GROUPS_FOR_SURVEY_BY_SURVEY_ID_QUERY,
    queryName: 'allGroupsForSurveyBySurveyId',
    node: ['createGroup', 'group'],
    type: 'add',
    variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withAddQuestion = Component =>
  withOperation({
    mutation: ADD_QUESTION_MUTATION,
    funcName: 'addQuestion',
    query: ALL_QUESTIONS_FOR_A_GROUP_BY_GROUP_ID_QUERY,
    queryName: 'allQuestionsForAGroupByGroupId',
    node: ['createQuestion', 'question'],
    type: 'add',
    variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withAddChoice = Component =>
  graphql(ADD_CHOICE_MUTATION, {
    props: ({ mutate, history }) => ({
      addChoice: async choiceData => {
        try {
          const {
            data: { createChoice }
          } = await mutate({
            variables: {
              choiceData
            }
          });

          message.destroy();
          return createChoice.choice;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

export const withAddSurvey = Component =>
  withOperation({
    mutation: ADD_SURVEY_MUTATION,
    funcName: 'addSurvey',
    query: ALL_SURVEY_QUERY,
    queryName: 'allSurveysForTokens',
    node: ['createSurvey', 'survey'],
    type: 'add',
    variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withSubmitTokenResponse = Component =>
  graphql(SUBMIT_TOKEN_RESPONSE_MUTATION, {
    props: ({ mutate, history }) => ({
      submitTokenResponse: async values => {
        try {
          const {
            data: { submitTokenResponse }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Submitted response!!');
          return submitTokenResponse.response;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

export const withMultipleCreateToken = Component =>
  graphql(CREATE_TOKEN_MUTATION, {
    props: ({ mutate }) => ({
      createToken: async values => {
        const {
          data: { createToken },
          errors
        } = await mutate({
          variables: {
            ...values
          }
        });
        return { token: createToken.token, errors };
      }
    })
  })(Component);

export const withCreateToken = Component =>
  withOperation({
    mutation: CREATE_TOKEN_MUTATION,
    funcName: 'createToken',
    query: GET_ALL_TOKENS_LIST_QUERY,
    queryName: 'getAllTokensList',
    node: ['createToken', 'token'],
    type: 'add',
    variable: { type: 'all', custom: true, omit: ['valid'] }
  })(Component);

export const withEditResponse = Component =>
  graphql(EDIT_RESPONSE_MUTATION, {
    props: ({ mutate }) => ({
      editResponse: async values => {
        try {
          const {
            data: { updateResponse }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateResponse.response;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditGroup = Component =>
  graphql(EDIT_GROUP, {
    props: ({ mutate }) => ({
      editGroup: async values => {
        try {
          const {
            data: { updateGroup }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateGroup.group;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditSurvey = Component =>
  graphql(EDIT_SURVEY, {
    props: ({ mutate }) => ({
      editSurvey: async values => {
        try {
          const {
            data: { updateSurvey }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateSurvey.survey;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditQuestion = Component =>
  graphql(EDIT_QUESTION, {
    props: ({ mutate }) => ({
      editQuestion: async values => {
        try {
          const {
            data: { updateQuestion }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateQuestion.question;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditChoice = Component =>
  graphql(EDIT_CHOICE, {
    props: ({ mutate }) => ({
      editChoice: async values => {
        try {
          const {
            data: { updateChoice }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateChoice.choice;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withDeleteToken = Component =>
  withOperation({
    mutation: DELETE_TOKEN,
    mutationVarName: 'token',
    funcName: 'deleteToken',
    query: GET_ALL_TOKENS_LIST_QUERY,
    queryName: 'getAllTokensList',
    node: ['deleteToken', 'token'],
    type: 'delete',
    variable: { type: 'all', custom: true, omit: ['valid'] }
  })(Component);

export const withDeleteGroup = Component =>
  withOperation({
    mutation: DELETE_GROUP,
    mutationVarName: 'id',
    funcName: 'deleteGroup',
    query: ALL_GROUPS_FOR_SURVEY_BY_SURVEY_ID_QUERY,
    queryName: 'allGroupsForSurveyBySurveyId',
    node: ['deleteGroup', 'group'],
    type: 'delete',
    variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withDeleteSurvey = Component =>
  withOperation({
    mutation: DELETE_SURVEY,
    mutationVarName: 'id',
    funcName: 'deleteSurvey',
    query: ALL_SURVEY_QUERY,
    queryName: 'allSurveysForTokens',
    node: ['deleteSurvey', 'survey'],
    type: 'delete',
    variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withDeleteQuestion = Component =>
  withOperation({
    mutation: DELETE_QUESTION,
    mutationVarName: 'id',
    funcName: 'deleteQuestion',
    query: ALL_QUESTIONS_FOR_A_GROUP_BY_GROUP_ID_QUERY,
    queryName: 'allQuestionsForAGroupByGroupId',
    node: ['deleteQuestion', 'question'],
    type: 'delete',
    variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withDeleteChoice = Component =>
  withOperation({
    mutation: DELETE_CHOICE,
    mutationVarName: 'id',
    funcName: 'deleteChoice',
    // query: ALL_QUESTIONS_FOR_A_GROUP_BY_GROUP_ID_QUERY,
    // queryName: 'allQuestionsForAGroupByGroupId',
    node: ['deleteChoice', 'choice'],
    type: 'delete'
    // variable: { type: 'all', custom: true, omit: ['sequence_Icontains'], varName: 'id' }
  })(Component);

export const withDeleteResponse = Component =>
  graphql(DELETE_RESPONSE, {
    props: ({ mutate }) => ({
      deleteResponse: async id => {
        try {
          const {
            data: { deleteResponse }
          } = await mutate({ variables: { id } });
          return deleteResponse.response;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

// Client
export const withSurveyState = Component =>
  graphql(SURVEY_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.surveyState;
      const surveyState = { ...removeTypename(rest), orderBy };
      return { ...surveyState, stateLoading: data.loading };
    }
  })(Component);

export const withSurveyFilterUpdating = Component =>
  graphql(UPDATE_SURVEY_FILTER, {
    props: ({ mutate }) => ({
      onOrderByChange(orderBy) {
        mutate({ variables: { orderBy } });
      },
      onPaginationChange(pagination) {
        mutate({ variables: { pagination } });
      },
      onFiltersRemove() {
        mutate({ variables: { ...surveyResolvers.defaults.surveyState } });
      },
      onNameChange(name_Icontains) {
        mutate({ variables: { filter: { name_Icontains } } });
      },
      onDescriptionChange(description_Icontains) {
        mutate({ variables: { filter: { description_Icontains } } });
      },
      onLabelChange(label_Icontains) {
        mutate({ variables: { filter: { label_Icontains } } });
      },
      onHelpTextChange(helpText_Icontains) {
        mutate({ variables: { filter: { helpText_Icontains } } });
      },
      onSequenceChange(sequence_Icontains) {
        mutate({ variables: { filter: { sequence_Icontains } } });
      },
      onQuestionTextChange(questionText_Icontains) {
        mutate({ variables: { filter: { questionText_Icontains } } });
      },
      onQuestionAddressChange(questionAddress_Icontains) {
        mutate({ variables: { filter: { questionAddress_Icontains } } });
      },
      onUserChange(user) {
        mutate({ variables: { filter: { user } } });
      },
      onSurveyChange(survey) {
        mutate({ variables: { filter: { survey } } });
      },
      onCreatedByChange(createdBy) {
        mutate({ variables: { filter: { createdBy } } });
      },
      onInvitedChange(invited) {
        mutate({ variables: { filter: { invited } } });
      },
      onValidChange(valid) {
        mutate({ variables: { filter: { valid } } });
      }
    })
  })(Component);
