import React from 'react';

import { compose } from '../../core';
import { EmployeeResilienceAnchorsView } from '../components/NewEmpResAnchors';
import { tipsData } from '../../dashboard/containers/tipsData';

import { withEmployeeResilienceAnchors } from './ManagerOperations';
import { withGetTips } from '../../user/containers/UserOperations';

const EmployeeResilienceAnchors = props => {
  const { reportingEmployeesResilienceAnchors, memberName, tips, setMemPaneClosed } = props;
  console.log('tipsdhdyf8',tips,tipsData,reportingEmployeesResilienceAnchors)
  return (
    <EmployeeResilienceAnchorsView setMemPaneClosed={setMemPaneClosed} tipsData={tips?.edges} memberName={memberName} title={'Resilience'} resilienceAnchors={reportingEmployeesResilienceAnchors} {...props} />
  );
};

export default compose(withEmployeeResilienceAnchors,withGetTips)(EmployeeResilienceAnchors);
