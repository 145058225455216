import gql from 'graphql-tag';
import { OPERAND_TYPE } from './OperandType.gql';

export const ADD_OPERAND_MUTATION = gql`
  ${OPERAND_TYPE}
  mutation addOperand($operandData: OperandInput!, $weightedQuestions: [Int]) {
    createOperand(operandData: $operandData, weightedQuestions: $weightedQuestions) {
      operand {
        ...OperandType
      }
    }
  }
`;
