import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import { Spin, Modal, Divider, Button, Form, Row, Col, Input } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import { compose } from '../../core';
import { MetaTags } from '../../look';

import { GET_USER_EMAIL_BY_USERNAME_OR_EMAIL_QUERY } from '../graphql/GetUserEmailByUsernameOrEmailQuery.gql';
import { RESEND_ACTIVATION_EMAIL } from '../graphql/ResendActivationEmail.gql';

import { withGetUserEmailByUsernameOrEmail, withResendActivationEmail } from './UserOperations';
import { Link } from 'react-router-dom';

const SendEmailVerify = props => {
  const { client, visible, setVisible, resendActivationEmail, getUserEmailByUsernameOrEmail, email, username } = props;
  const [forgotPass, setForgotPass] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const validateMessages = {
    required: '${label} is required!',
    types: { email: '${label} is not a valid email!' }
  };
  const layout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 24
    }
  };

  const EnterEmailForm = () => {
    // const handleForgotMailPassword = async () => {
    //
    //   try {
    //     const response = await resendActivationEmail(userData.email);
    //
    //     if (response.success) {
    //       setForgotPass(1);
    //     }
    //   } catch (e) {
    //     throw new Error(e.message);
    //   }
    // };
    const sendVerificationEmail = async () => {
      setLoading(true);

      try {
        const { data } = await client.mutate({
          mutation: RESEND_ACTIVATION_EMAIL,
          variables: { email: getUserEmailByUsernameOrEmail.email }
        });
        if (data) {
          setForgotPass(1);
        }
      } catch (e) {
        throw new Error(e.message);
      }
      setForgotPass(0);
      setLoading(false);
    };

    return (
      <div>
        <h3>Verify Your Email Address</h3>
        <LessDivider up />
        <h4>Before proceeding, please check your email for a verification link. If you did not receive the email,</h4>
        {getUserEmailByUsernameOrEmail && getUserEmailByUsernameOrEmail.email ? (
          <Link onClick={sendVerificationEmail}>click here to request another.</Link>
        ) : (
          'You have no email set.'
        )}
      </div>
    );
  };

  const EmailSent = () => (
    <>
      <h2>Check Email</h2>
      {/* <LessDivider up /> */}
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        <MailSent>
          <CheckCircleFilled /> &nbsp;
          <span>Mail Sent</span>
        </MailSent>
        <h3>A password reset email is sent to your registered email.</h3>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                setForgotPass(0);
                setVisible(false);
              }}
              size="middle"
            >
              Login
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Button type="primary" ghost onClick={() => setVisible(false)} size="middle">
              Login
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  return (
    <>
      <Modal
        closable={false}
        footer={null}
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        bodyStyle={{ padding: '0px' /* , position:'relative' */ }}
      >
        {loading && (
          <>
            <div style={{ width: '100%', height: '100%', position: 'absolute', background: 'black', opacity: '0.8' }} />
            <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'grid' }}>
              <Spin size="large" style={{ color: 'green', margin: 'auto' }} />
            </div>
          </>
        )}
        <ModalBody>
          {forgotPass === 0 && <EnterEmailForm />}
          {forgotPass === 1 && <EmailSent />}
        </ModalBody>
      </Modal>
    </>
  );
};

export default compose(withApollo, withGetUserEmailByUsernameOrEmail, withResendActivationEmail)(SendEmailVerify);

const MailSent = styled.div`
  color: #009c4a;
  font-size: 25px;
`;

const LessDivider = styled(Divider)`
  margin-top: ${props => {
    return props.up && '0px';
  }};
  @media only screen and (min-width: 480px) {
    margin: 0;
    margin-bottom: 0.5em;
  }
`;

const ModalBody = styled.div`
  padding: 24px 48px;
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;
