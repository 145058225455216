import React from 'react';
import { Spin, Row, Col, Button, Divider, message } from 'antd';
import { MenuFoldOutlined, ArrowLeftOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';

import EditIcon from './EditIcon';
import DeleteIcon from './DeleteIcon';
import RenderTable from './RenderTable';
import { getIntFromString } from './functions';

const RenderTableWithLayout = props => {
  const {
    history,
    title,
    loading,
    tableData,
    columns,
    onDelete,
    addLink,
    handleDrawer,
    showBack = false,
    extra,
    onFiltersRemove,
    onPaginationChange,
    rowKey = 'id',
    editLink,
    components,
    onSort
  } = props;
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const isUser = Boolean(addLink || editLink);
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      await Promise.all(
        selectedRowKeys.map(async i => {
          await onDelete(i);
        })
      );
      message.success('Deleted!');
    } catch (e) {
      message.error('Failed, try again!');
      throw new Error(e.message);
    }
    setDeleteLoading(false);
  };
  return (
    <Spin spinning={deleteLoading} size="large">
      <Row>
        <Col xs={4} lg={0}>
          <MenuFoldOutlined
            onClick={handleDrawer}
            style={{
              padding: '12px 0px 0px 24px',
              display: 'flex',
              fontSize: '18px',
              lineHeight: '64px',
              cursor: 'pointer',
              transition: 'color 0.3s'
            }}
          />
        </Col>
        <Col xs={17} lg={showBack ? (isUser ? 21 : 19) : isUser ? 23 : 21}>
          <h2>{title}</h2>
        </Col>
        {!isUser && (
          <Col lg={3} md={3} xs={2} align="right">
            <Button
              icon={<UndoOutlined />}
              shape="circle"
              type="primary"
              ghost
              disabled={tableData && tableData.totalCount < 1}
              onClick={() => {
                onFiltersRemove && onFiltersRemove();
              }}
            />
            <Divider type="vertical" />
            <DeleteIcon size="md" disabled={!(onDelete && selectedRowKeys.length > 0)} onClick={handleDelete} />
          </Col>
        )}
        {showBack && (
          <Col xs={0} lg={2} align="right">
            <Button shape="circle" onClick={() => history.goBack()}>
              <ArrowLeftOutlined />
            </Button>
            {' Back'}
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={24} xs={23}>
          {isUser && (
            <Row>
              <Col lg={extra ? 5 : 19} md={extra ? 2 : 20} xs={extra ? 3 : 20}>
                <Button disabled={!addLink} size="medium" type="primary" ghost>
                  <Link to={addLink}>
                    <PlusOutlined />
                    Add {title}
                  </Link>
                </Button>
              </Col>
              {extra && (
                <Col lg={extra ? 14 : 0} xs={extra ? 18 : 0}>
                  {extra}
                </Col>
              )}
              <Col lg={5} md={4} xs={2} align="right">
                <Button
                  icon={<UndoOutlined />}
                  shape="circle"
                  type="primary"
                  ghost
                  disabled={tableData && tableData.totalCount < 1}
                  onClick={() => {
                    onFiltersRemove && onFiltersRemove();
                  }}
                />
                <Divider type="vertical" />
                <EditIcon
                  disabled={!(editLink && selectedRowKeys.length === 1)}
                  href={
                    selectedRowKeys[0] &&
                    `${editLink}${
                      rowKey === 'index'
                        ? getIntFromString(tableData?.edges?.map(({ node }) => node)[selectedRowKeys[0] - 1]['id'])
                        : selectedRowKeys[0]
                    }`
                  }
                />
                <Divider type="vertical" />
                <DeleteIcon size="md" disabled={!(onDelete && selectedRowKeys.length > 0)} onClick={handleDelete} />
              </Col>
            </Row>
          )}
          <br />
          <RenderTable
            tableData={tableData}
            loading={loading}
            rowKey={rowKey}
            onPaginationChange={onPaginationChange}
            columns={columns}
            selectedRowKeys={selectedRowKeys}
            handleChange={setSelectedRowKeys}
            components={components}
            onSort={onSort}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default withRouter(RenderTableWithLayout);
