
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Space, Col, Row, Empty, Button, Carousel, Progress, Popover, Steps } from 'antd';
import { ReactMarkdown, getIntFromString, interpolate } from '../../look';
import SurveyMLSelectField from './surveyMLRadioButtom';
import { CaretRightOutlined, CheckOutlined } from '@ant-design/icons';
// import help_icon from '../../assets/help-text.svg'
// import disabled_dot from '../../assets/disabled-dot.svg'
// import enabled_dot from '../../assets/enabled-dot.svg'
import Item from 'antd/lib/list/Item';
const GenericMartx = (props)=>{
    const {
        questionList,
        matrix,
        sectionLabel,
        form,
        nextStep,
        srNo,
        timeoutFunc,
        closetimer,
        currentIsMatrix,
        currentSection,
        showOk,
        setShowOk,
        currentMatrixQuestion,
        survey,
        client,
        formValues,
        selectedId,
        values
    } = props;
    const [showInput,setShowInput]=React.useState(false)
    const [open, setOpen] = React.useState(false);
    const obj = {};
    
    const matrixLabel = (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px",width:"100%" ,paddingTop:"10px"}}>
            {props.required&&<span style={{color:"#DE4A4A"}}>*</span>}
            <div style={{display:'flex',flexDirection:'row',alignItems:'flex-start',gap:'0px'}}>
            <h4>{srNo}.</h4>
            <ReactMarkdown style={{ marginLeft: '4px' }}>{interpolate(sectionLabel?.lable?.replace(/\{\d+}/g, ''), obj )}</ReactMarkdown>
            </div>
        </div>
    );
    const areIdsIncludedInObjects=(idArray, objectArray) =>{
        console.log(idArray,objectArray)
        // Extract all the IDs from objectArray
        const idsInObjects = objectArray.map(obj => obj.questionId);
      
        // Check if all IDs in idArray are included in idsInObjects
        console.log("idArray.every(id => idsInObjects.includes(id))",idArray.every(id => idsInObjects.includes(id)))
        return idArray.every(id => idsInObjects.includes(id));
      }
   const OperandSelectedValues = ()=>{
    let  idArray = questionList?.map(questions=>getIntFromString(questions?.id))
        // Extract all the IDs from objectArray
        const idsInObjects = formValues.map(obj => obj.questionId);
    
        if(idArray.every(id => idsInObjects.includes(id))){

            setShowInput(true)
        }
        else{
            setShowInput(false)
        }
    }

    const helpContent =(text)=>{
        return (
            <div style={{maxWidth:"400px",width:"100%"}}>
                <p className='generic-survey-help-text'><ReactMarkdown>{text}</ReactMarkdown></p>
                <button className='generic-survey-help-text-btn' onClick={()=>setOpen(false)}>Got it</button>
            </div>
        )
    }
console.log('febjfjef8833j',questionList)
    return (
        <>
          <MatrixDiv  className="generic-survey-question-container" style={{background:areIdsIncludedInObjects(questionList?.map(questions=>getIntFromString(questions?.id)),formValues)?"rgba(236, 241, 244, 1)":"rgba(244, 249, 253, 1)"}} currentIsMatrix={currentSection === srNo && currentIsMatrix}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px" }}
                    onClick={()=>showInput?setShowInput(false):(OperandSelectedValues(),selectedId(sectionLabel?.id))}>
                    <h4 className='generic-survey-question' style={{  cursor: "pointer",whiteSpace:"nowrap"}}>{matrixLabel}</h4>
                </div>
                {/* <SequenceNo>{srNo}</SequenceNo>
                <h3 style={{ fontWeight: 'bold' }}>{displayDataCheck( sectionLabel )}</h3> */}
            {(currentSection === srNo||showInput)&& 

                <Row style={{ paddingBottom: '2vh', paddingTop: '2vh' }}>
                <Col lg={7} md={7} xs={0}></Col>
                <Col lg={17} md={17} xs={24}>
                <Row>
                    {questionList[0]?.choiceSet?.edges?.map(({ node: choice }, key) => (
                    <Col span={6} align="center">
                    <p className='survey-metrix-col-data'><ReactMarkdown> {choice?.label.replace(",","")}</ReactMarkdown></p>
                    </Col>
                    ))}
                </Row>
                </Col>
                </Row>}

            <Row>
                {currentIsMatrix && questionList?.length > 0 ? (
                    questionList
                        ?.sort( function ( a, b ) {
                            return parseInt( a.id ) - parseInt( b.id );
                        } )
                        .map( ( question, index ) => {
                            const { id, questionText, choiceSet, required } = question;
                            const rules = [ { required: true, message: 'An answer is required.' } ];
                            console.log('question8y88y8Text',questionText, question)
                            return (
                                <Col lg={{ span: 23, offset: 1 }} md={{ span: 23, offset: 1 }} xs={{ span: 24, offset: 0 }}  style={{background:values[id]!==undefined?"rgba(244, 249, 253, 1)":"#fff"}}>
                                    {currentSection === srNo && (
                                        <SurveyMLSelectField
                                            otherOption={true}
                                            name={id}
                                            label={
                                                <Space style={{display:'flex',flexDirection:"row",justifyContent:"start",alignItems:'center',gap:"10px"}}>
                                                    { values[id]!==undefined?<img src={"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1707735499/WorkplaceResilienceProduction/Survey/Images/enabled-dot_ayycgm.svg"} alt=''/>:<img src={"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1707735493/WorkplaceResilienceProduction/Survey/Images/disabled-dot_bjax2k.svg"} alt=''/>}
                                                    {<p className='survey-metrix-col-data' style={{display:"flex",flexDirection:"row",alignItems:'center',gap:"10px"}}><ReactMarkdown>{questionText}</ReactMarkdown></p>}
                                                </Space>
                                            }
                                            choices={choiceSet?.edges?.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                            rules={required ? rules : []}
                                            onChange={e=>e?.target?.value && setShowOk(true)}
                                        />
                                    )}
                                </Col>
                            );
                        } )
                ) :null}
            </Row>
            {showOk&&currentSection === srNo&&(
            <button className='generic-survey-ok-button' style={{marginTop:"10px"}} disabled={props.load} onClick={e => nextStep( srNo )} {...props}>
                Okay
            </button>
            )}
        </MatrixDiv>
        </>
    )
}


export default GenericMartx

const MatrixDiv = styled.div`
  max-height: 75vh; /* ${props => ( props.currentIsMatrix ? '75vh' : '60vh' )}; */
  overflow-y: auto;
  width:100%;
`;