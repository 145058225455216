import React from 'react';
import { compose } from '../../core';

import ResilienceAnchorsView from '../components/ResilienceAnchorsView';
import {
  withReportingTeamsResilienceAnchorsFilterUpdating,
  withReportingTeamsResilienceAnchorsState,
  withTeamsResilienceAnchors
} from './ManagerOperations';
import { withTeamsResiliencePulseCheck } from '../../dashboard/containers/DashboardOperations';

const TeamsResilienceAnchors = props => {
  const { reportingTeamsResilienceAnchors, reportingTeamsResiliencePulseCheck } = props;
console.log('reportingTea3msResiliencePulseCheffrck',reportingTeamsResiliencePulseCheck)
  return (
    <ResilienceAnchorsView
    reportingTeamsResiliencePulseCheck={reportingTeamsResiliencePulseCheck}
      resilienceAnchors={reportingTeamsResilienceAnchors && JSON.parse(reportingTeamsResilienceAnchors)}
      {...props}
    />
  );
};

export default compose(
  withReportingTeamsResilienceAnchorsState,
  withReportingTeamsResilienceAnchorsFilterUpdating,
  withTeamsResilienceAnchors,
  withTeamsResiliencePulseCheck
)(TeamsResilienceAnchors);
