import update from 'immutability-helper';

import { REPORTING_TEAMS_RESILIENCE_ANCHORS_STATE_QUERY } from '../graphql/ReportingTeamsResilienceAnchorsStateQuery.client.gql';

const TYPE_REPORTING_TEAMS_RESILIENCE_ANCHORS_STATE = 'ReportingTeamsResilienceAnchorsState';

const TYPE_REPORTING_TEAMS_RESILIENCE_ANCHORS_STATE_FILTER = 'ReportingTeamsResilienceAnchorsFilterInput';

const defaults = {
  reportingTeamsResilienceAnchorsState: {
    filter: {
      includingManager: false,
      __typename: TYPE_REPORTING_TEAMS_RESILIENCE_ANCHORS_STATE_FILTER
    },
    __typename: TYPE_REPORTING_TEAMS_RESILIENCE_ANCHORS_STATE
  }
};

const resolvers = {
  Mutation: {
    updateReportingTeamsResilienceAnchorsFilter: (_, { filter }, { cache }) => {
      const { reportingTeamsResilienceAnchorsState } = cache.readQuery({
        query: REPORTING_TEAMS_RESILIENCE_ANCHORS_STATE_QUERY
      });
      const newReportingTeamsResilienceAnchorsState = update(reportingTeamsResilienceAnchorsState, {
        filter: { $merge: filter }
      });

      cache.writeData({
        data: {
          reportingTeamsResilienceAnchorsState: newReportingTeamsResilienceAnchorsState,
          __type: TYPE_REPORTING_TEAMS_RESILIENCE_ANCHORS_STATE
        }
      });

      return null;
    }
  }
};

export default { defaults, resolvers };
