import React from 'react';
import { Button, Spin, Row, Col, Card, Space , Empty} from 'antd';
import { Link } from 'react-router-dom';

import { Pagination, getIntFromString, ReactMarkdown, DeleteIcon } from '../../look';
import REPORT_ROUTE from '../../report/route';

const RequestStatusView = props => {
  const { loading, allComparisonRequestFromUser, onCancel, allowComparison, onReport, pagination, onPaginationChange } =
    props;

  return (
    <>
      <h3>Request Status</h3>
      <br />

      <Spin spinning={loading}>
        <Row gutter={[24, 24]} style={{ minHeight: '60vh', maxHeight: '60vh', overflowY: 'scroll' }}>
          {allComparisonRequestFromUser && allComparisonRequestFromUser.edges.length > 0 ? allComparisonRequestFromUser?.edges?.map(({ node }) => (
            <Col md={12} xs={24}>
              <br />
              <Card
                style={{ display: 'grid', height: '100%' }}
                bodyStyle={{
                  borderRadius: '20px',
                  border: 0,
                  boxShadow: '0px 4px 15px 5px rgba(175, 176, 162, 0.15)'
                }}
                bordered={false}
              >
                <div style={{ height: '87%' }}>
                  <Row justify="space-between">
                    <h4>
                      To : {node?.receiver?.firstName} {node?.receiver?.lastName}
                    </h4>
                    <DeleteIcon tooltipSuffix={"Request"} size="sm" type="danger" onClick={() => onCancel(getIntFromString(node.id))} />
                  </Row>
                  <br />
                  <Row justify="space-between">
                    <Col>
                      <div>
                        On : {}
                        {node?.requestDate ? new Date(node?.requestDate).toDateString() : 'Not-available'}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        Accepted On : {}
                        {node?.responseDate ? new Date(node?.responseDate).toDateString() : 'Not-accepted'}
                      </div>
                    </Col>
                  </Row>

                  <br />
                  <div>
                    Message :
                    <div
                      style={{
                        border: '1px solid rgba(202, 202, 202, 0.25)',
                        margin: '7px',
                        padding: '15px',
                        height: '80%',
                        borderRadius: '2px'
                      }}
                    >
                      <ReactMarkdown>{node?.message}</ReactMarkdown>
                    </div>
                  </div>
                  <br />
                </div>

                <Row align="middle">
                  <Col span={14}>
                    <div>Survey : {node?.survey?.name}</div>
                  </Col>

                  <Col span={10} align="right">
                    {node?.accepted && (
                      <Space align="baseline">
                        <Link
                          to={{
                            pathname: `${REPORT_ROUTE.reportLink}${getIntFromString(node.survey.id)}${
                              REPORT_ROUTE.repotUserLink
                            }${allowComparison ? getIntFromString(node?.receiver?.id) : getIntFromString(node?.id)}${
                              REPORT_ROUTE.reportComparisonLink
                            }${`${node?.receiver?.firstName} ${node?.receiver?.lastName}`}`
                            // state: {
                            //   receiver: `${node?.receiver?.firstName} ${node?.receiver?.lastName}`,
                            //   surveyName: node?.survey?.name,
                            //   variables: allowComparison
                            //     ? { requestedUserId: getIntFromString(node?.sender?.id) }
                            //     : { requestId: getIntFromString(node?.id) },
                            //   responseId: { responseId: getIntFromString(node?.sharedResponse?.id) }
                            // }
                          }}
                          target="_blank"
                        >
                          <Button
                            type="primary"
                            // onClick={() =>
                            //   onReport(
                            //     `${node?.receiver?.firstName} ${node?.receiver?.lastName}`,
                            //     node?.survey?.name,
                            //     allowComparison
                            //       ? { requestedUserId: getIntFromString(node?.sender?.id) }
                            //       : { requestId: getIntFromString(node?.id) },
                            //     { responseId: getIntFromString(node?.sharedResponse?.id) }
                            //   )
                            // }
                          >
                            Get report
                          </Button>
                        </Link>
                      </Space>
                    )}
                  </Col>
                </Row>
              </Card>
              <br />
            </Col>
          )) : (
            <div style={{  margin: '0',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'}}>
              <Empty />
            </div>
        )}

          <Col span={24}>
            <br />
            {!loading && allComparisonRequestFromUser && allComparisonRequestFromUser.edges.length > 0 && (
              <Pagination
                data={allComparisonRequestFromUser}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
              />
            )}
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default RequestStatusView;
