import gql from 'graphql-tag';
import { ORGANIZATION_TYPE } from './OrganizationType.gql';

export const ADD_ORGANIZATION = gql`
  ${ORGANIZATION_TYPE}
  mutation addOrganization($employees: [Int], $organizationData: OrganizationInput!) {
    createOrganization(employees: $employees, organizationData: $organizationData) {
      organization {
        ...OrganizationType
      }
    }
  }
`;
