import update from 'immutability-helper';

import { LOOK_USER_STATE_QUERY } from '../graphql/LookUserStateQuery.client.gql';
import { LOOK_SURVEY_STATE_QUERY } from '../graphql/LookSurveyStateQuery.client.gql';
import { LOOK_ORGANIZATION_STATE_QUERY } from '../graphql/LookOrganizationStateQuery.client.gql';
import { LOOK_VERTICAL_STATE_QUERY } from '../graphql/LookVerticalStateQuery.client.gql';
import { LOOK_TAG_STATE_QUERY } from '../graphql/LookTagStateQuery.client.gql';
import { LOOK_CONDITION_STATE_QUERY } from '../graphql/LookConditionStateQuery.client.gql';
import { LOOK_FORM_SECTION_STATE_QUERY } from '../graphql/LookFormSectionStateQuery.client.gql';
import { LOOK_SIMPLE_FIELD_STATE_QUERY } from '../graphql/LookSimpleFieldStateQuery.client.gql';
import { LOOK_REFERENCE_FIELD_STATE_QUERY } from '../graphql/LookReferenceFieldStateQuery.client.gql';
import { LOOK_TOOL_STATE_QUERY } from '../graphql/LookToolStateQuery.client.gql';
import { LOOK_TEXT_SECTION_STATE_QUERY } from '../graphql/LookTextSectionStateQuery.client.gql';
import { LOOK_WEIGHTED_QUESTION_STATE_QUERY } from '../graphql/WeightedQuestionStateQuery.client.gql';

import { PAGINATION_LIMIT } from '../../../config';

const TYPE_LOOK_USER_STATE = 'LookUserState';
const TYPE_LOOK_SURVEY_STATE = 'LookSurveyState';
const TYPE_LOOK_ORGANIZATION_STATE = 'LookSurveyState';
const TYPE_LOOK_VERTICAL_STATE = 'LookVerticalState';
const TYPE_LOOK_TAG_STATE = 'LookTagState';
const TYPE_LOOK_CONDITION_STATE = 'LookConditionState';
const TYPE_LOOK_FORM_SECTION_STATE = 'LookFormSectionState';
const TYPE_LOOK_SIMPLE_FIELD_STATE = 'LookSimpleFieldState';
const TYPE_LOOK_REFERENCE_FIELD_STATE = 'LookReferenceFieldState';
const TYPE_LOOK_TOOL_STATE = 'LookToolState';
const TYPE_LOOK_TEXT_SECTION_STATE = 'LookTextSectionState';
const TYPE_LOOK_WEIGHTED_QUESTION_STATE = 'LookWeightedQuestionState';

const TYPE_LOOK_USER_STATE_FILTER = 'LookUserFilterInput';
const TYPE_LOOK_SURVEY_STATE_FILTER = 'LookSurveyFilterInput';
const TYPE_LOOK_ORGANIZATION_STATE_FILTER = 'LookSurveyFilterInput';
const TYPE_LOOK_VERTICAL_STATE_FILTER = 'LookVerticalFilterInput';
const TYPE_LOOK_TAG_STATE_FILTER = 'LookTagFilterInput';
const TYPE_LOOK_CONDITION_STATE_FILTER = 'LookConditionFilterInput';
const TYPE_LOOK_FORM_SECTION_STATE_FILTER = 'LookFormSectionFilterInput';
const TYPE_LOOK_SIMPLE_FIELD_STATE_FILTER = 'LookSimpleFieldFilterInput';
const TYPE_LOOK_REFERENCE_FIELD_STATE_FILTER = 'LookReferenceFieldFilterInput';
const TYPE_LOOK_TOOL_STATE_FILTER = 'LookToolFilterInput';
const TYPE_LOOK_TEXT_SECTION_FILTER = 'LookTextSectionFilterInput';
const TYPE_LOOK_WEIGHTED_QUESTION_FILTER = 'LookWeightedQuestionFilterInput';

const defaults = {
  lookUserState: {
    filter: {
      username_Icontains: '',
      firstName_Icontains: '',
      lastName_Icontains: '',
      nameIcontains: '',
      __typename: TYPE_LOOK_USER_STATE_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_USER_STATE
  },
  lookSurveyState: {
    filter: {
      first: PAGINATION_LIMIT,
      name_Icontains: '',
      questionText_Icontains: '',
      __typename: TYPE_LOOK_SURVEY_STATE_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_SURVEY_STATE
  },
  lookOrganizationState: {
    filter: {
      name_Icontains: '',
      __typename: TYPE_LOOK_ORGANIZATION_STATE_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_ORGANIZATION_STATE
  },
  lookVerticalState: {
    filter: {
      organization: '',
      name_Icontains: '',
      __typename: TYPE_LOOK_VERTICAL_STATE_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_VERTICAL_STATE
  },
  lookTagState: {
    filter: {
      name_Icontains: '',
      __typename: TYPE_LOOK_TAG_STATE_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_TAG_STATE
  },
  lookConditionState: {
    filter: {
      leftOperand: '',
      __typename: TYPE_LOOK_CONDITION_STATE_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_CONDITION_STATE
  },
  lookFormSectionState: {
    filter: {
      title_Icontains: '',
      __typename: TYPE_LOOK_FORM_SECTION_STATE_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_FORM_SECTION_STATE
  },
  lookSimpleFieldState: {
    filter: {
      label_Icontains: '',
      __typename: TYPE_LOOK_SIMPLE_FIELD_STATE_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_SIMPLE_FIELD_STATE
  },
  lookReferenceFieldState: {
    filter: {
      questionTextIcontains: '',
      __typename: TYPE_LOOK_REFERENCE_FIELD_STATE_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_REFERENCE_FIELD_STATE
  },
  lookToolState: {
    filter: {
      title_Icontains: '',
      __typename: TYPE_LOOK_TOOL_STATE_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_TOOL_STATE
  },
  lookTextSectionState: {
    filter: {
      note_Icontains: '',
      __typename: TYPE_LOOK_TEXT_SECTION_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_TEXT_SECTION_STATE
  },
  lookWeightedQuestionState: {
    filter: {
      question_QuestionText_Icontains: '',
      __typename: TYPE_LOOK_WEIGHTED_QUESTION_FILTER
    },
    orderBy: ['-id'],
    __typename: TYPE_LOOK_WEIGHTED_QUESTION_STATE
  }
};

const resolvers = {
  Mutation: {
    updateLookUserFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookUserState } = cache.readQuery({
        query: LOOK_USER_STATE_QUERY
      });
      const newLookUserState = update(lookUserState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookUserState: newLookUserState,
          __type: TYPE_LOOK_USER_STATE
        }
      });

      return null;
    },
    updateLookSurveyFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookSurveyState } = cache.readQuery({
        query: LOOK_SURVEY_STATE_QUERY
      });
      const newLookSurveyState = update(lookSurveyState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookSurveyState: newLookSurveyState,
          __type: TYPE_LOOK_SURVEY_STATE
        }
      });

      return null;
    },
    updateLookOrganizationFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookOrganizationState } = cache.readQuery({
        query: LOOK_ORGANIZATION_STATE_QUERY
      });
      const newLookOrganizationState = update(lookOrganizationState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookOrganizationState: newLookOrganizationState,
          __type: TYPE_LOOK_ORGANIZATION_STATE
        }
      });

      return null;
    },
    updateLookVerticalFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookVerticalState } = cache.readQuery({
        query: LOOK_VERTICAL_STATE_QUERY
      });
      const newLookVerticalState = update(lookVerticalState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookVerticalState: newLookVerticalState,
          __type: TYPE_LOOK_VERTICAL_STATE
        }
      });

      return null;
    },
    updateLookTagFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookTagState } = cache.readQuery({
        query: LOOK_TAG_STATE_QUERY
      });
      const newLookTagState = update(lookTagState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookTagState: newLookTagState,
          __type: TYPE_LOOK_TAG_STATE
        }
      });

      return null;
    },
    updateLookConditionFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookConditionState } = cache.readQuery({
        query: LOOK_CONDITION_STATE_QUERY
      });
      const newLookConditionState = update(lookConditionState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookConditionState: newLookConditionState,
          __type: TYPE_LOOK_CONDITION_STATE
        }
      });

      return null;
    },
    updateLookFormSectionFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookFormSectionState } = cache.readQuery({
        query: LOOK_FORM_SECTION_STATE_QUERY
      });
      const newLookFormSectionState = update(lookFormSectionState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookFormSectionState: newLookFormSectionState,
          __type: TYPE_LOOK_FORM_SECTION_STATE
        }
      });

      return null;
    },
    updateLookSimpleFieldFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookSimpleFieldState } = cache.readQuery({
        query: LOOK_SIMPLE_FIELD_STATE_QUERY
      });
      const newLookSimpleFieldState = update(lookSimpleFieldState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookSimpleFieldState: newLookSimpleFieldState,
          __type: TYPE_LOOK_SIMPLE_FIELD_STATE
        }
      });

      return null;
    },
    updateLookReferenceFieldFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookReferenceFieldState } = cache.readQuery({
        query: LOOK_REFERENCE_FIELD_STATE_QUERY
      });
      const newLookReferenceFieldState = update(lookReferenceFieldState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookReferenceFieldState: newLookReferenceFieldState,
          __type: TYPE_LOOK_REFERENCE_FIELD_STATE
        }
      });

      return null;
    },
    updateLookToolFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookToolState } = cache.readQuery({
        query: LOOK_TOOL_STATE_QUERY
      });
      const newLookToolState = update(lookToolState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookToolState: newLookToolState,
          __type: TYPE_LOOK_TOOL_STATE
        }
      });

      return null;
    },
    updateLookTextSectionFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookTextSectionState } = cache.readQuery({
        query: LOOK_TEXT_SECTION_STATE_QUERY
      });

      const newLookTextSectionState = update(lookTextSectionState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookTextSectionState: newLookTextSectionState,
          __type: LOOK_TEXT_SECTION_STATE_QUERY
        }
      });

      return null;
    },
    updateLookWeightedQuestionFilter: (_, { filter, orderBy }, { cache }) => {
      const { lookWeightedQuestionState } = cache.readQuery({
        query: LOOK_WEIGHTED_QUESTION_STATE_QUERY
      });

      const newLookWeightedQuestionState = update(lookWeightedQuestionState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookWeightedQuestionState: newLookWeightedQuestionState,
          __type: LOOK_WEIGHTED_QUESTION_STATE_QUERY
        }
      });

      return null;
    }
  }
};

export default { defaults, resolvers };
