import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Upload, Modal, Form, Button } from 'antd';

import { CLOUDINARY_CLOUDNAME, CLOUDINARY_PRESET } from '../../../config';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
// import upload_image from '../../assets/upload-image.svg'
const FormItem = Form.Item;

const RenderUploadWithCrop = props => {
  const {
    height,
    width,
    label,
    setUrl,
    /* icon = 'UploadOutlined', */ name,
    setLoad,
    disabled,
    cropPropSettings = {},
    value,
    children,
    offset = 1
  } = props;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [showUpload,setShowUpload] = useState(true);
  const [fileList, setFileList] = useState(
    value
      ? [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: value
          }
        ]
      : []
  );

  const onChangeHandler = ({ file, fileList }) => {
    console.log('onChangeHandler',file)
    if (file.status === 'uploading') {
      setLoad(true);
    }
    if (file.status === 'done') {
      setLoad(false);
      if (file.response) {
        let url = file.response.secure_url;
        if (url) {
          //set value in form
          setUrl(url);
          setPreviewImage(url);
          setFileList([{
            uid: file?.uid,
            name: file?.name,
            status:file?.status,
            url: url
          }])
        }
      }
    } else if (file.status === 'removed') {
      setLoad(false);
      //remove value in form
      setUrl('');
      // input.onChange('');
    }
    setFileList(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = file => {
    console.log('handlePreview', file);
    // setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
  };

  const uploadButton = <img style={{cursor:"pointer"}} src={"https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1707736297/WorkplaceResilienceProduction/Survey/Images/upload-image_yhazxf.svg"} alt=''/>;

  const cloudName = `${CLOUDINARY_CLOUDNAME}/image`;
  const unsignedUploadPreset = `${CLOUDINARY_PRESET}`;

  const cloudinary_url = 'https://api.cloudinary.com/v1_1/' + cloudName + '/upload';
  const cloudinary_data = { upload_preset: unsignedUploadPreset };

  let validateStatus = '';
  
  // console.log('props', shape);
  return (
    <FormItem
      name={name}
      label={label}
      validateStatus={validateStatus}
    >
      <div className="clearfix survey-upload-seaction" style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:'center',gap:"10px",marginTop:"10px"}}>
       <Upload
          action={cloudinary_url}
          data={cloudinary_data}
          fileList={fileList}
          listType="picture-card"
          onPreview={handlePreview}
          onChange={onChangeHandler}
          disabled={disabled}
        >
          {children ? children : fileList.length >= 1 ? null : uploadButton}
        </Upload>
        {/* <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="thumbnail" style={{ width: '100%' }} src={previewImage} />
        </Modal> */}
      </div>
    </FormItem>
  );
};

RenderUploadWithCrop.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  setLoad: PropTypes.func,
  value: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  form: PropTypes.object,
  cropPropSettings: PropTypes.object,
  name: PropTypes.string,
  icon: PropTypes.string,
  shape: PropTypes.string
};

export default RenderUploadWithCrop;
