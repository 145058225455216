import React from 'react';
import { Form, Transfer, Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { UserAddOutlined } from '@ant-design/icons';

import Loading from '../Loading';
import { getIntFromString } from '../functions';

export const LIST_ALL_USERS_QUERY = gql`
  query listAllUsers($employee_Isnull: Boolean) {
    listAllUsers(employee_Isnull: $employee_Isnull) {
      edges {
        cursor
        node {
          id
          firstName
          lastName
          username
          email
        }
      }
    }
  }
`;
const UserTranferField = props => {
  const { listAllUsers, initialValues, onAdd } = props;
  const { ...rest } = props;
  //
  const FormItem = Form.Item;
  const [mockData, setmockData] = React.useState([]);
  const [targetKeys, setTargetKeys] = React.useState([]);

  React.useEffect(() => {
    const targetKeys = [];
    // const mockData = [];
    // for (let i = 0; i < 20; i++) {
    //   const data = {
    //     key: i.toString(),
    //     title: `content${i + 1}`,
    //     description: `description of content${i + 1}`,
    //     chosen: Math.random() * 2 > 1,
    //   };
    // if (data.chosen) {
    //   targetKeys.push(data.key);
    // }
    // mockData.push(data);
    // }
    const mockData =
      listAllUsers &&
      listAllUsers.edges.map(({ node }) => ({
        title: node.firstName && node.lastName ? `${node.firstName} ${node.lastName}` : node.username,
        key: getIntFromString(node.id)
      }));

    initialValues &&
      initialValues.map(id => {
        targetKeys.push(id);
      });

    setmockData(mockData);
    setTargetKeys(targetKeys);
  }, [listAllUsers, initialValues]);

  const filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  const handleChange = targetkeys => {
    setTargetKeys(targetkeys);
  };
  return (
    <div style={{ marginBottom: '5vh' }}>
      <Row>
        <Col lg={{ span: 14, offset: 1 }} md={{ span: 15, offset: 1 }}>
          {listAllUsers ? (
            <FormItem
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24, offset: 1 }}
              {...rest}
              // rules={[
              //   ({ getFieldValue, setFieldsValue }) => ({
              //     validator(_, value) {
              //       // if (getFieldValue('employees')) {
              //       return Promise.resolve();
              //       // }
              //       // if (!value || getFieldValue('password') === value) {
              //       // }
              //       // return Promise.reject(new Error('The two passwords that you entered do not match!'));
              //     }
              //   })
              // ]}
            >
              <Transfer
                listStyle={{ height: '35vh', width: '18vw' }}
                dataSource={mockData}
                showSearch
                filterOption={filterOption}
                targetKeys={targetKeys}
                onChange={handleChange}
                // onSearch={handleSearch}
                render={item => item.title}
              />
            </FormItem>
          ) : (
            <Loading size="small" />
          )}
        </Col>
        {onAdd && (
          <Col span={2} style={{ paddingTop: '4vh' }}>
            <Button shape="circle" type="primary" ghost>
              <Link to={`${onAdd}`}>
                <UserAddOutlined />
              </Link>
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default graphql(LIST_ALL_USERS_QUERY, {
  options: props => {
    return {
      variables: {
        employee_Isnull: true
      },
      fetchPolicy: 'network-only'
    };
  },
  props({ data: { loading, error, fetchMore, listAllUsers, subscribeToMore, updateQuery } }) {
    return { loading, error, listAllUsers, subscribeToMore, updateQuery };
  }
})(UserTranferField);
