import React from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';

import { compose } from '../../core';
import { getIntFromString } from '../../look';
import { withMe } from '../../user/containers/UserOperations';

import CommentSectionView from '../components/CommentSectionView';

import { withAddComment, withAllCommentsForStoryByStoryId, withDeleteUserComment, withStoryState } from './StoryOperations';
import { subscribeToStoryComment } from './StorySubscriptions';

const CommentSection = props => {
  const { addComment, deleteUserComment, me, subscribeToMore } = props;
  const { id } = useParams();

  React.useEffect(() => {
    const subscribe = subscribeToStoryComment(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const handleDelete = async id => {
    try {
      const response = await deleteUserComment(id);
      response && message.success('Comment deleted!');
    } catch (e) {
      throw Error(e.message);
    }
  };

  const handleSubmit = async values => {
    try {
      const response = await addComment({
        commentData: {
          userId: getIntFromString(me.id),
          commentText: values,
          storyId: id
        }
      });
      response && message.success('Comment added!');
    } catch (e) {
      throw Error(e.message);
    }
  };

  return <CommentSectionView onDelete={handleDelete} onSubmit={handleSubmit} {...props} />;
};

export default compose(
  withMe,
  withStoryState,
  withAllCommentsForStoryByStoryId,
  withAddComment,
  withDeleteUserComment
)(CommentSection);
