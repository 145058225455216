import React from 'react';
import { Spin, message } from 'antd';

import { compose } from '../../core';
import HOME_ROUTE from '../../home/route';

import EditProfileView from '../components/EditProfileView';
import { withEditProfile, withGetProfileImplicit, withDeleteAccount, withPasswordChange } from './UserOperations';
import { removeItem, setItem } from '../../core/clientStorage';

const EditProfile = props => {
  const { history, editProfile, deleteAccount, passwordChange, getProfileImplicit } = props;
  const [passErrors, setPassErrors] = React.useState(null);
  const [delErrors, setDelErrors] = React.useState(null);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async values => {
    setSubmitLoading(true);
    try {
      const response = await editProfile({ id: getProfileImplicit.id, ...values });

      if (response) message.success('Profile updated!!');
      else message.error('Failed! try again.');
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  const handleDelete = async values => {
    setSubmitLoading(true);
    //
    try {
      const response = await deleteAccount(values);

      if (response.success) {
        message.success('Account deleted!!');
        removeItem('accessToken');
        removeItem('refreshToken');
        history.push(HOME_ROUTE.home);
      } else {
        message.error('Failed! try again.');
        setDelErrors(response.errors);
      }
      setSubmitLoading(false);
      return response.success;
    } catch (e) {
      setSubmitLoading(false);
      throw new Error(e.message);
    }
  };

  const handlePassChange = async values => {
    setSubmitLoading(true);

    try {
      const response = await passwordChange(values);
      if (response.success) {
        message.success('Password updated!!');
        removeItem('accessToken');
        removeItem('refreshToken');
        setTimeout(history.push(HOME_ROUTE.home), 500);
      } else {
        message.error('Failed! try again.');
        setPassErrors(response.errors);
      }
      setSubmitLoading(false);
      return response.success;
    } catch (e) {
      setSubmitLoading(false);
      throw new Error(e.message);
    }
  };

  return (
    <Spin spinning={submitLoading} size="large">
      {getProfileImplicit && (
        <EditProfileView
          onSubmit={handleSubmit}
          passErrors={passErrors}
          delErrors={delErrors}
          onPassChange={handlePassChange}
          onDelete={handleDelete}
          {...props}
        />
      )}
    </Spin>
  );
};

export default compose(withDeleteAccount, withPasswordChange, withGetProfileImplicit, withEditProfile)(EditProfile);
