import React from 'react';
import moment from 'moment-timezone';
import { Select,Form } from 'antd';
// import calender_icon from '../../assets/meeting-calender-icon.svg'
// import time_icon from '../../assets/time-icon.svg'
// import clock_icon from '../../assets/clock-icon.svg'
const FormItem = Form.Item;
const SurveyTimePicker =(props)=>{
    const {onedit,disabled, ...rest }=props
        const selectRef = React.useRef(null);
    const [selectedTime,setSelectedTime]=React.useState(moment())
    const [when,setWhen]=React.useState(moment())
    React.useEffect(() => {
        if (selectRef.current && selectRef.current.rcSelect) {
            const selectInstance = selectRef.current.rcSelect;
            const selectedOption = selectInstance.domRef.current.querySelector('.ant-select-selection-selected-value');
            if (selectedOption) {
              selectedOption.scrollIntoView();
            }
          }
        }, []);

    const roundTime =(time)=>{
        let timeValue
        if(moment(time).minute()<45){
            timeValue =moment({
            hour:moment(time).hour(),
            minute: Math.ceil(moment(time).minute() / 15) * 15,
            })
        }
        else{
            let time_diff = 60-Math.ceil(moment(time).minute())
            let new_time =moment(time).add(time_diff, 'minutes')
            timeValue = moment({
            hour:moment(new_time).hour(),
            minute: Math.ceil(moment(new_time).minute() / 15) * 15,
            }) 
        }
        return timeValue
    }
    const options = [];
    const startOfDay = (when && moment(when).isAfter(moment()))? moment().startOf('day'): roundTime(when);
    const endOfDay = moment().endOf('day');
    while (startOfDay.isSameOrBefore(endOfDay)) {
        options.push({
          value: startOfDay.format("h:mm A"),
          label: startOfDay.format('h:mm A'),
        });
        startOfDay.add(15, 'minutes');
    }

    const handleTimeChange = (selectedOption) => {
        let date =moment().format("YYYY-MM-DD")
        let selected_date_time =`${date} ${moment(selectedOption, ['h:mm A']).format('HH:mm')}`

            setSelectedTime(moment(selected_date_time))
            props.onChange(moment(selectedOption, ['h:mm ss']));
      };
    return (
        <>
          <FormItem  {...rest}>
            <Select
                ref={selectRef}
                style={{width:"200px"}}
                placeholder='Select Time'
                className="survey-time-select-picker"
                onChange={handleTimeChange}
                disabled={disabled}
                value={selectedTime ? moment(selectedTime, 'HH:mm A').format('h:mm A') :roundTime(when).format('h:mm A')}>
                    {
                    options?.map(item => 
                        {
                            return <Select.Option className="survey-time-drop-item" key={item?.value} value={item?.value} >{item?.label}</Select.Option>
                        }
                    )
                    }
            </Select>
        </FormItem>
        </>
    )
}

export default SurveyTimePicker