import React, { useState, createContext, useEffect, useRef } from "react";
import { Row, Col, Card, Statistic, Spin, Progress, Tooltip } from "antd";
import TeamComponent from "./TeamComponent";
import DashboardMenuBar from "./DashboardMenuBar";
import Dashboard from "../containers/Dashboard";

import ROUTE from "../route";
import PersonalResilienceReport from "../containers/PersonalResilienceReport";
import PersonalCommitment from "../containers/PersonalCommitment";
import RecentTools from "../containers/RecentTools";
import ResilienceAnchors from "../containers/ResilienceAnchors";
import ResiliencePulseCheck from "../containers/ResiliencePulseCheck";
import ResilienceToolkit from "../containers/ResilienceToolkit";
import TakeAssisment from "../containers/TakeAssisment";
export const Tipcontext = createContext()
const PersonalDashboardComponent = (props) => {
  const { loading, myResiliencePulseCheck } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [tipPosted, setTipPosted] = useState(false);
  const [perCommOpen, setPerCommOpen] = useState()
  const [resAnchorsOpen, setResAnchorsOpen] = useState()
  const scrollRef = useRef(()=>{})

  console.log('perComm99yOpen',perCommOpen)
  
  const scrollToElement = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth', // Optional: Adds smooth scrolling behavior
        block: 'start', // Optional: Scrolls to the top of the element
      });
    }
  };

  useEffect(()=>{
    if(!perCommOpen && perCommOpen !== undefined){
      scrollToElement()
      // triggerAnimation()
    }
    if(resAnchorsOpen){
      scrollToElement()
      // triggerAnimation()
    }
  },[perCommOpen, resAnchorsOpen])


  return (
    <Tipcontext.Provider value={{tipPosted,setTipPosted}}>
    <Spin spinning={loading || submitLoading} size="large">
      <Dashboard active={ROUTE.personal}>
        {!loading && (
          <Row>
            <Col ref={scrollRef} lg={{ span: 17 }}>
              <DashboardMenuBar
                active={ROUTE.personal}
                setActive={props.setActive}
              />
              <br />
              <Row gutter={[24, 24]} align="middle">
                <Col span={12} style={{pointerEvents:'none'}}>
                  <Card
                    bodyStyle={{
                      boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
                      borderRadius: "10px",
                      // paddingBottom: 0
                    }}
                    hoverable
                    bordered={false}
                  >
                    <Row gutter={[24, 8]} >
                      <Col span={24} align="center" >
                        <h2 style={{ fontWeight: "normal" }}>
                          Resilience Score
                        </h2>
                      </Col>

                      <Col span={24} align="center">
                        <Row justify="center">
                          <Progress
                            type="circle"
                            percent={(myResiliencePulseCheck/10)*100}
                            strokeColor={myResiliencePulseCheck?.toFixed(0) < 3 ? '#E83D2A' :( myResiliencePulseCheck?.toFixed(0) < 7 ? 'orange' : 'green' ) }
                            strokeWidth="2"
                            format={() => (
                              <h1
                                style={{ margin: "0", fontSize: "xxx-large" }}
                              >
                                {myResiliencePulseCheck?.toFixed(0) || 0}
                              </h1>
                            )}
                          />
                        </Row>
                      </Col>

                      <Col span={24} align="center">
                        <br />
                      </Col>


                    </Row>
                  </Card>
                </Col>

                <Col span={12}>
                  <Row  gutter={[24, 20]}>
                    <ResiliencePulseCheck personal />
                    <ResilienceToolkit />
                    <TakeAssisment />
                  </Row>
                </Col>
              </Row>
            </Col>

            <TeamComponent me={props.me} />

            <Col lg={{ span: 24 }}>
              <br />
              <Row gutter={[24, 24]}>
              <ResilienceAnchors
              setResAnchorsOpen={setResAnchorsOpen}
              setTipPosted={setTipPosted}
              />
                <PersonalCommitment
                  setPerCommOpen={setPerCommOpen}
                  dashboard={"personal"}
                  setSubmitLoading={setSubmitLoading}
                />
                <RecentTools />
              </Row>
            </Col>
          </Row>
        )}
      </Dashboard>
    </Spin>
    </Tipcontext.Provider>

  );
};

export default PersonalDashboardComponent;
