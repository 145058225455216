import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { ME_QUERY } from '../user/graphql/MeQuery.gql';
import { useHistory } from 'react-router-dom';

import { BackTop, Affix, Row, Col, Layout, Menu, Button, Drawer, Tooltip } from 'antd';
import {
  BarsOutlined,
  HomeOutlined,
  UserOutlined,
  LoginOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  DeploymentUnitOutlined,
  ApiOutlined,
  ReconciliationOutlined,
  MenuOutlined,
  ImportOutlined,
  ArrowUpOutlined
} from '@ant-design/icons';
import styled from 'styled-components';

import { BACKEND_URI, IS_ADMIN_DROPDOWN, NODE_ENV } from '../../config';
import { getItem, compose, removeItem } from '../core';
import Logo from '../assets/logo.png';

import HOME_ROUTE from '../home/route';
import PAGE_ROUTE from '../page/route';
import USER_ROUTE from '../user/route';
import SURVEY_ROUTE from '../survey/route';
import TOOL_ROUTE from '../tool/route';
import DASHBOARD_ROUTE from '../dashboard/route';
import ORGANIZATION_ROUTE from '../organization/route';
import Footer from './Footer';

const PageLayout = props => {
  const { me, adminRoutes } = props;
  let history = useHistory();
  const [jwt, setJwt] = React.useState(null);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const handleLoggedIn = React.useRef(() => {});
  const [visible1, setVisible1] = React.useState(false);
  const [visible2, setVisible2] = React.useState(false);

  handleLoggedIn.current = async () => {
    const storedJwt = await getItem('accessToken');
    setTimeout(3000, setLoggedIn(storedJwt));
  };

  // React.useEffect(() => {
  //   async function fetchMyAPI() {
  //     const storedJwt = await getItem('accessToken');
  //     setJwt(storedJwt);
  //   }

  //   fetchMyAPI();
  // }, []);
console.log('adminRoutesadminRoutes ::',adminRoutes)
  const handleLogout = () => {
    removeItem('accessToken');
    setJwt(null);
    window.location.pathname = HOME_ROUTE.home;
    // history.push(HOME_ROUTE.home);
  };

  React.useEffect(() => {
    handleLoggedIn.current();
  }, []);

  const diffBackgroundColorRoutes = [
    SURVEY_ROUTE.allOrganizationSurvey,
    SURVEY_ROUTE.surveyQuizLink,
    TOOL_ROUTE.genericToolLink,
    TOOL_ROUTE.genericToolAdminLink
  ].includes(window.location.pathname.replace(/[0-9]/g, ''));

  const noNavbarRoutes = [SURVEY_ROUTE.surveyQuizLink].includes(window.location.pathname.replace(/[0-9]/g, ''));
  const noBackTopRoutes = [SURVEY_ROUTE.surveyQuizLink].includes(window.location.pathname.replace(/[0-9]/g, ''));

  const MenuItems = (
    <>
      <Menu.Item key="home" icon={<HomeOutlined />}>
        <a href={HOME_ROUTE.home}>Home</a>
      </Menu.Item>
      <Menu.Item key="surveys" icon={<ReconciliationOutlined />}>
        <a href={SURVEY_ROUTE.allOrganizationSurvey}>Surveys</a>
      </Menu.Item>
      <Menu.Item key="faq" icon={<QuestionCircleOutlined />}>
        <a href={PAGE_ROUTE.faqPage}>FAQ</a>
      </Menu.Item>
      {loggedIn ? (
        <Menu.SubMenu key="profile" icon={<UserOutlined />} title="Profile">
          <Menu.Item key="login" icon={<UserOutlined />}>
            <a href={USER_ROUTE.profile}>{me ? me.username : 'My Profile'}</a>
          </Menu.Item>
          <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
            Logout
          </Menu.Item>
        </Menu.SubMenu>
      ) : (
        <Menu.Item key="login" icon={<LoginOutlined />}>
          {<a href={USER_ROUTE.login}>Login</a>}
        </Menu.Item>
      )}
    </>
  );
  const handleDrawer = params => {
    if (params === 1) {
      setVisible1(true);
      setVisible2(false);
    } else {
      setVisible1(false);
      setVisible2(true);
    }
  };

  return (
    <>
      {!noNavbarRoutes && (
        <Affix>
          <NewHeader>
            <Row
              justify="space-between"
              style={{
                // maxWidth: '1500px',
                margin: '0 auto'
              }}
            >
              <Col lg={0} xs={8} sm={8} md={8} align="left" style={{ lineHeight: '3' }}>
                {loggedIn && (
                  <MenuOutlined
                    onClick={() => handleDrawer(1)}
                    style={{
                      padding: '12px 24px 0px',
                      display: 'flex',
                      fontSize: '18px',
                      lineHeight: '64px',
                      cursor: 'pointer',
                      transition: 'color 0.3s'
                    }}
                  />
                )}
              </Col>
              <Col lg={0} xs={8} sm={8} md={8} align="center" style={{ lineHeight: '3' }}>
                <a href="/">
                  <img src={Logo} alt="logo" />
                </a>
              </Col>
              <Col lg={0} xs={8} sm={8} md={8} align="right" style={{ lineHeight: '3' }}>
                <ImportOutlined
                  onClick={() => handleDrawer(2)}
                  style={{
                    padding: '12px 0 0 65%',
                    display: 'flex',
                    fontSize: '18px',
                    lineHeight: '64px',
                    cursor: 'pointer',
                    transition: 'color 0.3s'
                  }}
                />
              </Col>
              <Col lg={12} xs={0} style={{ lineHeight: '3' }}>
                <Row>
                  <Col span={12}>
                    <a href="/">
                      <img src={Logo} alt="logo" />
                    </a>
                  </Col>
                  <Col span={12}>
                    {IS_ADMIN_DROPDOWN ? (
                      <Menu mode="horizontal" inlineIndent={50}>
                        {loggedIn && me && me.isSuperuser && (
                          <Menu.SubMenu key="profile" icon={<DeploymentUnitOutlined />} /* title="Admin" */>
                            {adminRoutes.map((r, idx) => (
                              <Menu.Item key={`${idx}`} icon={r.icon}>
                                <a href={r.path}>{r.name}</a>
                              </Menu.Item>
                            ))}
                            {NODE_ENV !== 'production' && (
                              <Menu.Item key={'graphiql'} icon={<ApiOutlined />}>
                                <a href={BACKEND_URI} target="_blank" rel="noopener noreferrer">
                                  Graphiql
                                </a>
                              </Menu.Item>
                            )}
                          </Menu.SubMenu>
                        )}
                      </Menu>
                    ) : (
                      loggedIn &&
                      me &&
                      me.isSuperuser && (
                        <Menu mode="horizontal" inlineIndent={50}>
                          <Menu.Item key="home">
                            <a href={ORGANIZATION_ROUTE.organization}>
                              &nbsp;&nbsp;&nbsp; <DeploymentUnitOutlined />
                              &nbsp;&nbsp;
                            </a>
                          </Menu.Item>
                          {NODE_ENV !== 'production' && (
                            <Menu.Item key={'graphiql'} rel="noopener noreferrer">
                              <a href={BACKEND_URI} target="_blank" without rel="noopener noreferrer">
                                &nbsp;&nbsp;&nbsp; <ApiOutlined />
                                &nbsp;&nbsp;
                              </a>
                            </Menu.Item>
                          )}
                        </Menu>
                      )
                    )}
                  </Col>
                </Row>
              </Col>
              <Col lg={12} xs={0} style={{ lineHeight: '3' }}>
                <Row justify="end">
                  <Menu mode="horizontal" inlineIndent={50}>
                    {MenuItems}
                  </Menu>
                </Row>
              </Col>
            </Row>
            {loggedIn && (
              <Drawer
                width="190px"
                bodyStyle={{ padding: '24px 0px' }}
                placement="left"
                closable={false}
                onClose={() => setVisible1(false)}
                visible={visible1}
              >
                <Menu mode="inline">
                  {loggedIn && me?.isSuperuser ? (
                    <>
                      {adminRoutes.map((r, idx) => (
                        <Menu.Item key={`${idx}`} icon={r.icon}>
                          <a href={r.path}>{r.name}</a>
                        </Menu.Item>
                      ))}
                    </>
                  ) : (
                    <>
                      <Menu.Item
                        key={DASHBOARD_ROUTE.personal /* getDashboardkey */}
                        // disabled={NODE_ENV === 'production'}
                        icon={<BarsOutlined />}
                        style={{ paddingRight: '3vw' }}
                      >
                        Resilience Toolkit
                      </Menu.Item>
                      <Menu.Item key={USER_ROUTE.profile} icon={<UserOutlined />} style={{ paddingRight: '3vw' }}>
                        My Profile
                      </Menu.Item>
                      <Menu.Item key={USER_ROUTE.myResponse} icon={<BarsOutlined />} style={{ paddingRight: '3vw' }}>
                        My Reports
                      </Menu.Item>
                    </>
                  )}
                </Menu>
              </Drawer>
            )}
            <Drawer
              width="190px"
              placement="right"
              bodyStyle={{ padding: '24px 0px' }}
              closable={false}
              onClose={() => setVisible2(false)}
              visible={visible2}
            >
              <Menu mode="inline">{MenuItems}</Menu>
            </Drawer>
          </NewHeader>
        </Affix>
      )}
      <BodyDiv diffBackgroundColorRoutes={diffBackgroundColorRoutes}>{props.children}</BodyDiv>
      {!noBackTopRoutes && (
        <BackTop>
          <Tooltip placement="left" title="Back to Top" autoAdjustOverflow={true}>
            <Button type="primary" size="large" shape="circle" icon={<ArrowUpOutlined />}></Button>
          </Tooltip>
        </BackTop>
      )}
      {!noNavbarRoutes && <Footer />}
    </>
  );
};

export default compose(
  graphql(ME_QUERY, {
    options: () => {
      return {
        fetchPolicy: 'network-only'
      };
    },
    props({ data: { loading, error, me, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, me, subscribeToMore, updateQuery };
    }
  })
)(PageLayout);

const BodyDiv = styled.div`
  position: relative;
  min-height: 100vh;
  padding: 5vh;
  background: ${props => props.diffBackgroundColorRoutes && '#f5f5f5'};
`;

const NewHeader = styled(Layout.Header)`
  height: 44px;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(32, 32, 32, 0.1);
  @media only screen and (max-width: 480px) {
    padding: 0;
  }
`;
