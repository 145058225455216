import gql from 'graphql-tag';
import { TEAM_TYPE } from './TeamType.gql';

export const TEAM_BY_TEAM_ID_QUERY = gql`
  ${TEAM_TYPE}
  query TeamByTeamId($teamId: ID!) {
    TeamByTeamId(teamId: $teamId) {
      ...TeamType
    }
  }
`;
