import gql from 'graphql-tag';
import { ORGANIZATION_TYPE_CONNECTION } from '../../organization/graphql/OrganizationTypeConnection.gql';
import { VERTICAL_TYPE_CONNECTION } from '../../organization/graphql/VerticalTypeConnection.gql';
import { TEAM_TYPE_CONNECTION } from '../../organization/graphql/TeamTypeConnection.gql';

import { GROUP_TYPE_CONNECTION } from './GroupTypeConnection.gql';

export const SURVEY_TYPE = gql`
  ${ORGANIZATION_TYPE_CONNECTION}, ${VERTICAL_TYPE_CONNECTION}, ${TEAM_TYPE_CONNECTION}, ${GROUP_TYPE_CONNECTION}
  fragment SurveyType on SurveyType {
    id
    name
    description
    termsConditions
    shuffle
    public
    activeFrom
    activeTo
    reportQuery
    organization {
      ...OrganizationTypeConnection
    }
    vertical {
      ...VerticalTypeConnection
    }
    team {
      ...TeamTypeConnection
    }
    pubDate
    groupSet {
      ...GroupTypeConnection
    }
  }
`;
