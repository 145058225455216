import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import CustomCommitFormComponent from "../containers/CustomCommitFormComponent";
import CommitmentsList from "../containers/CommitmentsList";

const PersonalEditCommitmentView = (props) => {
  const {
    defaultCustomUserCommitments,
    loading = false,
    customUserCommitments,
    allDefaultCommitmentsProgress,
    onDelete,
    dashboard,
    commitmentKey = "commitment",
    forUser,
    isManageComms,
    setIsManageComms
  } = props;
  const [visible, setVisible] = useState(false);
  const [customCommitment, setCustomCommitment] = useState(false);
  
  useEffect(() => {
    if (isManageComms) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [isManageComms])

  return (
    <>
      <Button style={{display:'none',boxShadow:'0px 4px 11.800000190734863px 0px rgba(165, 196, 43, 0.2)'}} type="link" onClick={() => setVisible(true)}>
        Add Commitment
        {/* <ArrowRightOutlined /> */}
      </Button>
      <Modal
        footer={null}
        centered
        visible={visible}
        width={"60%"}
        destroyOnClose={true}
        onCancel={() => {
          setVisible(false);
          setIsManageComms(false);
          setCustomCommitment(false);
        }}
        closeIcon={<img style={{width:'29%',height:'29%',marginTop:'0.6em',marginRight:'0.2em'}} src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1702892008/WorkplaceResilienceProduction/Other/Images/Vector_gw1nfd.svg" />}
      >
        {!customCommitment && (
          <CommitmentsList
            defaultCustomUserCommitments={defaultCustomUserCommitments}
            allDefaultCommitmentsProgress={allDefaultCommitmentsProgress}
            loading={loading}
            customUserCommitments={customUserCommitments}
            onDelete={onDelete}
            dashboard={dashboard}
            commitmentKey={commitmentKey}
            forUser={forUser}
          />
        )}

        {dashboard !== 'manager-personal' && (
          <CustomCommitFormComponent
            visible={customCommitment}
            setVisible={setCustomCommitment}
          />
        )}
      </Modal>
    </>
  );
};

export default PersonalEditCommitmentView;
