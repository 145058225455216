import gql from 'graphql-tag';
import { TAG_TYPE } from './TagType.gql';

export const TAG_BY_ID_QUERY = gql`
  ${TAG_TYPE}
  query tagById($tagId: ID!) {
    tagById(tagId: $tagId) {
      ...TagType
    }
  }
`;
