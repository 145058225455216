import gql from 'graphql-tag';
// import { DETAILED_TOKEN_LIST_TYPE } from './DetailedTokenListType.gql';

//    ${DETAILED_TOKEN_LIST_TYPE}
export const GET_ALL_TOKENS_LIST_QUERY = gql`
  query getAllTokensList(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $user: ID
    $survey: ID
    $createdBy: ID
    $invited: Boolean
    $valid: Boolean
  ) {
    getAllTokensList(
      first: $first
      offset: $offset
      orderBy: $orderBy
      user: $user
      survey: $survey
      createdBy: $createdBy
      invited: $invited
      valid: $valid
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          # ...DetailedTokenListType
          id
          user {
            id
            username
            firstName
            lastName
            email
            profile {
              phoneNumber
            }
          }
          invited
          createdBy {
            firstName
            lastName
          }
          valid
          dateCreated
          survey {
            id
            name
          }
          link
        }
      }
    }
  }
`;
