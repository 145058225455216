import gql from 'graphql-tag';
import { TEAM_TYPE_CONNECTION } from './TeamTypeConnection.gql';

export const TEAMS_BY_VERTICAL_ID_QUERY = gql`
  ${TEAM_TYPE_CONNECTION}
  query TeamsByVerticalId(
    $verticalId: ID!
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
    $employees_Icontains: [ID]
    $vertical: ID
  ) # $organization: ID
  {
    TeamsByVerticalId(
      verticalId: $verticalId
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
      employees_Icontains: $employees_Icontains
      vertical: $vertical
    ) # organization: $organization
    {
      ...TeamTypeConnection
    }
  }
`;
