import gql from 'graphql-tag';
import { COACHING_COMMITMENT_TYPE } from '../../dashboard/graphql/CoachingCommitmentType.gql';

export const ADD_CUSTOMIZED_COACHING_COMMITMENT_MUTATION = gql`
  ${COACHING_COMMITMENT_TYPE}
  mutation addCustomizedCoachingCommitment($coachingCommitmentData:CoachingCustomizedCommitmentInput!, $forUserId: Int!) {
    createCustomizedCoachingCommitment(coachingCommitmentData: $coachingCommitmentData, forUserId: $forUserId) {
      coachingCommitment {
        ...CoachingCommitmentType
      }
    }
  }
`;
