import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Row, Col, Card, Empty, Spin } from 'antd';
import { Link } from 'react-router-dom';

import { compose } from '../../core';
import { getIntFromString, getUniqueListBy } from '../../look';
import { withLookToolFilterUpdating } from '../../look/containers/LookOperations';

import TOOL_ROUTE from '../../tool/route';

export const ALL_PUBLIC_TOOLS_QUERY = gql`
  query getAllPublicTools($first: Int, $orderBy: [String], $title_Icontains: String) {
    getAllPublicTools(first: $first, orderBy: $orderBy, title_Icontains: $title_Icontains) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const GET_ALL_ORG_LVL_TOOLS_QUERY = gql`
  query getAllOrgLevelTools($first: Int, $orderBy: [String], $title_Icontains: String) {
    getAllOrgLevelTools(first: $first, orderBy: $orderBy, title_Icontains: $title_Icontains) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const GET_ALL_VERTICAL_LVL_TOOLS_QUERY = gql`
  query getAllVerticalLevelTools($first: Int, $orderBy: [String], $title_Icontains: String) {
    getAllVerticalLevelTools(first: $first, orderBy: $orderBy, title_Icontains: $title_Icontains) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const GET_ALL_TEAM_LVL_TOOLS_QUERY = gql`
  query getAllTeamLevelTools($first: Int, $orderBy: [String], $title_Icontains: String) {
    getAllTeamLevelTools(first: $first, orderBy: $orderBy, title_Icontains: $title_Icontains) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

const ToolCommitmentAutoComplete = props => {
  const {
    loading,
    getAllPublicTools,
    getAllOrgLevelTools,
    getAllVerticalLevelTools,
    getAllTeamLevelTools,
    margin,
    onTitleChange,
    onFiltersRemove
  } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const choices =
    !loading &&
    getUniqueListBy(
      [].concat.apply(
        [],
        [
          getAllPublicTools?.edges?.map(({ node }) => ({ title: node.title, value: getIntFromString(node.id) })) || [],
          getAllOrgLevelTools?.edges?.map(({ node }) => ({ title: node.title, value: getIntFromString(node.id) })) || [],
          getAllVerticalLevelTools?.edges?.map(({ node }) => ({ title: node.title, value: getIntFromString(node.id) })) ||
            [],
          getAllTeamLevelTools?.edges?.map(({ node }) => ({ title: node.title, value: getIntFromString(node.id) })) || []
        ]
      ),
      'value'
    );

  return (
    <Spin spinning={loading} size="large">
      {choices && choices?.length > 0
        ? choices?.map((node, idx) => (
            <>
              <Row gutter={24} align="middle">
                <Col span={2} align="center">
                  <span style={{ borderRadius: '50%', padding: '10px 13px', border: '1px solid #CACACA' }}>{idx + 1}</span>
                </Col>
                <Col span={21}>
                  <Link to={`${TOOL_ROUTE.genericToolLink}${getIntFromString(node.value)}`} target="_blank">
                    <Card
                      bordered={false}
                      style={{ boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)', borderRadius: '10px' }}
                      bodyStyle={{ padding: '12px' }}
                    >
                      <Row justify="space-between" gutter={[12, 12]}>
                        <Col span={24}>
                          <Hoverable /* style={{ whiteSpace: 'nowrap', overflow: 'scroll' }} */>{node?.title}</Hoverable>
                        </Col>
                      </Row>
                    </Card>
                  </Link>
                </Col>
                <Col span={1} />
              </Row>
              <br />
            </>
          ))
        : choices?.length === 0 && (
            <div align="center">
              <Empty />
            </div>
          )}
    </Spin>
  );
};

export default compose(
  // withLookToolState,

  graphql(ALL_PUBLIC_TOOLS_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllPublicTools, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllPublicTools, subscribeToMore, updateQuery };
    }
  }),

  graphql(GET_ALL_ORG_LVL_TOOLS_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllOrgLevelTools, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllOrgLevelTools, subscribeToMore, updateQuery };
    }
  }),

  graphql(GET_ALL_VERTICAL_LVL_TOOLS_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllVerticalLevelTools, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllVerticalLevelTools, subscribeToMore, updateQuery };
    }
  }),

  graphql(GET_ALL_TEAM_LVL_TOOLS_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllTeamLevelTools, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllTeamLevelTools, subscribeToMore, updateQuery };
    }
  }),

  withLookToolFilterUpdating
)(ToolCommitmentAutoComplete);
const Hoverable = styled.div`
  white-space: nowrap;
  overflow: hidden;
  :hover {
    white-space: unset;
    overflow: unset;
    cursor: pointer;
  }
`;
