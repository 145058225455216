import gql from 'graphql-tag';
import { TOOL_TYPE } from './ToolType.gql';

export const TOOL_BY_ID_QUERY = gql`
  ${TOOL_TYPE}
  query getToolById($toolId: ID!) {
    getToolById(toolId: $toolId) {
      ...ToolType
    }
  }
`;
