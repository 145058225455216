import gql from 'graphql-tag';
import { SURVEY_TYPE, SURVEY_TYPE_FOR_TITLES } from './SurveyType.gql';

export const ALL_ORG_SURVEY_BY_IMPLICIT_ORG_ID_QUERY = gql`
  ${SURVEY_TYPE}
  query allOrgSurveysByImplicitOrgId(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
  ) {
    allOrgSurveysByImplicitOrgId(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...SurveyType
        }
      }
      totalCount
      edgeCount
    }
  }
`;

// BELOW IS FOR TAKE ASSESSMENT IN DASHBOARD
export const ALL_ORG_SURVEY_BY_IMPLICIT_ORG_ID_QUERY_FOR_TA = gql`
  query allOrgSurveysByImplicitOrgId(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
  ) {
    allOrgSurveysByImplicitOrgId(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
        }
      }
      totalCount
      edgeCount
    }
  }
`;

export const ALL_ORG_SURVEY_TITLES_BY_IMPLICIT_ORG_ID_QUERY = gql`
  query allOrgSurveysByImplicitOrgId(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
  ) {
    allOrgSurveysByImplicitOrgId(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
        }
      }
      totalCount
      edgeCount
    }
  }
`;

