import React, { useState } from "react";

import { compose } from "../../core";
import CommitmentView from "../../dashboard/components/CommitmentView";
import {
  withEditCustomUserCommitment,
  withEditDefaultToolCommitment,
  withDeleteCustomUserCommitment
} from "../../dashboard/containers/DashboardOperations";
import { getIntFromString } from "../../look";
import { withMe } from "../../user/containers/UserOperations";
import { withApollo } from '@apollo/client/react/hoc';

// import CoachingCommitmentView from '../components/CoachingCommitmentView';
import {
  withGetAllCustomUserCommitmentsForReportingEmployee,
  withGetAllDefaultCommitmentsProgressForReportingEmployee,
  withGetMyCoachingCommitmentsProgress,
  withGetMyCoachingCommitmentsProgressWithUser,
} from "./ManagerOperations";
import { GET_MY_COACH_COMMITMENTS_PROGRESS } from "../graphql/GetMyCoachCommitmentsProgress.gql";


const CoachingCommitment = (props) => {
  const {
    setSubmitLoading,
    setManCoComm,
    editDefaultToolCommitment,
    editCustomUserCommitment,
    getMyCoachingCommitmentsProgress,
    getAllCustomUserCommitmentsForReportingEmployee,
    getAllDefaultCommitmentsProgressForReportingEmployee,
    deleteCustomUserCommitment,
    client
  } = props;

const [coachCommByUser, setCoachCommByUser] = useState()
  async function handleDefaultCommitmentProgress(values) {
    console.log('du333',values)
    setSubmitLoading(true);
    try {
      const response = await editDefaultToolCommitment(values);
      response && setSubmitLoading(false);
      return response && true;
    } catch (err) {
      setSubmitLoading(false);
      return false;
      //throw new Error(err.message);
    }
  }

  // async function handleCallCoachComm(userId){
  //   try{
  //     const response = await getMyCoachingCommitmentsProgress({forUser: userId})
  //     console.log('respons2efnc22', response)
  //   }catch(err){
  //     return false;
  //   }
  // }


   const handleCallCoachComm = async (userId) => {
    console.log('us2er54if:',userId)
    // try {
    
    const data = await client.query({
          query: GET_MY_COACH_COMMITMENTS_PROGRESS,
          variables: { forUser: userId }
        });
      data && setCoachCommByUser(data)
        // console.log('data7tfrepsosne:',data)
      // }
    //  catch (e) {
      // console.error(e.message);
      // throw new Error(e.message);
    // }
  };
  

  async function handleCustomUserCommitment(values) {
    setSubmitLoading(true);
    try {
      const response = await editCustomUserCommitment(values);
      response && setSubmitLoading(false);
      return response && true;
    } catch (err) {
      setSubmitLoading(false);
      return false;
      // throw new Error(err.message);
    }
  }

  const handleDeleteCustomUserCommitment = async (id) => {
    try {
      const response = await deleteCustomUserCommitment(id);
    } catch (err) {
      throw new Error(err.message);
    }
  };
console.log('getAllDefaultCommit3r3mentsProgressForReportingEmployee',getAllDefaultCommitmentsProgressForReportingEmployee)
  return (
    <CommitmentView
    setManCoComm={setManCoComm}
    delPersonalComm={handleDeleteCustomUserCommitment}
    
    coachCommByUser={coachCommByUser}
      customUserCommitments={getAllCustomUserCommitmentsForReportingEmployee}
      defaultCustomUserCommitments={
        getAllDefaultCommitmentsProgressForReportingEmployee
      }
      callByUserId={handleCallCoachComm}
      onDefaultCommitmentProgress={handleDefaultCommitmentProgress}
      onCustomUserCommitment={handleCustomUserCommitment}
      {...props}
    />
  );
};

export default compose(
  withApollo,
  withEditDefaultToolCommitment,
  withGetMyCoachingCommitmentsProgressWithUser,
  withGetAllCustomUserCommitmentsForReportingEmployee,
  withGetAllDefaultCommitmentsProgressForReportingEmployee,
  withEditCustomUserCommitment,
  withDeleteCustomUserCommitment
)(CoachingCommitment);
