import React from 'react';
import { Form, Slider } from 'antd';

const FormItem = Form.Item;

const RangeField = props => {
  const { min, max, marks, defaultValue, step, onAfterChange, trackStyle, handleStyle, disabled, ...rest } = props;

  return (
    <div style={{ marginBottom: '5vh', marginRight:'5vw' }}>
      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} {...rest}>
        <Slider
          min={min}
          max={max}
          marks={marks}
          defaultValue={defaultValue}
          step={step}
          onAfterChange={onAfterChange}
          trackStyle={trackStyle}
          handleStyle={handleStyle}
          disabled={disabled}
        />
      </FormItem>
    </div>
  );
};

export default RangeField;
