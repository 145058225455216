import React, { useEffect } from 'react';
import { Row, Col, Button, Form, DatePicker, Card, Modal } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import '../css/CustomCommitFormComponentView.css'

import { TextField, CKEditorField, ButtonsComponent, ToolCommitmentAutoComplete } from '../../look';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';

export const CKEditorFieldName = 'description';

const CustomCommitFormComponentView = props => {
  const { onSubmit, setDueDate, visible, setVisible, selectedTip,setDescForm } = props;
  const [button, setButton] = React.useState('');

  function disabledDate(current) {
    // If the date is before today (including today), disable it
    return current && current < moment().startOf('day');
  }

  const initialValue = {
    title: selectedTip,
    description: selectedTip,
    // toolId: customUserCommitment?.toolId
  };

  // useEffect(() => {
  //   if (initialValue?.description) {
  //     setDescForm(initialValue?.description)
  //   }
  //   // console.log('initia44lValu1e',initialValue)
  // }, [initialValue?.description])

  const handleEditDate = async (e, field) => {
    setDueDate(e)
  };
  // console.log('initia44lValu1e', initialValue)
  return (
    visible &&
    <Modal
    footer={null}
    centered
    visible={visible}
    bodyStyle={{padding:'0px'}}
    width={"60%"}
    destroyOnClose={true}
    onCancel={() => {
      setVisible(false);
      // setIsManageComms(false);
      // setCustomCommitment(false);
    }}
    closeIcon={<img style={{width:'29%',height:'29%',marginTop:'0.6em',marginRight:'0.2em'}} src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1702892008/WorkplaceResilienceProduction/Other/Images/Vector_gw1nfd.svg" />}
  >
      <Card

        // style={{ width: 'max-content' }}
      >
          <Row>
            <p className='text_inp_add_comm'>Add a Commitment</p>
          </Row>
          <Col className='master_col' span={24} offset={0}>
            <Form
              layout="vertical"
              initialValues={initialValue}
              onFinish={values => onSubmit(values, button)}
              required={false}
              scrollToFirstError={true}
            >
              <Row gutter={24} className='main_row'>
                <Col span={24} >
                  <TextField
                    className='text_inp_add_comm'
                    
                    name={'title'}
                    label="Title"
                    rules={[{ required: true, message: 'Title is required!' }]}
                    offset={0}
                  />
                </Col>
                <Row style={{ justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: '1.5em', padding: '0 12px' }}>
                  <Col className='tool_add_comm' span={12} style={{ width: '90%' }}>
                    <ToolCommitmentAutoComplete
                      name="toolId"

                      offset={0}
                      label="Tool"
                      rules={[{ required: true, message: 'Tool is required!' }]}
                      style={{ width: '100%' }}


                    />
                  </Col>
                  <Col span={11} style={{ height: '100%' }}>
                    <p style={{ marginBottom: '8px', fontWeight: '600', fontFamily: 'Poppins, sans-serif', fontSize: '1.1em' }}>Set an end date for your commitment</p>
                    <FormItem style={{ marginBottom: '0px' }}>
                      <DatePicker
                        disabledDate={disabledDate}
                        name='dueDate'
                        label="dueDate"
                        allowClear={false}
                        style={{ color: '#84A100', borderRadius: 5, width: '100%' }}
                        format="YYYY-MM-DD"
                        size="large"
                        onChange={e => handleEditDate(new Date(e._d), 'activeTo')}
                        suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: '#84A100' }} />}
                        className='dpick_add_comm'

                      />
                    </FormItem>
                  </Col>
                </Row>



              </Row>
              <Col style={{ marginLeft: '0' }} span={24} offset={0} className="ckeditor-parent">
                <TextArea
                onChange={(e)=>setDescForm(e?.target?.value)}
                  name={CKEditorFieldName} style={{ marginLeft: '0', resize:'none', height:'11em', fontWeight:'normal', fontFamily:'poppins, sans-serif' }} className='desc_input_text' label="Description" />
              </Col>
              <Row className='add_btn_green'>
                <ButtonsComponent style={{ backgroundColor: '#81b0a8' }} title={'Add'} setButton={setButton} saveadd={false} saveedit={false} />
              </Row>
            </Form>
          </Col>
      </Card>
    </Modal>




  );
};

export default CustomCommitFormComponentView;
